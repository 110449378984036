<template>
  <div class="sign-record-container">
    <div class="record-title">
      <!-- <h3>{{ type === '全部' ? '签到记录' + (monthYear ? '(' + monthYear + ')' : '') : '今日任务' }}</h3> -->
      <h3>{{ type === '全部' ? '签到记录' : '今日任务' }}</h3>
      <span class="sign-setting" @click="toSetting">签到设置</span>
    </div>
    <div v-if="type === '全部'" class="clock-info">
      <p class="clock-tip">
        目标{{ detailInfo.targetDays }}天,已坚持{{ detailInfo.totalSignDays }}天,{{ detailInfo.targetDays - detailInfo.totalSignDays > 0 ? '剩余' + (detailInfo.targetDays - detailInfo.totalSignDays) + '天' : '已完成任务' }}
      </p>
      <div class="clock-progress">
        <div class="progress">
          <span class="progress-total"
            :style="'width:calc(' + (detailInfo.totalSignDays / detailInfo.targetDays) * 100% + ')'">
          </span>
          <span class="progress-portion"
            :style="'width:calc(' + (detailInfo.totalSignDays / detailInfo.targetDays)  + '* 100%);background-color: #52B26B;'">
          </span>
        </div>
      </div>
    </div>
    <custom-calendar 
      v-if="calendarData.length > 0" 
      ref="calendar"
      :calendarData="calendarData"
      :type="type"
      :userInfo="userInfo"
      :recordInfo="recordInfo"
      @sendObj="changeInfo"
      @clock="clockPreTask"
      @change="resetUserInfo"
      @action="action"
    ></custom-calendar>
  </div>
</template>

<script>
import calendar from '@/components/calendar';
import { getWechatUserInfo } from '@/api/common';
import clockSetting from './clockSetting.vue';

export default {
  components: {
    'custom-calendar': calendar,
    'clock-setting': clockSetting
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    type: {
      type: String,
      require: true
    },
    calendarData: {
      type: Array,
      require: true
    },
    detailInfo: {
      type: Object,
      default: () => {}
    },
    recordInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showSetting: true,
      monthYear: ''
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  watch: {
    monthYear: {
      deep: true,
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (oldVal) {
          this.changeCalendarData(newVal);
        }
      }
    }
  },
  methods: {
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
    changeInfo(val) {
      this.monthYear = val;
    },
    changeCalendarData(date) {
      this.$emit('change', date);
    },
    clockPreTask(id) {
      this.$emit('clock', id);
    },
    toSetting() {
      this.$router.push({
        name: 'PaySetting',
        query: {
          circleId: this.id,
        }
      });
    },
    action(id) {
      this.$emit('clock', id);
    }
  }
};
</script>

<style lang="scss" scoped>
  .sign-record-container {
    margin: 18px 15px;
    background: #fff;
    box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.1);
    border-radius: 10px;
    overflow: hidden;
    .record-title {
      // line-height: 28px;
      position: relative;
      padding: 10px 12px 0;
      h3 {
        font-size: 14px;
        color: #333;
      }
      .sign-setting {
        color: #666;
        font-size: 12px;
        position: absolute;
        top: 10px;
        right: 12px;
        line-height: 18px;
      }
    }
    .clock-info {
      padding: 0 12px;
      .clock-tip {
        color: #666;
        font-size: 11px;
        margin: 9px 0;
      }
      .clock-progress {
        .progress {
          position: relative;
          border-radius: 2.5px;
          overflow: hidden;
          height: 5px;
          background: #BDEBE5;
          span {
            display: inline-block;
            height: 100%;
            background: #cbcbcb;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 2.5px;
          }
          .progress-total {
            opacity: 0.15;
            width: 100%;
            z-index: 1;
          }
          .progress-portion {
            z-index: 2;
          }
        }
      }
    }
  }
</style>
