<template>
    <div>
        <vant-popup  position="bottom" round v-model="show">
        <div style="width:40px;height:4px;background-color:#cdcdcd;margin-top:10px;margin-bottom:5px;margin-left:calc(50% - 20px);border-radius:3px;" @click="toggleShow"></div>
    <vant-cell-group>
        <vant-cell center style="border-bottom:1px solid #eee;">
          <template #default>
           <div style="text-align:center;">
            <span style="font-size:16px;font-weight:bold;margin:0 auto;">100万+用户在用的事项提醒工具</span>
           </div>
          </template>
        </vant-cell>
      </vant-cell-group>
	  
      <vant-cell-group style="margin-bottom:20px;" >
        <vant-cell center  :border="false" style="padding-top:20px;padding-bottom:0;">
            <template #default>
			  <div style="text-align:center;">
				<span style="font-size:14px;color:#666;">在「小提醒Pro」小程序打开享有更好体验</span>
			  </div>
			</template>
        </vant-cell>
        <vant-cell center  :border="false">
            <template #default>
              <div style="text-align:center;">
                <vant-button @click="onMiniprogram" type="primary" icon="miniprogram-o" icon-position="right" size="large" block style="background:#51b26b;border-radius:5px;width:76%;margin:0 auto;height:42px;">在小程序打开</vant-button>
              </div>
            </template>
        </vant-cell>
      </vant-cell-group>
  </vant-popup>
    </div>
</template>

<script>
import { Icon, Popup,Cell,CellGroup,Button } from 'vant';
import wxJsConfig from '@/utils/wxJsConfig.js';
export default {
    props:{
        
    },
    components: {
        'vant-icon': Icon,
        'vant-popup': Popup,
        'vant-cell': Cell,
        'vant-cell-group': CellGroup,
        'vant-button': Button,
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        this.isMiniprogram();
    },
    methods:{
        isMiniprogram(){
            return;//打开注释将关闭小程序提示框
            var ua = window.navigator.userAgent.toLowerCase();
            let that = this;
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //微信环境下
                wx.miniProgram.getEnv(function (res) {
                    // alert(JSON.stringify(res))
                if (res.miniprogram) {
                    // 小程序环境下逻辑
                    console.og("小程序环境下")
                    that.show = false;
                    return;
                } else {
                    //非小程序环境下逻辑
                    console.log("非小程序环境下")
                    that.show = true;
                    return;
                }
                });
            }else{
                that.show = true;
                return;
            }
        },
        onMiniprogram(){
            console.log("onMiniprogram")
            window.location.href = "weixin://dl/business/?appid=wxc392238190ea9ebb&path=pages/webview/webview"
        },
        launchHandle(e){
            console.log("launchHandle",e)
        },
        errorHandle(e){
            console.log("errorHandle",e)
        },
        toggleShow(){
            this.show = false
        }
    }
}
</script>
<style lang="scss" scoped>
	[class*=van-hairline]:after{
	    border: none;
	}
	.van-button__text{
		font-size: 16px;
	}
</style>