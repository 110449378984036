<template>
  <div class="search-box" >
    <div v-if="!selectType" class="input-box" style="max-width: 640px;">
      <vant-field type="text" v-model="searchVal" placeholder="请输入您要搜索的圈名或关键字"></vant-field>
      <span class="search-btn" @click="searchList">搜索</span>
      <span class="cancel-btn" @click="closePage">取消</span>
    </div>
    <div v-if="!isSearch && !selectType" style="max-width: 640px;" class="recommend-box">
      <p class="recommend-title">全部分类</p>
      <ul class="recommend-type-list">
        <li 
          v-for="item in selectList"
          :key="item.id"
          class="list-item"
          @click="selectRecommendType(item.id)"
        >
          {{ item.cateName }}
          </li>
      </ul>
    </div>
    <div 
      v-if="!isSearch && selectType" 
      class="recommend-box"
      style="display: flex; align-items:center;"
    >
      <ul class="recommend-type-list" style="flex: 1;flex-wrap: nowrap; overflow-x: auto;">
        <li 
          v-for="(item, index) in selectList"
          :key="index"
          :class="['list-item', {'active': selectType === item.id}]"
          :style="'flex-shrink: 0;margin-right: 10px;'"
          @click="selectRecommendType(item.id)"
        >
          {{ item.cateName }}
        </li>
      </ul>
      <span class="cancel-btn" @click="closePage">取消</span>
    </div>
    <vant-list 
      class="search-circle-list"
      :finished="noMore"
      @load="loadMore"
    >
      <div
        v-for="item in circleList"
        :key="item.id"
        @click="toDetail(item.id)"
      >
        <circle-item :item="item" @join="joinNow(item)"></circle-item>
      </div>
      <div v-if="(isSearch || selectType) && circleList.length === 0" class="none-data">
        <img src="../../assets/images/remind/none.png">
        <p class="none-tip">暂无圈子！</p>
      </div>
    </vant-list>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { Field, List } from 'vant';
import { getCircleList } from '@/api/find/index.js';
import circleItem from './circleItem';
import loading from '@/components/loading.vue';

export default {
  props: {
    recommendTypeList: {
      type: Array,
      require: true
    }
  },
  components: {
    'vant-field': Field,
    'circle-item': circleItem,
    'custom-loading': loading,
    'vant-list': List
  },
  data() {
    return {
      searchVal: '',
      isSearch: false,
      circleList: [],
      selectList: [],
      selectType: '',
      loading: false,
      noMore: false,
      page: 1,
      pageSize: 10
    };
  },
  watch: {
    selectType(newValue, oldValue) {
      if (!oldValue) {
        let index, obj = {};
        this.selectList.forEach((v, k) => {
          if (v.id === this.selectType) {
            index = k;
            obj = JSON.parse(JSON.stringify(v));
          }
        });
        this.selectList.splice(index, 1);
        this.selectList.splice(1, 0, obj);
      }
      this.page = 1;
      this.noMore = false;
      this.selectCircleList();
    }
  },
  mounted() {
    this.selectList = JSON.parse(JSON.stringify(this.recommendTypeList));
  },
  methods: {
    closePage() {
      this.$emit('close');
    },
    searchList() {
      this.isSearch = true;
      this.loading = true;
      getCircleList({ 
        keyword: this.searchVal,
        page: this.page,
        limit: this.pageSize
      }).then(response => {
        this.loading = false;
        if (response.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.circleList = [];
        }
        this.circleList = this.circleList.concat(JSON.parse(JSON.stringify(response)));
      }).catch(() => {
        this.loading = false;
      });
    },
    selectCircleList() {
      this.loading = true;
      getCircleList({ 
        cateId: this.selectType === -1 ? '' : this.selectType,
        page: this.page,
        limit: this.pageSize
      }).then(response => {
        this.loading = false;
        if (response.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.circleList = [];
        }
        this.circleList = this.circleList.concat(JSON.parse(JSON.stringify(response)));
      }).catch(() => {
        this.loading = false;
      });
    },
    selectRecommendType(id) {
      this.selectType = id;
      if (this.selectList[0].id !== -1) {
        this.selectList.unshift({
          id: -1,
          cateName: '全部'
        });
      }
    },
    joinNow(item) {
      this.$emit('join', item.id);
    },
    loadMore() {
      if (this.circleList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        if (this.isSearch) {
          this.searchList();
        } else {
          this.selectCircleList();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .search-box {
    position: fixed;
    background: #f9f9f9;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px 18px;
    display: flex;
    flex-direction: column;
    .input-box {
      position: relative;
      margin-bottom: 14px;
      .van-field {
        width: calc(100% - 32px);
        height: 30px;
        padding: 0 60px 0 15px;
        border: 1px solid #52B26B;
        border-radius: 15px;
        .van-field_body {
          height: 100%;
        }
      }
      .search-btn {
        width: 52.5px;
        height: 24px;
        position: absolute;
        top: 3px;
        right: 36px;
        border-radius: 12px;
        text-align: center;
        background: #52B26B;
        font-size: 12px;
        color: #fff;
        line-height: 24px;
      }
      .cancel-btn {
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        color: #333;
      }
    }
    .recommend-box {
      .recommend-title {
        color: #333;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .recommend-type-list {
        display: flex;
        flex-wrap: wrap;
        .list-item {
          width: 80px;
          height: 25px;
          line-height: 25px;
          background: rgba(0, 162, 146, 0.15);
          text-align: center;
          border-radius: 12.5px;
          font-size: 12px;
          color: #52B26B;
          margin-right: calc((100% - 320px) / 3);
          margin-bottom: 5px;
          &.active {
            background: #52B26B;
            color: #fff;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      .cancel-btn {
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        color: #333;
        margin-left: 20px;
      }
    }
    .search-circle-list {
      flex: 1;
      overflow-y: auto;
    }
  }
</style>
