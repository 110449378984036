import { request } from '@/utils/ajax.js';

//获取客服信息
export function getCustomInfo(params = {}) {
  return request({
    url: '/api/v1/user/customerCare',
    method: 'get',
    params: params,
    authorization: true
  });
}
