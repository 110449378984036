<template>
  <div class="ticket-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="ticket-content" style="max-height: calc(100% - 55px);padding: 10px 15px 0;overflow-y:auto;">
      <div class="circle-info">
        <img :src="item.avatar" style="width: 76.5px;height: 61.5px;margin-right: 12px;">
        <div class="info-content">
          <p class="info-num">订单编号：{{ item.orderNum }}</p>
          <p class="info-money">开票金额：￥{{ item.price }}</p>
        </div>
      </div>
      <div class="form-content">
        <div class="form-item">
          <span class="label">发票类型</span>
          <span class="ticket-type">增值税电子普通发票</span>
        </div>
        <div class="form-item">
          <span class="label">抬头类型</span>
          <div class="title-type">
            <vant-radio-group v-model="formInfo.titleType" @change="changeTitleType">
              <vant-radio :name="0" checked-color="#52B26B">个人或事业单位</vant-radio>
              <vant-radio :name="1" checked-color="#52B26B">企业</vant-radio>
            </vant-radio-group>
          </div>
        </div>
        <div class="form-item">
          <vant-field 
            v-model="formInfo.ticketTitle" 
            label="发票抬头" 
            :placeholder="formInfo.titleType === 0 ? '填写需要开具发票的姓名' : '填写需要开具发票的企业名称'"
          />
        </div>
        <div v-if="formInfo.titleType === 1" class="form-item">
          <vant-field v-model="formInfo.dutyParagraph" label="税号" placeholder="纳税人识别号或社会统一征信代码" />
        </div>
        <div v-if="formInfo.titleType === 1" class="form-item">
          <vant-field v-model="formInfo.bank" label="开户银行" placeholder="选填" />
        </div>
        <div v-if="formInfo.titleType === 1" class="form-item">
          <vant-field v-model="formInfo.bankAccount" label="银行账户" placeholder="选填" />
        </div>
        <div v-if="formInfo.titleType === 1" class="form-item">
          <vant-field v-model="formInfo.entAddress" label="企业地址" placeholder="选填" />
        </div>
        <div v-if="formInfo.titleType === 1" class="form-item">
          <vant-field v-model="formInfo.entPhone" label="企业电话" placeholder="选填" />
        </div>
        <div class="form-item">
          <vant-field v-model="formInfo.email" label="邮箱" placeholder="请填写邮箱地址" />
        </div>
      </div>
    </div>
    <div class="ticket-content" style="margin-top: 10px;padding: 0 15px;">
      <div class="form-content">
        <div class="form-item">
          <span class="label">发票内容</span>
          <div class="title-type">
            <vant-radio-group v-model="formInfo.ticketContent">
              <vant-radio :name="0" checked-color="#52B26B" style="margin-right: 0;">明细</vant-radio>
            </vant-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="commit-btns">
      <div class="submit-btn" @click="submitApply">提交申请</div>
      <div class="cancel-btn" @click="back">取消</div>
    </div>
  </div>
</template>

<script>
import { Field, RadioGroup, Radio, Notify } from 'vant';
import { invoice, getInvoiceInfo } from '@/api/mine/myOrders';
import utils from '@/utils/utils';

export default {
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  components: {
    'vant-field': Field,
    'vant-radio-group': RadioGroup,
    'vant-radio': Radio
  },
  mounted() {
    console.log(this.item);
  },
  data() {
    return {
      formInfo: {
        titleType: 0,
        ticketTitle: '',
        dutyParagraph: '',
        bank: '',
        bankAccount: '',
        entAddress: '',
        entPhone: '',
        email: '',
        ticketContent: 0
      }
    };
  },
  mounted() {
    this.getInvoiceInfo();
  },
  methods: {
    back() {
      this.$emit('close');
    },
    getInvoiceInfo() {
      getInvoiceInfo({ companyType: this.formInfo.titleType }).then(res => {
        this.formInfo.ticketTitle = res.header;
        this.formInfo.dutyParagraph = res.taxId;
        this.formInfo.bank = res.bank;
        this.formInfo.bankAccount = res.bankAccount;
        this.formInfo.entAddress = res.companyAddress;
        this.formInfo.entPhone = res.companyTel;
        this.formInfo.email = res.email;
      });
    },
    changeTitleType() {
      this.getInvoiceInfo();
    },
    submitApply() {
      if (!this.formInfo.ticketTitle) {
        Notify({ type: 'warning', message: '请填写发票抬头!' });
        return false;
      }
      if (!this.formInfo.email) {
        Notify({ type: 'warning', message: '请填写邮箱地址!' });
        return false;
      } else if (!utils.config.emailReg.test(this.formInfo.email)) {
        Notify({ type: 'warning', message: '手机号格式错误，请重新输入!' });
        return false;
      }
      if (this.formInfo.titleType == 1) {
        if (!this.formInfo.dutyParagraph) {
          Notify({ type: 'warning', message: '请填写税号!' });
          return false;
        }
      }
      invoice({
        orderNumber: this.item.orderNum,
        invoiceMoney: this.item.price,
        invoiceType: 0,
        header: this.formInfo.ticketTitle,
        taxId: this.formInfo.dutyParagraph,
        bankAccount: this.formInfo.bankAccount,
        bank: this.formInfo.bank,
        companyAddress: this.formInfo.entAddress,
        companyTel: this.formInfo.entPhone,
        companyType: this.formInfo.titleType,
        email: this.formInfo.email
      }).then(() => {
        Notify({ type: 'success', message: '提交成功' });
        setTimeout(() => {
          this.back();
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .ticket-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    z-index: 50;
    display: flex;
    flex-direction: column;
    padding-bottom: 110px;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
      }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .ticket-content {
      .circle-info {
        height: 77.5px;
        padding: 8px 12.5px;
        background: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        display: flex;
        margin-bottom: 10px;
        .info-content {
          flex:1;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          .info-num {
            color: #666;
          }
          .info-money {
            color: #52B26B;
            margin-top: auto;
          }
        }
      }
      .form-content {
        background: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        padding: 0 11px;
        .form-item {
          height: 45px;
          border-bottom: 1px solid #e2e2e2;
          display: flex;
          justify-content: space-between;
          &:last-child {
            border-bottom: none;
          }
          .label {
            font-size: 13px;
            color: #333;
            line-height: 45px;
          }
          .ticket-type {
            font-size: 12px;
            color: #333;
            line-height: 45px;
          }
          ::v-deep .van-field {
            height: 100%;
            padding: 0;
            text-align: right;
            .van-field__label {
              span {
                display: inline-block;
                line-height: 45px;
                font-size: 13px;
                color: #333;
              }
            }
            .van-field__control {
              text-align: right;
            }
          }
          ::v-deep .van-radio-group {
            display: flex;
            height: 100%;
            .van-radio {
              &:first-child {
                margin-right: 29px;
              }
              .van-radio__icon--round {
                height: 14px;
                .van-icon {
                  font-size: 11px;
                }
              }
              
            }
            .van-radio__label {
              color: #333;
              font-size: 12px;
            }
          }
        }
      }
    }
    .commit-btns {
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
      padding: 0 15px;
      div {
        border-radius: 5px;
        background: #52B26B;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        color: #FEFEFE;
        text-align: center;
        &:last-child {
          margin-top: 5px;
        }
        &.cancel-btn {
          background: #eaeaea;
          color: #333;
        }
      }
    }
  }
</style>
