<template>
  <div class="my-orders-container">
    <custom-back/>
    <div class="my-orders-content">
      <!-- <div class="select-list">
        <h3 class="select-title">订单状态</h3>
        <ul class="select-list-content">
          <li class="select-item" @click="selectOrders('待付款')">
            <vant-badge color="#F26A13" :content="orderCount[0] > 0 ? orderCount[0] : ''">
              <img class="item-image" src="../../../assets/images/mine/toPaid.png" style="width: 29px;height: 24px;">
            </vant-badge>
            <p class="item-name">待付款</p>
          </li>
          <li class="select-item" @click="selectOrders('待发货')">
            <vant-badge color="#F26A13" :content="orderCount[1] > 0 ? orderCount[1] : ''">
              <img class="item-image" src="../../../assets/images/mine/toDelivered.png" style="width: 29px;height: 25.5px;">
            </vant-badge>
            <p class="item-name">待发货</p>
          </li>
          <li class="select-item" @click="selectOrders('待收货')">
            <vant-badge color="#F26A13" :content="orderCount[2] > 0 ? orderCount[2] : ''">
              <img class="item-image" src="../../../assets/images/mine/toReceived.png" style="width: 28.5px;height: 26.5px;">
            </vant-badge>
            <p class="item-name">待收货</p>
          </li>
          <li class="select-item" @click="selectOrders('已完成')">
            <vant-badge color="#F26A13" :content="orderCount[3] > 0 ? orderCount[3] : ''">
              <img class="item-image" src="../../../assets/images/mine/afterSale.png" style="width: 34.5px;height: 24.5px;">
            </vant-badge>
            <p class="item-name">已完成</p>
          </li>
        </ul>
      </div>-->
      <div class="orders-list">
        <h3 class="orders-title">全部订单</h3>
        <vant-list 
          class="orders-list-content"
          :finished="noMore"
          @load="loadMore"
        >
          <div 
            v-for="(item, index) in circleList" 
            :key="index"
            class="order-list-item"
            :style="index === circleList.length - 1 ? 'border-bottom: none;': ''"
          >
            <circle-item :item="item" @click="toDetail" @pay="payOrder"></circle-item>
          </div>
          <div v-if="circleList.length === 0" class="none-data">
            <img src="../../../assets/images/remind/none.png">
            <p class="none-tip">暂无订单！</p>
          </div>
        </vant-list>
      </div>
    </div>
    <selected-orders v-if="showSelectedOrders" :type="this.selectType" @close="closeSelectedOrders"></selected-orders>
    <custom-loading :loading="loading" :content="loadingContent"></custom-loading>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import circleItem from './circleItem.vue';
import selectedOrders from './selectedOrders.vue';
import { Notify, List, Badge } from 'vant';
import { getOrderList, getOrderCount } from '@/api/mine/myOrders';
import { getWxPayConfig } from '@/api/common';
import wxJsConfig from '@/utils/wxJsConfig.js';
import loading from '@/components/loading.vue';

export default {
  components: {
    'custom-back': Back,
    'circle-item': circleItem,
    'selected-orders': selectedOrders,
    'custom-loading': loading,
    'vant-list': List,
    'vant-badge': Badge
  },
  data() {
    return {
      circleList: [],
      selectType: '',
      showSelectedOrders: false,
      loading: false,
      loadingContent: '',
      noMore: false,
      page: 1,
      pageSize: 10,
      orderCount: [0, 0, 0, 0]
    };
  },
  mounted() {
    this.getOrderCount();
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      getOrderList({ page: this.page, limit: this.pageSize }).then(res => {
        this.loading = false;
        if (res.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.circleList = [];
        }
        this.circleList = this.circleList.concat(JSON.parse(JSON.stringify(res)));
      }).catch(() => {
        this.loading = false;
      });
    },
    getOrderCount() {
      getOrderCount().then((res) => {
        this.orderCount = res.length > 0 ? res : [0, 0, 0, 0];
      });
    },
    selectOrders(type) {
      this.selectType = type;
      this.showSelectedOrders = true;
    },
    closeSelectedOrders() {
      this.selectType = '';
      this.showSelectedOrders = false;
    },
    toDetail(item) {
      this.$router.push({
        name: 'CircleDetail',
        query: {
          id: item.circleId
        }
      });
    },
    payOrder(item) {
      this.loading = true;
      this.loadingContent = '支付中...';
      this.getWxPayConfig(item.id, item.circleId);
    },
    getWxPayConfig(id, circleId) {
      getWxPayConfig({ orderId: id, payType: 0 }).then((res) => {
        this.initWxPay(res, circleId, id);
      });
    },
    initWxPay(data, circleId, orderId) {
      const that = this;
      wxJsConfig.init([
        'chooseWXPay'
      ]).then(() => {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: function (res) {
            that.closeLoading();
            if (res.errMsg == 'chooseWXPay:ok') {
              Notify({type: 'success', message: '支付成功' });
              that.$router.push({
                name: 'PaySetting',
                query: {
                  circleId: circleId,
                  orderId: orderId
                }
              });
              that.getOrderList();
            } else {
              Notify({type: 'danger', message: '支付失败' });
            }
          },
          cancel() {
            that.closeLoading();
            Notify({type: 'warning', message: '取消支付' });
          },
          fail() {
            that.closeLoading();
            Notify({type: 'danger', message: '支付失败' });
          }
        });
      });
    },
    closeLoading() {
      this.loading = false;
      this.loadingContent = '';
    },
    loadMore() {
      if (this.circleList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        this.getOrderList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-orders-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .my-orders-content {
      flex: 1;
      padding: 0 15px;
      overflow-y: hidden;
      .select-list {
        height: 77.5px;
        margin-top: 10px;
        .select-title {
          line-height: 42.5px;
          color: #333;
          font-size: 13px;
        }
        .select-list-content {
          height: 77.5px;
          display: flex;
          justify-content: space-around;
          background: #fff;
          border-radius: 5px;
          box-shadow: 0px 4px 6px 0px rgba(9, 193, 158, 0.08);
          .select-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0 11px;
            .item-name {
              color: #666;
              font-size: 12px;
              margin-top: auto;
            }
          }
        }
      }
      .orders-list {
        height: calc(100% - 60px);
        .orders-title {
          color: #333;
          font-size: 13px;
          margin: 10px 0;
        }
        .orders-list-content {
          height: calc(100% - 54.5px);
          background: #fff;
          border-radius: 5px;
          box-shadow: 0px 4px 6px 0px rgba(9, 193, 158, 0.08);
          overflow-y: auto;
          padding: 0 13px 0 11px;
          .order-list-item {
            border-bottom: 0.5px solid #eee;
          }
        }
      }
    }
    
  }
</style>
