<template>
  <div class="share-btns">
    <a class="share-btn" @click="download">
      <img src="../assets/images/find/download.png" style="width: 30px;heigth: 30px;">
      <p class="btn-name">保存到本地</p>
    </a>
    <div class="share-btn" @click="share">
      <img src="../assets/images/find/wx.png" style="width: 35px;heigth: 29.5px;">
      <p class="btn-name">微信好友</p>
    </div>
    <div class="share-btn" @click="share">
      <img src="../assets/images/find/firendCircle.png" style="width: 30px;heigth: 30.5px;">
      <p class="btn-name">朋友圈</p>
    </div>
    <div 
      class="share-btn"
      :data-clipboard-text="linkUrl"
      data-clipboard-action="copy"
      @click="copyLink"
    >
      <img src="../assets/images/find/link.png" style="width: 28px;heigth: 29px;">
      <p class="btn-name">复制链接</p>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { Notify, Dialog } from 'vant';
import config from '../../config/config.default.js';

export default {
  props: {
    id: {
      type: Number,
      require: true
    },
    status: {
      type: Boolean,
      require: true
    },
    shareImg: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      linkUrl: ''
    };
  },
  mounted() {
    this.linkUrl = config[process.env.NODE_ENV].originUrl + '/find/circleDetail?id=' + this.id;
  },
  methods: {
    copyLink() {
      const clipboard = new Clipboard('.share-btn');
      clipboard.on('success', e => {
        Notify({ type: 'success', message: '复制成功!' });
        clipboard.destroy();
      });
      clipboard.on('error', e => {
        Notify({ type: 'warning', message: '该浏览器不支持自动复制!' });
        console.log('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },
    share() {
      Dialog.alert({
        message: '请点击右上角...进行分享!',
      }).then(() => {
        // on close
      });
    },
    download() {
      Dialog.alert({
        message: '请长按保存图片!',
      }).then(() => {
        // on close
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .share-btns {
    width: 345px;
    height: 98px;
    margin: 0 15px;
    position: absolute;
    bottom: 10px;
    left: 0;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    .share-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0 14.5px;
      .btn-name {
        color: #333;
        font-size: 12px;
      }
    }
  }
</style>
