<template>
  <div class="pay-setting-container" :style="orderId ? 'padding: 10px 15px 25px;' : 'padding: 54px 15px 25px;'">
    <div v-if="!orderId" class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="circle-setting setting-box" :style="orderId ? '' : 'padding-top: 0;'">
      <h3 class="pay-title" :style="orderId ? '' : 'text-align: left;color: #333;font-size: 14px;'">{{ orderId ? '您已支付成功！请完善信息！' : '' }}</h3>
      <div class="setting-item" :style="circleRemind ? '' : 'border-bottom: none;'">
        <span class="setting-title">圈子提醒</span>
        <span class="setting-content" style="padding: 11px 0;">
          <vant-switch v-model="circleRemind" active-color="#52B26B" inactive-color="#EDEDED" @change="changeStatus"></vant-switch>
        </span>
      </div>
      <div v-if="circleRemind && cycleValue" class="picker-select-box">
        <span class="label">提醒周期</span>
        <div class="picker-content">
          {{ cycleValue }}
        </div>
      </div>
      <div v-if="circleRemind">
        <div 
          v-for="(item, index) in remindTimeList" 
          :key="index"
          class="setting-item remind-time-item"
          :style="remindTimeList.length === 1 || !remindTimeList[remindTimeList.length - 1].value ? 'border-bottom: 0;': ''"
          @click="setRemindTime(index)"
        >
          <span class="setting-title">{{ index === 0 ? '提醒时间' : '' }}</span>
          <div v-if="item.value" class="img-box" @click.stop="deleteTime(index)">
            <img src="../../../assets/images/remind/delete.png">
          </div>
          <span class="setting-content">{{ item.value || '请选择时间' }}</span>
          <span class="setting-icon"></span>
        </div>
        <div v-if="remindTimeList[remindTimeList.length - 1].value" class="setting-item">
          <div class="add-remind-time-btn" @click="addRemindTime">增加时间</div>
        </div>
      </div>
      <div v-if="circleRemind" class="setting-item" style="height: 130px;padding-top: 10px;border-bottom: none;flex-direction: column;">
        <div class="title" style="line-height: 16px;margin-bottom: 4px;">提醒方式</div>
          <vant-checkbox-group v-model="remindType">
            <vant-checkbox :name="0" checked-color="#F26A13">公众号提醒<span class="label-tip">(永久免费)</span></vant-checkbox>
            <vant-checkbox :name="1" checked-color="#F26A13">短信提醒<span class="label-tip">(1积分/次，剩余积分：{{ userInfo.score }})</span></vant-checkbox>
          </vant-checkbox-group>
      </div>
    </div>
    <div v-if="orderId && goods.goodsName" class="user-setting setting-box">
      <div class="goods-info">
        <p class="title" style="font-size: 13px;font-weight: bold;">实物领取</p>
        <div class="info-box">
          <div class="image-box">
            <img v-if="goods.goodsPic" :src="goods.goodsPic">
          </div>
          <div class="info-content">
            <p class="goods-title">{{ goods.goodsName }}</p>
            <p class="goods-price">领取费用：￥{{ goods.goodsPrice || 0 }}元</p>
          </div>
        </div>
      </div>
      <div class="setting-item">
        <span class="setting-title">收货人姓名</span>
        <vant-field v-model="name" placeholder="请选择收货人姓名" readonly="readonly" @click="selectAddress"></vant-field>
      </div>
      <div class="setting-item">
        <span class="setting-title">收货人电话</span>
        <vant-field v-model="phone" placeholder="请选择收货人电话" readonly="readonly" @click="selectAddress"></vant-field>
      </div>
      <div class="setting-item">
        <span class="setting-title">收货地址</span>
        <vant-field v-model="address" placeholder="请选择收货地址" readonly="readonly" @click="selectAddress"></vant-field>
      </div>
      <p class="tip">
        提醒：字帖邮寄预计在5个工作日内，在我的>>订单中可查看相关物流状态.
      </p>
    </div>
    <!-- <div v-if="orderId" class="concern-setting setting-box">
      <p class="concern-title">扫码关注公众号</p>
      <div class="code-box">
        <img :src="qrCode">
      </div>
      <p class="concern-desc">提醒：关注公众号后会提醒您打卡！</p>
    </div> -->
    <div class="submit-btn" @click="submitData">{{ orderId ? '提交' : '设置完成' }}</div>
    <vant-popup v-model="showTimePicker" :close-on-click-overlay="false" round position="bottom">
      <vant-datetime-picker
        type="datetime"
        :formatter="formatter"
        @confirm="confirmRemindTime"
        @cancel="closeRemindTime"
      />
    </vant-popup>
    <vant-overlay :show="showBindPhone">
      <phone-dialog v-if="showBindPhone" :phone="userInfo.phone" @close="closeDialog"></phone-dialog>
      <vant-icon name="close" @click="closeDialog(false)"></vant-icon>
    </vant-overlay>
    <vant-overlay :show="showConcern">
      <concern-dialog v-if="showConcern" :type="'签到'" :code="qrCode" @close="closeConcernDialog"></concern-dialog>
      <vant-icon name="close" @click="closeConcernDialog"></vant-icon>
    </vant-overlay>
    <address-list v-if="showAddressList" :type="'paySetting'" @close="closeAddressList"></address-list>
  </div>
</template>

<script>
import { Switch, Popup, DatetimePicker, Notify, CheckboxGroup, Checkbox, Field, Overlay, Icon } from 'vant';
import addressList from '../../mine/setting/addressList';
import phoneDialog from '../../mine/setting/phoneDialog';
import { getUserCircleReminderConfig } from '@/api/find/circleDetail';
import $ from 'jquery';
import config from '../../../../config/config.default.js';
import { getWechatUserInfo } from '@/api/common';
import concernDialog from '../../remind/concern.vue';

export default {
  components: {
    'vant-switch': Switch,
    'vant-datetime-picker': DatetimePicker,
    'vant-popup': Popup,
    'vant-checkbox-group': CheckboxGroup,
    'vant-checkbox': Checkbox,
    'vant-field': Field,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'address-list': addressList,
    'phone-dialog': phoneDialog,
    'concern-dialog': concernDialog
  },
  data() {
    return {
      circleId: 0,
      orderId: 0,
      circleRemind: false,
      cycleValue: '',
      remindTimeList: [{}],
      showTimePicker: false,
      selectTime: '',
      selectTimeIndex: 0,
      remindType: [0],
      name: '',
      phone: '',
      address: '',
      showAddressList: false,
      addressId: 0,
      qrCode: '',
      originDetail: {},
      planId: 0,
      goods: {},
      showBindPhone: false,
      showConcern: false,
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  watch: {
    remindType: {
      deep: true,
      handler() {
        if (this.remindType.indexOf(1) !== -1) {
          if (!this.userInfo.phone) {
            this.showBindPhone = true;
          }
        }
      }
    }
  },
  mounted() {
    this.circleId = this.$route.query.circleId;
    this.orderId = this.$route.query.orderId;
    this.circleRemind = this.orderId ? true : false;
    if (this.orderId) {
      document.title = '加入成功';
    }
    this.getUserCircleReminderConfig();
  },
  methods: {
    getUserCircleReminderConfig() {
      getUserCircleReminderConfig({ circleId: this.circleId }).then(res => {
        this.circleRemind = res.isOpen === 1 ? true : (this.orderId ? true : false);
        if (!Array.isArray(res.plan)) {
          this.planId = res.plan.id;
          this.addressId = res.plan.addressId || 0;
          this.remindType = res.plan.way ? res.plan.way.split(',').map(Number) : [];
          res.plan.times.forEach(v => {
            v.value = `${v.hour}:${v.minute}`;
          });
          this.remindTimeList = res.plan.times.length > 0 ? res.plan.times : [{}];
        } else if (res.plan.length === 0) {
          this.remindTimeList[0] = {
            hour: res.hour,
            minute: res.minute,
            value: `${res.hour}:${res.minute}`
          };
        }
        this.qrCode = res.qrCode;
        this.goods = res.goods;
        this.cycleValue = res.reminderPeriod;
        this.originDetail = JSON.parse(JSON.stringify(res));
        console.log(this);
      });
    },
    changeStatus() {
      this.remindType = [0];
      this.remindTimeList = [
        {
          hour: this.originDetail.hour,
          minute: this.originDetail.minute,
          value: `${this.originDetail.hour}:${this.originDetail.minute}`
        }
      ];
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    },
    addRemindTime() {
      if (this.remindTimeList[this.remindTimeList.length - 1].value) {
        this.remindTimeList.push({});
        this.setRemindTime(this.remindTimeList.length - 1);
      } else {
        Notify({ type: 'warning', message: '请先选择提醒时间' });
      }
    },
    setRemindTime(index) {
      this.selectTime = this.remindTimeList[index].value;
      this.selectTimeIndex = index;
      this.showTimePicker = true;
    },
    deleteTime(index) {
      this.remindTimeList.splice(index, 1);
      if (this.remindTimeList.length === 0) {
        this.remindTimeList = [{}];
      }
    },
    confirmRemindTime(res) {
      let value = `${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`,
        index = -1;
      this.remindTimeList.forEach((v, k) => {
        if (k !== this.selectTimeIndex) {
          if (v.value === value) {
            index = k;
          }
        }
      });
      if (index === -1) {
        this.remindTimeList[this.selectTimeIndex].value = value;
        this.remindTimeList[this.selectTimeIndex].hour = this.fullData(new Date(res).getHours());
        this.remindTimeList[this.selectTimeIndex].minute = this.fullData(new Date(res).getMinutes());
        this.showTimePicker = false;
      } else {
        Notify({ type: 'warning', message: '提醒时间已存在，请重新选择!' });
      }
    },
    closeRemindTime() {
      this.showTimePicker = false;
    },
    fullData(param) {
      return param < 10 ? '0' + param : '' + param;
    },
    selectAddress() {
      this.showAddressList = true;
    },
    closeAddressList(obj) {
      this.showAddressList = false;
      if (obj) {
        this.name = obj.receiver;
        this.phone = obj.tel;
        this.address = obj.province + obj.city + obj.district + obj.street + obj.address;
        this.addressId = obj.id;
      }
    },
    submitData() {
      const sendData = {};
      sendData.isOpen = this.circleRemind ? 1 : 0;
      sendData.circleId = this.circleId;
      sendData.orderId = this.orderId;
      if (this.circleRemind) {
        if (this.remindTimeList.length === 0) {
          Notify({ type: 'warning', message: '请添加提醒时间!' });
          return;
        } else {
          let hasEmptyData = false;
          this.remindTimeList.forEach(v => {
            if (!v.value) {
              hasEmptyData = true;
            }
          });
          if (hasEmptyData) {
            Notify({ type: 'warning', message: '请选择提醒时间!' });
            return;
          }
        }
        if (this.remindType.length === 0) {
          Notify({ type: 'warning', message: '请选择提醒方式!' });
          return;
        }
        if (this.planId > 0) {
          sendData.id = this.planId;
        }
        sendData.way = this.remindType.join(',');
        if (this.originDetail.plan && this.originDetail.plan.times) {
          let commonArr = [], originTimes = JSON.parse(JSON.stringify(this.originDetail.plan.times));
          this.remindTimeList.forEach(v => {
            if (!v.id) {
              v.type = 'add';
            } else {
              let changeData = false;
              this.originDetail.plan.times.forEach((m, k) => {
                if (v.id === m.id) {
                  commonArr.push(k);
                  if (v.value !== m.value) {
                    changeData = true;
                  }
                }
              });
              if (changeData) {
                v.type = 'edit';
              } else {
                v.type = '';
              }
            }
          });
          if (commonArr.length > 0) {
            commonArr.reverse();
            commonArr.forEach(v => {
              originTimes.splice(v, 1);
            });
          }
          originTimes.forEach(v => {
            v.type = 'del';
          });
          sendData.times = JSON.parse(JSON.stringify(this.remindTimeList.concat(originTimes)));
        } else {
          this.remindTimeList.forEach(v => {
            v.type = 'add';
          });
          sendData.times = JSON.parse(JSON.stringify(this.remindTimeList));
        }
        if (this.orderId && this.goods.goodsName) {
          if (this.addressId === 0) {
            Notify({ type: 'warning', message: '请选择提收货信息!' });
            return;
          }
          sendData.addressId = this.addressId;
        }
      } else {
        if (this.orderId && this.goods.goodsName) {
          if (this.addressId === 0) {
            Notify({ type: 'warning', message: '请选择提收货信息!' });
            return;
          }
          sendData.addressId = this.addressId;
        }
      }
      $.ajax({
        type: 'post',
        url: config[process.env.NODE_ENV].baseUrl + '/api/v1/user/setCircleReminderConfig',
        data: JSON.stringify(sendData),
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        success: (res) => {
          Notify({ type: 'success', message: '设置成功!' });
          this.showConcern = true;
          // this.resetUserInfo();
        },
        fail() {
          this.loading = false;
        },
        complete() {
          this.loading = false;
        }
      });
    },
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
        if (this.userInfo.subscribe === 0) {
          this.showConcern = true;
        } else {
          this.$router.go(-1);
        }
      });
    },
    closeDialog(status) {
      if (status) {
        this.resetUserInfo();
      } else {
        this.remindType.splice(this.remindType.indexOf(1));
      }
      this.showBindPhone = false;
    },
    back() {
      this.$router.go(-1);
    },
    closeConcernDialog() {
      this.showConcern = false;
      this.$router.go(-1);
    },
  }
};
</script>

<style lang="scss" scoped>
  .pay-setting-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
        }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .setting-box {
      background: #FFFFFF;
      box-shadow: 0px 3px 4.5px 0px rgba(170, 170, 170, 0.1);
      border-radius: 5px;
      padding: 15px 12px 0;
      margin-bottom: 10px;
      .pay-title {
        font-size: 14px;
        color: #52B26B;
        text-align: center;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .tip {
        font-size: 10px;
        color: #888;
        line-height: 18px;
      }
      .picker-select-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        .label {
          font-size: 12px;
          color: #333;
        }
        .picker-content {
          font-size: 12px;
          color: #666;
          display: flex;
          align-items: center;
          .picker-btn {
            width: 12px;
            height: 12px;
            border-left: 2px solid #ccc;
            border-bottom: 2px solid #ccc;
            -webkit-transform: translate(0,-50%) rotate(-135deg);
            transform: translate(0,-50%) rotate(-135deg);
            margin-left: auto;
            margin-top: 15px;
            margin-right: 5px;
          }
        }
      }
      .setting-item {
        border-bottom: 1px solid #e2e2e2;
        height: 50px;
        line-height: 50px;
        display: flex;
        .img-box {
          width: 50px;
          height: 50px;
          img {
            width: 12px;
            height: 12px;
            margin: 19px;
          }
        }
        .setting-title {
          color: #333;
          font-size: 13px;
          margin-right: auto;
        }
        .setting-content {
          color: #666;
          font-size: 12px;
        }
        .setting-icon {
          width: 12px;
          height: 12px;
          border-left: 2px solid #ccc;
          border-bottom: 2px solid #ccc;
          -webkit-transform: translate(0,-50%) rotate(-135deg);
          transform: translate(0,-50%) rotate(-135deg);
          margin-left: 5px;
          margin-top: 25px;
          margin-right: 5px;
        }
        .add-remind-time-btn {
          width: 71px;
          height: 22.5px;
          border-radius: 11.25px;
          line-height: 22.5px;
          text-align: center;
          color: #fff;
          font-size: 11px;
          margin: auto;
          background: #52B26B;
        }
        ::v-deep .van-field {
          flex: 1;
          padding: 0;
          input {
            text-align: right;
          }
        }
        ::v-deep .van-checkbox-group {
          width: 100%;
          height: 100px;
          .van-checkbox {
            height: 50px;
            border-bottom: 1px solid #e2e2e2;
            &:last-child {
              border-bottom: none;
            }
            .van-checkbox__icon--round {
              height: 100%;
              position: relative;
              width: 15px;
              .van-icon {
                font-size: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            
          }
          .van-checkbox__label {
            color: #666;
            font-size: 12px;
            line-height: 50px;
            position: relative;
            .label-tip {
              position: absolute;
              left: 80px;
              top: 0;
              white-space: nowrap;
              font-size: 12px;
            }
          }
        }
      }
      &.concern-setting {
        display: flex;
        flex-direction: column;
        padding: 15px 0 20px;
        font-size: 12px;
        color: #333;
        background: #fff;
        align-items: center;
        .code-box {
          width: 76.5px;
          height: 75.5px;
          margin: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .concern-desc {
          font-size: 11px;
        }
      }
    }
    .goods-info {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 16px;
      .title {
        font-size: 12px;
        color: #333;
        padding: 10px 0;
      }
      .info-box {
        display: flex;
        .image-box {
          width: 70px;
          height: 70px;
          margin-right: 10px;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info-content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          p {
            font-size: 13px;
            color: #333;
            &.goods-price {
              font-size: 10px;
              color: #52B26B;
            }
          }
        }
      }
    }
    .submit-btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      background: #52B26B;
      color: #fff;
      font-size: 13px;
      text-align: center;
      margin-top: 20px;
    }
    ::v-deep .van-datetime-picker {
      .van-picker-column {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
