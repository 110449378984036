<template>
  <div class="dynamic-container">
    <vant-list 
      class="dynamic-list"
      :finished="noMore"
      @load="loadMore"
    >
      <div v-for="(item, index) in dynamicList" :key="item.id" class="dynamic-item"
        :style="index === dynamicList.length - 1 ? 'margin-bottom: 0;border-bottom: none;' : ''"
      >
        <div class="dynamic-title">
          <div class="anchor-avatar-box">
            <img :src="item.userInfo.avatar">
          </div>
          <div class="dynamic-info">
            <p class="info-name">{{ item.userInfo.nickName }}</p>
            <p class="info-time">{{ item.createdAt }}</p>
          </div>
        </div>
        <p class="dynamic-content">{{ item.content }}</p>
        <div v-if="item.type === 0" class="dynamic-image-list">
          <div v-for="(tmp, index) in item.file" :key="index" class="dynamic-image"
            :style="item.file.length === 1 ? 'max-width: 100%;height: 200px;' : 'width: 70px;height: 70px;position:relative;'"
          >
            <img :src="tmp" :style="item.file.length > 1 ? 'position:absolute;top:50%;left: 50%;transform: translate(-50%, -50%);' : ''" @click="previewImage(item.file, index)">
          </div>
        </div>
        <div v-if="item.type === 1" class="audio-box">
          <audio v-if="!showMore" :src="item.file[0]" controls="controls" :autoplay="false"></audio>
        </div>
        <div v-if="item.type === 2" class="video-box">
          <video 
            v-if="!showMore" 
            :src="item.file[0]" 
            controls="controls" 
            :autoplay="false"
            webkit-playsinline="true"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
          ></video>
        </div>
        <div class="dynamic-other-operate">
          <custom-user v-if="item.priseInfos.length > 0" :users="item.priseInfos" @show="showMoreUsers(item.id)"></custom-user>
          <p v-if="item.priseInfos.length > 0" class="prise-tip" :style="'color: #888;font-size: 10px;margin-left:10px'">赞过</p>
          <p 
            :style="item.isPrise === 1 ? 'color: #F26C16;margin-left: auto;' : 'margin-left: auto;'" class="dynamic-operate-btn"
            @click="prise(item.id, item.isPrise === 1 ? 0 : 1)"
          >
            <img 
              v-if="item.isPrise === 1" src="../../../assets/images/find/liked.png" 
              style="margin-right: 3px;width: 16px;height: 15.5px;">
            <img 
              v-if="item.isPrise === 0" src="../../../assets/images/find/nolike.png"
              style="margin-right: 3px;width: 16px;height: 15.5px;">
            {{ item.priseNum }}
          </p>
          <p class="dynamic-operate-btn" @click="comment('', '', item.id)">
            <img src="../../../assets/images/find/remark.png" style="margin-right: 3px;">
            {{ item.commentNum }}
          </p>
        </div>
        <div v-if="item.comment.length > 0" class="remark-list">
          <p v-for="(commentItem, k) in item.comment" :key="k" class="remark-item" @click="comment(commentItem.userId, commentItem.replyUid, item.id, commentItem.id)">
            <span v-if="commentItem.replyNickname" class="remark-name">
              {{ commentItem.nickName }} <span> 回复 </span> {{ commentItem.replyNickname }}：
            </span>
            <span v-if="!commentItem.replyNickname" class="remark-name">
              {{ commentItem.nickName }}：
            </span>
            <span>{{ commentItem.comment }}</span>
          </p>
        </div>
        <more-users v-if="showMore && showMoreId === item.id" :id="item.id" :circle-id="id" :type="'点赞'" @close="closeMoreUsers"></more-users>
      </div>
      <div v-if="dynamicList.length === 0" class="none-data">
        <img src="../../../assets/images/remind/none.png">
        <p class="none-tip">暂无动态！</p>
      </div>
    </vant-list>
  </div>
</template>

<script>
import { getDynamicList, priseDynamic } from '@/api/find/circleDetail.js';
import { Notify, List, ImagePreview } from 'vant';
import user from '@/components/user.vue';
import moreUsers from '@/components/moreUsers.vue';

export default {
  props: {
    id: {
      type: Number,
      require: true
    },
    isMine: {
      type: Boolean,
      require: true
    },
    showCommentBox: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'custom-user': user,
    'more-users': moreUsers,
    'vant-list': List
  },
  data() {
    return {
      dynamicList: [],
      dynamicId: '',
      commentId: '',
      showMore: false,
      showMoreId: 0,
      noMore: false,
      page: 1,
      pageSize: 10
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.getDynamicList();
  },
  methods: {
    getDynamicList() {
      getDynamicList({ 
        circleId: this.id,
        page: this.page,
        limit: this.pageSize
      }).then(response => {
        if (response.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.dynamicList = [];
        }
        this.dynamicList = this.dynamicList.concat(JSON.parse(JSON.stringify(response)));
      });
    },
    prise(id, val) {
      priseDynamic({ topicId: id, isPrise: val }).then(() => {
        this.getDynamicList();
        if (val === 1) {
          Notify({ type: 'success', message: '点赞成功' });
        } else {
          Notify({ type: 'success', message: '取消成功' });
        }
      });
    },
    comment(uid, replyUid, id, commentId) {
      if (!this.isMine) {
        Notify({ type: 'warning', message: '您还未加入该圈子!' });
        return;
      }
      if (replyUid && replyUid > 0) {
        this.dynamicId = '';
        this.commentId = '';
        this.$emit('toggle', false);
        return;
      }
      if (uid === this.userInfo.id) {
        this.dynamicId = '';
        this.commentId = '';
        this.$emit('toggle', false);
        return;
      }
      if (id !== this.dynamicId) {
        this.dynamicId = id;
        this.$emit('toggle', true);
        if (commentId) {
          this.commentId = commentId;
        } else {
          this.commentId = '';
        }
      } else {
        if (commentId && commentId !== this.commentId) {
          this.commentId = commentId;
          this.$emit('toggle', true);
        } else {
          this.dynamicId = '';
          this.commentId = '';
          this.$emit('toggle', false);
        }
      }
    },
    clearCommentContent() {
      this.dynamicId = '';
      this.commentId = '';
    },
    showMoreUsers(id) {
      this.showMore = true;
      this.showMoreId = id;
    },
    closeMoreUsers() {
      this.showMore = false;
      this.showMoreId = 0;
    },
    loadMore() {
      if (this.dynamicList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.page += 1;
        this.getDynamicList();
      }
    },
    previewImage(files, index) {
      ImagePreview({
        images: files,
        startPosition: index,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .dynamic-container {
    height: 100%;
    padding: 13px 0 0;
    .dynamic-list {
      overflow-y: auto;
      .dynamic-item {
        margin-bottom: 19px;
        border-bottom: 1px solid #e2e2e2;
        .dynamic-title {
          display: flex;
          .anchor-avatar-box {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .dynamic-info {
            position: relative;
            flex: 1;
            .info-name {
              font-size: 13px;
              color: #666;
              line-height: 40px;
            }
            .info-time {
              font-size: 10px;
              color: #888;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
        .dynamic-content {
          margin: 12.5px 0;
          font-size: 13px;
          color: #666;
        }
        .dynamic-image-list {
          display: flex;
          .dynamic-image {
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            margin-right: calc((100% - 280px)/3);
            &:nth-child(4n) {
              margin-right: 0;
            }
            img {
              width: auto;
              height: 100%;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .audio-box {
          position: relative;
          audio {
            width: 100%;
            height: 40px;
          }
        }
        .video-box {
          position: relative;
          video {
            width: 100%;
            height: 200px;
          }
        }
        .dynamic-other-operate {
          height: 48px;
          display: flex;
          align-items: center;
          .dynamic-operate-btn {
            font-size: 12px;
            color: #8A8A8A;
            display: flex;
            align-items: center;
            &:last-child {
              margin: 0 6.5px 0 31px;
              img {
                width: 17.5px;
                height: 15px;
              }
            }
          } 
        }
        .remark-list {
          padding: 5px 7px;
          background: #F7F7F7;
          border-radius: 5px;
          margin-bottom: 12px;
          .remark-item {
            font-size: 12px;
            color: #333;
            line-height: 18px;
            margin-bottom: 5px;
            >span {
              display: inline-block;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .remark-name {
              display: inline-block;
              color: #F26C16;
              span {
                color: #333;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
</style>
