<template>
  <div class="rank-container">
    <div class="rank-type-list">
      <div 
        :class="['rank-type-item', {'active': rankType === '今日排名'}]"
        @click="changeRankType('今日排名')"
      >
        今日排名
        <div v-if="rankType === '今日排名'" class="type-line"></div>
      </div>
      <div 
        :class="['rank-type-item', {'active': rankType === '总排名'}]"
        @click="changeRankType('总排名')"
      >
        总排名
        <div v-if="rankType === '总排名'" class="type-line"></div>
      </div>
    </div>
    <div class="rank-content">
      <div class="rank-item my-rank">
        <span class="rank-num">{{ myRank.rankId }}</span>
        <img :src="myRank.avatar" class="rank-avatar">
        <h6 class="rank-name">{{ myRank.nickName}}</h6>
        <span v-if="rankType === '今日排名'" class="rank-day">{{ myRank.createdAt }}</span>
        <span v-if="rankType === '总排名'" class="rank-day">已打卡{{ myRank.serialSignNums }}天</span>
      </div>
      <ul class="rank-list">
        <li v-for="item in rankList" :key="item.rankId" class="rank-item">
          <span class="rank-num">{{ item.rankId }}</span>
          <img :src="item.avatar" class="rank-avatar">
          <h6 class="rank-name">{{ item.nickName}}</h6>
          <span v-if="rankType === '今日排名'" class="rank-day">{{ item.createdAt }}</span>
          <span v-if="rankType === '总排名'" class="rank-day">已打卡{{ item.serialSignNums }}天</span>
        </li>
        <li v-if="rankList.length === 0" class="none-data">
          <img src="../../../assets/images/remind/none.png">
          <p class="none-tip">暂无排名！</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getRankList } from '@/api/find/circleDetail';

export default {
  props: {
    id: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      rankType: '今日排名',
      allRankList: {},
      rankList: [],
      myRank: {}
    };
  },
  watch: {
    rankType() {
      if (this.rankType === '今日排名') {
        this.rankList = this.allRankList.today;
        this.myRank = this.allRankList.todayRank;
      } else if (this.rankType === '总排名') {
        this.rankList = this.allRankList.serial;
        this.myRank = this.allRankList.serialRank;
      }
    }
  },
  mounted() {
    this.getRankList();
  },
  methods: {
    getRankList() {
      getRankList({ circleId: this.id }).then(response => {
        this.allRankList = JSON.parse(JSON.stringify(response));
        this.rankList = this.allRankList.today;
        this.myRank = this.allRankList.todayRank;
      });
    },
    changeRankType(type) {
      this.rankType = type;
    }
  }
};
</script>

<style lang="scss" scoped>
  .rank-container {
    width: 345px;
    margin: 0 15px;
    height: 75%;
    position: absolute;
    top: 5%;
    left: 0;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    .rank-type-list {
      border-bottom: 1px solid #e2e2e2;
      display: flex;
      justify-content: space-around;
      background: #fff;
      height: 45px;
      margin-bottom: 5px;
      .rank-type-item {
        color: #333;
        font-size: 14px;
        line-height: 45px;
        position: relative;
        &.active {
          color: #52B26B;
          font-weight: bold;
        }
        .type-line {
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: #52B26B;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .rank-content {
      height: calc(100% - 50px);
      .rank-list {
        height: calc(100% - 60px);
        overflow-y: auto;
        padding: 0 11px;
      }
      .rank-item {
        display: flex;
        height: 55px;
        border-bottom: 1px solid #E2E2E2;
        font-size: 13px;
        color: #000003;
        align-items: center;
        &:last-child {
          border-bottom: none;
        }
        &.my-rank {
          color: #52B26B;
          border-bottom: 5px solid #f3f3f3;
          padding: 0 11px;
        }
        .rank-num {
          width: 25px;
          text-align: center;
        }
        .rank-avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin: 0 18px;
        }
        .rank-name {
          font-size: 13px;
        }
        .rank-day {
          width: 86px;
          margin-left: auto;
          font-size: 11px;
          color: #666;
        }
      }
    }
  }
</style>
