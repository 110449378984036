import { request } from '@/utils/ajax.js';

//获取手机验证码
export function sendSms(params = {}) {
  return request({
    url: '/api/v1/user/sendSms',
    method: 'post',
    params: params,
    authorization: true
  });
}

//绑定手机
export function bindPhone(params = {}) {
  return request({
    url: '/api/v1/user/bindPhone',
    method: 'post',
    params: params,
    authorization: true
  });
}

//修改用户信息
export function changeUserInfo(params = {}) {
  return request({
    url: '/api/v1/user/setUser',
    method: 'post',
    params: params,
    authorization: true
  });
}

//获取收货地址
export function getAddressList(params = {}) {
  return request({
    url: '/api/v1/user/addrList',
    method: 'get',
    params: params,
    authorization: true
  });
}

//编辑或新增收货地址
export function editAddress(params = {}) {
  return request({
    url: '/api/v1/user/addAddr',
    method: 'post',
    params: params,
    authorization: true
  });
}

//删除收货地址
export function deleteAddress(params = {}) {
  return request({
    url: '/api/v1/user/delAddr',
    method: 'post',
    params: params,
    authorization: true
  });
}
export function bindReminderPhone(params = {}) {
  return request({
    url: '/api/v1/user/bindReminderPhone',
    method: 'post',
    data: params,
    authorization: true
  });
}
export function getReminderPhone(params = {}) {
  return request({
    url: '/api/v1/user/getReminderPhone',
    method: 'get',
    data: params,
    authorization: true
  });
}
export function getReminderPhoneList(params = {}) {
  return request({
    url: '/api/v1/user/getReminderPhoneList',
    method: 'get',
    data: params,
    authorization: true
  });
}
export function delReminderPhone(params = {}) {
  return request({
    url: '/api/v1/user/delReminderPhone',
    method: 'delete',
    data: params,
    authorization: true
  });
}
export function addReminderPlan(params = {}) {
  return request({
    url: '/api/v1/reminder/addReminderPlan',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    authorization: true
  });
}
