import { request } from '@/utils/ajax.js';

//获取我的动态
export function getMyDynamic(params = {}) {
  return request({
    url: '/api/v1/user/myTopic',
    method: 'get',
    params: params,
    authorization: true
  });
}

//删除我的动态
export function deleteDynamic(params = {}) {
  return request({
    url: '/api/v1/user/delTopic',
    method: 'post',
    params: params,
    authorization: true
  });
}
