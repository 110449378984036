import { request } from '@/utils/ajax.js';

//获取我的积分记录
export function getIntegralRecord(params = {}) {
  return request({
    url: '/api/v1/user/score',
    method: 'get',
    params: params,
    authorization: true
  });
}

//用户充值
export function recharge(params = {}) {
  return request({
    url: '/api/v1/order/recharge',
    method: 'post',
    params: params,
    authorization: true
  });
}
