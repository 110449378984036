import utils from './utils';
import { getSession, getWechatUserInfo } from '@/api/common.js';
import store from '../store.js';

export function getCode(src) {
  if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
    return;
  }
  const code = getUrl('code');
  const local = src?src:location.origin+"?phone="+(localStorage.getItem('phoneTel')?localStorage.getItem('phoneTel'):"");
  if (!code || code === null) {
    window.location.href = 
      `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${utils.config.appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=da5ea65285a40dd824c57e859dd57460&connect_redirect=1#wechat_redirect`;
  } else {
     if(getloginSession(code,src)){
      return true
     }
  }
}

//获取url参数
export function getUrl(val) {
  let name,
    value,
    i,
    num = location.href.indexOf('?'),
    str = location.href.substr(num+1),
    arr = str.split('&');
  for(i = 0;i < arr.length; i++){ 
    num = arr[i].indexOf('='); 
    if(num > 0){ 
      name = arr[i].substring(0,num);
      if (name === val) {
        value = arr[i].substr(num+1);
      }
    } 
  }
  return value;
}

export function getloginSession(code,url) {
  getSession({
    code: code,
    phone:localStorage.getItem('phoneTel')?localStorage.getItem('phoneTel'):""
  }).then(response => {
    localStorage.setItem('isNew', response.isNew);
    localStorage.setItem('token', response.tokenType + ' ' + response.accessToken);
    localStorage.setItem('token_deadtime', new Date().getTime() + response.expiresIn * 1000);
    getUserInfo(url);
  }).catch(err=>{
  });
}

export function getUserInfo(url) {
  getWechatUserInfo().then(response => {
    response.isPrise = response.isPrise === 1 ? true : false;
    localStorage.setItem('userInfo', JSON.stringify(response));
    store.commit('changeUserInfo', response);
    if(url){
      location.replace(location.href)
    }
  });
}

export function refreshCode() {
  
}
