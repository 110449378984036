import { request } from '@/utils/ajax.js';

//获取我的打卡记录
export function getClockRecord(params = {}) {
  return request({
    url: '/api/v1/user/signList',
    method: 'get',
    params: params,
    authorization: true
  });
}

//兑换补签卡
export function buySignCard(params = {}) {
  return request({
    url: '/api/v1/user/buySignCard',
    method: 'post',
    params: params,
    authorization: true
  });
}
