import { request } from '@/utils/ajax.js';

//获取token
export function getSession(params = {}) {
  return request({
    url: '/api/v1/user/access',
    method: 'get',
    params: params
  });
}

//获取微信用户信息
export function getWechatUserInfo(params = {}) {
  return request({
    url: '/api/v1/user/userInfo',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取微信配置信息
export function getWxConfig(params = {}) {
  return request({
    url: '/api/v1/wechat/getSign',
    method: 'get',
    params: params,
    authorization: false
  });
}

//上传文件
export function upload(params = {}) {
  return request({
    url: '/api/v1/file/upload',
    method: 'post',
    params: params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    authorization: true
  });
}

//获取微信支付配置信息
export function getWxPayConfig(params = {}) {
  return request({
    url: '/api/v1/payment',
    method: 'get',
    params: params,
    authorization: false
  });
}

//获取收货地址列表
export function getAddressList(params = {}) {
  return request({
    url: '/api/v1/user/getAddress',
    method: 'get',
    params: params,
    authorization: false
  });
}
