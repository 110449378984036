<template>
  <div class="circle-list-item">
    <div class="image-box">
      <img :src="item.avatar" alt="" @click.stop="joinNow(item, type === 1)">
    </div>
    <div class="item-info">
      <p class="info-title-box">
        <span class="info-title">{{ item.title }}</span>
        <span v-if="item.price && item.isOut !== 1" class="info-price">{{ item.isFree === 1 ? '免费': '￥' + item.price }}</span>
        <span v-if="item.isOut === 1" class="info-quit" @click="quitCircle(item.orderId)">退出</span>
      </p>
      <p class="info-join-count">已有{{ item.joinNums }}人加入</p>
      <p class="info-detail">
        <custom-user :users="item.joinUsers" @show="showMoreUsers"></custom-user>
        <span 
          :style="item.signStatus === 1 || (item.periodNums === item.totalSignNums && type === 1) ? 'background: #E9E9E9;' : ''"
          class="operate-btn"
          @click.stop="joinNow(item, type === 1)"
        >
          {{ type === 1 ? (item.periodNums === item.totalSignNums ? '已完成' : (item.signStatus === 1 ? '已打卡' : '今日打卡')) : '立即加入' }}
        </span>
      </p>
    </div>
    <more-users v-if="showMore" :id="item.id" :circle-id="item.id" :type="'邀请'" @close="closeMoreUsers"></more-users>
  </div>
</template>

<script>
import user from '@/components/user.vue';
import moreUsers from '@/components/moreUsers.vue';

export default {
  props: {
    item: {
      type: Object,
      require: true
    },
    type: {
      type: Number,
      require: true
    },
  },
  components: {
    'custom-user': user,
    'more-users': moreUsers
  },
   data() {
    return {
      showMore: false
    };
  },
  methods: {
    joinNow(item, type) {
      this.$emit('join', { id: item.id, type: type });
    },
    quitCircle(id) {
      console.log(1);
      this.$emit('quit', id);
    },
    showMoreUsers() {
      this.showMore = true;
    },
    closeMoreUsers() {
      this.showMore = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .circle-list-item {
    height: 92px;
    display: flex;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .image-box {
      width: 115px;
      height: 92px;
      border-radius: 5px;
      margin-right: 11px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-info {
      flex: 1;
      height: 100%;
      .info-title-box {
        font-size: 13px;
        color: #333;
        line-height: 13px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        padding-top: 2px;
        .info-title {
          flex: 1;
          font-weight: bold;
        }
        .info-price {
          color: #F26A13;
          font-size: 10px;
        }
        .info-quit {
          color: #666;
          font-size: 10px;
        }
      }
      .info-join-count {
        font-size: 12px;
        color: #666;
        line-height: 18px;
      }
      .info-detail {
        display: flex;
        margin-top: 6px;
        .operate-btn {
          margin-left: auto;
          // margin-right: 5px;
          width: 100px;
          height: 25px;
          line-height: 25px;
          background: #52B26B;
          text-align: center;
          color: #fffefe;
          font-size: 12px;
          border-radius: 12.5px;
        }
      }
    }
  }
</style>
