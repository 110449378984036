<template>
    <div class="loginPhone" style="position:relative">
        <img src="../../assets/images/login/bg_top1.png" class="loginPng" v-show="loginType == 1" alt="">
        <div class="topword1">
            小提醒助手
        </div>
        <div class="topword2">
            欢迎您登录
        </div>
        <img src="../../assets/images/login/regesit.png" class="loginPng" v-show="loginType == 2" alt="">
        <div class="loginArea" >
            <img src="../../assets/images/login/tab_r.png" class="tab_r" alt="">
            <div class="loginPop">
                <div class="hearder">
                    <div :class="loginType == 1 ? 'btns active' : 'btns'">登录</div>
                    <div :class="loginType == 2 ? 'btns active' : 'btns'" @click="changeLogin(2)">注册</div>
                </div>
                <div class="bottom">
                    <div class="inps" v-for="(v, i) in form" :key="i">
                        <div>
                            <img :src="v.img" class="phoneImg" alt="">
                        </div>
                        <input 
                        :oninput="v.name=='phone'?'if(value.length>10)value=value.slice(0,11)':'if(value.length>19)value=value.slice(0,20)'" :type="v.name=='phone'?'number':v.invisible ? 'password' : 'text'" :placeholder="v.phaceholder" v-model="v.value">
                        <div v-if="v.invisibleImg">
                            <img :src="v.invisible?v.invisibleImg:v.invisibleImg2" @click="v.invisible = !v.invisible" class="invisibleImg" alt="">
                        </div>
                        <div v-if="v.type == 'code'" class="getCode" @click="getPhoneCode">
                            <span style="display:inline-block;width:15.5px;"></span>{{ phoneCodeWord }}
                        </div>
                    </div>
                    <div class="btnArea">
                        <van-button class="btnBorder" size="large" @click="loginMethod" type="primary"><span>登录</span>
                        </van-button>

                        <div class="forget">
                            <span @click="toReset">忘记密码?</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loginWechat" style="margin-top:120px;">
            <div class="top">
                <div class="splitLine"></div>
                <span>第三方登录</span>
                <div class="splitLine"></div>
            </div>
            <div class="middle" @click="weLogin">
                <img src="../../assets/images/login/icon_login_wechat.svg" alt="">
            </div>
            <div class="bottom">
                微信登录
            </div>
        </div>
        <van-dialog v-model="agreementPopal" title="用户协议、隐私政策" show-cancel-button :showConfirmButton="true"
            :theme="'round-button'" confirm-button-text="同意" confirmButtonColor="#52b26b" :showCancelButton="false" @confirm="readChecked = true">
            <div style="height:60vh;overflow:scroll;padding: 10px 18px;">
                <agreement />
            </div>
        </van-dialog>
        <van-dialog v-model="dialogPopal" title="用户协议、隐私政策" show-cancel-button @confirm="readConfirm">
            <div style="padding:10px">
                为了更好地保障您的合法权益，同事遵守相关监管要求，请您阅读并同意<font color="#51B26B" @click.stop="agreementPopal = true">用户协议、隐私政策
                </font>
            </div>
        </van-dialog>
        <custom-loading :loading="loading" />
    </div>
</template>
  
<script>
import { Checkbox } from 'vant';
import { Dialog } from 'vant';
import agreement from '../../components/agreement.vue';
import { sendSms } from '@/api/mine/setting';
import { login } from '@/api/login/login';
import loading from '@/components/loading.vue';
import { getCode } from '@/utils/commonFunction';
export default {
    components: {
        "van-checkbox": Checkbox,
        [Dialog.Component.name]: Dialog.Component,
        'custom-loading': loading,
        agreement
    },
    data() {
        return {
            agreementPopal: false,//隐私协议弹窗
            loginType: 1,//1是登录，2是注册
            readChecked: false,//选中已读
            loginArea: [{
                name: "phone",
                img: require("../../assets/images/login/phone.png"),
                phaceholder: "输入手机号",
                value: ""
            }, {
                name: "password",
                img: require("../../assets/images/login/password.png"),
                phaceholder: "输入登录密码",
                invisible: true,
                value: "",
                invisibleImg: require("../../assets/images/login/invisible.png"),
                invisibleImg2: require("../../assets/images/login/visible2.png"),
            }],
            regesit: [{
                name: "phone",
                img: require("../../assets/images/login/phone.png"),
                phaceholder: "输入手机号",
                value: ""
            }, {
                name: "phone",
                img: require("../../assets/images/login/veritification.png"),
                phaceholder: "输入验证码",
                type: 'code',
                value: ""
            }
                , {
                name: "password",
                img: require("../../assets/images/login/password.png"),
                phaceholder: "设置密码：6 ～ 24 位",
                invisible: true,
                invisibleImg: require("../../assets/images/login/invisible.png"),
                invisibleImg2: require("../../assets/images/login/visible2.png"),
                value: ""
            }],
            form: [],
            phoneCodeWord: "发送验证码",
            codeTimer: "",
            loading: false,
            dialogPopal: false,
            loginType2: ""
        };
    },
    name: "LoginPhone",
    beforeMount() {
    },
    watch: {
        '$route': { // $route可以用引号，也可以不用引号  监听的对象
            handler(to, from){
                if (localStorage.getItem('token')&&localStorage.getItem('token_deadtime')&&(localStorage.getItem('token_deadtime') > new Date().getTime())){
                    location.replace(location.origin)
                }
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }
    },
    mounted() {
        this.form = this.loginType == 1 ? this.loginArea : this.regesit;
    },
    beforeDestroy() {
        window.clearInterval(this.codeTimer);
    },
    methods: {
        changeLogin(i) {
            this.$router.push({
                path: 'Regesit',
            });
        },
        // 登录
        loginMethod() {
            if (this.form[0].value.length != 11) {
                this.$notify({ type: 'warning', message: '请输入正确的手机号' });
                return;
            }
            if (this.form[1].value.length < 6 || this.form[1].value.length > 20) {
                this.$notify({ type: 'warning', message: '密码只能为6-20位' });
                return;
            }
            this.login();
        },
        login() {
            this.loading = true;
            login({
                phone: this.form[0].value,
                password: this.form[1].value,
            }).then(res => {
                console.log(res);
                this.$notify({
                    type: "success",
                    message: "登录成功"
                })
                // localStorage.setItem('token', res.tokenType + ' ' + res.accessToken);
                localStorage.setItem('phoneTel', this.form[0].value);
                getCode();
                // localStorage.setItem('token_deadtime', new Date().getTime() + res.expiresIn * 1000);
                this.loading = false;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        // 微信登录
        weLogin() {
            if (this.loginType == 1) {
                getCode();
                return;
            };
            if (!this.readChecked) {
                this.dialogPopal = true;
                this.loginType2 = 1;
            } else {
                getCode();
            };
        },
        // 确认阅读按钮
        readConfirm() {
            this.readChecked = true;
            if (this.loginType2 == 1) {
                this.weLogin();
            } else {
                this.getRegesit();
            };
        },
        // 跳转重置界面
        toReset() {
            this.$router.push({
                path: 'ResetPass',
            });
        }
    }
};
</script>
  
<style lang="scss" scoped>
.loginPhone {
    width: 100%;
    height: 100%;
    position: relative;
    background: #FFFFFF;
    overflow: scroll;

    .loginPng {
        width: 100%;
    }

    .loginArea {
        position: relative;
        width: 345px;
        padding-bottom: 24px;
        margin-top: -74px;
        margin: -74px auto 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
        border-radius: 16px;

        .tab_l {
            width: 205px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .tab_r {
            width: 205px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .loginPop {
            position: relative;
            width: 100%;

            .hearder {
                width: 100%;
                display: flex;
            }

            .btns {
                width: 50%;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                /* identical to box height, or 122% */
                text-align: center;
                height: 60px;
                line-height: 60px;
                color: #000000;
            }

            .active {
                color: #51B26B;
            }

            .bottom {
                width: 100%;

                .inps {
                    width: 305px;
                    height: 48px;
                    background: #F7F7F7;
                    border-radius: 8px;
                    padding: 14px 15px;
                    display: flex;
                    margin: 24px auto 0;
                    box-sizing: border-box;

                    .phoneImg {
                        width: 12px;
                        margin: 0 2px;
                        vertical-align: middle;

                    }

                    .getCode {
                        border-left: 1px solid #CCCCCC;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        display: inline-block;
                        font-size: 14px;
                        line-height: 18px;
                        box-sizing: border-box;
                        color: #51B26B;
                        white-space: nowrap;
                    }

                    .img {
                        width: 16px;
                        vertical-align: middle;
                    }

                    .invisibleImg {
                        width: 16px;
                        vertical-align: middle;
                    }

                    input {
                        min-width: 0;
                        width: auto;
                        flex-grow: 1;
                        padding-left: 15px;
                        font-size: 14px;
                        outline: none;
                        border: none;
                        vertical-align: middle;
                        background-color: transparent;
                    }
                }

                .btnArea {
                    width: 100%;
                    text-align: center;

                    .btnBorder {
                        border-radius: 8px;
                        width: 305px;
                        height: 48px;
                        margin: 24px 0;
                    }

                    .forget {
                        width: 305px;
                        margin: 0 auto;
                        text-align: right;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #51B26B;
                    }

                    .enSure {
                        display: flex;
                        justify-content: center;

                        .read {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #000000;
                        }

                        .agreement {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #51B26B;
                        }
                    }
                }
            }
        }
    }

    .loginWechat {
        text-align: center;
        position: relative;
        width: 100%;
        padding-bottom: 36px;

        .top {
            .splitLine {
                display: inline-block;
                width: 40px;
                height: 1px;
                background-color: #E6E6E6;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #999999;
                margin: 0 16px;
            }
        }

        .middle {
            text-align: center;
            margin: 16px auto 8px;
            width: 40px;
            height: 40px;
            background: #52B26B;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 16px;
            }
        }

        .bottom {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            text-align: center;

            color: #000000;
        }
    }
}


</style>
<style >
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #888888!important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #888888!important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #888888!important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #888888!important;
}
.topword1{
    font-size: 24px;position:absolute;top:47px;left:30px;color: #FFFFFF;line-height: 22px;
}
.topword2{
    font-size: 18px;line-height: 28px;position:absolute;top:81px;left:30px;color: #FFFFFF;
}
</style>
  