<template>
  <div class="users-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
      <div class="invite" @click="invite">
        <img src="../assets/images/find/invite.png">
        <h3 class="invite-name">邀请</h3>
      </div>
    </div>
    <div class="users-content">
      <h3 class="users-title">{{ type === '邀请' ? '已加入成员' : '已点赞成员' }}</h3>
      <div class="users-list">
        <div v-for="(item, index) in usersList" :key="index" class="user-item">
          <div class="avatar-box">
            <img :src="item.avatar">
            <p v-if="type === '邀请'" class="avatar-tip">打卡{{ item.signDays }}天</p>
          </div>
          <p class="name">{{ item.nickName }}</p>
        </div>
      </div>
    </div>
    <vant-overlay :show="showShare">
      <share-dialog
        v-if="circleId && showShare"
        :id="circleId"
        :status="showShareBtn"
        :type="type"
        @close="closeShare"
        @change="changeShowShareBtn"
      ></share-dialog>
      <vant-icon v-if="!showShareBtn" name="close" @click="closeShare"></vant-icon>
      <share-btn 
        v-if="showShareBtn"
        :id="circleId"
        :status="showShareBtn"
        :share-img="shareImg"
      ></share-btn>
    </vant-overlay>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getJoinUsers, getPriseUsers } from '@/api/find/index';
import share from '@/components/share';
import shareBtn from '@/components/shareBtn';
import { Overlay, Icon, Notify } from 'vant';
import loading from '@/components/loading';

export default {
  props: {
    id: {
      type: Number,
      require: true
    },
    circleId: {
      type: Number,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  components: {
    'share-dialog': share,
    'share-btn': shareBtn,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'custom-loading': loading
  },
  data() {
    return {
      usersList: [],
      showShare: false,
      showShareBtn: false,
      shareImg: '',
      loading: false
    };
  },
  mounted() {
    if (this.type === '邀请') {
      this.getJoinUsers();
    } else {
      this.getPriseUsers();
    }
    
  },
  methods: {
    getJoinUsers() {
      this.loading = true;
      getJoinUsers({ circleId: this.id}).then(res => {
        this.loading = false;
        this.usersList = res;
      }).catch(() => {
        this.loading = false;
      });
    },
    getPriseUsers() {
      this.loading = true;
      getPriseUsers({ topicId: this.id}).then(res => {
        this.loading = false;
        this.usersList = res;
      }).catch(() => {
        this.loading = false;
      });
    },
    back() {
      this.$emit('close');
    },
    invite() {
      this.showShare = true;
    },
    closeShare() {
      this.showShare = false;
      this.showShareBtn = false;
    },
    changeShowShareBtn(obj) {
      this.showShareBtn = obj.status;
      this.shareImg = obj.img;
    },
  }
};
</script>

<style lang="scss" scoped>
  .users-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    z-index: 9998;
    display: flex;
    flex-direction: column;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
      }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
      .invite {
        position: absolute;
        top: 0;
        right: 15px;
        display: flex;
        height: 100%;
        align-items: center;
        img {
          width: 16px;
          height: 15px;
        }
        .invite-name {
          font-size: 14px;
          color: #333;
          margin-left: 4px;
        }
      }
    }
    .users-content {
      margin: 10px 15px;
      padding: 0 12px 36px;
      width: 345px;
      max-height: calc(100% - 64px);
      background: #fff;
      box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.1);
      border-radius: 5px;
      .users-title {
        line-height: 32px;
        font-size: 14px;
        color: #333;
      }
      .users-list {
        display: flex;
        flex-wrap: wrap;
        .user-item {
          margin-right: calc((100% - 262.5px) / 4);
          margin-top: 15px;
          width: 52.5px;
          &:nth-child(5n) {
            margin-right: 0;
          }
          .avatar-box {
            width: 52.5px;
            height: 52.5px;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .avatar-tip {
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(102, 102, 102, 0.8);
              width: 100%;
              height: 16px;
              line-height: 16px;
              color: #fff;
              font-size: 9px;
              text-align: center;
            }
          }
          .name {
            font-size: 12px;
            color: #333;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 8%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
