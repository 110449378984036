<template>
  <div class="circle-list-item">
    <div class="image-box">
      <img :src="item.avatar">
        </div>
        <div class="item-info">
          <p class="info-title-box" :style="item.orderStatus === 5 ? 'color: #666;' : ''">
            <span class="info-title">{{ item.title }}</span>
            <span 
              class="info-orderStatus" 
              :style="
                item.orderStatus === 5 || item.orderStatus === 6 ? 'color: #333;' : (
                  item.orderStatus === 0 ? 'color: #F26A13;' : 'color: #52B26B;')
              "
            >
              {{ 
                item.orderStatus === 5 ? '已过期' : (
                  item.orderStatus === 6 ? '已取消' : (
                    item.orderStatus === 0 ? '待付款' : '已付款'))
              }}
            </span>
          </p>
          <p class="info-order-number" :style="item.orderStatus === 5 ? 'color: #666;' : ''">订单编号：{{ item.orderNum }}</p>
		  <p class="info-order-time" :style="item.orderStatus === 5 ? 'color: #666;' : ''">订单时间：{{ item.orderTime }}</p>
          <p class="info-detail">
            <span class="info-price" :style="item.orderStatus === 5 ? 'color: #666;' : ''">实付款：￥{{ item.price }}</span>
            <span 
              v-if="item.orderStatus !== 6 && ((item.orderType === 2||item.orderType === 3) && item.orderStatus === 0)"
              :style="
                item.orderStatus === 0 ? 'background: #F26A13;' : (
                  item.orderStatus === 1 || item.signStatus === 1 || item.periodNums === item.totalSignNums ? 'background: #E9E9E9;' : '')
              "
              class="operate-btn"
              @click.stop="joinNow(item)"
            >
              {{ 
                item.orderStatus === 5 ? '再次购买' : (
                  item.orderStatus === 0 ? '去付款' : (item.periodNums === item.totalSignNums ? '已完成' : (item.signStatus === 1 ? '已打卡' : '今日打卡'))) 
              }}
            </span>
          </p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
        require: true
    }
  },
  methods: {
    joinNow(item) {
      if (item.orderStatus === 0) {
        this.$emit('pay', item);
      } else {
        this.$emit('click', item);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .circle-list-item {
    height: 92px;
    display: flex;
    padding: 14px 0 12px 3px;
    box-sizing: content-box;
    .image-box {
      width: 100px;
      height: 80px;
      border-radius: 5px;
      margin-right: 10px;
      overflow: hidden;
	  background: #eee;
	  text-align: center;
		  
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .item-info {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      .info-title-box {
        font-size: 13px;
        color: #333;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        .info-title {
          flex: 1;
          font-weight: bold;
		  margin-right: 10px;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;
        }
        .info-orderStatus {
          font-size: 12px;
        }
      }
      .info-order-number, .info-order-time {
        font-size: 12px;
        color: #666;
        line-height: 18px;
      }
      .info-detail {
        display: flex;
        margin-top: 5px;
		align-items: center;
		
        .info-price {
          color: #333;
          font-size: 13px;
          line-height: 18px;
        }
        .operate-btn {
          margin-left: auto;
          width: 60px;
          height: 25px;
          line-height: 25px;
          background: #52B26B;
          text-align: center;
          color: #fffefe;
          font-size: 12px;
          border-radius: 12.5px;
        }
      }
    }
  }
</style>
