import { render, staticRenderFns } from "./resetPass.vue?vue&type=template&id=020b9274&scoped=true"
import script from "./resetPass.vue?vue&type=script&lang=js"
export * from "./resetPass.vue?vue&type=script&lang=js"
import style0 from "./resetPass.vue?vue&type=style&index=0&id=020b9274&prod&lang=scss&scoped=true"
import style1 from "./resetPass.vue?vue&type=style&index=1&id=020b9274&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020b9274",
  null
  
)

export default component.exports