<template>
  <div class="calendar">
    <div class='w100P showData'>
      <div class="title">日</div>
      <div class="title">一</div>
      <div class="title">二</div>
      <div class="title">三</div>
      <div class="title">四</div>
      <div class="title">五</div>
      <div class="title">六</div>
    </div>
    <div class='content'>
      <div v-if="calendarType === 'month'" class="back-month">
        {{ currentMonth >= 10 ? currentMonth : '0' + currentMonth }}
      </div>
      <div 
        v-for="(item, index) in allArr" :key="index"
        :class="['itemData', { 'week-hidden': calendarType === 'week' && (index + 1 > weekStartIndex * 7 || index + 1 < (weekStartIndex - 1) * 7 + 1) }]"
      >
        <div :style="item.month === 'pre' || item.month === 'next' ? 'display: none;': ''">
          <p 
            class="icon" 
            :style="item.month === 'current' ? (
              item.isTodaySign === 1 ? 'background: #CCECE9' : (
                item.isTask ? 'background: #fff' : '')): ''">
            <img 
              v-if="item.isTask && item.signStatus === 1 && item.isLast === 0" 
              src="../assets/images/calendar/isClock.png"
              style="width: 15px;height: 11.5px;"
            >
            <img 
              v-if="
                item.isTask && ((
                item.signStatus === 0 && 
                (
                  currentYear < nowYear || 
                  (currentYear === nowYear && currentMonth < nowMonth) ||
                  (currentYear === nowYear && currentMonth === nowMonth && nowDate > item.day))) || item.actionStatus === 3) &&
                item.isLast === 0" 
              src="../assets/images/calendar/noClock.png"
              style="width: 12.5px;height: 12.5px;"
            >
            <img 
              v-if="
                item.isTask && 
                item.signStatus === 0 && 
                (
                  currentYear > nowYear || 
                  (currentYear === nowYear && currentMonth > nowMonth) ||
                  (currentYear === nowYear && currentMonth === nowMonth && nowDate <= item.day)) &&
                  item.isLast === 0" 
              src="../assets/images/calendar/needClock.png"
              style="width: 13px;height: 14px;"
            >
            <img 
              v-if="item.isTask && item.isLast === 1" 
              src="../assets/images/calendar/gift.png"
              style="width: 16px;height: 15.5px;"
            >
          </p>
          <p 
            v-if="
              !item.isTask ||
              item.signStatus === 1 ||
              (
                  currentYear > nowYear || 
                  (currentYear === nowYear && currentMonth > nowMonth) ||
                  (currentYear === nowYear && currentMonth === nowMonth && nowDate <= item.day)) ||
              type === '今日'" 
            :class="['date', { 'today': currentYear === nowYear && currentMonth === nowMonth && item.date === nowDate}]"
          >{{ currentYear === nowYear && currentMonth === nowMonth && item.date === nowDate ? '今日' : item.date}}</p>
          <p v-if="
            item.isTask && ((
              item.signStatus === 0 && 
                (currentYear < nowYear || 
                  (currentYear === nowYear && currentMonth < nowMonth) ||
                  (currentYear === nowYear && currentMonth === nowMonth && nowDate > item.day)) && item.actionStatus === 3)) && type === '全部'" class="date close-date" @click="clockPreTask(item.taskId)">补签</p>
        </div>
      </div>
      <div class="pre-month operate" @click="gotoPreMonth">
        <img src="../assets/images/calendar/arrow-left.png">
      </div>
      <div class="next-month operate" @click="gotoNextMonth">
        <img src="../assets/images/calendar/arrow-right.png">
      </div>
    </div>
    <div class="arrow-btn">
      <img
        src="../assets/images/mine/more.png"
        :style="calendarType === 'month' ? 'transform: rotate(180deg);' : ''"
        @click="changeCalendarType"
      >
    </div>
    <vant-overlay :show="showDialog">
      <exchange-dialog v-if="showDialog" :num="recordInfo.cardScore" @close="closeDialog"></exchange-dialog>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
  </div>
</template>

<script>
import { Dialog, Overlay, Icon, } from 'vant';
import exchangeDialog from '@/views/mine/clockRecord/exchangeDialog.vue';

export default {
  props: {
    calendarData: {
      type: Array,
      require: true
    },
    type: {
      type: String,
      require: true
    },
    userInfo: {
      type: Object,
      require: true
    },
    recordInfo: {
      type: Object,
      require: true
    }
  },
  components: {
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'exchange-dialog': exchangeDialog
  },
  data() {
    return {
      currentMonthDateLen: 0, // 当月天数
      preMonthDateLen: 0, // 当月中，上月多余天数
      currentYear: new Date().getFullYear(), // 当前显示的年
      currentMonth: new Date().getMonth() + 1, // 当前显示的月
      monthYear: new Date().getFullYear() + '-' + (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)), //当前年月
      nowYear: new Date().getFullYear(), // 当前显示的月
      nowMonth: new Date().getMonth() + 1, // 当前显示的月
      nowDate: new Date().getDate(), // 当前显示的日
      allArr:[],
      calendarType: 'week',
      weekStartIndex: 0,
      showDialog: false,
      clickPreNext: false
    };
  },
  watch: {
    calendarData: {
      deep: true,
      handler() {
        this.getAllArr();
      }
    }
  },
  mounted() {
    this.$emit('sendObj', this.monthYear);
    if (this.nowYear === this.currentYear && this.nowMonth === this.currentMonth) {
      this.weekStartIndex = (this.nowDate - this.nowDate % 7) / 7 + 1;
    } else {
      this.weekStartIndex = 1;
    }
    this.getAllArr();
    if (this.allArr[this.weekStartIndex * 7 - 1].date < this.nowDate) {
      this.weekStartIndex += 1;
    }
  },
  methods: {
    // 获取某年某月总共多少天
    getDateLen(year, month) { 
      let actualMonth = month - 1;
      let timeDistance = +new Date(year, month) - +new Date(year, actualMonth);
      return timeDistance / (1000 * 60 * 60 * 24);
    },
    // 获取某月1号是周几
    getFirstDateWeek(year, month) { 
      return new Date(year, month - 1, 1).getDay();
    },
    // 上月 年、月
    preMonth(year, month) { 
      if (month == 1) {
        return {
          year: --year,
          month: 12
        };
      } else {
        return {
          year: year,
          month: --month
        };
      }
    },
    // 下月 年、月
    nextMonth(year, month) { 
      if (month == 12) {
        return {
          year: ++year,
          month: 1
        };
      } else {
        return {
          year: year,
          month: ++month
        };
      }
    },
    // 获取当月数据，返回数组
    getCurrentArr(){ 
      let currentMonthDateLen = this.getDateLen(this.currentYear, this.currentMonth); // 获取当月天数
      let currentMonthDateArr = []; // 定义空数组
      if (currentMonthDateLen > 0) {
        for (let i = 1; i <= currentMonthDateLen; i++) {
          currentMonthDateArr.push({
            month: 'current', // 只是为了增加标识，区分上下月
            date: i
          });
        }
      }
      this.currentMonthDateLen = currentMonthDateLen;
      return currentMonthDateArr;
    },
    // 获取当月中，上月多余数据，返回数组
    getPreArr(){ 
      let preMonthDateLen = this.getFirstDateWeek(this.currentYear, this.currentMonth); // 当月1号是周几 == 上月残余天数）
      let preMonthDateArr = []; // 定义空数组
      if (preMonthDateLen > 0) {
        let { year, month } = this.preMonth(this.currentYear, this.currentMonth); // 获取上月 年、月
        let date = this.getDateLen(year, month); // 获取上月天数
        for (let i = 0; i < preMonthDateLen; i++) {
          preMonthDateArr.unshift({ // 尾部追加
            month: 'pre', // 只是为了增加标识，区分当、下月
            date: date
          });
          date--;
        }
      }
      this.preMonthDateLen = preMonthDateLen;
      return preMonthDateArr;
    },
    // 获取当月中，下月多余数据，返回数组
    getNextArr() { 
      let nextMonthDateLen = 35 - this.preMonthDateLen - this.currentMonthDateLen; // 下月多余天数
      let nextMonthDateArr = []; // 定义空数组
      if (nextMonthDateLen > 0) {
        for (let i = 1; i <= nextMonthDateLen; i++) {
          nextMonthDateArr.push({
            month: 'next',// 只是为了增加标识，区分当、上月
            date: i
          });
        }
      }
      return nextMonthDateArr;
    },
    // 整合当月所有数据
    getAllArr(){ 
      let preArr = this.getPreArr();
      let currentArr = this.getCurrentArr();
      let nextArr = this.getNextArr();
      let allArr = [...preArr, ...currentArr, ...nextArr];
      allArr.forEach(v => {
        if (v.month === 'current') {
          this.calendarData.forEach(m => {
            if (v.date === m.day) {
              v = Object.assign(v, m);
            }
          });
        }
      });
      this.allArr = allArr;
      if (this.calendarType === 'week' && this.clickPreNext) {
        this.weekStartIndex = this.weekStartIndex === 1 ? this.allArr.length / 7 : 1;
      }
      this.clickPreNext = false;
    },
    // 点击 上月
    gotoPreMonth(){ 
      if (this.calendarType === 'month') {
        let { year, month } = this.preMonth(this.currentYear, this.currentMonth);
        this.currentYear = year;
        this.currentMonth = month;
        this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
        this.clickPreNext = true;
        this.$emit('sendObj', this.monthYear);
      } else {
        if (this.weekStartIndex === 1) {
          let { year, month } = this.preMonth(this.currentYear, this.currentMonth);
          this.currentYear = year;
          this.currentMonth = month;
          this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
          this.clickPreNext = true;
          this.$emit('sendObj', this.monthYear);
        } else {
          this.weekStartIndex -= 1;
        }
      }
    },
    // 点击 下月
    gotoNextMonth() {
      if (this.calendarType === 'month') {
        let { year, month } = this.nextMonth(this.currentYear, this.currentMonth);
        this.currentYear = year;
        this.currentMonth = month;
        this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
        this.clickPreNext = true;
        this.$emit('sendObj', this.monthYear);
      } else {
        if (this.allArr.length < (this.weekStartIndex + 1) * 7) {
          let { year, month } = this.nextMonth(this.currentYear, this.currentMonth);
          this.currentYear = year;
          this.currentMonth = month;
          this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
          this.clickPreNext = true;
          this.$emit('sendObj', this.monthYear);
        } else {
          this.weekStartIndex += 1;
        }
      }
    },
    clockPreTask(id) {
      if (this.userInfo.signCardNum === 0) {
        Dialog.alert({
          title: '提示',
          message: '补签卡数量不足，请先去兑换补签卡!',
        }).then(() => {
          this.showDialog = true;
        });
      } else {
        Dialog.confirm({
          title: '提示',
          message: '补签将要消耗补签卡，确认需要补签吗？',
        }).then(() => {
          this.$emit('action', id);
        }).catch(() => {});
      }
    },
    changeCalendarType() {
      if (this.calendarType === 'month') {
        this.calendarType = 'week';
        if (this.nowYear === this.currentYear && this.nowMonth === this.currentMonth) {
          this.weekStartIndex = (this.nowDate - this.nowDate % 7) / 7 + 1;
        } else {
          this.weekStartIndex = 1;
        }
      } else {
        this.calendarType = 'month';
      }
    },
    closeDialog(type) {
      if (type) {
        this.$emit('change');
      }
      this.showDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar{
  width: 100%;
  background: #fff;
}
.pre,.next{
 color:white;
 background:#4680ff;
 border-radius: 50%;
 height:20px;
 width:20px;
 text-align:center;
 line-height: 20px;
}
.showData{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
}
.showData .title{
  width: 14.2%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  flex-shrink: 0;
  font-size: 13px;
  color: #52B26B;
}
.calendar .tit{
  display: flex;
  justify-content: center;
  align-items: center;
}
.current{
  font-size: 18px;
  color: #4680ff;
  font-weight: bold;
}
.calendar .tit .current{
  margin: 0 40px;
}
.calendar .content{
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 30px;
  position: relative;
}
.calendar .content .itemData{
  width: 14.2%;
  height: 55px;
  flex-shrink: 0;
  font-size: 14px;
  text-align:center;
  &.week-hidden {
    display: none;
  }
  >div {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    position: relative;
  }
  .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f7f7f7;
    box-shadow: 0px 6px 9px 0px rgba(168, 168, 168, 0.2);
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .date {
    color: #888;
    font-size: 10px;
    margin: 5px 0 10px;
    &.today {
      color: #52B26B;
    }
    &.close-date {
      width: 34px;
      height: 16px;
      border: 1px solid #888;
      text-align: center;
      line-height: 16px;
      border-radius: 8px;
    }
  }
}

.operate {
  width: 30px;
  padding: 0 11.25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  &.pre-month {
    left: 0;
  }
  &.next-month {
    right: 0;
  }
  img {
    width: 7.5px;
    height: 14px;
  }
}
.arrow-btn {
  height: 35px;
  position: relative;
  img {
    width: 15px;
    height: 10.5px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.back-month {
  font-family: 'serif';
  font-size: 150px;
  color: #52B26B;
  opacity: 0.08;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.van-icon-close {
  position: absolute;
  color: #fff;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);
  font-size: 28px;
}
</style>
