import { request } from '@/utils/ajax.js';

//获取圈子分类列表
export function getCircleTypeList(params = {}) {
  return request({
    url: '/api/v1/circle/cateList',
    method: 'get',
    params: params
  });
}

//获取圈子列表
export function getCircleList(params = {}) {
  return request({
    url: '/api/v1/circle/list',
    method: 'get',
    params: params,
    authorization: localStorage.getItem('token') ? true : false
  });
}

//获取我的圈子
export function getMyCircle(params = {}) {
  return request({
    url: '/api/v1/circle/myCircle',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取圈子加入人员信息
export function getJoinUsers(params = {}) {
  return request({
    url: '/api/v1/circle/joinUsers',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取点赞人员信息
export function getPriseUsers(params = {}) {
  return request({
    url: '/api/v1/circle/priseUsers',
    method: 'get',
    params: params,
    authorization: true
  });
}
