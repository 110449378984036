import axios from 'axios';
import config from '../../config/config.default.js';
import { Notify } from 'vant';
import qs from 'qs'

export function request(options) {
  const promise = new Promise((resolve, reject) => {
    try {
      if (options.authorization) {
        if (options.headers) {
          options.headers.Authorization = localStorage.getItem('token');
        } else {
          options.headers = { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': localStorage.getItem('token')
          };
        }
      } else {
        options.headers = { 
          'Content-Type': 'application/x-www-form-urlencoded'
        };
      }
      axios.request({
        url: options.url,
        params: options.params,
        data:qs.stringify(options.data),
        method: options.method || 'get',
        baseURL: config[process.env.NODE_ENV].baseUrl || '',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': localStorage.getItem('token'),
        }
      }).then(response => {
        if (response.success || response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          reject(response.msg);
        }
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token_deadtime');
          window.location.href=window.location.origin+'/login/loginBefore'
          reject(e);
        } else {
          Notify({ type: 'danger', message: e.response.data.message });
          reject(e);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
  return promise;
}
