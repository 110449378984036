<template>
  <div class="address-edit-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="address-edit-content">
      <div class="address-info">
        <div class="info-item">
          <vant-field v-model="editInfo.receiver" label="收货人" placeholder="请填写收货人" />
        </div>
        <div class="info-item">
          <vant-field v-model="editInfo.tel" label="手机号码" placeholder="请填写手机号码" />
        </div>
        <div class="info-item area" @click="showSelect">
          <vant-field 
            v-model="editInfo.area"
            label="所在地区"
            placeholder="请选择所在地区"
            readonly="readonly"
          />
        </div>
        <div class="info-item">
          <vant-field v-model="editInfo.address" label="详细地址" placeholder="请填写详细地址" />
        </div>
      </div>
      <div class="address-operate">
        <div class="operate-item">
          <span class="label">设为默认地址</span>
          <vant-switch v-model="editInfo.isDefault" active-color="#52B26B" inactive-color="#EDEDED" style="margin: 11.5px 0;"></vant-switch>
        </div>
        <div v-if="editInfo.id" class="operate-item">
          <span class="label" style="color: #52B26B;" @click="deleteAddress">删除收货地址</span>
        </div>
      </div>
      <div class="save-btn" @click="saveInfo">保存</div>
    </div>
    <vant-popup v-model="showAreaSelect" :close-on-click-overlay="false" round position="bottom">
      <vant-cascader
        v-model="editInfo.area"
        title="请选择所在地区"
        :options="areaSelectList"
        @close="showAreaSelect = false"
        @finish="onFinish"
      />
    </vant-popup>
  </div>
</template>

<script>
import { editAddress, deleteAddress } from '@/api/mine/setting';
import utils from '@/utils/utils';
import { Field, Switch, Notify, Dialog, Popup, Cascader } from 'vant';
import { getWechatUserInfo } from '@/api/common';

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'vant-field': Field,
    'vant-switch': Switch,
    'vant-popup': Popup,
    'vant-cascader': Cascader
  },
  data() {
    return {
      editInfo: {},
      isAdd: true,
      showAreaSelect: false,
      areaSelectList: []
    };
  },
  mounted() {
    this.editInfo = JSON.parse(JSON.stringify(this.item));
    this.isAdd = Object.keys(this.editInfo).length > 0 ? false : true;
    this.areaSelectList = JSON.parse(localStorage.getItem('addressList'));
  },
  methods: {
    back() {
      this.$emit('close');
    },
    showSelect() {
      this.showAreaSelect = true;
    },
    onFinish(res) {
      console.log(res);
      this.editInfo.province = res.selectedOptions[0].value;
      this.editInfo.city = res.selectedOptions[1].value;
      this.editInfo.district = res.selectedOptions[2].value;
      this.editInfo.street = res.selectedOptions[3].value;
      this.editInfo.area = this.editInfo.province + this.editInfo.city + this.editInfo.district + this.editInfo.street;
      this.showAreaSelect = false;
    },
    deleteAddress() {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除该地址吗？',
      }).then(() => {
        deleteAddress({ addrId: this.editInfo.id }).then((res) => {
          Notify({ type: 'success', message: '删除成功' });
          this.resetUserInfo();
          this.$emit('close', true);
        });
      });
    },
    saveInfo() {
      console.log(this.editInfo);
      if (!this.editInfo.receiver) {
        Notify({ type: 'warning', message: '请填写收货人!' });
        return false;
      }
      if (!this.editInfo.tel) {
        Notify({ type: 'warning', message: '请输入手机号!' });
        return false;
      } else if (!utils.config.phoneReg.test(this.editInfo.tel)) {
        Notify({ type: 'warning', message: '手机号格式错误，请重新输入!' });
        return false;
      }
      if (!this.editInfo.area) {
        Notify({ type: 'warning', message: '请选择所在地区!' });
        return false;
      }
      if (!this.editInfo.address) {
        Notify({ type: 'warning', message: '请填写详细地址!' });
        return false;
      }
      editAddress({
        receiver: this.editInfo.receiver,
        tel: this.editInfo.tel,
        province: this.editInfo.province,
        city: this.editInfo.city,
        district: this.editInfo.district,
        street: this.editInfo.street,
        address: this.editInfo.address,
        isDefault: this.editInfo.isDefault ? 1 : 0,
        addrId: this.editInfo.addrId
      }).then(res => {
        Notify({ type: 'success', message: '保存成功' });
        this.resetUserInfo();
        this.$emit('close', true);
      });
    },
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .address-edit-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    z-index: 100;
    display: flex;
    flex-direction: column;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
        }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .address-edit-content {
      flex: 1;
      padding: 10px 15px 60px;
      overflow-y: auto;
      .address-info {
        padding: 0 11px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 1px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        .info-item {
          height: 55px;
          border-bottom: 1px solid #e2e2e2;
          display: flex;
          justify-content: space-between;
          &.area {
            ::v-deep .van-field {
              .van-field__body {
                input {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
          .label {
            font-size: 13px;
            color: #333;
            line-height: 55px;
          }
          ::v-deep .van-field {
            height: 100%;
            padding: 0;
            .van-field__label {
              span {
                display: inline-block;
                line-height: 55px;
                font-size: 13px;
                color: #333;
              }
            }
          }
        }
      }
      .address-operate {
        padding: 0 11px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 1px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        margin-top: 14px;
        .operate-item {
          height: 55px;
          border-bottom: 1px solid #e2e2e2;
          display: flex;
          justify-content: space-between;
          &:last-child {
              border-bottom: none;
          }
          .label {
              font-size: 13px;
              color: #333;
              line-height: 55px;
          }
          .van-switch {
            border: none;
          }
        }
      }
      .save-btn {
        position: absolute;
        left: 15px;
        bottom: 10px;
        border-radius: 5px;
        background: #52B26B;
        width: calc(100% - 30px);
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        color: #FEFEFE;
        text-align: center;
      }
    }
  }
</style>
