import { request } from '@/utils/ajax.js';

//获取提醒计划列表
export function getPlanList(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderTimeList',
    method: 'get',
    authorization:true,
    params: params
  });
}
// 获取提醒设置
export function getReminderSetting(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderSetting',
    method: 'get',
    authorization:true,
    params: params
  });
}

//获取节假日列表
export function getHolidayList(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderFestivals',
    method: 'get',
    params: params
  });
}

//获取提醒标签
export function getReminderTags(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderTags',
    method: 'get',
    authorization:true,
    params: params
  });
}

//关注和取关节假日提醒
export function concernHoliday(params = {}) {
  return request({
    url: '/api/v1/reminder/addAndCancelCurrFestivalReminderPlan',
    method: 'post',
    params: params
  });
}

//添加或修改提醒计划
export function addPlan(params = {}) {
  return request({
    url: '/api/v1/reminder/addReminderPlan',
    method: 'post',
    authorization:true,
    params: params
  });
}
//获取提醒计划详情
export function getPlanDetail(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderPlanDetail',
    method: 'get',
    authorization:true,
    params: params
  });
}
//获取邀请人员列表
export function getJoinUserList(params = {}) {
  return request({
    url: '/api/v1/reminder/getJoinUserList',
    method: 'get',
    authorization:true,
    params: params
  });
}
//删除提醒计划
export function delPlan(params = {}) {
  return request({
    url: '/api/v1/reminder/delPlan',
    method: 'post',
    authorization: true,
    params: params
  });
}
export function getSignList(params = {}) {
  return request({
    url: '/api/v1/reminder/getSignList',
    method: 'get',
    params: params
  });
}
export function getReminderPlanDetail(params = {}) {
  return request({
    url: '/api/v1/reminder/getReminderPlanDetail',
    method: 'get',
    authorization: true,
    params: params
  });
}
export function sign(params = {}) {
  return request({
    url: '/api/v1/reminder/sign',
    method: 'post',
    authorization: true,
    data: params
  });
}
export function updateBackImage(params = {}) {
  return request({
    url: '/api/v1/reminder/updateBackImage',
    method: 'get',
    authorization: true,
    params: params
  });
}
export function signPop(params = {}) {
  return request({
    url: '/api/v1/reminder/signPop',
    method: 'get',
    authorization: true,
    params: params
  });
}
export function invitePop(params = {}) {
  return request({
    url: '/api/v1/reminder/invitePop',
    method: 'get',
    authorization: true,
    params: params
  });
}
export function getImageByBase64(params = {}) {
  return request({
    url: '/api/v1/images/getImageByBase64',
    method: 'post',
    data: params
  });
}
export function saveBackImage(params = {}) {
  return request({
    url: '/api/v1/reminder/saveBackImage',
    method: 'post',
    data: params
  });
}
export function inviteReminderPlan(params = {}) {
  return request({
    url: '/api/v1/reminder/inviteReminderPlan',
    method: 'post',
    data: params,
    authorization: true,
  });
}
export function getTextParser(params = {}) {
  return request({
    url: '/api/v1/reminder/getTextParser',
    method: 'get',
    params: params,
    authorization: true,
  });
}
export function delJoinUserList(params = {}) {
  return request({
    url: '/api/v1/reminder/delJoinUserList',
    method: 'delete',
    params: params,
    authorization: true,
  });
}
export function delReminderTags(params = {}) {
  return request({
    url: '/api/v1/reminder/delReminderTags',
    method: 'delete',
    params: params,
    authorization: true,
  });
}