<template>
  <div v-if="loading" class="loading-box">
    <vant-loading color="#0094ff" vertical>{{ content }}</vant-loading>
  </div>
</template>

<script>
import { Loading } from 'vant';

export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    content: {
      type: String,
      default: () => ''
    }
  },
  components: {
    'vant-loading': Loading
  }
};
</script>

<style lang="scss" scoped>
.loading-box {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
