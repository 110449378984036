<template>
    <div class="loginPhone">
        <img src="../../assets/images/login/bg_top1.png" class="loginPng"  alt="">
        <div class="topword1">
            小提醒助手
        </div>
        <div class="topword2">
            请在此重置密码
        </div>
        <div class="loginArea" >
            <div class="loginPop">
                <div class="hearder">
                    重置密码
                </div>
                <div class="bottom">
                    <div class="inps" v-for="(v, i) in form" :key="i">
                        <div>
                            <img :src="v.img" class="phoneImg" alt="">
                        </div>
                        <input :type="v.invisible ? 'password' : 'text'" v-model="v.value" :placeholder="v.phaceholder">
                        <div v-if="v.invisibleImg">
                            <img :src="v.invisible?v.invisibleImg:v.invisibleImg2" @click="v.invisible = !v.invisible" class="invisibleImg" alt="">
                        </div>
                        <div v-if="v.type == 'code'" class="getCode" @click="getPhoneCode">
                            <span style="display:inline-block;width:15.5px;"></span>{{phoneCodeWord}}
                        </div>
                    </div>
                    <div class="btnArea">
                        <van-button class="btnBorder" size="large" type="primary"  @click="resetMethod"><span>重置密码</span></van-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="loginWechat" :style="loginType == 2 ? 'margin-top:40px;' : 'margin-top:120px;'">
            <div class="top">
                <div class="splitLine"></div>
                <span>第三方登录</span>
                <div class="splitLine"></div>
            </div>
            <div class="middle" @click="weLogin">
                <img src="../../assets/images/login/icon_login_wechat.svg" alt="">
            </div>
            <div class="bottom">
                微信登录
            </div>
        </div>
       
        <custom-loading :loading="loading" />
    </div>
</template>
  
<script>
import { Checkbox } from 'vant';
import {forgetPwd} from '@/api/login/login';
import { sendSms } from '@/api/mine/setting';
import { getCode } from '@/utils/commonFunction';
import loading from '@/components/loading.vue';
export default {
    components: {
        "van-checkbox": Checkbox,
        'custom-loading': loading,
    },
    data() {
        return {
            agreementPopal: false,
            loginType: 2,
            readChecked: false,
            loading: false,
            forgetData: [{
                name: "phone",
                img: require("../../assets/images/login/phone.png"),
                phaceholder: "输入手机号",
                value:"",
            }, {
                name: "phone",
                value:"",
                img: require("../../assets/images/login/veritification.png"),
                phaceholder: "输入验证码",
                type: 'code'
            }
                , {
                name: "password",
                value:"",
                img: require("../../assets/images/login/password.png"),
                phaceholder: "设置密码：6 ～ 24 位",
                invisible: true,
                invisibleImg: require("../../assets/images/login/invisible.png"),
                invisibleImg2: require("../../assets/images/login/visible2.png"),
            }],
            form: [],
            phoneCodeWord: "发送验证码",
            codeTimer: "",
        };
    },
    name: "LoginPhone",
    mounted() {
        this.form = this.forgetData;
    },
    watch: {
        '$route': { // $route可以用引号，也可以不用引号  监听的对象
            handler(to, from){
                if (localStorage.getItem('token')&&localStorage.getItem('token_deadtime')&&(localStorage.getItem('token_deadtime') > new Date().getTime())){
                    location.replace(location.origin)
                }
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }
    },
    methods: {
        // 获取验证码
        getPhoneCode() {
            if (this.phoneCodeWord != '发送验证码') {
                return;
            };
            if (this.form[0].value.length != 11) {
                this.$notify({ type: 'warning', message: '请输入正确的手机号' });
                return;
            };
            this.loading = true;
            sendSms({ phone: this.form[0].value }).then(res => {
                if (res.errorMsg == "发送成功") {
                    this.loading = false;
                    this.$notify({ type: 'success', message: '发送成功' });
                    this.phoneCodeWord = 60;
                    this.codeTimer = setInterval(() => {
                        if (this.phoneCodeWord == 0) {
                            this.phoneCodeWord = '发送验证码';
                            window.clearInterval(this.codeTimer);
                            return;
                        }
                        this.phoneCodeWord--;

                    }, 1000);
                }
            }).catch(err => {
                this.loading = false;
            });
        },
        resetMethod() {
            if (this.form[0].value.length != 11) {
                this.$notify({ type: 'warning', message: '请输入正确的手机号' });
                return;
            }
            if (this.form[1].value.length != 6) {
                this.$notify({ type: 'warning', message: '请输入正确的验证码' });
                return;
            }
            if (this.form[2].value.length < 6 || this.form[2].value.length > 24) {
                this.$notify({ type: 'warning', message: '密码只能为6-24位' });
                return;
            }
            this.loading = true;
            forgetPwd({
                phone: this.form[0].value,
                vertifyCode: this.form[1].value,
                newPassword: this.form[2].value,
            }).then(res => {
                this.loading = false;
               this.$notify({ type: 'success', message: '密码重置成功' });
            //    localStorage.setItem('token', res.tokenType + ' ' + res.accessToken);
               localStorage.setItem('phoneTel', this.form[0].value);
               getCode();
            //    localStorage.setItem('token_deadtime', new Date().getTime() + res.expiresIn * 1000);
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });

        },
        weLogin(){
                getCode();
        },
    }
};
</script>
  
<style lang="scss" scoped>
.loginPhone {
    width: 100%;
    height: 100%;
    position: relative;
    background: #FFFFFF;
    overflow: scroll;

    .loginPng {
        width: 100%;
    }

    .loginArea {
        position: relative;
        width: 345px;
        padding-bottom: 72px;
        margin-top: -74px;
        margin: -74px auto 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
        border-radius: 16px;

        .tab_l {
            width: 205px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .tab_r {
            width: 205px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .loginPop {
            position: relative;
            width: 100%;

            .hearder {
                padding-top: 20px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                text-align: center;
                width: 100%;
            }



            .active {
                color: #51B26B;
            }

            .bottom {
                width: 100%;

                .inps {
                    width: 305px;
                    height: 48px;
                    background: #F7F7F7;
                    border-radius: 8px;
                    padding: 14px 15px;
                    display: flex;
                    margin: 24px auto 0;
                    box-sizing: border-box;

                    .phoneImg {
                        width: 12px;
                        margin: 0 2px;
                        vertical-align: middle;

                    }

                    .getCode {
                        border-left: 1px solid #CCCCCC;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        display: inline-block;
                        font-size: 14px;
                        line-height: 18px;
                        box-sizing: border-box;
                        color: #51B26B;
                        white-space: nowrap;
                    }

                    .img {
                        width: 16px;
                        vertical-align: middle;
                    }

                    .invisibleImg {
                        width: 16px;
                        vertical-align: middle;
                    }

                    input {
                        min-width: 0;
                        width: auto;
                        flex-grow: 1;
                        padding-left: 15px;
                        font-size: 14px;
                        outline: none;
                        border: none;
                        vertical-align: middle;
                        background-color: transparent;
                    }
                }

                .btnArea {
                    width: 100%;
                    text-align: center;

                    .btnBorder {
                        border-radius: 8px;
                        width: 305px;
                        height: 48px;
                        margin: 24px 0 0;
                    }

                    .forget {
                        width: 305px;
                        margin: 0 auto;
                        text-align: right;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #51B26B;
                    }

                    .enSure {
                        display: flex;
                        justify-content: center;

                        .read {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #000000;
                        }

                        .agreement {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #51B26B;
                        }
                    }
                }
            }
        }
    }

    .loginWechat {
        text-align: center;
        position: relative;
        width: 100%;
        padding-bottom: 36px;

        .top {
            .splitLine {
                display: inline-block;
                width: 40px;
                height: 1px;
                background-color: #E6E6E6;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #999999;
                margin: 0 16px;
            }
        }

        .middle {
            text-align: center;
            margin: 16px auto 8px;
            width: 40px;
            height: 40px;
            background: #52B26B;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 16px;
            }
        }

        .bottom {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            text-align: center;

            color: #000000;
        }
    }
}
.topword1{
    font-size: 24px;position:absolute;top:47px;left:30px;color: #FFFFFF;line-height: 22px;
}
.topword2{
    font-size: 18px;line-height: 28px;position:absolute;top:81px;left:30px;color: #FFFFFF;
}
</style>
<style lang="css">
input::placeholder{
    color: #C7C7C7;
    }
    
</style>
  