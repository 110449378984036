<template>
  <div class="clock-record-container">
    <custom-back></custom-back>
    <div class="clock-record-content">
      <div v-if="recordInfo.todaySign && recordInfo.todaySign.length > 0" class="is-clock-content">
        <h3 class="content-title">今日已打卡</h3>
        <ul class="clock-list">
          <li v-for="(item, index) in recordInfo.todaySign" :key="index" class="clock-list-item">
            <h4 class="item-name" style="color:#52B26B;">
              {{ item.circleName }}
              <span v-if="item.signTime" class="clock-time"> {{ item.signTime }}</span>
            </h4>
            <p class="item-tip">
              已连续打卡{{ item.serialSignNums }}{{ item.period }}，再连续{{ item.periodNums - item.serialSignNums }}{{ item.period }}签到即可获得精美礼品
            </p>
            <div class="item-progress">
              <div class="progress">
                <span class="progress-total" style="width: 100%;">
                </span>
                <span class="progress-portion"
                  :style="'width:calc(' + (item.totalSignNums / item.periodNums)  + '* 100%);background-color: #52B26B;'">
                </span>
              </div>
              <div class="progress-remark"><span style="color:#52B26B">{{ item.totalSignNums }}</span> / {{ item.periodNums }}</div>
              <!-- <div class="clock-btn">去打卡</div> -->
            </div>
          </li>
        </ul>
      </div>
      <div v-if="recordInfo.todayNoSign && recordInfo.todayNoSign.length > 0" class="no-clock-content">
        <h3 class="content-title">今日未打卡</h3>
        <ul class="clock-list">
          <li v-for="(item, index) in recordInfo.todayNoSign" :key="index" class="clock-list-item">
            <h4 class="item-name">
              {{ item.circleName }}
              <!-- <span v-if="item.signTime" class="clock-time"> {{ item.signTime }}</span> -->
            </h4>
            <p class="item-tip">
              已连续打卡{{ item.serialSignNums }}{{ item.period }}，再连续{{ item.periodNums - item.serialSignNums }}{{ item.period }}签到即可获得精美礼品
            </p>
            <div class="item-progress">
              <div class="progress">
                <span class="progress-total" style="'width:100%'">
                </span>
                <span class="progress-portion"
                  :style="'width:calc(' + (item.totalSignNums / item.periodNums) + '* 100%);background-color: #52B26B;'">
                </span>
              </div>
              <div class="progress-remark">{{ item.totalSignNums }} / {{ item.periodNums }}</div>
              <div 
                class="clock-btn"
                :style="item.totalSignNums === item.periodNums ? 'background: #E9E9E9' : ''"
                @click="action(item)"
              >{{ item.totalSignNums === item.periodNums ? '已完成' : '去打卡' }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="card-num">
        <h3 class="card-num-title">我的补签卡{{ recordInfo.cardNums }}张</h3>
        <div class="card-image-box">
          <img src="../../../assets/images/mine/card-l.png">
        </div>
      </div>
      <div class="get-card">
        <h3 class="content-title">获取补签卡</h3>
        <ul class="method-list">
          <li class="method-item">
            {{ recordInfo.cardScore }}积分兑换
            <span class="method-btn" @click="showExchangeDialog">去兑换</span>
          </li>
        </ul>
      </div>
    </div>
    <vant-overlay :show="showDialog">
      <exchange-dialog v-if="showDialog" :num="recordInfo.cardScore" @close="closeDialog"></exchange-dialog>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import { Overlay, Icon, Notify } from 'vant';
import exchangeDialog from './exchangeDialog.vue';
import { getClockRecord } from '@/api/mine/clockRecord';
import { getWechatUserInfo } from '@/api/common';
import loading from '@/components/loading';

export default {
  components: {
    'custom-back': Back,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'exchange-dialog': exchangeDialog,
    'custom-loading': loading
  },
  data() {
    return {
      recordInfo: {},
      showDialog: false,
      loading: false
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.getClockRecord();
  },
  methods: {
    getClockRecord() {
      this.loading = true;
      getClockRecord().then(res => {
        this.loading = false;
        this.recordInfo = res;
      }).catch(() => {
        this.loading = false;
      });
    },
    showExchangeDialog() {
      if (this.recordInfo.cardScore > this.userInfo.score) {
        Notify({ type: 'warning', message: '积分不足，无法兑换!' });
        return;
      }
      this.showDialog = true;
    },
    closeDialog(type) {
      if (type) {
        this.getClockRecord();
        this.resetUserInfo();
      }
      this.showDialog = false;
    },
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
    action(item) {
      this.$router.push({
        name: 'CircleDetail',
        query: {
          id: item.circleId,
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .clock-record-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .clock-record-content {
      flex: 1;
      padding: 0 15px 50px;
      overflow-y: auto;
      .content-title {
        height: 40px;
        color: #333;
        font-size: 14px;
        line-height: 40px;
      }
      .clock-list {
        .clock-list-item {
          height: 98.5px;
          background: #FFFFFF;
          box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.1);
          border-radius: 10px;
          padding: 15px 8.5px 15px 13.5px;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          .item-name {
            color: #333;
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 6px;
            position: relative;
            .clock-time {
              color: #888888;
              position: absolute;
              top: 0;
              right: 0;
              font-weight: normal;
            }
          }
          .item-tip {
            color: #888888;
            font-size: 10px;
            line-height: 18px;
          }
          .item-progress {
            display: flex;
            align-items: center;
            position: relative;
            margin-top: auto;
            .progress {
              width: 180px;
              position: relative;
              border-radius: 2.5px;
              overflow: hidden;
              height: 5px;
              background: #e2e2e2;
              span {
                display: inline-block;
                height: 100%;
                background: #cbcbcb;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 2.5px;
              }
              .progress-total {
                opacity: 0.15;
                width: 100%;
                z-index: 1;
              }
              .progress-portion {
                z-index: 2;
              }
            }
            .progress-remark {
              color: #888;
              font-size: 10px;
              margin-left: 8px;
              line-height: 25px;
            }
            .clock-btn {
              width: 61px;
              height: 25px;
              line-height: 25px;
              background: #52B26B;
              border-radius: 12.5px;
              text-align: center;
              color: #fff;
              font-size: 10px;
              margin-left: auto;
            }
          }
        }
      }
      .card-num {
        .card-num-title {
          font-size: 13px;
          color: #333;
          line-height: 43px;
        }
        .card-image-box {
          width: 184px;
          height: 112px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .method-list {
        background: #FFFFFF;
        box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.1);
        border-radius: 10px;
        margin-bottom: 10px;
        .method-item {
          height: 48px;
          color: #333333;
          font-size: 12px;
          line-height: 48px;
          padding: 0 8.5px 0 14px;
          display: flex;
          align-items: center;
          .method-btn {
            width: 61px;
            height: 25px;
            line-height: 25px;
            background: #52B26B;
            border-radius: 12.5px;
            text-align: center;
            color: #fff;
            font-size: 10px;
            margin-left: auto;
          }
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
