import { request } from '@/utils/ajax.js';

//绑定手机
export function addFeedBack(params = {}) {
  return request({
    url: '/api/v1/user/addFeedBack',
    method: 'post',
    params: params,
    authorization: true
  });
}
