import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    pageBack: ''
  },
  mutations: {
    changeUserInfo(state, res) {
      state.userInfo = res;
    },
    changePageBack(state, res) {
      state.pageBack = res;
    }
  },
  actions: {},
  modules: {}
});
