<template>
    <div class="openMember">
        <div class="openHole">
            <div class="tables">
                <div class="tr1 memberDiv">特权</div>
                <div class="tr2 memberDiv">
                    <div><img src="../../assets/images/member/vip01.png" alt=""> <span>非会员</span></div>
                </div>
                <div class="tr3 memberDiv">
                    <div><img src="../../assets/images/member/vip02.png" alt=""> <span>会员</span></div>
                </div>
            </div>
            <div class="tables" v-for="(v, i) in authority" :key="i">
                <div class="tr1 ">{{ v.name }}</div>
                <div class="tr2 ">
                    <div v-if="i > 0">
                        <img src="../../assets/images/member/icon_no.png" class="checkImg" alt="">
                    </div>
                    <div v-else>有广告</div>
                </div>
                <div class="tr3 memberDiv">
                    <div v-if="i > 0">
                        <img src="../../assets/images/member/icon_yes.png" class="checkImg" alt="">
                    </div>
                    <div v-else>无广告</div>
                </div>
            </div>
            <div class="memberType">
                <div class="types" @click="checkedMember = i" v-for="(v, i) in memberTypes" :key="i"
                    :style="checkedMember == i ? 'border-color:#52B26B;' : null">
                    <div class="date">{{ v.name }}</div>
                    <div class="give">赠送 {{ v.score }} 积分</div>
                    <div class="splitLine"></div>
                    <div class="prize">￥{{ v.price ? v.price : "" }}</div>
                    <div class="produce">{{ v.desc ? v.desc : null }}</div>
                    <div class="rectangle" v-if="v.tag">
                        <img v-if="checkedMember == i" :src="rectangle2" alt="">
                        <img v-else :src="rectangle" alt="">
                        <div :style="checkedMember == i ? 'color:#fff' : null">
                            {{ v.tag }}
                        </div>
                    </div>
                    <img v-if="checkedMember == i" src="../../assets/images/member/selected.png" class="activeChecked"
                        alt="">
                </div>
            </div>
            <div class="words">
                <h3>
                    会员购买说明：
                </h3>
                <div>
                    1、购买完成后立即生效，会员服务开通后不支持退款；
                </div>
                <div>
                    2、如需开具发票可在【我的】-【联系客服】申请发票；
                </div>
                <div>
                    3、如有任何问题或建议可在【我的】-【意见反馈】。
                </div>
                <div style="margin-top:12px;">
                    开通会员即视为您已经同意我们《<span @click="agreementPopal = true">会员服务协议</span>》
                </div>
                <div>
                    更多条款，请参见我们的<span @click="agreementPopal = true">隐私保护政策</span>和<span
                        @click="agreementPopal = true">用户服务协议</span>。
                </div>
            </div>
        </div>
        <div class="bottomBtn" :style="isIos?'padding-bottom:50px':null">
            <van-button type="primary" class="btnBorder" size="large"
                @click="buyvipClick">立即开通</van-button>
        </div>
        <van-dialog v-model="agreementPopal" title="用户协议、隐私政策" show-cancel-button :showConfirmButton="true"
            :theme="'round-button'" confirm-button-text="同意" confirmButtonColor="#52b26b" :showCancelButton="false"
            @confirm="agreementPopal = true">
            <div style="height:60vh;overflow:scroll;padding: 10px 18px;">
                <agreement />
            </div>
        </van-dialog>
        <custom-loading :loading="loading" />
    </div>
</template>
  
<script>
import { Checkbox } from 'vant';
import { vipInfo, buyvip } from '@/api/member/member';
import { Dialog } from 'vant';
import loading from '@/components/loading.vue';
import wxJsConfig from '@/utils/wxJsConfig.js';
import agreement from '../../components/agreement.vue';
import { getWxPayConfig } from '@/api/common';
export default {
    data() {
        return {
            agreementPopal: false,
            loading: false,
            checkedMember: 0,
            rectangle: require("../../assets/images/member/rectangle.png"),
            rectangle2: require("../../assets/images/member/rectangle2.png"),
            memberDescList: ['VIP标识', '自定义背景图', '短信提醒', '电话提醒', '赠送积分', '标识', '个性化设置'],
            memberTypes: [],
            authority: [],
            isIos:false
        };
    },
    components: {
        "van-checkbox": Checkbox,
        "custom-loading": loading,
        agreement,
        [Dialog.Component.name]: Dialog.Component,
    },
    name: "Open",
    mounted() {
        this.getvipInfo()
        const ua = navigator.userAgent;
        const isiOS = ua.match(/(iPhone|iPad|iPod|iOS)/i);
        if (isiOS) {
            // 是
            this.isIos = true
        }
    },
    methods: {
        getvipInfo() {
            this.loading = true;
            vipInfo().then(res => {
                this.loading = false;
                this.authority = res.authority;
                this.memberTypes = res.vipList;
            }).catch(err => {
                this.loading = false
            });
        },
        buyvipClick() {
            this.loading = true
            buyvip({
                vipId: this.authority[this.checkedMember].id
            }).then(res => {
                if (res.orderId) {
                    this.$emit("success")
                    getWxPayConfig({ orderId: res.orderId, payType: 0 }).then((res2) => {
                        this.initWxPay(res2, res.orderId);
                    });
                    // this.$router.push({
                    //     name: "MyOrders"
                    // })
                }
            }).catch(err => {
                this.loading = false
            });
        },
        initWxPay(data, id) {
            const that = this;
            wxJsConfig.init([
                'chooseWXPay'
            ]).then(() => {
                wx.chooseWXPay({
                    timestamp: data.timeStamp,
                    nonceStr: data.nonceStr,
                    package: data.package,
                    signType: data.signType,
                    paySign: data.paySign,
                    debug: false,
                    success: function (res) {
                        that.loading = false;
                        if (res.errMsg == 'chooseWXPay:ok') {
                            that.$notify({ type: 'success', message: '支付成功' });
                            that.$emit("success")
                        } else {
                            that.$notify({ type: 'danger', message: '支付失败' });
                        }
                    },
                    cancel() {
                        that.loading = false;
                        that.$notify({ type: 'warning', message: '取消支付' });
                    },
                    fail() {
                        that.loading = false;
                        that.$notify({ type: 'danger', message: '支付失败' });
                    },
                });
            });
        },
    }
};
</script>
  
<style lang="scss" scoped>
.openMember {
    width: 100%;
    padding-bottom: 83px;

    .openHole {
        width: 100%;
        padding: 0 15px 40px;
        box-sizing: border-box;
    }

    .tables {
        background-color: #fff;
        height: 44px;
        border: 0.5px solid #E8E8E8;
        display: flex;

        div {
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #333333;

            span {
                vertical-align: middle;
                margin-left: 4px;
            }

            img {
                width: 14px;
                vertical-align: middle;
            }
        }

        .checkImg {
            width: 20px;
            height: 20px;
        }

        .tr1 {
            width: 145px;

        }

        .tr2 {
            width: 99.5px;
            border-left: 0.5px solid #E8E8E8;
            border-right: 0.5px solid #E8E8E8;
        }

        .tr3 {
            width: 99.5px;
        }

        .memberDiv {
            background: #FFFAF5;
        }
    }

    .tables:nth-child(n+2) {
        border-top: none;
    }

    .memberType {
        width: 100%;
        margin-top: 22px;
        margin-bottom: 16px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        .types {
            padding: 30px 0 12px;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            border-radius: 8px;
            position: relative;
            box-sizing: border-box;

            .date {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #000000;
            }

            .give {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-align: center;
                color: #666666;
                margin: 8px 0 16px;
            }

            .splitLine {
                background: #eee;
                width: 88%;
                height: 1px;
                margin: 10px auto;
            }

            .prize {
                font-family: 'DIN Alternate';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: #FF6600;

            }

            .produce {
                height: 16px;
                margin-top: 2px;
                text-align: center;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #333333;
            }

            .rectangle {
                position: absolute;
                top: -5px;
                width: 64px;
                left: -1px;
                height: 20px;

                img {
                    width: 100%;
                }

                div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: #52B26B;
                }
            }

            .activeChecked {
                width: 20px;
                position: absolute;
                right: 0;
                top: -1%;
            }
        }
    }

    .words {
        margin: 20px 0 0;
		
		h3{
			margin-bottom: 5px;
		}

        div {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: normal;
            font-size: 12px !important;
            line-height: 20px;
            color: #666666;

            span {
                font-size: 12px !important;
                color: #51B26B;
            }
        }
    }

    .bottomBtn {
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.04);
        border-radius: 20px 20px 0px 0px;
        padding: 12px 15px;
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0;

        .btnBorder {
            width: 100%;
            border-radius: 8px;
            height: 48px;
			font-weight: 600;
        }
    }
}
</style>
  