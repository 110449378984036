<template>
  <div class="add-container" style="max-width: 640px;">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="add-content">
      <div class="content-item" style="padding: 10px 10px 11px">
        <div class="plan-label-list">
          <p class="title">选择标签</p>
          <ul class="label-list">
            <li 
                v-for="(item, index) in labelList" 
                :key="index" 
                :class="['label-item', { 'active': labelId === item.id }]"
            >{{ item.name }}</li>
            <li 
                :class="['label-item', { 'active': labelId === 0 }]"
            >自定义</li>
          </ul>
          <vant-field v-if="labelId === 0" v-model="labelName" maxlength="4" placeholder="在这里输入你的自定义标题" readonly="readonly"></vant-field>
        </div>
        <div class="content" style="margin-top: 20px;">
          <p class="title">内容</p>
          <vant-field 
            type="textarea" 
            v-model="content" 
            placeholder="请输入提醒内容"
            maxlength="50"
            show-word-limit
            readonly="readonly"
          ></vant-field>
          <div class="content-tip">{{ content.length }} / 50</div>
        </div>
      </div>
      <div class="content-item">
        <div class="picker-select-box" :style="cycleValue === '每天' ? '' : 'border-bottom: 1px solid #e2e2e2;'">
          <span class="label">提醒周期</span>
          <div class="picker-content">
            {{ cycleValue }}
          </div>
        </div>
        <div class="select-outplan">
          <div v-if="cycleValue === '每天'" class="plan-label-list" style="border-bottom: 1px solid #e2e2e2;">
            <p class="title" style="font-size: 10px;color: #888;">选择哪天不提醒</p>
            <ul class="label-list">
              <li 
                v-for="(item, index) in weekDayList" 
                :key="index" 
                :class="['label-item', { 'active': weekLabel.indexOf(item.id) > -1 }]"
              >{{ item.name }}</li>
            </ul>
          </div>
          <div v-if="cycleValue === '每年'" class="picker-select-box" style="border-bottom: 1px solid #e2e2e2;">
            <span class="label">历法</span>
            <div class="picker-content">
              {{ calendarValue }}
              <p class="picker-btn"></p>
            </div>
          </div>
          <div v-if="cycleValue === '每年'" class="picker-select-box" style="border-bottom: 1px solid #e2e2e2;">
            <span class="label">提醒</span>
            <div class="picker-content">
              {{ leadTimeValue }}
              <p class="picker-btn"></p>
            </div>
          </div>
          <div 
            v-for="(item, index) in remindTimeList" 
            :key="index"
            class="setting-item remind-time-item"
            :style="remindTimeList.length === 1 && !remindTimeList[0].value ? 'border-bottom: 0;': ''"
          >
            <span class="setting-title">{{ index === 0 ? '提醒时间' : '' }}</span>
            <span class="setting-content">{{ item.value || '请选择时间' }}</span>
          </div>
        </div>
      </div>
      <div class="content-item" style="padding: 0 10px;">
        <div class="setting-item" style="height: 130px;padding-top: 10px;border-bottom: none;flex-direction: column;">
          <div class="title" style="line-height: 16px;margin-bottom: 4px;">提醒方式</div>
          <vant-checkbox-group v-model="remindType">
            <vant-checkbox :name="0" checked-color="#F26A13" disabled="disabled">公众号提醒<span class="label-tip">(永久免费)</span></vant-checkbox>
            <vant-checkbox :name="1" checked-color="#F26A13" disabled="disabled">短信提醒<span class="label-tip">(1积分/次，剩余积分：{{ userInfo.score }})</span></vant-checkbox>
          </vant-checkbox-group>
        </div>
      </div>
    </div>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getReminderTags, getPlanDetail } from '@/api/remind/index';
import { Field, Popup, Picker, DatetimePicker, Notify, CheckboxGroup, Checkbox, Overlay, Icon } from 'vant';
import phoneDialog from '../mine/setting/phoneDialog';
import concern from './concern.vue';
import loading from '@/components/loading';

export default {
  components: {
    'vant-field': Field,
    'vant-popup': Popup,
    'vant-picker': Picker,
    'vant-datetime-picker': DatetimePicker,
    'vant-checkbox-group': CheckboxGroup,
    'vant-checkbox': Checkbox,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'phone-dialog': phoneDialog,
    'concern-dialog': concern,
    'custom-loading': loading
  },
  data() {
    return {
      id: 0,
      labelList: [],
      labelId: '',
      labelName: '',
      content: '',
      showPicker: false,
      pickerType: '',
      cycleList: ['单次', '每天', '每周', '每月', '每年'],
      cycleValue: '单次',
      calendarValue: '阳历(公历)',
      calendarList: ['阳历(公历)', '阴历(农历)'],
      leadTimeValue: '当天提醒',
      leadTimeList: ['当天提醒', '提前一天', '提前两天', '提前三天'],
      dataTimeType: 'once',
      weekLabel: [],
      weekDayList: [
        { id: 1, name: '周一'},
        { id: 2, name: '周二'},
        { id: 3, name: '周三'},
        { id: 4, name: '周四'},
        { id: 5, name: '周五'},
        { id: 6, name: '周六'},
        { id: 0, name: '周日'},
        { id: 7, name: '节假日'},
      ],
      remindTimeList: [{}],
      showTimePicker: false,
      selectTime: '',
      selectTimeIndex: 0,
      remindType: [0],
      originDetail: {},
      showBindPhone: false,
      showConcern: false,
      weekDatePickerColumns: [],
      loading: false
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.id = this.$route.query.id || 0;
    this.getReminderTags();
    if (this.id > 0) {
      this.getPlanDetail();
    }
  },
  methods: {
    getPlanDetail() {
      getPlanDetail({ id: this.id }).then(res => {
        this.labelId = res.tagId;
        this.labelName = res.tagName;
        this.content = res.content;
        this.cycleValue = this.cycleList[res.period];
        this.remindType = res.way ? res.way.split(',').map(Number) : [];
        if (this.cycleValue === '每年') {
          this.calendarValue = this.calendarList[res.calendar];
          this.leadTimeValue = this.leadTimeList[res.leadTime];
        }
        res.times.forEach(v => {
          if (this.cycleValue === '每月') {
            v.value = `${v.day}日 ${v.hour}:${v.minute}`;
          } else if (this.cycleValue === '每年') {
            v.value = `${v.month}月${v.day}日 ${v.hour}:${v.minute}`;
          } else if (this.cycleValue === '单次') {
            v.value = `${v.year}年${v.month}月${v.day}日 ${v.hour}:${v.minute}`;
          } else if (this.cycleValue === '每周') {
            v.value = `${this.weekDatePickerColumns[0].values[v.week]} ${v.hour}:${v.minute}`;
          } else {
            v.value = `${v.hour}:${v.minute}`;
          }
        });
        this.weekLabel = res.except ? res.except.split(',').map(Number) : [];
        this.originDetail = JSON.parse(JSON.stringify(res));
        this.remindTimeList = res.times;
      });
    },
    getReminderTags() {
      getReminderTags().then(res => {
        this.labelList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    back() {
      this.$router.replace({ name: 'Remind'});
    },
  }
};
</script>

<style lang="scss" scoped>
  .add-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 12px 50px;
    background: #f9f9f9;
    z-index: 50;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 5px;
        margin-top: 21px;
      }
      .back-name {
        position: absolute;
        top: 0;
        left: 22px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .add-content {
      height: 100%;
      overflow-y: auto;
      .content-item {
        background: #FFFFFF;
        box-shadow: 0px 6px 6px 0px rgba(17, 168, 153, 0.08);
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 0 10px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
      }
      .plan-label-list {
        .label-list {
          display: flex;
          flex-wrap: wrap;
          .label-item {
            width: calc((100% - 30px)/4);
            margin-right: 10px;
            margin-bottom: 10px;
            height: 25px;
            line-height: 25px;
            background: rgba(17, 168, 153, 0.15);
            border-radius: 15px;
            text-align: center;
            font-size: 12px;
            color: #11A899;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &.active {
              background: #F26A13;
              color: #fff;
            }
          }
        }
        .van-field {
          height: 45px;
          line-height: 45px;
          border-bottom: 1px solid #e2e2e2;
          padding: 0 10px;
          margin-bottom: 10px;
        }
      }
      .content {
        position: relative;
        margin-top: 8px;
        .van-field {
          height: 87px;
          border: 1px solid #E4E4E4;
          border-radius: 5px;
          padding: 0;
          &:after {
            display: none;
          }
          ::v-deep textarea {
            padding: 10px;
            height: 100%;
          }
        }
        .content-tip {
          position: absolute;
          bottom: 5px;
          right: 7px;
          font-size: 13px;
          color: #888;
        }
      }
      .picker-select-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        .label {
          font-size: 12px;
          color: #333;
        }
        .picker-content {
          font-size: 12px;
          color: #666;
          display: flex;
          align-items: center;
          .picker-btn {
            width: 12px;
            height: 12px;
            border-left: 2px solid #ccc;
            border-bottom: 2px solid #ccc;
            -webkit-transform: translate(0,-50%) rotate(-135deg);
            transform: translate(0,-50%) rotate(-135deg);
            margin-left: auto;
            margin-top: 15px;
            margin-right: 5px;
          }
        }
      }
      .setting-item {
        border-bottom: 1px solid #e2e2e2;
        height: 50px;
        line-height: 50px;
        display: flex;
        .setting-title {
          color: #333;
          font-size: 13px;
          margin-right: auto;
        }
        .img-box {
          width: 50px;
          height: 50px;
          img {
            width: 12px;
            height: 12px;
            margin: 19px;
          }
        }
        .setting-content {
          color: #666;
          font-size: 12px;
        }
        .setting-icon {
          width: 12px;
          height: 12px;
          border-left: 2px solid #ccc;
          border-bottom: 2px solid #ccc;
          -webkit-transform: translate(0,-50%) rotate(-135deg);
          transform: translate(0,-50%) rotate(-135deg);
          margin-left: 5px;
          margin-top: 25px;
          margin-right: 5px;
        }
        .add-remind-time-btn {
          width: 71px;
          height: 22.5px;
          border-radius: 11.25px;
          line-height: 22.5px;
          text-align: center;
          color: #fff;
          font-size: 11px;
          margin: auto;
          background: #52B26B;
        }
        ::v-deep .van-field {
          flex: 1;
          padding: 0;
          input {
            text-align: right;
          }
        }
        ::v-deep .van-checkbox-group {
          width: 100%;
          height: 100px;
          .van-checkbox {
            height: 50px;
            border-bottom: 1px solid #e2e2e2;
            &:last-child {
              border-bottom: none;
            }
            .van-checkbox__icon--round {
              height: 100%;
              position: relative;
              width: 15px;
              &.van-checkbox__icon--checked {
                .van-icon {
                  background-color: #F26A13;
                  border-color: #F26A13;
                }
              }
              .van-icon {
                font-size: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            
          }
          .van-checkbox__label {
            color: #666;
            font-size: 12px;
            line-height: 50px;
            position: relative;
            .label-tip {
              position: absolute;
              left: 80px;
              top: 0;
              white-space: nowrap;
              font-size: 12px;
            }
          }
        }
      }
      .btns {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0px -6px 9px 0px rgba(0, 162, 146, 0.11);
        align-items: center;
        .back {
          width: 67.5px;
          height: 40px;
          color: #333;
          font-size: 13px;
          text-align: center;
          line-height: 40px;
          background: #eaeaea;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .submit {
          width: 260px;
          height: 40px;
          color: #fff;
          font-size: 13px;
          text-align: center;
          background: #52B26B;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
    ::v-deep .van-datetime-picker {
      &.time {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      &.month {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
        }
      }
      &.year {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
