module.exports = {
  development: {
    // baseUrl: 'https://beta-api.xtx.vta.cn', //接口请求域名
    baseUrl: 'https://xtx.mp.vta.cn',
    // baseUrl: 'https://xtx.mp.vta.cn',
    originUrl: 'http://xdq.oneinall.cn', //服务器域名
    websocketUrl: '', //websocket域名
    appId: 'wx2ef36dd709f14fc6', //公众号appId
  },
  production: {
    baseUrl: 'https://xtx.mp.vta.cn', //接口请求域名
    originUrl: 'http://xdq.oneinall.cn', //服务器域名
    websocketUrl: '', //websocket域名
    appId: 'wx2ef36dd709f14fc6', //公众号appId
  }
};
