import { request } from '@/utils/ajax.js';

//获取订单列表
export function getOrderList(params = {}) {
  return request({
    url: '/api/v1/order/list',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取各分类订单数量
export function getOrderCount(params = {}) {
  return request({
    url: '/api/v1/order/getOrderCount',
    method: 'get',
    params: params,
    authorization: true
  });
}

//订单取消
export function closeOrder(params = {}) {
  return request({
    url: '/api/v1/order/close',
    method: 'post',
    params: params,
    authorization: true
  });
}

//提醒发货
export function delivery(params = {}) {
  return request({
    url: '/api/v1/order/delivery',
    method: 'post',
    params: params,
    authorization: true
  });
}

//确认收货
export function finish(params = {}) {
  return request({
    url: '/api/v1/order/finish',
    method: 'post',
    params: params,
    authorization: true
  });
}

//获取物流详情
export function getLogisticsDetail(params = {}) {
  return request({
    url: '/api/v1/order/expressDetail',
    method: 'get',
    params: params,
    authorization: true
  });
}

//订单开票
export function invoice(params = {}) {
  return request({
    url: '/api/v1/order/invoice',
    method: 'post',
    params: params,
    authorization: true
  });
}


//获取默认开票信息
export function getInvoiceInfo(params = {}) {
  return request({
    url: '/api/v1/order/invoiceInfo',
    method: 'get',
    params: params,
    authorization: true
  });
}
