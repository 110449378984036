<template>
  <div id="find-container" class="find-container" @scroll="scrollPage">
    <div v-if="myCircleList.length > 0" class="my-circle">
      <p class="my-circle-title">我的圈子</p>
      <ul class="my-circle-list">
        <li 
          v-for="item in myCircleList" 
          :key="item.id" 
          class="my-circle-item"
        >
          <p 
            :style="item.signStatus === 1 || item.signedDays === item.totalDays ? '' : 'color: #fff;background:' + item.bgColor"
            class="my-circle-item-title"
          >{{ item.title }}</p>
          <div class="my-circle-item-content">
            <p class="my-circle-item-desc">{{ item.intro }}</p>
            <p class="my-circle-item-time">{{ item.signTimeByDay }}</p>
            <div class="my-circle-item-progress">
              <div class="progress">
                <span class="progress-total"
                  :style="item.signStatus === 1 || item.signedDays === item.totalDays ? 'width:100%;' : 'width:100%;background:' + item.bgColor + ';'">
                </span>
                <span class="progress-portion"
                  :style="item.signStatus === 1 || item.signedDays === item.totalDays ? 'width:calc(' + (item.signedDays / item.totalDays)  + '* 100%);' : 'width:calc(' + (item.signedDays / item.totalDays)  + '* 100%);background:' + item.bgColor">
                </span>
              </div>
              <div class="progress-remark">{{ item.signedDays }} / {{ item.totalDays }}</div>
            </div>
            <div 
              :style="item.signStatus === 1 || item.signedDays === item.totalDays ? '' : 'color: #fff;background:' + item.bgColor"
              class="my-circle-item-sign-btn"
              @click="toDetail(item.id)"
            >
              {{ item.signedDays === item.totalDays ? '已完成' : (item.signStatus === 1 ? '已打卡' : '今日打卡') }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="circle-library" :style="hideMyCircle ? 'padding-top: 49px' : (myCircleList.length > 0 ? 'margin-top: 10px;': 'padding-top: 26px;')">
      <ul 
        id="circle-type-list" 
        class="circle-type-list"
        :style="myCircleList.length === 0 ? 'position: fixed;top: 0;z-index: 99;width: calc(100% - 30px);max-width: 640px;' : 'max-width: 640px;'">
        <li 
          v-for="item in circleTypeList"
          :key="item.id"
          :class="['circle-type-item', {'active': item.id === circleTypeId}]"
          @click="changeType(item.id)"
        >
          {{ item.cateName }}
          <div v-if="item.id === circleTypeId" class="type-line"></div>
        </li>
      </ul>
      <vant-list 
        class="circle-list"
        :finished="noMore"
        @load="loadMore"
      >
        <div 
          v-for="item in circleList"
          :key="item.id"
        >
          <circle-item :item="item" @join="toDetail(item.id)"></circle-item>
        </div>
        <div v-if="circleList.length === 0" class="none-data">
          <img src="../../assets/images/remind/none.png">
          <p class="none-tip">暂无圈子！</p>
        </div>
      </vant-list>
    </div>
    <circle-search 
      v-if="showSearch"
      :recommend-type-list="originCircleTypeList"
      @join="toDetail"
      @close="closeSearch"
    ></circle-search>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getCircleTypeList, getCircleList, getMyCircle } from '@/api/find/index.js';
import circleSearch from './circleSearch';
import circleItem from './circleItem';
import loading from '@/components/loading.vue';
import config from '../../../config/config.default';
import { List, Cell } from 'vant';
import { getCode, getUrl, getloginSession } from '@/utils/commonFunction.js';

export default {
  components: {
    'circle-search': circleSearch,
    'circle-item': circleItem,
    'custom-loading': loading,
    'vant-list': List,
    'vant-cell': Cell
  },
  data() {
    return {
      myCircleList: [],
      originCircleTypeList: [],
      circleTypeList: [],
      circleTypeId: '',
      circleList: [],
      showSearch: false,
      loading: false,
      code: '',
      noMore: false,
      page: 1,
      pageSize: 10,
      hideMyCircle: false
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  watch: {
    circleTypeId() {
      this.page = 1;
      this.noMore = false;
      if (this.circleTypeId !== -1) {
        if (!this.code || localStorage.getItem('token_deadtime') > new Date().getTime()) {
          this.loading = true;
          this.getCircleList();
        }
      }
    },
    userInfo: {
      deep: true,
      handler() {
        this.getCircleList();
        this.getMyCircle();
      }
    },
  },
  mounted() {
    this.code = getUrl('code');
    if (this.code && localStorage.getItem('token_deadtime') < new Date().getTime()) {
      getloginSession(this.code);
    }
    this.getCircleTypeList();
    if (Object.keys(this.userInfo).length > 0) {
      this.getMyCircle();
    }
  },
  methods: {
    getCircleTypeList() {
      this.loading = true;
      getCircleTypeList().then(response => {
        this.originCircleTypeList = JSON.parse(JSON.stringify(response));
        const list = response.length > 3 ? JSON.parse(JSON.stringify(response)).slice(0,3) : JSON.parse(JSON.stringify(response));
        list.unshift({ id: 0, cateName: '热门'});
        list.push({ id: -1, cateName: '更多'});
        this.circleTypeList = list;
        this.circleTypeId = list[0].id;
      }).catch(() => {
        this.loading = false;
      });
    },
    getCircleList() {
      getCircleList({ 
        cateId: this.circleTypeId, 
        isHot: this.circleTypeId === 0 ? 1 : 0,
        page: this.page,
        limit: this.pageSize
      }).then(response => {
        this.loading = false;
        if (response.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.circleList = [];
        }
        this.circleList = this.circleList.concat(JSON.parse(JSON.stringify(response)));
      }).catch(() => {
        this.loading = false;
      });
    },
    getMyCircle() {
      getMyCircle({ type: 0 }).then(response => {
        this.myCircleList = JSON.parse(JSON.stringify(response));
      }).catch(() => {
        this.loading = false;
      });
    },
    changeType(id) {
      if (id !== this.circleTypeId) {
        if (id === -1) {
          this.showSearch = true;
        } else {
          this.circleTypeId = id;
        }
      }
    },
    closeSearch() {
      this.showSearch = false;
    },
    toDetail(id) {
      if (!localStorage.getItem('token_deadtime') || localStorage.getItem('token_deadtime') < new Date().getTime()) {
        getCode(config[process.env.NODE_ENV].originUrl + '/find/circleDetail?id=' + id);
      } else {
        this.$router.push({
          name: 'CircleDetail',
          query: {
            id: id
          }
        });
      }
    },
    scrollPage(e) {
      let typeList = document.getElementById('circle-type-list');
      if (this.myCircleList.length > 0) {
        let scrollTop = e.target.scrollTop;
        if(scrollTop > document.getElementsByClassName('circle-library')[0].offsetTop){ //当滚动距离大于225px时执行下面的东西
          typeList.style.position = 'fixed';
          typeList.style.top = '0';
          typeList.style.zIndex = '99';
          typeList.style.width = 'calc(100% - 30px)';
          this.hideMyCircle = true;
        }else{//当滚动距离小于225的时候执行下面的内容，也就是让导航栏恢复原状
          typeList.style.position = 'static';
          typeList.style.width = '100%';
          this.hideMyCircle = false;
        }
      }
    },
    loadMore() {
      if (this.circleList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        this.getCircleList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .find-container {
    padding: 15px 15px 98px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    .my-circle {
      .my-circle-title {
        font-size: 14px;
        color: #333;
        line-height: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .my-circle-list {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        height: 180px;
        .my-circle-item {
          display: inline-block;
          width: 121px;
          height: 100%;
          border-radius: 5px;
          color: #666;
          font-size: 12px;
          line-height: 18px;
          margin-right: 12.5px;
          overflow: hidden;
          position: relative;
          .my-circle-item-title {
            background: #E9E9E9;
            padding: 0 7.5px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .my-circle-item-content {
            padding: 8px;
            background: #fff;
            position: absolute;
            top: 22.5px;
            left: 0;
            right: 0;
            border-radius: 5px 5px 0 0;
            .my-circle-item-desc {
              color: #333;
              font-size: 13px;
              height: 35px;
              white-space: pre-wrap;
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin-bottom: 20px;
            }
            .my-circle-item-time {
              font-size: 11px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .my-circle-item-progress {
              .progress {
                width: 100%;
                position: relative;
                border-radius: 2.5px;
                overflow: hidden;
                height: 5px;
                margin: 8px 0 5px;
                border-radius: 2.5px;
                span {
                  display: inline-block;
                  height: 100%;
                  background: #e9e9e9;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .progress-total {
                  opacity: 0.15;
                  width: 100%;
                  z-index: 1;
                  background: #e9e9e9;
                  border-radius: 2.5px;
                }
                .progress-portion {
                  z-index: 2;
                  border-radius: 2.5px;
                }
              }
            }
            .my-circle-item-sign-btn {
              width: 100%;
              height: 25px;
              margin-top: 10px;
              border-radius: 12.5px;
              background: #e9e9e9;
              position: relative;
              line-height: 27px;
              text-align: center;
            }
          }
        }
      }
    }
    .circle-library {
      display: flex;
      flex-direction: column;
      position: relative;
      .circle-type-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #f9f9f9;
        .circle-type-item {
          color: #666;
          font-size: 14px;
          line-height: 44px;
          position: relative;
          &.active {
            color: #52B26B;
            font-weight: bold;
            font-size: 15px;
          }
          .type-line {
            height: 2px;
            width: 100%;
            background: #52B26B;
            border-radius: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .none-data {
    display: flex;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    img {
      width: 175px;
      height: 134px;
    }
    .none-tip {
      font-size: 13px;
      color: #52B26B;
      margin-top: 15px;
    }
  }
</style>
