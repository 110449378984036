<template>
  <div class="clock-container">
    <div class="clock-box">
      <vant-notice-bar
        left-icon="volume-o"
        text="请上传相关文字、图片、记录下今天的打卡情况吧"
      />
      <vant-field v-model="clockValue" type="textarea" placeholder="这一刻的想法..."></vant-field>
      <div v-if="type === 'image'" class="image-upload">
        <ul class="image-list">
          <li v-for="(item, index) in uploadImageList" :key="index" class="image-box">
            <img :src="item">
            <vant-icon name="cross" @click="delFile(index)"></vant-icon>
          </li>
          <li v-if="uploadImageList.length < max" class="image-box">
            <vant-uploader
              :max-count="max"
              :after-read="upload"
              multiple
              @oversize="onOversize"
            >
              <img src="../../../assets/images/find/photo.png" style="width: 28.5px;height: 24px;">
            </vant-uploader>
          </li>
        </ul>
      </div>
      <div v-if="type === 'audio'" class="audio-box">
        <audio :src="uploadAudioList[0]" controls="controls" :autoplay="false"></audio>
        <vant-icon name="cross" @click="delFile" style="z-index: 1000;"></vant-icon>
      </div>
      <div v-if="type === 'video'" class="video-box">
        <video 
          :src="uploadVideoList[0]" 
          controls="controls" 
          :autoplay="false"
          webkit-playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
        ></video>
        <vant-icon name="cross" @click="delFile" style="z-index: 1000;"></vant-icon>
      </div>
      <div v-if="type === ''" class="enclosure-btns">
        <div class="photo-btn enclosure-btn">
          <vant-uploader
            :max-count="max"
            :after-read="upload"
            multiple
            @oversize="onOversize"
          >
            <img src="../../../assets/images/find/photo.png" style="width: 28.5px;height: 24px;">
          </vant-uploader>
        </div>
        <div class="audio-btn enclosure-btn">
          <vant-uploader
            :max-count="1"
            :accept="'audio/*'"
            :after-read="upload"
          >
            <img src="../../../assets/images/find/audio.png" style="width: 22.5px;height: 31px;">
          </vant-uploader>
        </div>
        <div class="video-btn enclosure-btn">
          <vant-uploader
            :max-count="1"
            :accept="'video/*'"
            :after-read="upload"
          >
            <img src="../../../assets/images/find/video.png" style="width: 32.5px;height: 20px;">
          </vant-uploader>
        </div>
      </div>
      <div class="authority-select-box" @click="showPicker = true">
        <span class="label">权限</span>
        <div class="authority-content">
          {{ authorityContent }}
          <p class="authority-btn"></p>
        </div>
      </div>
    </div>
    <div class="clock-btns">
      <span class="back" @click="back">返回</span>
      <span class="clock-in" @click="clockIn">提交</span>
    </div>
    <vant-popup v-model="showPicker" :close-on-click-overlay="false" round position="bottom">
      <vant-picker
        show-toolbar
        :columns="authorityList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </vant-popup>
  </div>
</template>

<script>
import { NoticeBar, Field, Popup, Picker, Notify, Uploader, Icon } from 'vant';
import { addTopic } from '@/api/find/circleDetail';
import $ from 'jquery';
import config from '../../../../config/config.default';

export default {
  props: {
    taskId: {
      type: Number,
      require: true
    },
    circleId: {
      type: Number,
      require: true
    }
  },
  components: {
    'vant-notice-bar': NoticeBar,
    'vant-field': Field,
    'vant-popup': Popup,
    'vant-picker': Picker,
    'vant-uploader': Uploader,
    'vant-icon': Icon
  },
  data() {
    return {
      authorityContent: '全部',
      clockValue: '',
      type: '',
      max: 8,
      uploadImageList: [],
      uploadAudioList: [],
      uploadVideoList: [],
      showPicker: false,
      authorityList: ['全部', '仅自己']
    };
  },
  watch: {
    uploadImageList: {
      deep: true,
      handler() {
        if (this.uploadImageList.length === 0) {
          this.type = '';
        }
      }
    },
    uploadAudioList: {
      deep: true,
      handler() {
        if (this.uploadAudioList.length === 0) {
          this.type = '';
        }
      }
    },
    uploadVideoList: {
      deep: true,
      handler() {
        if (this.uploadVideoList.length === 0) {
          this.type = '';
        }
      }
    }
  },
  methods: {
    onConfirm(val) {
      this.authorityContent = val;
      this.showPicker = false;
    },
    onOversize() {
      Notify({ type: 'danger', message: '图片大小不能超过20M'});
    },
    upload(file) {
      console.log(file.file.type.indexOf('image'));
      if (file.file.type.indexOf('image') !== -1) {
        this.type = 'image';
      } else if (file.file.type.indexOf('audio') !== -1) {
        this.type = 'audio';
      } else if (file.file.type.indexOf('video') !== -1) {
        this.type = 'video';
      }
      console.log(this.type);
      let formData = new FormData();
      formData.append('file', file.file);
      $.ajax({
        type: 'post',
        url: config[process.env.NODE_ENV].baseUrl + '/api/v1/file/upload',
        data: formData,
        contentType: false,
        processData: false,
        mineType: 'multipart/form-data',
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        'mimeType': 'multipart/form-data',
        success: (res) => {
          if (this.type === 'image') {
            this.uploadImageList.push(JSON.parse(res).path);
          } else if (this.type === 'audio') {
            this.uploadAudioList.push(JSON.parse(res).path);
          } else if (this.type === 'video') {
            this.uploadVideoList.push(JSON.parse(res).path);
          }
          
        }
      });
    },
    delFile(index) {
      if (this.type === 'image') {
        this.uploadImageList.splice(index, 1);
      } else if (this.type === 'audio') {
        this.uploadAudioList = [];
      } else if (this.type === 'video') {
        this.uploadVideoList = [];
      }
    },
    back() {
      this.$emit('close');
    },
    clockIn() {
      addTopic({ 
        taskId: this.taskId,
        circleId: this.circleId,
        signContent: this.clockValue,
        type: this.type === 'image' ? 0 : (
                this.type === 'audio' ? 1 : (
                  this.type === 'video' ? 2 : '')),
        file: this.type === 'image' ? this.uploadImageList : (
                this.type === 'audio' ? this.uploadAudioList : (
                  this.type === 'video' ? this.uploadVideoList : [])),
        access: this.authorityContent === '全部' ? 0 : 1 
      }).then(res => {
        console.log(res);
        Notify({ type: 'success', message: '打卡成功!' });
        this.$emit('close', true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.clock-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f9f9f9;
  .clock-box {
    padding: 0 24.5px 0 29px;
    background: #fff;
    box-shadow: 0px 6px 9px 0px rgba(0, 162, 146, 0.1);
    border-radius: 0 0 10px 10px;
    .van-notice-bar {
      background-color: #fff;
      color: #333;
      font-size: 12px;
      height: 33px;
      line-height: 33px;
      border-bottom: 1px solid #e2e2e2;
      padding: 0;
    }
    .van-field {
      margin: 10px 0;
      padding: 0;
      height: 80px;
      &:after {
        display: none;
      }
    }
    .image-upload {
      padding-bottom: 15px;
      border-bottom: 1px solid #e2e2e2;
      .image-list {
        display: flex;
        flex-wrap: wrap;
        .image-box {
          width: 70px;
          height: 70px;
          background: #EFEFEF;
          position: relative;
          border-radius: 5px;
          overflow: hidden;
          margin-bottom: 10px;
          margin-right: calc((100% - 280px)/3);
          &:nth-child(4n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .audio-box {
      position: relative;
      audio {
        width: 300px;
        height: 40px;
      }
    }
    .video-box {
      position: relative;
      video {
        width: 300px;
        height: 200px;
      }
    }
    .enclosure-btns {
      display: flex;
      padding-bottom: 14px;
      border-bottom: 1px solid #e2e2e2;
      .enclosure-btn {
        width: 70px;
        height: 70px;
        position: relative;
        background: #efefef;
        border-radius: 10px;
        margin-right: 7.5px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .authority-select-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      .label {
        font-size: 11px;
        color: #666;
      }
      .authority-content {
        font-size: 13px;
        color: #333;
        display: flex;
        align-items: center;
        .authority-btn {
          width: 12px;
          height: 12px;
          border-left: 2px solid #ccc;
          border-bottom: 2px solid #ccc;
          -webkit-transform: translate(0,-50%) rotate(-135deg);
          transform: translate(0,-50%) rotate(-135deg);
          margin-left: auto;
          margin-top: 15px;
          margin-right: 5px;
        }
      }
    }
  }
  .clock-btns {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 48px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px -6px 9px 0px rgba(0, 162, 146, 0.11);
    align-items: center;
    .back {
      width: 67.5px;
      height: 40px;
      color: #333;
      font-size: 13px;
      text-align: center;
      line-height: 40px;
      background: #eaeaea;
      border-radius: 5px;
    }
    .clock-in {
      width: 260px;
      height: 40px;
      color: #fff;
      font-size: 13px;
      text-align: center;
      line-height: 40px;
      background: #52B26B;
      border-radius: 5px;
    }
  }
  .van-icon-cross {
    position: absolute;
    color: #fff;
    background: grey;
    top: 0;
    right: 0;
    font-size: 13px;
  }
}
</style>
