<template>
  <div class="logistics-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="logistics-content">
      <h3 class="title">物流详情</h3>
      <div class="info">
        <div class="image-box">
          <img src="../../../assets/images/mine/toReceived.png">
        </div>
        <div class="info-content">
          <p class="info-desc">收件地址：{{ logisticsInfo.addr }}</p>
          <!-- <p class="info-time">预计送达时间：{{ logisticsInfo.expectTime }}</p> -->
        </div>
      </div>
      <div class="logistics-detail">
        <p class="detail-title">
          快递单号：{{ logisticsInfo.number }}
          <span class="title-ent">{{ logisticsInfo.expName }}</span>
        </p>
        <div v-if="logisticsInfo.list && logisticsInfo.list.length > 0" class="detail-content">
          <vant-steps direction="vertical" :active="0">
            <vant-step v-for="(item, index) in logisticsInfo.list" :key="index">
              <div class="step-info">{{ item.status }}</div>
              <div class="step-time">{{ item.time }}</div>
            </vant-step>
          </vant-steps>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { getLogisticsDetail } from '@/api/mine/myOrders';
import { Step, Steps } from 'vant';

export default {
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  components: {
    'vant-step': Step,
    'vant-steps': Steps
  },
  data() {
    return {
      logisticsInfo: {}
    };
  },
  mounted() {
    this.getLogisticsDetail();
  },
  methods: {
    getLogisticsDetail() {
      getLogisticsDetail({ orderId: this.item.id }).then(res => {
        this.logisticsInfo = res;
      });
    },
    back() {
      this.$emit('close');
    },
  }
};
</script>

<style lang="scss" scoped>
  .logistics-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    z-index: 50;
    display: flex;
    flex-direction: column;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
        }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .logistics-content {
      flex: 1;
      padding: 0 15px 20px;
      overflow-y: auto;
      .title {
        font-size: 13px;
        color: #333;
        line-height: 32px;
      }
      .info {
        height: 72.5px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        padding: 0 40px 0 9px;
        margin-bottom: 10px;
        .image-box {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          position: relative;
          box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.09);
          margin-right: 19px;
          img {
            width: 28.5px;
            height: 26.5px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .info-content {
          flex: 1;
          .info-desc {
            font-size: 13px;
            color: #333;
          }
          .info-time {
            font-size: 10px;
            color: #666;
            margin-top: 9px;
          }
        }
      }
      .logistics-detail {
        background: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(9, 193, 158, 0.08);
        border-radius: 5px;
        height: calc(100% - 115px);
        .detail-title {
          height: 25px;
          line-height: 25px;
          padding: 0 8px;
          background: #F2F2F2;
          border-radius: 5px 5px 0 0;
          color: #666;
          font-size: 10px;
          .title-ent {
            float: right;
          }
        }
        .detail-content {
          height: calc(100% - 25px);
          overflow-y: auto;
          .step-info {
            color: #333;
            font-size: 13px;
          }
          .step-time {
            color: #666;
            font-size: 10px;
          }
        }
      }
    }
  }
</style>
