import { request } from '@/utils/ajax.js';

//获取我的圈子
export function getMyCircles(params = {}) {
  return request({
    url: '/api/v1/circle/myCircle',
    method: 'get',
    params: params,
    authorization: true
  });
}

//退出圈子
export function deleteMyCircle(params = {}) {
  return request({
    url: '/api/v1/order/del',
    method: 'post',
    params: params,
    authorization: true
  });
}
