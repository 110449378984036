<template>
  <div class="custom-service-container">
    <div class="header">
      联系客服
    </div>
    <div class="content">
      <div class="image-box">
        <img v-if="customInfo.qrCode" :src="customInfo.qrCode" style="width: 100%;height: 100%;">
      </div>
      <p class="tip">扫一扫添加客服微信</p>
    </div>
  </div>
</template>

<script>
import { getCustomInfo } from '@/api/mine/customService';

export default {
  data() {
    return {
      customInfo: {}
    };
  },
  mounted() {
    this.getCustomInfo();
  },
  methods: {
    getCustomInfo() {
      getCustomInfo().then((response) => {
        this.customInfo = JSON.parse(JSON.stringify(response));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .custom-service-container {
    margin: 0 47.5px;
    width: 280px;
    height: 300px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 0%;
    border-radius: 5px;
    .header {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #52b26b;
      border-radius: 5px 5px 0 0;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      .content-tel {
        color: #666;
        font-size: 14px;
        line-height: 18px;
        img {
          width: 14px;
          height: 11.5px;
          vertical-align: top;
          margin-top: 3.25px;
        }
      }
      .image-box {
        width: 150px;
        margin: 0 0 5px;
      }
      .tip {
        color: #888;
        font-size: 14px;
      }
    }
  }
</style>
