<template>
  <div class="showOverlay2" style="max-width: 640px;">
                <div class="showOverlay2Area">
                    <div class="showOverlay2AreaTop">
                        <img src="../../assets/images/remind/bg.png" alt="">
                        <div>
                            接下来请关注小提醒服务号
                        </div>
                    </div>
                    <div class="showOverlay2AreaMiddle">
                        <div class="erma">
                            <img src="https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20230101/e655d40d685460e23630deaf28e56e5a.jpg" alt="">
                        </div>
                        <div class="desc">
                            请长按扫码关注，否则将无法接收提醒!
                        </div>
                    </div>
                </div>
               
            </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    }
  }
};
</script>

<style lang="scss" scoped>
  .concern-setting {
    margin: 0 35px;
    width: 305px;
    height: 250px;
    background: #fff;
    position: absolute;
    top: 30%;
    left: 0%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    font-size: 12px;
    color: #333;
    background: #fff;
    align-items: center;
    .code-box {
      width: 100%;
      height: 110px;
      margin: 25px;
      text-align: center;
      img {
        height: 100%;
      }
    }
    .concern-desc {
      font-size: 12px;
      padding: 0 30px;
      text-align: center;
    }
  }
  .showOverlay2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .showOverlay2Area {
        background: #FFFFFF;
        border-radius: 8px;
        width: 275px;

        .showOverlay2AreaTop {
            width: 100%;
            font-size: 0;
            position: relative;
            width: 100%;

            img {
                width: 100%;
            }

            div {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 100%;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .showOverlay2AreaMiddle {
            background-color: #fff;
            padding: 26px 0 38px;

            .erma {
                text-align: center;
                font-size: 0;

                img {
                    width: 155px;
                }
            }

            .desc {
                text-align: center;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                color: #666666;
                margin-top: 26px;
            }
        }
    }

    .wrapperClose {
        text-align: center;
        margin-top: 32px;

        .closeimg {
            width: 30px;
        }
    }
}

</style>
