<template>
  <div class="my-circles-container">
    <custom-back></custom-back>
    <div class="circles-content">
      <div v-if="myCircle.length > 0" class="circles-item">
        <p class="circles-title">我加入的圈子</p>
        <ul class="circle-item">
          <li v-for="(item, index) in myCircle" :key="index" :style="index < myCircle.length - 1 ? 'margin-bottom: 15px;' : ''">
            <circle-item :item="item" :type="1" @join="toDetail"  @quit="quitCircle"></circle-item>
          </li>
          <li v-if="myCircle.length === 0" class="none-data">
            <img src="../../../assets/images/remind/none.png">
            <p class="none-tip">暂无圈子！</p>
          </li>
        </ul>
      </div>
      <div v-if="inviteCircle.length > 0" class="circles-item">
        <p class="circles-title">猜你感兴趣</p>
        <ul class="circle-item">
          <li v-for="(item, index) in inviteCircle" :key="index" :style="index < inviteCircle.length - 1 ? 'margin-bottom: 10px;' : ''">
            <circle-item :item="item" :type="0" @join="toDetail"></circle-item>
          </li>
          <li v-if="inviteCircle.length === 0" class="none-data">
            <img src="../../../assets/images/remind/none.png">
            <p class="none-tip">暂无圈子！</p>
          </li>
        </ul>
      </div>
    </div>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import circleItem from './circleItem.vue';
import { getMyCircles, deleteMyCircle } from '@/api/mine/myCircles';
import { Notify } from 'vant';
import loading from '@/components/loading';

export default {
  components: {
    'custom-back': Back,
    'circle-item': circleItem,
    'custom-loading': loading
  },
  data() {
    return {
      myCircle: [],
      inviteCircle: [],
      loading: false,
    };
  },
  mounted() {
    this.getMyCircles();
  },
  methods: {
    getMyCircles() {
      this.loading = true;
      getMyCircles({ type: 1}).then(res => {
        this.loading = false;
        this.myCircle = res.myCircle;
        this.inviteCircle = res.inviteCircle;
      }).catch(() => {
        this.loading = false;
      });
    },
    toDetail(obj) {
      if (localStorage.getItem('token_deadtime') < new Date().getTime()) {
        getCode();
      } else {
        this.$router.push({
          name: 'CircleDetail',
          query: {
            id: obj.id
          }
        });
      }
    },
    quitCircle(id) {
      console.log(2);
      deleteMyCircle({ orderId: id}).then(() => {
        Notify({ type: 'success', message: '退出成功' });
        this.getMyCircles();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-circles-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .circles-content {
      flex: 1;
      overflow-y: auto;
      padding: 0 15px 10px;
      .circles-item {
        .circles-title {
          color: #333;
          font-size: 14px;
          line-height: 40px;
          font-weight: bold;
        }
        .circle-item {
          li {
            padding: 5px 7px;
            background: #fff;
            border-radius: 5px;
          }
        }
      }
    }
  }
</style>
