<template>
    <div class="deposit">
        <div class="head">
            <div class="tlte1">
                积分充值
            </div>
            <div class="headRight">
                <span>
                    我的积分：
                </span>
                <span class="count">
                    {{userInfo.score}}
                </span>
            </div>
        </div>
        <div class="integralArea">
            <div :class="i == selectedCount ? 'intes active' : 'intes'" v-for="(v, i) in scoreCard" :key="i"
                @click="changeselectedCount(i)">
                <div>
                    <span class="count">{{ v.num }}</span>
                    <span class="word">积分</span>
                </div>
                <img src="../../assets/images/member/selected2.png" v-if="i == selectedCount" class="selectedImg"
                    alt="">
            </div>
        </div>
        <div class="wePayWord">
            <div class="tlte1">
                微信支付：
            </div>
            <div class="headRight">
                <span class="count">
                    {{scoreCard[selectedCount].num/scoreRate}}元
                </span>
            </div>
        </div>
        <div class="parWord">
            <div>
                积分充值说明：
            </div>
            <div>
                1、充值完成后立即生效，积分充值后不支持退款；
            </div>
            <div>
                2、积分充值后有效期为2年，到期后清零。
            </div>
        </div>
        <van-button type="primary" class="btnBorder" size="large" @click="charge">立即充值</van-button>
        <div class="recordArea">
            <div class="recordsTop">
                积分记录
            </div>
            <div class="records">
                <!-- <div class="record">
                    <div class="record1">
                        <span class="date">
                            2022-11-12 12:30:59
                        </span>
                        <span class="add">
                            +1
                        </span>
                    </div>
                    <div class="record2">
                        打卡得积分
                    </div>
                </div> -->
                <div class="record" v-for="(v, i) in rewordList">
                    <div class="record1">
                        <span class="date">
                            {{ v.createdAt }}
                        </span>
                        <span class="add">
                            +{{ v.score }}
                        </span>
                    </div>
                    <div class="record2">
                        {{ v.type }}
                    </div>
                </div>
            </div>
        </div>
        <custom-loading :loading="loading" />
    </div>
</template>
  
<script>
import { score, recharge } from '@/api/member/member';
import loading from '@/components/loading.vue';
import wxJsConfig from '@/utils/wxJsConfig.js';
import { getWxPayConfig } from '@/api/common';
import { getWechatUserInfo } from '@/api/common';
export default {
    data() {
        return {
            checked: false,
            scoreCard: [{id: 11, num: "10"},
{id: 12, num: "200"},
{id: 13, num: "500"},
{id: 14, num: "1000"}],
            loading: false,
            selectedCount: 0,
            rewordList: [],
            userInfo:{},
            scoreRate:10
        };
    },
    components: {
        "custom-loading": loading
    },
    name: "deposit",
    mounted() {
        this.getMyScore()
    },
    methods: {
        changeselectedCount(i){
            this.selectedCount = i
            console.log(this.scoreCard[this.selectedCount].num/this.scoreRate)
        },
        getMyScore() {
            this.loading = true
            score().then(res => {
                this.scoreCard = res.scoreCard
                this.rewordList = res.scoreList
                this.scoreRate = res.scoreRate
                this.resetUserInfo()
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },
        resetUserInfo() {
            this.loading = true
            getWechatUserInfo().then(response => {
                response.isPrise = response.isPrise === 1 ? true : false;
                localStorage.setItem('userInfo', JSON.stringify(response));
                this.userInfo = response
                this.$store.commit('changeUserInfo', response);
                this.loading = false
            }).catch(err => {
                this.loading = false
            });
        },
        charge() {
            this.loading = true
            recharge({
                scoreCardId: this.scoreCard[this.selectedCount].id
            }).then(res => {
                if (res.orderId) {
                    getWxPayConfig({ orderId: res.orderId, payType: 0 }).then((res2) => {
                        this.initWxPay(res2, res.orderId);
                    });

                }
            }).catch(err => {
                this.loading = false
            })
        },
        initWxPay(data, id) {
            const that = this;
            wxJsConfig.init([
                'chooseWXPay'
            ]).then(() => {
                wx.chooseWXPay({
                    timestamp: data.timeStamp,
                    nonceStr: data.nonceStr,
                    package: data.package,
                    signType: data.signType,
                    paySign: data.paySign,
                    debug: false,
                    success: function (res) {
                        if (res.errMsg == 'chooseWXPay:ok') {
                            that.$notify({ type: 'success', message: '支付成功' });
                            that.resetUserInfo()
                            that.loading = false
                            that.getMyScore()
                            that.that.$emit("success")
                        } else {
                            that.$notify({ type: 'danger', message: '支付失败' });
                            that.loading = false
                        }
                    },
                    cancel() {
                        that.loading = false;
                        that.$notify({ type: 'warning', message: '取消支付' });
                    },
                    fail() {
                        that.loading = false;
                        that.$notify({ type: 'danger', message: '支付失败' });
                    },
                });
            });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.deposit {
    width: 100%;
    background: #F2F2F2;
    padding: 8px 15px 19px;

    .head {
        display: flex;
        justify-content: space-between;

        .tlte1 {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #666666;
        }

        .headRight {
            span {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #666666;
            }

            .count {
                color: #52B26B;
                font-family: 'DIN Alternate';
            }
        }
    }

    .integralArea {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin-top: 12px;

        .intes {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            border-radius: 8px;
            border: 1.5px solid transparent;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            font-size: 0;

            .selectedImg {
                width: 20px;
                vertical-align: top;
                position: absolute;
                top: -4%;
                right: -1%;
            }

            .count {
                font-family: 'DIN Alternate';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                color: #000000;

            }

            .word {
                margin-left: 4px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #000000;
            }
        }

        .active {
            border: 1.5px solid #52B26B;

            .count {
                color: #52B26B;
                ;
            }

            .word {
                color: #52B26B;
                ;
            }
        }
    }

    .wePayWord {
        display: flex;
        margin: 12px 0;

        .tlte1 {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #666666;
        }

        .headRight {

            .count {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #52B26B;
                font-family: 'DIN Alternate';
            }
        }
    }

    .parWord {
        div {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #666666;
        }
    }

    .btnBorder {
        width: 100%;
        border-radius: 8px;
        height: 48px;
        margin-top: 16px;
		font-weight: 600;
    }

    .recordArea {
        margin-top: 16px;
        background: #FFFFFF;
        border-radius: 8px;
        width: 100%;

        .recordsTop {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            padding: 16px 0 12px 15px;
            border-bottom: 1px solid #E8E8E8;
            ;
        }

        .records {
            padding: 0px 15px;

            .record {
                padding: 16px 0;
                border-bottom: 1px solid #E8E8E8;

                .record1 {
                    display: flex;
                    justify-content: space-between;

                    .date {
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #888888;

                    }

                    .add {
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: right;
                        color: #161A22;
                    }
                }

                .record2 {
                    margin-top: 4px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #161A22;
                }
            }

            .record:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>
  