import Vue from 'vue';
import VueRouter from 'vue-router';

import Find from './views/find/app.vue';
import Mine from './views/mine/app.vue';
import Remind from './views/remind/app.vue';

//发现（圈子相关路由）
import CircleDetail from './views/find/circleDetail/app.vue';
import TaskDetail from './views/find/circleDetail/taskDetail.vue';
import PaySetting from './views/find/circleDetail/paySetting.vue';

//我的
import MyOrders from './views/mine/myOrders/app.vue';//我的订单
import ClockRecord from './views/mine/clockRecord/app.vue';//我的打卡记录
import MyCircles from './views/mine/myCircles/app.vue';//我的圈子
import MyDynamics from './views/mine/myDynamics/app.vue';//我的动态etting
import Setting from './views/mine/setting/app.vue';//设置
import Feedback from './views/mine/feedback/app.vue';//意见反馈
import Integral from './views/mine/integral/app.vue';//积分

//提醒
import PlanAdd from './views/remind/planAdd.vue';//计划编辑
import PlanAdd2 from './views/remind/planAdd2.vue';//计划编辑
import PlanDetail from './views/remind/planDetail.vue';//计划详情
import SetRemind from './views/remind/setRemind.vue';//计划编辑

// 登录
import LoginBefore from './views/login/loginBefore.vue'; //登录之前
import LoginPhone from './views/login/loginPhone.vue'; //手机登录
import ResetPass from './views/login/resetPass.vue'; //重置密码
import Regesit from './views/login/regesit.vue'; //注册

// 会员
import Member from'./views/member/member.vue';
import Open from'./views/member/open.vue';
import Deposit from'./views/member/deposit.vue';

Vue.use(VueRouter);

const routes = [
  {
    path:'/',
    redirect:{
      name:'Remind'
    }
  },
  {
    path: '/remind',
    name: 'Remind',
    component: Remind
  },
  {
    path: '/find',
    name: 'Find',
    component: Find
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {
    path: '/find/circleDetail',
    name: 'CircleDetail',
    component: CircleDetail,
  },
  {
    path: '/find/taskDetail',
    name: 'TaskDetail',
    component: TaskDetail,
  },
  {
    path: '/find/paySetting',
    name: 'PaySetting',
    component: PaySetting,
    meta: {
      title: '签到设置'
    }
  },
  {
    path: '/mine/myOrders',
    name: 'MyOrders',
    component: MyOrders,
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/mine/clockRecord',
    name: 'ClockRecord',
    component: ClockRecord,
    meta: {
      title: '打卡记录'
    }
  },
  {
    path: '/mine/myCircles',
    name: 'MyCircles',
    component: MyCircles,
    meta: {
      title: '我加入的圈子'
    }
  },
  {
    path: '/mine/myDynamics',
    name: 'MyDynamics',
    component: MyDynamics,
    meta: {
      title: '我发布的动态'
    }
  },
  {
    path: '/mine/setting',
    name: 'Setting',
    component: Setting,
    meta: {
      title: '设置'
    }
  },
  {
    path: '/mine/feedback',
    name: 'Feedback',
    component: Feedback,
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/mine/integral',
    name: 'Integral',
    component: Integral,
    meta: {
      title: '我的积分'
    }
  },
  {
    path: '/remind/planAdd',
    name: 'PlanAdd',
    component: PlanAdd,
    meta: {
      title: '创建提醒'
    }
  },
  {
    path: '/remind/planAdd2',
    name: 'PlanAdd2',
    component: PlanAdd2,
    meta: {
      title: '创建提醒'
    }
  },
  {
    path: '/remind/planDetail',
    name: 'PlanDetail',
    component: PlanDetail
  },
  {
    path: '/login/loginBefore',
    name: 'LoginBefore',
    component: LoginBefore,
  },
  {
    path: '/login/loginphone',
    name: 'LoginPhone',
    component: LoginPhone,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/login/regesit',
    name: 'Regesit',
    component: Regesit,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/login/resetPass',
    name: 'ResetPass',
    component: ResetPass,
    meta: {
      title: '重置密码'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: Member,
    children:[
      {
        path: '/',
        name: 'Open',
        component: Open,
        meta: {
          title: '开通会员'
        }
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: Deposit,
        meta: {
          title: '充值积分'
        }
      },
    ]
  },
  {
    path: '/remind/setRemind',
    name: 'SetRemind',
    component: SetRemind,
    meta: {
      title: '提醒'
    }
  },
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title;
  } else {
    document.title = '小提醒';
  }
  window.scroll(0, 0);
  next();
});

export default router;
