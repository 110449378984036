<template>
  <div class="detail-container">
    <div v-if="lectureInfo" class="lecture">
      <div class="lecture-avatar-box">
        <img v-if="lectureInfo.avatar" :src="lectureInfo.avatar" alt="">
      </div>
      <div class="lecture-info">
        <p class="info-name">讲师：{{ lectureInfo.name }}</p>
        <p class="info-intro">{{ lectureInfo.intro }}</p>
      </div>
    </div>
    <div class="course-info" :style="lectureInfo ? '' : 'margin-top: 0;'">
      <!-- <p class="course-info-desc">{{ intro }}</p> -->
      <!-- <p class="course-info-intro-name">详细介绍：</p> -->
      <p class="course-info-intro" v-html="desc"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lectureInfo: {
      type: Object,
      require: true
    },
    intro: {
      type: String,
      require: true
    },
    desc: {
      type: String,
      require: true
    }
  },
  data() {
    return {

    };
  },
  mounted() {
    console.log(this.lectureInfo, this.desc, this.intro);
  }
};
</script>

<style lang="scss" scoped>
  .detail-container {
    padding: 14px 0 0;
    height: 100%;
    .lecture {
      display: flex;
      .lecture-avatar-box {
        width: 106px;
        height: 106px;
        margin-right: 13px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .lecture-info {
        flex: 1;
        .info-name {
          color: #333;
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 18px;
        }
        .info-intro {
          // font-size: 13px;
          color: #666;
          line-height: 16px;
          white-space: pre-wrap;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    .course-info {
      margin-top: 22px;
      .course-info-desc {
        font-size: 12px;
        color: #666;
        line-height: 16px;
        // margin-bottom: 14px;
      }
      .course-info-intro-name {
        color: #333;
        font-size: 13px;
        line-height: 18px;
        margin: 16px 0 12px 0;
      }
      ::v-deep .course-info-intro {
        width: 100%;
        border-radius: 5px;
        margin-top: 10px;
        p {
          width: 100%;
          video {
            width: 100%!important;
            height: 200px!important;
          }
          audio {
            width: 100%;
            height: 40px;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>
