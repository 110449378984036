<template>
  <div class="exchange-container">
    <div class="exchange-title">兑换补签卡</div>
    <div class="exchange-content">
      <div class="content-left">
        <img src="../../../assets/images/mine/integral.png" style="width: 39.5px;height: 34.5px;">
        <h3 class="left-info">{{ num }}积分</h3>
      </div>
      <p class="exchange-icon">
        <img src="../../../assets/images/mine/more.png" style="width: 14px;height: 13px;transform: rotate(-90deg);">
      </p>
      <div class="content-right">
        <img src="../../../assets/images/mine/card-s.png" style="width: 59px;height: 36.5px;">
        <h3 class="right-info">1张补签卡</h3>
      </div>
    </div>
    <div class="exchange-btn" @click="exchange">{{ userInfo.score >= num ? '兑换(剩余积分: ' + userInfo.score + ')' : '去充值' }}</div>
  </div>
</template>

<script>
import { buySignCard } from '@/api/mine/clockRecord';
import { Notify } from 'vant';

export default {
  props: {
    num: {
      type: Number,
      require: true
    }
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  methods: {
    exchange() {
      if (this.userInfo.score >= this.num) {
        buySignCard({
          cardNum: 1,
          cardScore: this.num
        }).then(() => {
          Notify({ type: 'success', message: '兑换成功!' });
          this.$emit('close', true);
        });
      } else {
        this.$router.push({name: 'Integral'});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .exchange-container {
    margin: 0 15px;
    width: 345px;
    height: 279px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 0%;
    border-radius: 5px;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .exchange-title {
      color: #333;
      font-size: 13px;
    }
    .exchange-content {
      flex: 1;
      display: flex;
      color: #52B26B;
      align-items: center;
      .content-left {
        .left-info {
          margin-top: 9px;
          font-size: 13px;
        }
      }
      .exchange-icon {
        margin: 0 30px 35px;
      }
      .content-right {
        .right-info {
          margin-top: 7px;
          font-size: 13px;
        }
      }
    }
    .exchange-btn {
      width: 300px;
      height: 40px;
      line-height: 40px;
      background: #52B26B;
      border-radius: 5px;
      text-align: center;
      color: #fff;
      font-size: 13px;
    }
  }
</style>
