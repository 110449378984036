import { request } from '@/utils/ajax.js';

//获取圈子详情列表
export function getCircleDetail(params = {}) {
   return request({
    url: '/api/v1/circle/detail',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取任务列表
export function getTaskList(params = {}) {
  return request({
    url: '/api/v1/circle/taskList',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取动态列表
export function getDynamicList(params = {}) {
  return request({
    url: '/api/v1/circle/topicList',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取排名列表
export function getRankList(params = {}) {
  return request({
    url: '/api/v1/circle/rankList',
    method: 'get',
    params: params,
    authorization: true
  });
}

//动态点赞/取消点赞
export function priseDynamic(params = {}) {
  return request({
    url: '/api/v1/circle/prise',
    method: 'post',
    params: params,
    authorization: true
  });
}

//动态评论
export function commentDynamic(params = {}) {
  return request({
    url: '/api/v1/circle/comment',
    method: 'post',
    params: params,
    authorization: true
  });
}


//评论回复
export function replyComment(params = {}) {
  return request({
    url: '/api/v1/circle/reply',
    method: 'post',
    params: params,
    authorization: true
  });
}

//打卡签到发布动态
export function addTopic(params = {}) {
  return request({
    url: '/api/v1/circle/addTopic',
    method: 'post',
    params: params,
    authorization: true
  });
}

//订单生成
export function store(params = {}) {
  return request({
    url: '/api/v1/order/store',
    method: 'post',
    params: params,
    authorization: true
  });
}

//获取日历数据
export function getCalendarData(params = {}) {
  return request({
    url: '/api/v1/circle/taskSignRecords',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取任务详情
export function getTaskDetail(params = {}) {
  return request({
    url: '/api/v1/circle/taskDetail',
    method: 'get',
    params: params,
    authorization: true
  });
}

//获取圈子用户设置信息
export function getUserCircleReminderConfig(params = {}) {
  return request({
    url: '/api/v1/user/getUserCircleReminderConfig',
    method: 'get',
    params: params,
    authorization: true
  });
}

//邀请关系绑定
export function invite(params = {}) {
  return request({
    url: '/api/v1/user/invite',
    method: 'get',
    params: params,
    authorization: true
  });
}
