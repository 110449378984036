import { request } from '@/utils/ajax.js';

//获取分享信息
export function getShareInfo(params = {}) {
  return request({
    url: '/api/v1/circle/signPop',
    method: 'get',
    params: params
  });
}

//获取邀请信息
export function getInviteInfo(params = {}) {
  return request({
    url: '/api/v1/circle/invitePop',
    method: 'get',
    params: params
  });
}

//更换主题
export function changeTheme(params = {}) {
  return request({
    url: '/api/v1/circle/updateBackImage',
    method: 'get',
    params: params
  });
}


//base64转成图片
export function getImage(params = {}) {
  return request({
    url: '/api/v1/images/getImageByBase64',
    method: 'post',
    params: params,
    authorization: false
  });
}
