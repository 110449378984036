<template>
  <div class="address-list-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="address-list-content">
      <ul class="address-list">
        <li v-for="(item, index) in addressList" :key="index" class="list-item" @click="commitAddress(item)">
          <p class="item-info">
            <span class="info-name">{{ item.receiver }}</span>
            <span class="info-phone">{{ item.tel }}</span>
            <img src="../../../assets/images/mine/edit.png" @click.stop="editAddress(item)">
          </p>
          <p class="item-address">{{ item.province + item.city + item.district + item.street + item.address }}</p>
        </li>
        <li v-if="addressList.length === 0" class="none-data">
          <img src="../../../assets/images/remind/none.png">
          <p class="none-tip">暂无地址！</p>
        </li>
      </ul>
      <div class="add-btn" @click.stop="editAddress({})">
        <img src="../../../assets/images/mine/add.png">
        新增收货地址
      </div>
    </div>
    <address-edit v-if="showAddressEdit" :item="editItem" @close="closeAddressEdit"></address-edit>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import axios from "axios";
import addressEdit from './addressEdit.vue';
import loading from '@/components/loading';

export default {
  props: {
    type: {
      type: String,
      default: () => ''
    }
  },
  components: {
    'address-edit': addressEdit,
    'custom-loading': loading
  },
  data() {
    return {
      addressList: [],
      showAddressEdit: false,
      loading: false,
      editItem: {
        receiver: '',
        tel: '',
        province: '',
        city: '',
        district: '',
        street: '',
        area: '',
        address: '',
        isDefault: false
      }
    };
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    getAddressList() {
      this.loading = true;
      axios.get("./city.json").then(res => {
        this.loading = false;
        if (res.data.length > 0) {
          res.data.forEach(v => {
            v.area = v.province + v.city + v.district + v.street;
            v.isDefault = v.isDefault === 1 ? true : false;
            v.addrId = v.id;
          });
        }
        res.area = res.province + res.city + res.district + res.street;
        this.addressList = res.data;
      }).catch(() => {
        this.loading = false;
      });
    },
    back() {
      this.$emit('close');
    },
    editAddress(item) {
      this.editItem = item;
      this.showAddressEdit = true;
    },
    closeAddressEdit(type) {
      if (type) {
        this.getAddressList();
      }
      this.editItem = {
        receiver: '',
        tel: '',
        province: '',
        city: '',
        district: '',
        street: '',
        area: '',
        address: '',
        isDefault: false
      };
      this.showAddressEdit = false;
    },
    commitAddress(item) {
      if (this.type) {
        this.$emit('close', item);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  .address-list-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9f9f9;
    z-index: 50;
    display: flex;
    flex-direction: column;
    .back {
      position: relative;
      height: 44px;
      border-top: 1px solid transparent;
      background: #fff;
      .back-icon {
        width: 10px;
        height: 10px;
        border-right: 2px solid #333;
        border-top: 2px solid #333;
        -webkit-transform: translate(0,-50%) rotate(-135deg);
        transform: translate(0,-50%) rotate(-135deg);
        margin-left: 15px;
        margin-top: 21px;
        }
      .back-name {
        position: absolute;
        top: 0;
        left: 32px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #333;
      }
    }
    .address-list-content {
      flex: 1;
      padding: 10px 15px 60px;
      overflow-y: auto;
      position: relative;
      .address-list {
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 0 11px;
        border-radius: 5px;
        .list-item {
          height: 55px;
          border-bottom: 1px solid #e2e2e2;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          .item-info {
            color: #333;
            font-size: 13px;
            font-weight: bold;
            .info-name {
              margin-right: 22.5px;
            }
            img {
              width: 14.5px;
              height: 14px;
              float: right;
            }
          }
          .item-address {
            color: #666;
            font-size: 11px;
            margin-top: auto;
          }
        }
      }
      .add-btn {
        background: #52B26B;
        height: 50px;
        line-height: 50px;
        font-size: 13px;
        color: #F1F1F1;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          vertical-align: top;
          // margin-top: 13px;
        }
      }
    }
  }
</style>
