<template>
  <div class="wrap">
    <p class="tc fs20"><strong>“小提醒”用户服务协议</strong></p>
    <p class="mt20px"><strong>欢迎您使用“小提醒”软件及相关服务！</strong></p>
    <p class="mt20px"><strong>一、特别提示</strong></p>
    <p>
      <strong>1、“小提醒”软件及相关服务，系指南京网罗科技有限公司及其关联方（以下简称“公司”）合法拥有并运营的、标注名称为“小提醒”或“小提醒Pro”的客户端应用程序（同时含其简化版等不同版本）向您提供的产品与服务。《“小提醒”用户服务协议》（以下称“本协议”）是您与公司就您下载、安装、注册、登录、使用（以下统称“使用”）“小提醒”软件，并获得“小提醒”软件提供的相关服务所订立的协议。</strong>
    </p>
    <p class="mt10px">
      <strong>2、在此特别提醒您（以下或称“用户”）在注册成为小提醒用户之前，应当认真阅读本《用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款，并选择接受或不接受本协议。其中，对于免除或限制责任条款等内容将以加粗形式提醒您注意，您应重点阅读。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为即视为您已充分阅读、理解并同意完全接受本协议的全部内容，用户应当受本协议的约束。如您未满18周岁，请您应在法定监护人的陪同下仔细阅读本协议并充分理解本协议，并征得法定监护人的同意后方可注册成为小提醒用户。</strong>
    </p>
    <p class="mt10px">
      <strong>3、本协议约定小提醒与用户之间关于“小提醒”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由小提醒随时更新，更新后的协议条款一旦公布即代替原来的协议条款，并将以弹窗或其他形式提醒用户。用户可在本APP中查阅最新版协议条款。在修改协议条款后，如您不接受修改后的条款，您也可以选择停止使用，小提醒亦有权因此终止您的注册进程及服务使用。您继续使用小提醒提供的服务，则视为您已充分理解最新协议，并同意作为本协议的一方当事人接受本协议以及其他与“小提醒”软件及相关服务相关的协议和规则（包括但不限于《“小提醒”隐私政策》）的约束。</strong>
    </p>

    <p class="mt20px"><strong>二、账号注册</strong></p>
    <p>1、用户在使用本服务前需要注册一个“小提醒”账号，经“小提醒”注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得本协议规定用户应当享有的一切权限；未经身份认证用户不享有任何使用权限。</p>
    <p>
      2、“小提醒”账号应当使用手机号码绑定注册，请用户使用尚未与“小提醒”账号绑定的手机号码，以及未被小提醒根据本协议封禁的手机号码注册“小提醒”账号。小提醒可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
    </p>
    <p>3、如果注册申请者有被小提醒封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，小提醒将拒绝其注册申请。</p>
    <p>
      4、在用户注册及使用本服务时，小提醒需要搜集能识别用户身份的个人信息以便小提醒可以在必要时联系用户，或为用户提供更好的使用体验。您同意小提醒为向您提供服务而收集您的个人信息，小提醒搜集的信息包括但不限于用户的姓名、手机号码。小提醒对这些信息的使用将受限于用户个人隐私信息保护的约束。
    </p>

    <p class="mt20px"><strong>三、账号注销</strong></p>
    <p>1、用户在需要终止使用“小提醒”帐号服务时，符合以下条件的，您可以申请注销您的“小提醒”帐号：</p>
    <p>（1）您仅能申请注销您本人的帐号，并依照“小提醒”的流程进行注销；</p>
    <p>（2）您仍应对您在注销帐号前且使用“小提醒”服务期间的行为承担相应责任；</p>
    <p>（3）注销成功后，帐号记录、功能等将无法恢复或提供。</p>
    <p>2、如您需要注销您的“小提醒”帐号，请联系客服或在意见反馈申请注销账号。</p>
    <p>3、您理解并承诺，您所设置的帐号不得违反国家法律法规及“小提醒”的相关规则，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设帐号，不得恶意注册“小提醒”帐号（包括但不限于频繁注册、批量注册帐号等行为）。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。公司有权对您提交的注册信息进行审核。
    </p>
    <p>
      4、您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任，包括但不限于您在“小提醒”软件及相关服务上进行的任何数据修改、言论发表、款项支付等操作行为可能引起的一切法律责任。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知公司。
    </p>

    <p class="mt20px"><strong>四、账户安全</strong></p>
    <p>1、用户一旦注册成功，成为小提醒的用户，将有权利使用自己的用户名及密码随时登录小提醒。</p>
    <p>2、用户对用户名和密码的安全负责，同时对以其账户进行的所有活动和事件负全部责任。</p>
    <p>3、用户不得以任何形式擅自转让或授权他人使用自己的小提醒账户。</p>
    <p>
      4、如果用户泄漏了账户密码，或用户发现任何人未经授权使用您的账号和密码的情况，应当立即修改密码并与小提醒客服人员取得联系，并授权小提醒暂停提供服务及展开调查，根据调查结果作出相应处理。用户理解小提醒根据用户请求采取行动需要合理时间，小提醒对采取行动前已经产生的后果和损失不承担任何责任。
    </p>
    <p>5、用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知小提醒工作人员。</p>
    <p>6、因黑客行为或用户的保管疏忽导致账号非法使用，“小提醒”不承担任何责任。</p>

    <p class="mt20px"><strong>五、用户声明与保证</strong></p>
    <p>1、用户承诺其为具有完全民事行为能力的民事主体，且具有履行本协议约定义务的能力。</p>
    <p>
      2、用户保证其为履行本协议而向小提醒提供的全部资料均真实、有效。用户有义务在注册时提供自己的真实身份信息，并保证诸如手机号码、姓名等必要身份信息的有效性及安全性，保证小提醒工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关身份信息实际变更时及时更新有关注册资料。
    </p>
    <p>3、用户在使用小提醒账号或本服务的过程中所制作、上载、复制、发布、传播的任何内容，不得违反国家相关法律制度，包括但不限于：</p>
    <p>（1）反对宪法所确定的基本原则的；</p>
    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>（3）损害国家荣誉和利益的；</p>
    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
    <p>（10）以非法民间组织名义活动的；</p>
    <p>（11）含有法律、行政法规禁止的其他内容的。</p>
    <p>4、用户不得利用小提醒账号或本服务制作、上载、复制、发布、传播任何干扰小提醒正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：</p>
    <p>（1）含有任何性或性暗示的；</p>
    <p>（2）含有辱骂、恐吓、威胁内容的；</p>
    <p>（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
    <p>（4）涉及他人隐私、个人信息或资料的；</p>
    <p>（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
    <p>（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
    <p>
      5、用户承若对其发表或者上传小提醒的所有信息均享有完整的知识产权，或者已经得到相关权利人的合法授权，如用户违反本条规定造成小提醒被第三人索赔的，用户应全额赔偿小提醒一切损失费用（包括但不限于各种赔偿费、律师代理费、诉讼费及为此支出的其他合理费用）。
    </p>
    <p>6、当第三人认为用户发表或上传小提醒的信息侵犯其权利，并根据相关法律法规向小提醒发送权利通知书时，用户同意小提醒可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，小提醒不会自动回复上述删除信息。
    </p>

    <p class="mt20px"><strong>六、服务内容</strong></p>
    <p>
      1、小提醒具体服务内容由小提醒根据实际情况提供，包括但不限于：电话提醒、短信提醒、公众号提醒、生日提醒、打卡提醒、喝水提醒、单次提醒、每年/月/周循环提醒等。具体以小提醒实际提供的功能或服务为准。
    </p>
    <p>2、小提醒有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明、违反公序良俗的内容（包括但不限于文字、语音、图片、视频、图文等）。</p>
    <p>3、用户认可，小提醒发给用户的所有通知、公告及其他消息都可通过用户所提供的联系方式向用户送达或通知。</p>

    <p class="mt20px"><strong>七、会员服务相关</strong></p>
    <p>小提醒VIP会员：是小提醒提供的虚拟增值服务，属于虚拟计算机软件类商品。根据《消费者权益保护法》第二十五条规定，计算机软件等数字化商品不支持7天无理由退款，故小提醒会员服务一经售出，不支持无条件退款。</p>

    <p class="mt20px"><strong>八、服务的暂停或终止</strong></p>
    <p>1、在下列情况下，小提醒有权视具体情况自主决定暂停或终止向用户提供服务：</p>
    <p>（1）在用户违反本服务协议相关规定时，小提醒有权视具体情况自主决定暂停或终止向该用户提供服务。</p>
    <p>（2）如小提醒通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法与用户取得联系的，小提醒将以系统通知的方式告知用户更改，如用户在三个工作日内仍未能提供新的联系方式，小提醒有权终止向该用户提供服务。
    </p>
    <p>（3）本服务条款终止或更新时，用户明示不愿接受新的服务条款的。</p>
    <p>（4）用户向小提醒申请注销其账户，经小提醒审核同意的。</p>
    <p>（5）其他小提醒认为需终止服务的情况。</p>
    <p>2、用户理解并同意：</p>
    <p>（1）服务终止后，小提醒没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。</p>
    <p>（2）用户在使用本服务期间存在违法行为或违反本协议行为的，小提醒仍可依据本协议想用户主张权利或依法依规向行政、司法等机关进行披露。</p>
    <p>（3）用户在使用本服务期间与其他第三方（如有）之间发生的关系，不因本服务或本协议的终止而终止，第三方仍有权向用户主张权利，用户应继续向第三方履行相关义务。</p>
    <p>3、小提醒保有删除系统内各类不符合法律政策或者不实信息内容而无须通知用户的权利。</p>
    <p>
      4、若用户未遵守本协议规定的或其他服务条件的行为，小提醒有权作出独立判断并采取暂停或关闭用户账号等措施，对于因此而造成用户无法正常使用账号及相关服务、无法正常获取用户账号内权益的等，小提醒不承担任何责任。用户须对自己在网上的言论和行为承担法律责任。对于涉嫌违反法律法规、涉嫌违法犯罪的行为、小提醒将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。
    </p>

    <p class="mt20px"><strong>九、服务的变更、中断</strong></p>
    <p>
      1、用户理解并同意，小提醒提供的服务是按照现有技术和条件所能达到的现状提供的。用户亦明确知道使用服务存在一定信息风险，小提醒将尽力维护用户使用服务的合法权益，但不担保服务一定能满足用户要求，也不担保服务的及时性、安全性、真实性、稳定性、正确性，对用户使用服务中出现的信息（包括但不限于用户发布的信息删除或存储失败），小提醒为此不承担任何责任。
    </p>
    <p>2、用户理解并同意，小提醒为了整体服务运营安全需要或定期或不定期地检测或者更新需要，有权视具体情况决定服务变更、中断、中止或终止服务，但小提醒将尽可能事先进行通告。</p>
    <p>
      3、用户理解并同意，鉴于网络服务的特殊性，服务可能会受到多种因素（包括但不限于境内外基础运营商的网络故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击或其他非小提醒技术能力范围内的事因等）的影响或干扰，小提醒不能随时或始终预见和防范上述因素造成的服务中断、用户存储信息丢失、未保存、出现乱码、错误接收、无法接收、延迟接收等，小提醒无需为此对任何用户或任何第三方承担责任。若发生上述因此，小提醒将尽可能及时通过公告、系统通知等其他合理方式通知受到影响的用户。
    </p>

    <p class="mt20px"><strong>十、知识产权条款</strong></p>
    <p>1、南京网罗科技有限公司对本服务中所有内容，包括但不限于设计、画面安排、软件架构、图片等均由我公司依法拥有其知识产权（包括但不限于商标权、专利权、著作权、商业秘密等）。</p>
    <p>2、非经南京网罗科技有限公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表小提醒程序或内容。</p>
    <p>3、尊重知识产权是用户应尽的义务，如有违反，应承担相应赔偿责任。</p>

    <p class="mt20px"><strong>十一、服务条款修改</strong></p>
    <p>1、小提醒有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，小提醒将会通过适当方式（包括但不限于弹窗）向用户提示修改内容。</p>
    <p>2、如果不同意小提醒对本服务条款所做的修改，用户有权停止使用本服务。</p>
    <p>3、如果用户继续使用网络服务，则视为用户接受小提醒对本服务条款所做的修改。</p>

    <p class="mt20px"><strong>十二、隐私保护</strong></p>
    <p>请阅读《隐私保护政策》。</p>

    <p class="mt20px"><strong>十三、其他</strong></p>
    <p>1、若小提醒已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</p>
    <p>2、用户同意保障和维护小提醒及其他用户的利益，由于用户在使用小提醒有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给小提醒及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。</p>
    <p>3、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和小提醒之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均有权向小提醒住所地人民法院提起诉讼。</p>
    <p>4、本协议的任何条款被司法部门认定为无效或不具可执行性，或违反所须适用的法律的，则该条款将被视为无效，其余条款仍然有效，对双方具有约束力。</p>
    <p>5、本协议最终解释权归南京网罗科技有限公司所有。</p>

    <p class="mt20px"><strong>十四、免责声明</strong></p>
    <p>1、用户明确同意其使用小提醒服务，以及对小提醒服务加以依赖所存在的全部责任和风险将完全有其自己承担，因其使用小提醒服务过程的行为，以及因此而产生的一切后果也由其自己承担，小提醒对用户不承担任何责任。</p>
    <p>2、小提醒不担保服务一定能满足用户要求，也不担保服务不会中断，对服务的及时性、安全性、准确性也都不作担保。</p>
    <p>3、用户因使用本服务而产生的任何间接的、附带的、特殊的、结果性的或惩戒性的损害，小提醒概不负责，均由用户自行承担。</p>

    <p class="mt20px"><strong>十五、如何联系我们</strong></p>
    <p>如果您对本协议有任何疑问、意见或建议，通过以下方式与我们取得联系：</p>
    <p>公司全称：南京网罗科技有限公司</p>
    <p>联系邮箱：enco@oneroo.com</p>
    <p>一般情况下，我们将在十五个工作日内回复。</p>

    <p class="mt20px"><strong>本协议自2022年12月31日更新。</strong></p>
    <p><strong>本协议自用户点击确认注册之时生效。</strong></p>
  </div>
</template>
<script>
export default {
  name: "agreement"
};
</script>
