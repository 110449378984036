<template>
  <div class="share-container" @click="closeShareBtn">
    <div id="share-image-box" class="share-image-box">
      <canvas id="share-canvas" :style="`width:${canvasWidth}px;height:${canvasHeight}px`"></canvas>
      <img v-if="shareImg" :src="shareImg" crossorigin="Anonymous">
    </div>
    <div class="share-btns">
      <div class="change-theme" @click="changeTheme">
        <img src="../assets/images/find/change.png" alt="">
        <p class="theme-name">更换主题</p>
      </div>
      <div class="share-btn" @click.stop="share">分享这一刻</div>
    </div>
    <div class="share-info">
      <custom-user v-if="type !== '邀请'" :users="shareInfo.todaySignUsers"></custom-user>
      <p class="info-tip" :style="type === '邀请' || (shareInfo.todaySignUsers && shareInfo.todaySignUsers.length === 0) ? 'margin-left: 0;' : 'margin-left:10px;'">
        {{ type === '邀请' ? '邀请成员壮大你的打卡圈' : '你是今日打卡第' + shareInfo.todayRank + '名' }}
      </p>
    </div>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getShareInfo, getInviteInfo, changeTheme } from '@/api/components';
import config from '../../config/config.default.js';
import user from './user';
import wxJsConfig from '@/utils/wxJsConfig.js';
import $ from 'jquery';
import loading from '@/components/loading';

export default {
  props: {
    id: {
      type: Number,
      require: true
    },
    status: {
      type: Boolean,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  components: {
    'custom-user': user,
    'custom-loading': loading
  },
  data() {
    return {
      canvasWidth: 0,
      canvasHeight: 0,
      shareInfo: {},
      canvasImg: '',
      linkUrl: '',
      shareImg: '',
      loading: false
    };
  },
  mounted() {
    this.linkUrl = config[process.env.NODE_ENV].originUrl + '/find/circleDetail?id=' + this.id;
    let canvasBox = document.getElementsByClassName('share-image-box')[0];
    this.canvasWidth = Number(canvasBox.getBoundingClientRect().width.toFixed());
    this.canvasHeight = Number(canvasBox.getBoundingClientRect().height.toFixed());
    this.loading = true;
    if (this.type === '邀请') {
      this.getInviteInfo();
    } else {
      this.getShareInfo();
    }
  },
  methods: {
    getShareInfo() {
      getShareInfo({ 
        circleId: this.id, 
        linkUrl: this.linkUrl
      }).then(res => {
        this.shareInfo = res;
        this.shareInit();
        this.loading = false;
        this.initCanvas();
      }).catch(() => {
        this.loading = false;
      });
    },
    getInviteInfo() {
      getInviteInfo({ 
        circleId: this.id,
        linkUrl: this.linkUrl
      }).then(res => {
        this.shareInfo = res;
        this.initCanvas();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    share() {
      this.$emit('change', { status: !this.status, img: this.canvasImg });
    },
    closeShareBtn() {
      this.$emit('change', { status: false, img: this.canvasImg });
    },
    changeTheme() {
      changeTheme({ circleId: this.id, }).then(res => {
        if (res.image !== this.shareInfo.backImage) {
          this.loading = true;
          this.clearCanvas();
          this.shareInfo.backImage = res.image;
          this.initCanvas();
        }
      });
    },
    initCanvas() {
      this.canvas = document.getElementById('share-canvas');
      this.ctx = this.canvas.getContext('2d');
      let backingStore = this.ctx.backingStorePixelRatio ||
        this.ctx.webkitBackingStorePixelRatio ||
        this.ctx.mozBackingStorePixelRatio ||
        this.ctx.msBackingStorePixelRatio ||
        this.ctx.oBackingStorePixelRatio ||
        this.ctx.backingStorePixelRatio || 1;
      this.scale = (window.devicePixelRatio || 1) / backingStore;
      this.canvas.width = this.canvasWidth * this.scale;
      this.canvas.height = this.canvasHeight  * this.scale;
      this.ctx.scale(this.scale, this.scale);
      this.drawBackgroundImage();
    },
    clearCanvas() {
      this.canvas = document.getElementById('share-canvas');
      this.canvas.width = 0;
      this.canvas.height = 0;
    },
    drawBackgroundImage() {
      let img = new Image();
      img.setAttribute('crossOrigin','Anonymous');
      img.src = this.shareInfo.backImage;
      img.width = this.canvasWidth;
      img.height = this.canvasHeight;
      img.onload = () => {
        this.ctx.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
        if (this.type === '邀请') {
          this.drawText('昵称：' + this.shareInfo.nickname, 85, 51, '12px MicrosoftYaHei', '#fff');
          this.drawText('邀请你加入' + this.shareInfo.circleName, 21, 95, '12px MicrosoftYaHei', '#fff');
        } else {
          this.drawText(this.shareInfo.address, 85, 51, '12px MicrosoftYaHei', '#fff');
          this.drawText(this.shareInfo.signTime, 21, 117, '25px BalooBhai', '#fff');
          this.drawText(this.shareInfo.signDate, 90, 117, '12px BalooBhai', '#fff');
          this.drawText('连续打卡' + this.shareInfo.serialSignDays + '天', 21, 145, '16px MicrosoftYaHei', '#fff');
          this.drawText('让努力被看见！', 80, this.canvasHeight - 13 - 9, '16px MicrosoftYaHei', '#fff');
        }
        this.drawQrCode(this.shareInfo.qrCode, 10, this.canvasHeight - 62 - 13, 62);
      };
    },
    drawAvatarCircle(src, x, y, r) {
      let d = r * 2;
      let cx = x + r;
      let cy = y + r;
      this.ctx.arc(cx, cy, r, 0, 2 * Math.PI);
      this.ctx.strokeStyle = 'transparent'; // 设置绘制圆形边框的颜色
      this.ctx.stroke(); // 绘制出圆形，默认为黑色，可通过 ctx.strokeStyle = '#FFFFFF'， 设置想要的颜色
      this.ctx.clip();
      this.drawAvatarImage(src, x, y, d);
    },
    drawAvatarImage(src, x, y, d) {
      let img = new Image();
      img.setAttribute('crossOrigin','Anonymous');
      img.src = src;
      img.width = d;
      img.height = d;
      img.onload = () => {
        this.ctx.drawImage(img, x, y, d, d);
        try {
          this.canvasImg = this.canvas.toDataURL('image/png');
        } catch (error) {
          alert(error);
        }
        this.getImage();
      };
    },
    shareInit() {
      wxJsConfig.init([
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ]).then(() => {
        wx.updateAppMessageShareData({
          title: this.shareInfo.circleName, // 分享标题
          desc: this.shareInfo.circleIntro, // 分享描述
          link: this.linkUrl, // 分享链接
          imgUrl: this.shareImg, // 分享图标
        });
        wx.updateTimelineShareData({
          title: this.shareInfo.circleName, // 分享标题
          desc: this.shareInfo.circleIntro, // 分享描述
          link: this.linkUrl, // 分享链接
          imgUrl: this.shareImg, // 分享图标
        });
      });
    },
    drawText(text, x, y ,font, color) {
      this.ctx.font = font;
      this.ctx.fillStyle = color;
      this.ctx.fillText(text, x, y);
    },
    drawQrCode(src, x, y, d) {
      let img = new Image();
      img.setAttribute('crossOrigin','Anonymous');
      img.src = src;
      img.width = d;
      img.height = d;
      img.onload = () => {
        this.ctx.drawImage(img, x, y, d, d);
        this.drawAvatarCircle(this.shareInfo.avatar, 20, 20, 25);
      };
    },
    getImage() {
      this.loading = false;
      $.ajax({
        type: 'post',
        url: config[process.env.NODE_ENV].baseUrl + '/api/v1/images/getImageByBase64',
        data: JSON.stringify({ base64Str: this.canvasImg }),
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        success: (res) => {
          this.loading = false;
          this.shareImg = res.image;
          this.shareInit();
        },
        fail() {
          this.loading = false;
        },
        complete() {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .share-container {
    width: 345px;
    margin: 0 15px;
    height: calc(95% - 118px);
    position: absolute;
    top: 5%;
    left: 0;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    .share-image-box {
      width: 100%;
      height: calc(100% - 86px);
      img {
        width: 100%;
        height: 100%;
      }
      canvas {
        position: absolute;
        top: 0;
        right: -200%;
      }
    }
    .share-btns {
      margin: 8px 0;
      display: flex;
      justify-content: space-between;
      height: 40px;
      padding: 0 8px 0 11.5px;
      .change-theme {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        img {
          width: 22px;
          height: 22px;
        }
        .theme-name {
          font-size: 10px;
          color: #52B26B;
          line-height: 18px;
        }
      }
      .share-btn {
        width: 270px;
        height: 100%;
        background: #52B26B;
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 13px;
      }
    }
    .share-info {
      width: 270px;
      margin-left: auto;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .info-tip {
        font-size: 10px;
        color: #52B26B;
      }
    }
  }
</style>
