<template>
  <div>
    <div class="btn">
      <div class="btn_left" @click="err">取消</div>
      <div class="btn_right" @click="send">确定</div>
    </div>
    <div class="select">
      <div>
        <van-picker title="月" :columns="mlist" @change="lunarm" />
      </div>
      <div>
        <van-picker title="月" :columns="dlist" @change="lunard" />
      </div>
      <div>
        <van-picker title="月" :columns="hourList" @change="lunarh" />
      </div>
      <div>
        <van-picker title="月" :columns="minList" @change="lunarmin" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  GetLunarDateString,
  GetSolarDateString,
  SolarYearDays,
  SolarMonthDays,
  LunarYearDays,
  LunarMonthDays,
  GetLeapMonth,
  FormatLunarMonth,
  FormatLunarDay,
  FormatLunarYear
} from "./lucnDate.js";
import { stringify } from "querystring";
import { Picker } from 'vant'
import { nextTick } from "q";
export default {
  data() {
    return {
      ylist: [],
      mlist: [],
      dlist: [],
      data: {},
      choseData: {
        minute: "00",
        hour: "00",
        month: "正月",
        day: "初一",
      },
      hourList: [],
      minList: []
    };
  },
  components: {
    "van-picker": Picker
  },
  methods: {
    // 取消
    err() {
      // 向父组件传值
      this.$emit("func", '');
    },
    // 确认
    send() {
      this.choseData.value = this.choseData.month + this.choseData.day +' '+ this.choseData.hour + "时" + this.choseData.minute
      this.choseData.month = this.cahngeMonth(this.choseData.month)
      this.choseData.day = this.changeDay(this.choseData.day)
      this.$emit("func", this.choseData);
    },
    // 年
    // 月
    lunarm(e, value) {
      // 将农历月转化为公历月
      this.choseData.month = value
      // 输入当年当月的天数
      this.dlist = this.lunarDayOptions(this.choseData.year, this.m);
    },
    changeDay(value) {
      let m = ""
      let arr = value.split("")
      let a = arr[0]
      let b = arr[1]
      if(a=="初"){
        m += 0;
      }
      if(a=="十"){
        m += 1;
      }
      if(a=="廿"||a=="二"){
        m += 2;
      }
      if(a=="三"){
        m += 3;
      }
      switch (b) {
        case "十":
          m += 0;
          break;
        case "一":
          m += 1;
          break;
        case "二":
          m += 2;
          break;
        case "三":
          m += 3;
          break;
        case "四":
          m += 4;
          break;
        case "五":
          m += 5;
          break;
        case "六":
          m += 6;
          break;
        case "七":
          m += 7;
          break;
        case "八":
          m += 8;
          break;
        case "九":
          m += 9;
          break;
        default:
      }
      if(a=="初"&&b=="十"){
        m=10
      }
      return m
    },
    cahngeMonth(value) {

      let m
      switch (value) {
        case "正月":
          m = 1;
          break;
        case "二月":
          m = 2;
          break;
        case "三月":
          m = 3;
          break;
        case "四月":
          m = 4;
          break;
        case "五月":
          m = 5;
          break;
        case "六月":
          m = 6;
          break;
        case "七月":
          m = 7;
          break;
        case "八月":
          m = 8;
          break;
        case "九月":
          m = 9;
          break;
        case "十月":
          m = 10;
          break;
        case "冬月":
          m = 11;
          break;
        case "腊月":
          m = 12;
          break;
        default:
      }
      return m
    },
    // 天
    lunard(e, value) {
      this.choseData.day = value
    },
    lunarh(e, value) {
      this.choseData.hour = value.split("时")[0]
    },
    lunarmin(e, value) {
      this.choseData.minute = value.split("分")[0]
    },
    numToChinese(num) {
      num = num.toString()
      let arr = num.split('')
      let str = ''
      arr.forEach(item => {
        str += data[Number(item)]
      })
      return str
    },
    // 循环年
    lunarYearOptions(from, to, curyear) {
      var str = [];
      for (var i = from; i <= to; i++) {
        str.push(i + "年");
      }
      return str;
    },
    // 循环月
    lunarMonthOptions(year, month) {
      if (typeof (year) == "string") {
        year = year.split('年')[0]
      }
      year = Number(year)
      var leapMonth = GetLeapMonth(year);
      var str = [];
      var from = 1;
      var to = 12;
      var leap = false;
      if (month > 12) {
        leap = true;
        month = month / 10;
      }
      for (var i = from; i <= to; i++) {
        str.push(FormatLunarMonth(i));
      }
      return str;
    },
    // 循环天
    lunarDayOptions(year, month, day) {
      year = year ? year : new Date().getFullYear()
      if (typeof (year) == "string") {
        year = year.split('年')[0]
      }
      year = Number(year)
      var from = 1;
      var to = 0;
      var leap = false;
      if (month > 12) {
        month = month / 10;
        leap = true;
        var monthdaynum = (LunarMonthDays(year, month) >> 16) & 0xffff;
      } else {
        var monthdaynum = LunarMonthDays(year, month) & 0xffff;
      }
      to = 30;
      var str = [];
      for (var i = from; i <= to; i++) {
        str.push(FormatLunarDay(i));
      }
      return str;
    }
  },
  mounted() {
    for (let i = 0; i < 60; i++) {
      if (i < 24) {
        this.hourList.push(i < 10 ? '0' + i + '时' : i + '时');
      }
      this.minList.push(i < 10 ? '0' + i + '分' : i + '分');
    }
    // //设定当前农历公历日期
    var date = new Date()
    var curSolarDate = [date.getFullYear(), 1, 1];
    // 年,月，日
    var curLunarDate = GetSolarDateString(curSolarDate);
    this.ylist = this.lunarYearOptions(1902, date.getFullYear(), curLunarDate[0]).reverse();
    this.mlist = this.lunarMonthOptions(
      curLunarDate[0],
      curLunarDate[1],
      curLunarDate[2]
    );
    this.dlist = this.lunarDayOptions(date.getFullYear(), '正月')
  }
};
</script>
<style scoped>
.btn {
  height: 0.3rem;
  line-height: 1rem;
  font-size: 0.29rem;
}

.btn_left {
  float: left;
  padding-left: 0.1rem;
  color: #969799;
}

.btn_right {
  float: right;
  padding-right: 0.1rem;
  color: #576b95;
}

select {
  border: none;
  outline: none;
  height: 120%;
}

.select {
  width: 100%;
  /* text-align: center; */
  display: flex;
  justify-content: space-around;
  overflow-y: hidden;
}

.select_y option {
  /* width: 33%; */
  /*去掉默认的下拉三角*/
  /* appearance: none; */
  height: 0.7rem;
  padding: 0.2rem 0;
}

.select_m option {
  /* width: 33%; */
  height: 0.7rem;
  padding: 0.2rem 0;
}

.select_d option {
  /* width: 33%; */
  height: 0.7rem;
  padding: 0.2rem 0;
}
</style>>
  