<template>
  <div class="circle-list-item">
    <div class="circle-info-content">
      <div class="image-box">
        <img :src="item.avatar">
      </div>
      <div class="item-info">
        <p class="info-title-box">
          <span class="info-title">{{ item.title }}</span>
          <span 
            class="info-status" 
            :style="
              item.status === -1 ? 'color: #333;' : (
                item.status === 0 ? 'color: #F26A13;' : (
                  item.status === 1 ? 'color: #52B26B;' : ''))
            "
          >
            {{ 
              item.status === -1 ? '已过期' : (
                item.status === 0 ? '待付款' : (
                  item.status === 1 ? '已付款' : '')) 
            }}
          </span>
        </p>
        <p class="info-order-number">订单编号：{{ item.orderNum }}</p>
		<p class="info-order-time">订单时间：{{ item.orderTime }}</p>
        <p class="info-detail">
          <span class="info-price">实付款￥{{ item.price }}</span>
        </p>
      </div>
    </div>
    <!-- <p v-if="type === '已完成'" class="tips">
      正在处理：商家将在10天内处理
    </p> -->
    <div class="item-btns">
      <span class="ticket" @click="applyTicket">
        {{ 
          (type === '待发货' || type === '待收货' || (type === '已完成' && item.isFree === 0)) ? (item.isInvoice == 2 ? '已开票' : item.isInvoice == 2 ? '已开票' : (item.isInvoice === 1 ? '已申请' : '申请开票')) : ''
        }}
      </span>
      <span 
        v-if="!(type === '已完成' && item.orderType !== 1)"
        class="operate-btn operate-btn-second"
        @click.stop="operateMiddle(item.id)"
      >
        {{ 
          type === '待收货' || (type === '已完成' && item.orderType === 1) ? '查看物流' : (
            type === '待付款' ? '取消订单' : (
              type === '待发货' ? '取消订单' : ''
            )) 
        }}
      </span>
      <span
        :style="type === '待付款' ? 'background: #F26A13;' : ''"
        class="operate-btn operate-btn-third"
        @click.stop="operateRight(item.id, item.circleId)"
      >
        {{ 
          type === '待收货' ? '确认收货' : (
            type === '待付款' ? '去付款' : (
              type === '待发货' ? '提醒发货' : (
                type === '已完成' ? '再次购买' : ''
              ))) 
        }}
      </span>
    </div>
    <custom-loading :loading="loading" :content="loadingContent"></custom-loading>
  </div>
</template>

<script>
import { closeOrder, delivery, finish } from '@/api/mine/myOrders';
import { Notify } from 'vant';
import { getWxPayConfig } from '@/api/common';
import wxJsConfig from '@/utils/wxJsConfig.js';
import loading from '@/components/loading.vue';

export default {
  props: {
    item: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  components: {
    'custom-loading': loading
  },
  data() {
    return {
      loading: false,
      loadingContent: ''
    };
  },
  methods: {
    joinNow() {
      this.$emit('join');
    },
    closeOrder(id) {
      closeOrder({ orderId: id }).then(() => {
        Notify({ type: 'success', message: '订单取消成功' });
        this.$emit('change');
      });
    },
    delivery(id) {
      delivery({ orderId: id }).then(() => {
        Notify({ type: 'success', message: '订单提醒成功' });
      });
    },
    finish(id) {
      finish({ orderId: id }).then(() => {
        Notify({ type: 'success', message: '订单确认成功' });
        this.$emit('change');
      });
    },
    operateMiddle(id) {
      if (this.type === '待收货' || this.type === '已完成') {
        //查看物流
        this.$emit('open', this.item);
      } else if (this.type === '待付款') {
        this.closeOrder(id);
      } else if (this.type === '待发货') {
        this.closeOrder(id);
      }
    },
    operateRight(id, circleId) {
      if (this.type === '待收货') {
        this.finish(id);
      } else if (this.type === '待付款') {
        // this.toDetail(id);
        this.loading = true;
        this.loadingContent = '支付中...';
        this.getWxPayConfig(id, circleId);
      } else if (this.type === '待发货') {
        this.delivery(id);
      } else if (this.type === '已完成') {
        this.toDetail(id);
      }
    },
    applyTicket() {
      if (this.item.isInvoice === 1) {
        return;
      }
      this.$emit('apply', this.item);
    },
    toDetail() {
      this.$router.push({
        name: 'CircleDetail',
        query: {
          id: this.item.circleId
        }
      });
    },
    getWxPayConfig(id, circleId) {
      getWxPayConfig({ orderId: id, payType: 0 }).then((res) => {
        console.log(res);
        this.initWxPay(res, circleId, id);
      });
    },
    initWxPay(data, circleId, orderId) {
      const that = this;
      wxJsConfig.init([
        'chooseWXPay'
      ]).then(() => {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: function (res) {
            that.closeLoading();
            if (res.errMsg == 'chooseWXPay:ok') {
              Notify({type: 'success', message: '支付成功' });
              that.$emit('change');
              that.$router.push({
                name: 'PaySetting',
                query: {
                  circleId: circleId,
                  orderId: orderId
                }
              });
            } else {
              Notify({type: 'danger', message: '支付失败' });
            }
          },
          cancel() {
            that.closeLoading();
            Notify({type: 'warning', message: '取消支付' });
          },
          fail() {
            that.closeLoading();
            Notify({type: 'danger', message: '支付失败' });
          }
        });
      });
    },
    closeLoading() {
      this.loading = false;
      this.loadingContent = '';
    }
  }
};
</script>

<style lang="scss" scoped>
  .circle-list-item {
    padding: 10px 0;
    border-bottom: 1px solid #E2E2E2;
    border-radius: 5px;
    &:last-child {
      border-bottom: none;
    }
    .circle-info-content {
      display: flex;
      padding-bottom: 10px;
      .image-box {
        width: 100px;
        height: 80px;
        border-radius: 5px;
        margin-right: 10px;
        overflow: hidden;
		background: #eee;
		text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .item-info {
        flex: 1;
        height: 100%;
        display: flex;
        height: 92px;
        flex-direction: column;
        padding-right: 14px;
        .info-title-box {
          font-size: 13px;
          color: #333;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
          .info-title {
            flex: 1;
            font-weight: bold;
			margin-right: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
          }
          .info-status {
            font-size: 12px;
          }
        }
        .info-order-number, .info-order-time {
          font-size: 12px;
          color: #666;
          margin-top: 5px;
        }
        .info-detail {
          display: flex;
          margin-top: 5px;
          justify-items: flex-end;
          .info-price {
            color: #333;
            font-size: 13px;
            margin-left: auto;
            color: #52B26B;
          }
        }
      }
    }
    .tips {
      color: #333;
      font-size: 12px;
      line-height: 26px;
      background: #f4f4f4;
    }
    .item-btns {
      display: flex;
      margin-top: 10px;
      .ticket {
        color: #666;
        font-size: 10px;
        line-height: 25px;
        margin-right: auto;
      }
      .operate-btn {
        width: 80px;
        height: 25px;
        line-height: 25px;
        background: #52B26B;
        text-align: center;
        color: #fffefe;
        font-size: 12px;
        border-radius: 12.5px;
        margin-left: 10px;
        &.operate-btn-first {
          border: 1px solid #888;
          color: #888;
          background: #fff;
        }
        &.operate-btn-second {
          border: 1px solid #52B26B;
          color: #52B26B;
          background: #fff;
        }
      }
    }
  }
</style>
