const config = {
  emailReg: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
	phoneReg: /^1(3|4|5|6|7|8|9)\d{9}$/,
  nameReg: '',
  passwordReg: '',
  appId: 'wx2ef36dd709f14fc6' //wxef568545579c9cf7
};

export default {
  config
};
