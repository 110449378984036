<template>
  <div class="user-content" :style="'width:' + (25 + (users.length > 5 ? 4 : users.length - 1) * 15) + 'px'" @click="showMoreUsers">
    <div 
      v-for="(item, index) in users"
      :key="index" 
      :style="index > 0 ? (index > 4 ? 'display:none;' : 'left:' + (index * 15) + 'px;z-index:'+ (5 - index)) : ''"
      class="user-item"
    >
      <img :src="item">
    </div>
    <div
      v-if="users.length > 5"
      style="left:60px;z-index:1;"
      class="user-item"
    >
      <img src="../assets/images/find/more.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    showMoreUsers() {
      this.$emit('show');
    }
  }
};
</script>

<style lang="scss" scoped>
  .user-content {
    height: 25px;
    position: relative;
    .user-item {
      width: 25px;
      height: 25px;
      overflow: hidden;
      padding: 1px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background: #fff;
      z-index: 5;
      img {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        vertical-align: top;
      }
    }
  }
</style>
