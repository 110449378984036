<template>
    <div class="beforeLogin">
        <div class="bellOut">
            <div>
                <img src="../../assets/images/login/logo.png" class="logo" alt="">
            </div>
            <div class="bell">
                <span class="pro">
                    Pro
                </span>
            </div>
        </div>
        <div class="noPass">
            <img src="../../assets/images/login/word.png" class="word" alt="">
            <div class="moveXAnimation" style="width:100%;height:100%;position:absolute;left:0;top:0;background:#fff">
            </div>
        </div>
        <div class="loginBottom">
            <van-button type="primary" class="btnBorder" size="large" @click="loginWechat"><img
                    src="../../assets/images/login/wechat.svg" class="wx" alt=""> <span>微信登录</span></van-button>
            <van-button class="btnBorder" style="background: #F6F6F6;" size="large" @click="loginPhone"><img
                    src="../../assets/images/login/icon_mp.png" class="wx phone" alt=""> <span>手机登录</span></van-button>
            <div class="enSure">
                <van-checkbox v-model="readChecked" icon-size="16px" checked-color="#52B26B">
                    <span class="read">我已阅读并同意</span>
                    <span class="agreement" @click.stop="agreementPopal = true">用户协议、隐私政策</span>
                </van-checkbox>
            </div>
        </div>
        <van-dialog v-model="agreementPopal" confirmButtonColor="#52b26b" title="用户协议、隐私政策" show-cancel-button :showConfirmButton="true"
            :theme="'round-button'" confirm-button-text="同意" :showCancelButton="false" @confirm="readChecked = true">
            <div style="height:60vh;overflow:scroll;padding: 10px 18px;">
                <agreement />
            </div>
        </van-dialog>
        <van-dialog v-model="dialogPopal" title="用户协议、隐私政策" show-cancel-button @confirm="loginCoimired">
            <div style="padding:10px">
                为了更好地保障您的合法权益，同事遵守相关监管要求，请您阅读并同意<font color="#51B26B" @click.stop="agreementPopal = true">用户协议、隐私政策
                </font>
            </div>
        </van-dialog>
    </div>
</template>
  
<script>
import { getCode } from '@/utils/commonFunction';
import agreement from '../../components/agreement.vue';
import { Dialog } from 'vant';
import { Checkbox } from 'vant';
export default {
    data() {
        return {
            readChecked: false,
            agreementPopal: false,
            dialogPopal: false,
            loginType: ''
        };
    },
    components: {
        "van-checkbox": Checkbox,
        [Dialog.Component.name]: Dialog.Component,
        agreement
    },
    methods: {
        loginWechat() {
            if (!this.readChecked) {
                this.dialogPopal = true;
                this.loginType = 1;
                return;
            };
            getCode();
        },
        loginPhone() {
            if (!this.readChecked) {
                this.dialogPopal = true;
                this.loginType = 2;
                return;
            };
            this.$router.push({
                path: 'LoginPhone',
                query: {
                    type: 1
                }
            });
        },
        loginCoimired(){
            this.readChecked = true;
            if (this.loginType == 2) {
                this.loginPhone();
            } else {
                this.loginWechat();
            }
        }
    },
    watch: {
        '$route': { // $route可以用引号，也可以不用引号  监听的对象
            handler(to, from){
                if (localStorage.getItem('token')&&localStorage.getItem('token_deadtime')&&(localStorage.getItem('token_deadtime') > new Date().getTime())){
                    location.replace(location.origin)
                }
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }
    },
    name: "LoginBefore"
};
</script>
  
<style lang="scss" scoped>
.beforeLogin {
    width: 100%;
    height: auto;
	min-height: calc(100vh);
	padding-top: 28%;
    position: relative;
    /*overflow: hidden;*/
    background: #FFFFFF;

    .bellOut {
        margin: auto;
        width: 100px;
        overflow: hidden;
        height: 100px;
        background: #51B26B;
        position: relative;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            width: 60px;
        }
    }

    .bell {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: absolute;
        top: -29%;
        right: -29%;
        background: #FFB33C;

        .pro {
            font-family: 'DIN Alternate';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            transform: rotate(45deg);
            transform-origin: center;
            position: absolute;
            bottom: 13%;
            left: 13%;
            color: #FFFFFF;
        }
    }

    .noPass {
        width: 100%;
        text-align: center;
        margin: 32px auto 0;
        position: relative;
        .word {
            width: 236px;
        }
    }

    .loginBottom {
        position: absolute;
        margin-top: 60px;
        width: 100%;
        text-align: center;

        .btnBorder {
            border-radius: 8px;
            width: 295px;
            height: 48px;

            span {
                vertical-align: middle;
            }

            .wx {
                width: 20px;
                vertical-align: top;
                margin-right: 9px;
            }

            .phone {
                width: 12px;
            }
        }

        .btnBorder:nth-child(n+2) {
            margin-top: 16px;

        }

        .enSure {
            display: flex;
            margin-top: 24px;
            justify-content: center;

            .read {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #000000;
            }

            .agreement {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #51B26B;
            }
        }
    }

}
@keyframes moveX {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
}
.moveXAnimation{
    animation: moveX linear 2.5s forwards;
}
</style>
  