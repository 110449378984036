import { request } from '@/utils/ajax.js';
export function register(params = {}) {
  return request({
    url: '/api/v1/user/register',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    authorization: true
  });
};
export function login(params = {}) {
  return request({
    url: '/api/v1/user/login',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    authorization: true
  });
};
export function forgetPwd(params = {}) {
  return request({
    url: '/api/v1/user/forgetPwd',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    authorization: true
  });
};