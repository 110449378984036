<template>
  <div class="calendar">
    <!-- <p class="calendar-title">{{ monthYear }}</p> -->
    <div class='w100P showData'>
      <div class="title" :style="new Date().getDay() == 7 ? 'color: #52B26B;' : null">日</div>
      <div class="title" :style="new Date().getDay() == 1 ? 'color: #52B26B;' : null">一</div>
      <div class="title" :style="new Date().getDay() == 2 ? 'color: #52B26B;' : null">二</div>
      <div class="title" :style="new Date().getDay() == 3 ? 'color: #52B26B;' : null">三</div>
      <div class="title" :style="new Date().getDay() == 4 ? 'color: #52B26B;' : null">四</div>
      <div class="title" :style="new Date().getDay() == 5 ? 'color: #52B26B;' : null">五</div>
      <div class="title" :style="new Date().getDay() == 6 ? 'color: #52B26B;' : null">六</div>
    </div>
    <div class='content'>
      <div v-if="calendarType === 'month'" class="back-month">
        {{ currentMonth >= 10 ? currentMonth : '0' + currentMonth }}
      </div>
      <div v-for="(item, index) in allArr" :key="index" :class="['itemData', {
        'week-hidden': calendarType === 'week' && (Math.ceil((index - 0 + 1) / 7) != col),
        'nowDay': item.month === 'current' && nowYear === currentYear && nowMonth === currentMonth && nowDate === item.date && !isInit,

      }]" @click="chooseDay(item)">
        <div :class="{
      }">
          <p :class="['date', {
        'today': currentYear === new Date().getFullYear() && currentMonth === new Date().getMonth() + 1 && item.date === new Date().getDate() && item.month === 'current'
      }]">{{ item.month === 'current' ? (currentYear === new Date().getFullYear() && currentMonth === new
        Date().getMonth() + 1 && item.date === new Date().getDate() && item.month === 'current') ? '今' : item.date :
        ''
            }}</p>
          <p :class="['nong', {
        'today': currentYear === new Date().getFullYear() && currentMonth === new Date().getMonth() + 1 && item.date === new Date().getDate() && item.month === 'current'
      }]">
            {{ item.month === 'current' ? lunarcalendar1(`${currentYear}-${currentMonth}-${item.date}`) : '' }}
          </p>
        </div>
      </div>
      <div class="pre-month operate" @click="gotoPreMonth">
        <img src="../assets/images/calendar/arrow-left.png">
      </div>
      <div class="next-month operate" @click="gotoNextMonth">
        <img src="../assets/images/calendar/arrow-right.png">
      </div>
    </div>
    <div class="arrow-btn">
      <img src="../assets/images/remind/btn_arrowd.png"
        :style="calendarType === 'month' ? 'transform: rotate(180deg);' : ''" @click="changeCalendarType">
    </div>
  </div>
</template>

<script>
import lunar from "lunar-javascript"
var holidays = ['妇女节', '植树节', '清明节', '劳动节', '青年节', '母亲节', '护士节', '儿童节', '端午节', '父亲节', '建党节', '建军节', '七夕节', '教师节', '中秋节', '国庆节', '重阳节', '11.11', '感恩节', '圣诞节', '元旦节', '情人节', '春节', '元宵节']
export default {
  props: {
    calendarData: {
      type: Array,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      currentMonthDateLen: 0, // 当月天数
      preMonthDateLen: 0, // 当月中，上月多余天数
      currentYear: new Date().getFullYear(), // 当前显示的年
      currentMonth: new Date().getMonth() + 1, // 当前显示的月
      monthYear: new Date().getFullYear() + '-' + (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)), //当前年月
      nowYear: new Date().getFullYear(), // 当前显示的月
      nowMonth: new Date().getMonth() + 1, // 当前显示的月
      nowDate: new Date().getDate(), // 当前显示的日 
      allArr: [],
      isInit: true,
      calendarType: 'week',
      weekStartIndex: 0,
      row: 0,
      col: 0
    };
  },
  mounted() {
    this.getAllArr();
    if (this.nowYear === this.currentYear && this.nowMonth === this.currentMonth) {
      this.allArr.forEach((item, i) => {
        if (item.date == this.nowDate) {
          this.row = i % 7 - 0 + 1
          this.col = Math.floor(i / 7) - 0 + 1
        }
      })
    } else {
      this.allArr.forEach((item, i) => {
        if (item.date == this.nowDate) {
          this.row = i % 7 - 0 + 1
          this.col = Math.floor(i / 7) - 0 + 1
        }
      })
    }
    for (let i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i].date == this.nowDate) {
        this.row = (i % 7) - 0 + 1;
        this.col = Math.floor(i / 7) - 0 + 1;
      }
    }
    // console.log(this.allArr,this.nowDate)
  },
  methods: {
    lunarcalendar1(ymd) {
      let arrYmd = ymd.split("-")
      let nowFestivals = [
        ...lunar.Solar.fromDate(new Date(ymd)).getFestivals(),
        ...lunar.Solar.fromYmd(arrYmd[0], arrYmd[1], arrYmd[2]).getLunar().getFestivals()
      ]
      if (lunar.Solar.fromYmd(arrYmd[0], arrYmd[1], arrYmd[2]).getLunar().getJie() == '清明') {
        nowFestivals.push("清明节")
      }
      if (arrYmd[1] == 5 && arrYmd[2] == 12) {
        nowFestivals.push("护士节")
      }
      if (arrYmd[1] == 11 && arrYmd[2] == 11) {
        nowFestivals.push("11.11")
      }
      let arr = lunar.Solar.fromDate(new Date(ymd)).getLunar().toString().split('年')[1].split('月')
      let returnStr = ''
      if (arr[1] == '初一') {
        returnStr = arr[0] + '月'
      } else {
        returnStr = arr[1]
      }
      for (let i = 0; i < nowFestivals.length; i++) {
        if (holidays.includes(nowFestivals[i])) {
          returnStr = nowFestivals.join(',')
          return returnStr
        }
      }
      return returnStr
    },
    // 获取某年某月总共多少天
    getDateLen(year, month) {
      let actualMonth = month - 1;
      let timeDistance = +new Date(year, month) - +new Date(year, actualMonth);
      return timeDistance / (1000 * 60 * 60 * 24);
    },
    // 获取某月1号是周几
    getFirstDateWeek(year, month) {
      return new Date(year, month - 1, 1).getDay();
    },
    // 上月 年、月
    preMonth(year, month) {
      if (month == 1) {
        return {
          year: --year,
          month: 12
        };
      } else {
        return {
          year: year,
          month: --month
        };
      }
    },
    // 下月 年、月
    nextMonth(year, month) {
      if (month == 12) {
        return {
          year: ++year,
          month: 1
        };
      } else {
        return {
          year: year,
          month: ++month
        };
      }
    },
    // 获取当月数据，返回数组
    getCurrentArr() {
      let currentMonthDateLen = this.getDateLen(this.currentYear, this.currentMonth); // 获取当月天数
      let currentMonthDateArr = []; // 定义空数组
      if (currentMonthDateLen > 0) {
        for (let i = 1; i <= currentMonthDateLen; i++) {
          currentMonthDateArr.push({
            month: 'current', // 只是为了增加标识，区分上下月
            date: i
          });
        }
      }
      this.currentMonthDateLen = currentMonthDateLen;
      return currentMonthDateArr;
    },
    // 获取当月中，上月多余数据，返回数组
    getPreArr() {
      let preMonthDateLen = this.getFirstDateWeek(this.currentYear, this.currentMonth); // 当月1号是周几 == 上月残余天数）
      let preMonthDateArr = []; // 定义空数组
      if (preMonthDateLen > 0) {
        let { year, month } = this.preMonth(this.currentYear, this.currentMonth); // 获取上月 年、月
        let date = this.getDateLen(year, month); // 获取上月天数
        for (let i = 0; i < preMonthDateLen; i++) {
          preMonthDateArr.unshift({ // 尾部追加
            month: 'pre', // 只是为了增加标识，区分当、下月
            date: date
          });
          date--;
        }
      }
      this.preMonthDateLen = preMonthDateLen;
      return preMonthDateArr;
    },
    // 获取当月中，下月多余数据，返回数组
    getNextArr() {
      let nextMonthDateLen = 35 - this.preMonthDateLen - this.currentMonthDateLen; // 下月多余天数
      let nextMonthDateArr = []; // 定义空数组
      if (nextMonthDateLen > 0) {
        for (let i = 1; i <= nextMonthDateLen; i++) {
          nextMonthDateArr.push({
            month: 'next',// 只是为了增加标识，区分当、上月
            date: i
          });
        }
      }
      return nextMonthDateArr;
    },
    // 整合当月所有数据
    getAllArr() {
      let preArr = this.getPreArr();
      let currentArr = this.getCurrentArr();
      let nextArr = this.getNextArr();
      let allArr = [...preArr, ...currentArr, ...nextArr];
      this.allArr = allArr;
      let sendObj = {
        currentYear: this.currentYear,
        currentMonth: this.currentMonth,
        monthYear: this.currentYear + '-' + (this.currentMonth < 10 ? '0' + this.currentMonth : this.currentMonth),
        nowYear: this.nowYear,
        nowMonth: this.nowMonth,
        nowDate: this.nowDate,
        fullDate: this.nowYear + '-' + this.nowMonth + '-' + this.nowDate,
        allArr: allArr
      };
      if (this.calendarType === 'week') {
        this.allArr.forEach((item, i) => {
          if (item.date == this.nowDate) {
            this.row = i % 7 - 0 + 1
            this.col = Math.floor(i / 7) - 0 + 1
          }
        })
      }
      this.$emit('sendObj', sendObj);
    },
    // 点击 上月
    gotoPreMonth() {
      if (this.calendarType === 'month') {
        let { year, month } = this.preMonth(this.currentYear, this.currentMonth);
        this.currentYear = year;
        this.currentMonth = month;
        this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
        this.getAllArr();
      } else {
        if (this.weekStartIndex === 1) {
          let { year, month } = this.preMonth(this.currentYear, this.currentMonth);
          this.currentYear = year;
          this.currentMonth = month;
          this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
          this.getAllArr();
        } else {
          this.weekStartIndex -= 1;
        }
      }
    },
    // 点击 下月
    gotoNextMonth() {
      if (this.calendarType === 'month') {
        let { year, month } = this.nextMonth(this.currentYear, this.currentMonth);
        this.currentYear = year;
        this.currentMonth = month;
        this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
        this.getAllArr();
      } else {
        if (this.allArr.length < (this.weekStartIndex + 1) * 7) {
          let { year, month } = this.nextMonth(this.currentYear, this.currentMonth);
          this.currentYear = year;
          this.currentMonth = month;
          this.monthYear = year + '-' + (month < 10 ? '0' + month : month);
          this.getAllArr();
        } else {
          this.weekStartIndex += 1;
        }
      }
    },
    clockPreTask(id) {
      this.$emit('clock', id);
    },
    chooseDay(item) {
      if (item.month === 'current') {
        this.nowYear = this.currentYear;
        this.nowMonth = this.currentMonth;
        this.nowDate = item.date;
        this.isInit = false;
        this.$emit('change');
      }
    },
    changeCalendarType() {
      if (this.calendarType === 'month') {
        this.calendarType = 'week';
        for (let i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i].date == this.nowDate) {
        this.row = (i % 7) - 0 + 1;
        this.col = Math.floor(i / 7) - 0 + 1;
      }
    }
      } else {
        this.calendarType = 'month';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  .calendar-title {
    font-size: 13px;
    color: #333;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    color: #52B26B;
  }
}

.showData {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.showData .title {
  width: 14.2%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  flex-shrink: 0;
  font-size: 14px;
  color: #333;
}

.calendar .tit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar .content {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
}

.calendar .content .itemData {
  width: 14.2%;
  padding: 8px 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  &.week-hidden {
    display: none;
  }

  .nong {
    color: #999;
    font-size: 11px;

    &.today {
      color: #51B26B;
    }
  }

  &.nowDay {
	max-height: 50px;
    border-radius: 50%;
    background: #51B26B;
    color: #FFFFFF;

    .date {
      color: #fff;

      &.today {
        color: #fff;
      }
    }

    .nong {
      color: #fff;
    }
  }

  .date {
    color: #333;
    width: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    align-items: center;

    &.today {
      color: #51B26B;
    }



    // &:after {
    //   content: ' ';
    //   position: absolute;
    //   left: 50%;
    //   bottom: 2px;
    //   transform: translateX(-50%);
    //   width: 3px;
    //   height: 3px;
    //   background-color: #52B26B;
    //   border-radius: 50%;
    // }
  }
}

.operate {
  width: 30px;
  padding: 0 11.25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.pre-month {
    left: -10px;
  }

  &.next-month {
    right: -10px;
  }

  img {
    width: 7.5px;
    height: 14px;
  }
}

.arrow-btn {
  height: 35px;
  position: relative;

  img {
    width: 15px;
    height: 10.5px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.back-month {
  font-family: 'serif';
  font-size: 150px;
  color: #52B26B;
  opacity: 0.08;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
