import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vant/lib/index.css';
import 'amfe-flexible/index.js';
import './assets/scss/font.scss';
import { Button,Notify } from 'vant';
import wx from 'weixin-js-sdk';
Vue.use(Button);
Vue.prototype.$notify = Notify;
window.wx = wx;
Vue.config.productionTip = false;
Vue.prototype.laoding=()=>{
  document.getElementsByTagName('body')[0].appendChild('<div style="width:100vw;height:100vh;position:fixed;top:0;left:0;"></div>');
};
if(document.documentElement.style.fontSize.split("p")[0]>60){
  document.documentElement.style.fontSize = '60px'
}
window.addEventListener('resize', function(){
  if(document.documentElement.style.fontSize.split("p")[0]>60){
    document.documentElement.style.fontSize = '60px'
  }
})
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
