<template>
  <div id="app" style="max-width: 640px;">
      <router-view/>
    <custom-tabbar/>
  </div>
</template>

<script>
import Tabbar from '@/components/tabbar.vue';
// import {getAddressList} from '@/api/common';
// import axios from "axios";
export default {
  components: {
    'custom-tabbar': Tabbar
  },
  mounted() {
    // if (!localStorage.getItem('addressList')) {
    //   this.getAddressList();
    // }
  },
  methods: {
    // getAddressList() {
    //   axios.get("./city.json").then((res) => {
    //     localStorage.setItem('addressList', JSON.stringify(res.data));
    //   })
    // }
  }
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}
html {
  height: 100%;
}
#app {
  height: 100%
}
body {
  height: 100%;
  background: #f9f9f9;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}
.van-overlay {
  z-index: 100!important;
}
.van-popup--bottom {
  z-index: 10000!important;
  &.van-popup--round {
    border-radius: 5px 5px 0 0!important;
  }
}
.van-field__body {
  height: 100%;
  input {
    height: 100%;
  }
}
.van-uploader {
  width: 100%;
  height: 100%;
  .van-uploader__wrapper {
    width: 100%;
    height: 100%;
    .van-uploader__input-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.van-switch {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  width: 2em;
  height: 1em;
  font-size: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  &.van-switch--on {
    .van-switch__node {
      // left: -1px;
      transform: translateX(1em);
    }
  }
  .van-switch__node {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    font-size: inherit;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 3px 1px 0 rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 3px 0 rgb(0 0 0 / 5%);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.3, 1.05, 0.4, 1.05);
    transition: -webkit-transform 0.3s cubic-bezier(0.3, 1.05, 0.4, 1.05);
    transition: transform 0.3s cubic-bezier(0.3, 1.05, 0.4, 1.05);
    transition: transform 0.3s cubic-bezier(0.3, 1.05, 0.4, 1.05), -webkit-transform 0.3s cubic-bezier(0.3, 1.05, 0.4, 1.05);
  }
}

::-webkit-input-placeholder {
  color: #666!important;
  font-size: 12px;
}

/* Firefox 4-18 */
:-moz-placeholder {
  color: #666!important;
  font-size: 12px;
}

/* Firefox 19-50 */
::-moz-placeholder {
  color: #666!important;
  font-size: 12px;
}

/* - Internet Explorer 10–11
- Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: #666 !important;
  font-size: 12px;
}

/* Edge (also supports ::-webkit-input-placeholder) */
::-ms-input-placeholder {
  color: #666!important;
  font-size: 12px;
}

/* CSS Working Draft */
::placeholder {
  color: #666!important;
  font-size: 12px;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}
input:disabled {
  background-color: #fff!important;
}
textarea:disabled {
  background-color: #fff!important;
}
</style>
