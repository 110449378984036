<template>
  <div class="feedback-container">
    <custom-back></custom-back>
    <div class="feedback-content">
      <div class="feedback-type" @click="showPicker = true">
        <span class="label">反馈类型</span>
        <div class="type-name">
          <vant-field v-model="feedbackType" placeholder="请选择" readonly="readonly"></vant-field>
          <p class="type-btn"></p>
        </div>
      </div>
      <div class="content">
        <!-- <h3 class="content-title">
          请填写反馈内容
        </h3> -->
        <vant-field 
          v-model="feedbackContent"
          type="textarea" 
          placeholder="请填写遇到的问题，以便于我们提供更好的帮助"
        ></vant-field>
      </div>
      <div class="image-upload">
        <p class="upload-title">截图({{ uploadImageList.length + '/' + max }})</p>
        <ul class="image-list">
          <li v-for="(item, index) in uploadImageList" :key="index" class="image-box">
            <img :src="item">
            <vant-icon name="cross" @click="delImage(index)"></vant-icon>
          </li>
          <li v-if="uploadImageList.length < max" class="image-box">
            <vant-uploader
              :max-count="max"
              :after-read="uploadImage"
              multiple
              @oversize="onOversize"
            >
              <img src="../../../assets/images/find/photo.png" style="width: 28.5px;height: 24px;">
            </vant-uploader>
          </li>
        </ul>
        
      </div>
      <div class="contact-content">
        <span class="label">联系方式</span>
        <vant-field v-model="contact" placeholder="邮箱/手机号"></vant-field>
      </div>
      <div class="submit-btn" @click="submitFeedback">提交</div>
    </div>
    <vant-popup v-model="showPicker" :close-on-click-overlay="false" round position="bottom">
      <vant-picker
        show-toolbar
        :columns="feedbackTypeList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </vant-popup>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import { Popup, Picker, Icon, Notify, Field, Uploader } from 'vant';
import utils from '@/utils/utils';
import { addFeedBack } from '@/api/mine/feedback';
import config from '../../../../config/config.default';
import $ from 'jquery';

export default {
  components: {
    'custom-back': Back,
    'vant-popup': Popup,
    'vant-picker': Picker,
    'vant-field': Field,
    'vant-uploader': Uploader,
    'vant-icon': Icon
  },
  data() {
    return {
      showPicker: false,
      feedbackTypeList: ['功能异常', '产品建议', '违规举报'],
      feedbackType: '',
      feedbackContent: '',
      submitFeedbackBol:true,
      max: 4,
      uploadImageList: [],
      contact: ''
    };
  },
  methods: {
    onConfirm(val) {
      this.feedbackType = val;
      this.showPicker = false;
    },
    onOversize() {
      Notify({ type: 'danger', message: '图片大小不能超过20M'});
    },
    uploadImage(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      $.ajax({
        type: 'post',
        url: config[process.env.NODE_ENV].baseUrl + '/api/v1/file/upload',
        data: formData,
        contentType: false,
        processData: false,
        mineType: 'multipart/form-data',
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        'mimeType': 'multipart/form-data',
        success: (res) => {
          this.uploadImageList.push(JSON.parse(res).path);
        }
      });
    },
    delImage(index) {
      this.uploadImageList.splice(index, 1);
    },
    submitFeedback() {
      if (!this.feedbackType) {
        Notify({ type: 'warning', message: '请选择反馈类型!' });
        return false;
      }
      if (!this.feedbackContent) {
        Notify({ type: 'warning', message: '请输入反馈内容!' });
        return false;
      }
      if (!this.contact) {
        Notify({ type: 'warning', message: '请填写联系方式!' });
        return false;
      } else if (!utils.config.phoneReg.test(this.contact) && !utils.config.emailReg.test(this.contact)) {
        Notify({ type: 'warning', message: '联系方式格式错误，请重新输入!' });
        return false;
      }
      let typeNum = 0;
      this.feedbackTypeList.forEach((v, k) => {
        if (v === this.feedbackType) {
          typeNum = k;
        }
      });
      if(this.submitFeedbackBol){
        this.submitFeedbackBol = false
      }else{
        return
      }
      addFeedBack({
        type: typeNum,
        img: this.uploadImageList,
        phone: this.contact,
        feedbackContent: this.feedbackContent
      }).then(() => {
        Notify({ type: 'success', message: '反馈成功!' });
        setTimeout(() => {
          this.$router.go(-1);
          this.submitFeedbackBol = true
        }, 1500);
      }).catch(err=>{
        this.submitFeedback = true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .feedback-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .feedback-content {
      flex: 1;
      padding: 15px 17.5px;
      ::v-deep .van-cell {
        background: transparent;
      }
      .feedback-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        font-size: 13px;
        color: #333;
        .label {
          color: #333;
          font-size: 13px;
          margin-right: 55px;
          line-height: 50px;
          height: 100%;
        }
        .type-name {
          display: flex;
          align-items: center;
          height: 100%;
          ::v-deep .van-field {
            height: 100%;
            flex: 1;
            padding: 0;
            &:after {
              display: none;
            }
            input {
              text-align: right;
            }
          }
          .type-btn {
            width: 10px;
            height: 10px;
            border-left: 2px solid #ccc;
            border-bottom: 2px solid #ccc;
            -webkit-transform: translate(0,-50%) rotate(-135deg);
            transform: translate(0,-50%) rotate(-135deg);
            margin-left: 10px;
            margin-top: 12px;
            margin-right: 5px;
          }
        }
      }
      .content {
        margin-top: 10px;
        .content-title {
          line-height: 55px;
          color: #333;
          font-size: 14px;
          text-align: center;
        }
        .van-field {
          height: 118px;
          border: 1px solid #E2E2E2;
          border-radius: 5px;
          padding: 0;
          ::v-deep textarea {
            padding: 10px;
            height: 100%;
          }
        }
      }
      .image-upload {
        padding-bottom: 15px;
        border-bottom: 1px solid #e2e2e2;
        .upload-title {
          color: #333;
          font-size: 13px;
          line-height: 18px;
          margin: 15px 0;
        }
        .image-list {
          display: flex;
          .image-box {
            width: 70px;
            height: 70px;
            background: #EFEFEF;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            margin-right: calc((100% - 280px)/3);
            &:last-child {
              margin-right: 0;
            }
            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .van-icon-cross {
              position: absolute;
              color: #fff;
              background: grey;
              top: 0;
              right: 0;
              font-size: 13px;
            }
          }
        }
      }
      .contact-content {
        height: 50px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        .label {
          color: #333;
          font-size: 13px;
          margin-right: 55px;
          line-height: 50px;
          height: 100%;
        }
        .van-field {
          height: 100%;
          flex: 1;
          padding: 0;
        }
      }
      .submit-btn {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        background: #52B26B;
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
        color: #fefefe;
        font-size: 14px;
      }
    }
  }
</style>
