<template>
  <div class="setting-container">
    <custom-back></custom-back>
    <div class="setting-content">
      <div class="setting-form">
        <div class="form-item" @click="bindPhone">
          <span class="form-title">绑定手机</span>
          <span class="form-content">{{ userInfo.phone }}</span>
          <span class="form-icon"></span>
        </div>
        <div class="form-item" @click="showReminderPhonePopup">
          <span class="form-title">提醒手机</span>
          
          <span class="form-icon"></span>
        </div>
        <div class="form-item" @click="changeAddress">
          <span class="form-title">收货地址</span>
          <span class="form-content" style="max-width: 200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">{{ userInfo.address }}</span>
          <span class="form-icon"></span>
        </div>
        <!-- <div class="form-item" @click="setRemindTime">
          <span class="form-title">默认提醒时间</span>
          <span class="form-content">{{ userInfo.remindTimeDefault }}</span>
          <span class="form-icon"></span>
        </div> -->
        <div class="form-item">
          <span class="form-title">点赞通知</span>
          <span class="form-content" style="padding: 8px 0;">
            <vant-switch v-model="userInfo.isPrise" active-color="#52B26B" inactive-color="#EDEDED" @change="toggleStatus"></vant-switch>
          </span>
        </div>
      </div>
    </div>
    <vant-overlay :show="showPhoneDialog">
      <phone-dialog v-if="showPhoneDialog" :phone="userInfo.phone" @close="closeDialog"></phone-dialog>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
    <vant-popup v-model="showTimePicker" :close-on-click-overlay="false" round position="bottom">
      <vant-datetime-picker 
        v-model="userInfo.remindTimeDefault" 
        type="time"
        @confirm="confirmDefaultRemindTime"
      />
    </vant-popup>
    <address-list v-if="showAddressList" @close="closeAddressList"></address-list>
    <vant-popup v-model="showReminderPhone" round  position="bottom" @close="cloeReminderPhonePopup" style="max-height: 35%;">
      <vant-cell-group>
        <vant-cell title="提醒手机管理">
          <template #default>
           <span style="color: #52B26B;" @click="addPhoneBol = true">添加</span>
          </template>
        </vant-cell>
      </vant-cell-group>
      <vant-cell-group>
        <vant-cell v-for="(item,index) in phoneList">
          <template #title>
            <span class="custom-title">{{ item.phone }}</span>
          </template>
          <template #default>
           <span style="color: #ee0a24;" @click="delteReminderPhone(item)">删除</span>
          </template>
        </vant-cell>
      </vant-cell-group>
    </vant-popup>

    <vant-popup v-model="addPhoneBol" round position="bottom">
      <div class="addPhoneBol">
        <div class="inps" v-for="(v, i) in formAddPhoone" :key="i">
          <div>
            <img :src="v.img" class="phoneImg" alt="">
          </div>
          <input :type="v.invisible ? 'password' : 'text'"
            :oninput="v.name == 'phone' ? 'if(value.length>10)value=value.slice(0,11)' : v.name == 'code' ? 'if(value.length>5)value=value.slice(0,6)' : 'if(value.length>19)value=value.slice(0,20)'"
            :placeholder="v.phaceholder" v-model="v.value">
          <div v-if="v.invisibleImg">
            <img :src="v.invisible ? v.invisibleImg : v.invisibleImg2" @click="v.invisible = !v.invisible"
              class="invisibleImg" alt="">
          </div>
          <div v-if="v.type == 'code'" class="getCode" @click="getPhoneCode">
            <span style="display:inline-block;width:15.5px;"></span>{{ phoneCodeWord }}
          </div>
        </div>
        <div class="remindSaveBtn">
          <van-button class="btn" type="primary" @click="saveAddPhone">保存</van-button>
        </div>
      </div>

    </vant-popup>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import { Switch, Overlay, Icon, Notify, DatetimePicker, Popup, Cell,CellGroup, Button, Dialog } from 'vant';
import phoneDialog from './phoneDialog';
import { changeUserInfo,getReminderPhoneList,delReminderPhone,bindReminderPhone,sendSms } from '@/api/mine/setting';
import { getWechatUserInfo } from '@/api/common';
import addressList from './addressList';

export default {
  components: {
    'custom-back': Back,
    'vant-switch': Switch,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'vant-datetime-picker': DatetimePicker,
    'vant-popup': Popup,
    'vant-cell': Cell,
    'vant-cell-group': CellGroup,
    'vant-button': Button,
    'phone-dialog': phoneDialog,
    'address-list': addressList
  },
  data() {
    return {
      showPhoneDialog: false,
      showTimePicker: false,
      showAddressList: false,
      showReminderPhone: false,
      phoneList: [],
      addPhoneBol: false,
      formAddPhoone: [{
        name: "phone",
        img: require("../../../assets/images/login/phone.png"),
        phaceholder: "输入手机号",
        value: ""
      }, {
        name: "code",
        img: require("../../../assets/images/login/veritification.png"),
        phaceholder: "输入验证码",
        type: 'code',
        value: ""
      }
      ],
      phoneCodeWord: "发送验证码",
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    console.log(this.userInfo);
  },
  methods: {
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
    bindPhone() {
      this.showPhoneDialog = true;
    },
    changeAddress() {
      this.showAddressList = true;
    },
    setRemindTime() {
      this.showTimePicker = true;
    },
    confirmDefaultRemindTime(res) {
      this.changeUserInfo({ remindTimeDefault: res});
      this.showTimePicker = false;
    },
    toggleStatus(res) {
      this.changeUserInfo({ isPrise: res ? 1 : 0});
    },
    changeUserInfo(sendData) {
      changeUserInfo(sendData).then(res => {
        Notify({ type: 'success', message: '修改成功' });
        this.resetUserInfo();
      });
    },
    closeDialog() {
      this.resetUserInfo();
      this.showPhoneDialog = false;
    },
    closeAddressList() {
      this.showAddressList = false;
    },
    showReminderPhonePopup(){
      this.getReminderPhoneList();
      this.showReminderPhone = true;
    },
    cloeReminderPhonePopup(){
      this.resetUserInfo();
      this.showReminderPhone = false;
    },
    getReminderPhoneList() {
      // 获取绑定手机号
      this.loading = true;
      getReminderPhoneList().then(res => {
        this.loading = false;
        this.phoneList = JSON.parse(JSON.stringify(res))
      }).catch(err => {
        this.loading = false;
      })
    },
    delteReminderPhone(e){
      Dialog.confirm({
        title: '提示',
        confirmButtonText: "确定删除",
        message: '确定删除该手机？',
      }).then(()=>{
        delReminderPhone({id:e.id}).then(res => {
            Notify({ type: 'success', message: '删除成功' });
            this.getReminderPhoneList();
        })
      })
      
    },
    getPhoneCode() {
      if (this.phoneCodeWord != '发送验证码') {
        return;
      };
      if (this.formAddPhoone[0].value.length != 11) {
        this.$notify({ type: 'warning', message: '请输入正确的手机号' });
        return;
      };
      this.loading = true;
      sendSms({ phone: this.formAddPhoone[0].value }).then(res => {
        if (res.errorMsg == "发送成功") {
          this.loading = false;
          this.$notify({ type: 'success', message: '发送成功' });
          this.phoneCodeWord = 60;
          this.codeTimer = setInterval(() => {
            if (this.phoneCodeWord == 0) {
              this.phoneCodeWord = '发送验证码';
              window.clearInterval(this.codeTimer);
              return;
            }
            this.phoneCodeWord--;

          }, 1000);
        }
      }).catch(err => {
        this.loading = false;
      });
    },
    saveAddPhone() {
      // 增加手机号bindReminderPhone
      if (this.formAddPhoone[0].value.length != 11) {
        this.$notify({ type: 'warning', message: '请输入正确的手机号' });
        return;
      };
      // if (this.formAddPhoone[1].value.length != 6) {
      //   this.$notify({ type: 'warning', message: '请输入正确的验证码' });
      //   return;
      // };
      this.loading = true;
      let phone = this.formAddPhoone[0].value
      bindReminderPhone({
        phone,
        vertifyCode: this.formAddPhoone[1].value,
      }).then(res => {
        if (res.errorMsg == "绑定成功") {
          this.$notify({ type: 'success', message: '绑定成功' });
        }
        this.getReminderPhoneList();
        this.loading = false;
        this.addPhoneBol = false
      }).catch(err => {
        this.loading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .setting-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .setting-content {
      flex: 1;
      padding: 10px 15px 23px;
      .setting-form {
        height: 100%;
        background: #fff;
        box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.1);
        border-radius: 10px;
        padding: 0 15px;
        .form-item {
          border-bottom: 1px solid #eee;
          height: 60px;
          line-height: 60px;
          display: flex;
          .form-title {
            color: #333;
            font-size: 14px;
            margin-right: auto;
          }
          .form-content {
            color: #666;
            font-size: 14px;
            .van-switch {
              border: none;
            }
          }
          .form-icon {
            width: 10px;
            height: 10px;
            border-left: 2px solid #ccc;
            border-bottom: 2px solid #ccc;
            -webkit-transform: translate(0,-50%) rotate(-135deg);
            transform: translate(0,-50%) rotate(-135deg);
            margin-left: 10px;
            margin-top: 30px;
            margin-right: 10px;
          }
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
  .addPhoneBol {
  width: 100%;
  background: #FFFFFF;
  padding: 16px 15px 32px;

  .inps {
    width: 305px;
    height: 48px;
    background: #F7F7F7;
    border-radius: 8px;
    padding: 14px 15px;
    display: flex;
    margin: 0 auto 24px;
    box-sizing: border-box;

    .phoneImg {
      width: 12px;
      margin: 0 2px;
      vertical-align: middle;

    }

    .getCode {
      border-left: 1px solid #CCCCCC;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      box-sizing: border-box;
      color: #51B26B;
      white-space: nowrap;
    }

    .img {
      width: 16px;
      vertical-align: middle;
    }

    .invisibleImg {
      width: 16px;
      vertical-align: middle;
    }

    input {
      min-width: 0;
      width: auto;
      flex-grow: 1;
      padding-left: 15px;
      font-size: 14px;
      outline: none;
      border: none;
      vertical-align: middle;
      background-color: transparent;
    }
  }
  .remindSaveBtn {
    display: flex;
    justify-content: center;

    .btn {
      width: 155px;
      height: 44px;
      background: #52B26B;
      border-radius: 8px;
    }
  }
}
.van-cell{
	padding: 20px 16px;
	.van-cell__title{
		.custom-title{
			font-size: 16px;
		}
	}
}
</style>
