<template>
    <div class="add-container" style="max-width: 640px;" @click.stop="xiaLabol = false">
      <div class="add-content">
        <div class="content-item" style="padding: 15px">
          <!-- <div class="plan-label-list">
            <p class="title">选择标签</p>
            <ul class="label-list">
              <li v-for="(item, index) in labelList" :key="index"
                :class="['label-item', { 'active': labelId === item.id }]" @click="changeLabel(item.id)">{{ item.name }}
              </li>
              <li :class="['label-item', { 'active': labelId === 0 }]" @click="changeLabel(0)">自定义</li>
            </ul>
            <vant-field v-if="labelId === 0" v-model="labelName" maxlength="4" placeholder="在这里输入你的自定义标题"></vant-field>
          </div> -->
          <!-- <div class="content" style="margin-top: 20px;">
            <p class="title">内容</p>
            <vant-field type="textarea" v-model="content" placeholder="请输入提醒内容" maxlength="50" show-word-limit>
            </vant-field>
            <div class="content-tip">{{ content.length }} / 50</div>
          </div> -->
          <div class="inp1">
            <input type="text" v-model="sendData.content" v-if="isInvite" disabled placeholder="输入事项内容" maxlength="20">
            <input type="text" v-model="sendData.content" ref="inputs" v-else placeholder="输入事项内容" maxlength="20">
          </div>
          <div class="inp2">
            <textarea type="textarea" v-model="textContent" v-if="isInvite" disabled placeholder="备注信息（选填）"
              maxlength="100" />
            <textarea type="textarea" v-model="textContent" v-else placeholder="备注信息（选填）" maxlength="100" />
            <div class="limit-text">
              {{ textContent.length }}/100
            </div>
          </div>
          <div class="inp3">
            <div class="inp3-left">
              <img src="../../assets/images/remind/label.png" alt="">
              <input type="text" v-model="sendData.tagName" v-if="isInvite" disabled placeholder="输入标签" maxlength="4">
              <input type="text" v-model="sendData.tagName" v-else placeholder="输入标签" maxlength="4">
              <img v-if="!isInvite" src="../../assets/images/remind/shanglajiantou.svg" class="xiala" alt=""
                @click.stop="xiaLabol = !xiaLabol">
              <div class="xialaArea" v-if="xiaLabol" @click.stop>
                <div class="lis" v-for="v in xialaList" @click="sendData.tagName = v; xiaLabol = false">
                  {{ v }}
                </div>
              </div>
            </div>
            <div class="inp3-right">
              <div class="colors" v-for="(v, i) in tagColor" @click="chosenoSelefColor(v)" :key="i"
                :style="'background:' + v">
                <div>
                  <img :style="noSelefColor == v ? 'opacity:1' : 'opacity:0'"
                    src="../../assets/images/remind/tag_selected.png" alt="">
                </div>
              </div>
              <div class="colors"
                :style="(choosedColor ? 'background:' + choosedColor : 'background:#666') + ';margin:0;position:relative'">
                <div>
                  <img src="../../assets/images/remind/icon_edit.png" alt="">
                </div>
                <div class="cloreChose" @click="choseColor">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="picker-select-box" @click="chooseCycle" style="border-top:none;">
            <span class="label">提醒周期</span>
            <div class="picker-content" :style="cycleValue ? '' : 'color:#888;'">
              {{ cycleValue || '请选择周期' }}
              <img src="../../assets/images/remind/arrow_r3.png" class="picker-btn" alt="">
            </div>
          </div>
          <div class="select-outplan">
            <!-- <div v-if="cycleValue === '每天提醒'" class="plan-label-list" style="border-top: 1px solid #e2e2e2;">
              <p class="title" style="font-size: 10px;color: #888;">选择哪天不提醒</p>
              <ul class="label-list">
                <li v-for="(item, index) in weekDayList" :key="index"
                  :class="['label-item', { 'active': weekLabel.indexOf(item.id) > -1 }]"
                  @click="changeWeekLabel(item.id)">{{ item.name }}</li>
              </ul>
            </div> -->
            <div v-if="cycleValue === '每年提醒'" class="picker-select-box" style="border-top: 1px solid #e2e2e2;"
              @click="chooseCalendar">
              <span class="label">历法</span>
              <div class="picker-content">
                {{ calendarValue }}
                <img src="../../assets/images/remind/arrow_r3.png" class="picker-btn" alt="">
              </div>
            </div>
  
            <!-- <div 
              v-for="(item, index) in remindTimeList" 
              :key="index"
              class="setting-item remind-time-item"  
              @click="setRemindTime(index)"
              :style="remindTimeList.length === 1 && !remindTimeList[0].value ? 'border-bottom: 0;': ''"
            >
              <span class="setting-title">{{ index === 0 ? '提醒时间' : '' }}</span>
              <div v-if="item.value" class="img-box" @click.stop="deleteTime(index)">
                <img src="../../assets/images/remind/delete.png">
              </div>
              <span class="setting-content">{{ item.value || '请选择时间' }}</span>
              <div class="setting-icon">
                <img src="../../assets/images/remind/arrow_r3.png"  alt="">
              </div>
            </div> -->
            <!-- <div v-if="remindTimeList[remindTimeList.length - 1].value" class="setting-item" style="border-bottom: none;">
              <div class="add-remind-time-btn" @click="addRemindTime">增加时间</div>
            </div> -->
  
            <div class="picker-select-box" v-if="cycleValue"
              :style="cycleValue === '每年提醒' ? 'border-top: 1px solid #e2e2e2;' : ''">
              <span class="label">提醒时间</span>
              <div class="picker-content">
                <div class="setting-item flexCenter" style="border-bottom: none;">
                  <div class="add-remind-time-btn flexCenter" @click="addRemindTime">
                    <div style="display:flex;">
                      <div class="flexCenter text">
                        <img src="../../assets/images/remind/add_icon3.svg" alt="">
                        <span style="color: #52B26B;font-size: 13px;">
                          增加时间
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pickedTimeListArea" v-for="(item, index) in CircleRemindTimeList"
              v-if="(cycleValue == '循环提醒') && (item.type != 'del')">
              <div class="pickedTimeList">
                <div>
                  <img src="../../assets/images/remind/btn_delete.png" class="btn_delete" alt=""
                    @click="deleteTime(index, item)">
                </div>
                <div>
                  <span :class="item.value ? 'span2' : 'span1'" @click="setRemindTime(index, item)">
                    {{ item.value || '请选择时间' }}
                  </span>
                  <img src="../../assets/images/remind/arrow_r3.png" class="picker-btn" alt="">
                </div>
              </div>
            </div>
            <div class="pickedTimeListArea" v-for="(item, index) in remindTimeList"
              v-if="cycleValue && (cycleValue != '循环提醒') && (item.type != 'del')">
              <div class="pickedTimeList">
                <div>
                  <img src="../../assets/images/remind/btn_delete.png" class="btn_delete" alt=""
                    @click="deleteTime(index, item)">
                </div>
                <div>
                  <span :class="item.value ? 'span2' : 'span1'" @click="setRemindTime(index)">
                    {{ item.value || '请选择时间' }}
                  </span>
                  <img src="../../assets/images/remind/arrow_r3.png" class="picker-btn" alt="">
                </div>
              </div>
            </div>
            <div class="picker-select-box" @click="chooseLeadTime" v-if="cycleValue">
              <span class="label">提醒设置</span>
              <div class="picker-content ">
                <span class="setting-box">
                  <font v-if="noMindSettingBol">不提醒</font>
                  <font v-else>
                    <font v-for="(v, i) in dataChoosedSetting" :key="i" v-if="v.id && v.id != 8">
                      {{ v.name }}<span v-if="dataChoosedSetting.length > 1">、</span>
                    </font>
                    <font v-if="mindSetting.bol">
                      到期{{ mindSetting.inp }}{{ mindSetting.type }}后未打卡提醒
                    </font>
                  </font>
                </span>
                <img src="../../assets/images/remind/arrow_r3.png" class="picker-btn" alt="">
              </div>
            </div>
  
          </div>
        </div>
        <div class="content-item" style="padding-bottom:16px;">
          <div class="" style="padding-top: 10px;border-bottom: none;flex-direction: column;">
            <div class="titleArea">
              <div class="title1">
                提醒方式
              </div>
              <div v-if="userInfo.isVip == 1">
                <span>
                  当前积分：
                </span>
                <span class="count">
                  {{ userInfo.score }}
                </span>
                <span class="btn" @click="toMember">
                  充值
                </span>
              </div>
            </div>
            <div class="checkTypeArea">
              <div class="checkTypes">
                <div>
                  <span class="text1">
                    <span>
                      公众号提醒
                    </span>
                    <span class="desc">
                      (永久免费)
                    </span>
                  </span>
                  <div class="text2">
                    由小提醒服务号通知（请创建提醒后关注）
                  </div>
                </div>
                <div class="flexCenter">
                  <vant-checkbox :name="0" @click="changeWxRemind" shape="square" checked-color="#52B26B"
                    v-model="remindType1"></vant-checkbox>
                </div>
              </div>
              <div class="checkTypes">
                <div>
                  <span class="text1">
                    <span>
                      短信提醒
                    </span>
                    <span class="desc">
                      (会员可用，1积分/次)
                    </span>
                  </span>
                  <div class="text2" v-if="!remindType2">
                    提醒手机号：{{ phoneList.length }}个
                  </div>
                </div>
                <div class="flexCenter">
                  <vant-checkbox :name="0" @click="changeMailRemind" shape="square" checked-color="#52B26B"
                    v-model="remindType2"></vant-checkbox>
                </div>
              </div>
              <div class="phonesBefore" v-if="remindType2" style="">
                <span>提醒手机号</span>
                <span style="color: #52B26B;display:inline-flex;justify:center;">
                  <img src="../../assets/images/remind/add_icon3.svg" alt="">
                  <span style="color: #52B26B;font-size: 13px;" @click="addPhoneBol = true">
                    增加手机
                  </span>
                </span>
              </div>
              <div class="myphones" v-if="remindType2">
                <div class="phones" v-for="(v, i) in phoneList" :key="i">
                  <div>
                    <img src="../../assets/images/remind/btn_delete.png" class="btn_delete" alt=""
                      @click="deletePhone(i)">
                  </div>
                  <div class="phone">
                    {{ v }}
                  </div>
                </div>
              </div>
              <div class="checkTypes">
                <div>
                  <span class="text1">
                    <span>
                      电话提醒
                    </span>
                    <span class="desc">
                      (会员可用，2积分/次)
                    </span>
                  </span>
                  <div class="text2" v-if="!remindType3">
                    提醒手机号：{{ phoneList2.length }}个
                  </div>
                </div>
                <div class="flexCenter">
                  <vant-checkbox :name="0" @click="changePhoneRemind" shape="square" checked-color="#52B26B"
                    v-model="remindType3"></vant-checkbox>
                </div>
              </div>
              <div class="phonesBefore" v-if="remindType3" style="">
                <span>提醒手机号</span>
                <span style="color: #52B26B;display:inline-flex;justify:center;">
                  <img src="../../assets/images/remind/add_icon3.svg" alt="">
                  <span style="color: #52B26B;font-size: 13px;" @click="addPhoneBol = true">
                    增加手机
                  </span>
                </span>
              </div>
              <div class="myphones" v-if="remindType3">
                <div class="phones" v-for="(v, i) in phoneList2" :key="i">
                  <div>
                    <img src="../../assets/images/remind/btn_delete.png" class="btn_delete" alt=""
                      @click="deletePhone2(i)">
                  </div>
                  <div class="phone">
                    {{ v }}
                  </div>
                </div>
              </div>
            </div>
  
            <!-- <vant-checkbox-group v-model="remindType">
              <vant-checkbox :name="0" checked-color="#F26A13">公众号提醒<span class="label-tip">(永久免费)</span></vant-checkbox>
              <vant-checkbox :name="1" checked-color="#F26A13">短信提醒<span class="label-tip">(1积分/次，剩余积分：{{ userInfo.score
              }})</span></vant-checkbox>
            </vant-checkbox-group> -->
          </div>
        </div>
        <div class="bottomBtn">
          <span class="submit" @click="submit"> 完成 </span>
        </div>
        <!-- <div class="btns">
          <span class="back" @click="back">返回</span>
          <span class="submit" @click="submit">{{ id ? '修改提醒' : '创建提醒' }}</span>
        </div> -->
      </div>
      <vant-popup v-model="circleRemindShow" position="bottom" :round="true">
        <div class="popalContent" v-if="circleRemindShow">
          <div class="popalTitle">
            提醒时间
          </div>
          <div class="popalTab">
            <span :class="choosedType == 0 ? 'active' : null" @click="choosedType = 0">
              循环提醒
            </span>
            <span :class="choosedType == 1 ? 'active' : null" style="margin-left:64px;" @click="choosedType = 1">
              智能推算
            </span>
          </div>
          <div class="contentItem">
            <span class="text" style="margin-right:8px">从</span>
            <div class="item-centerArea" @click="chooseCircle">
              <span :class="chooseCircleTime.timeValue ? 'active' : null">{{ chooseCircleTime.timeValue || '请选择时间'
                }}</span>
              <div>
                <img src="../../assets/images/remind/arrow_r3.png" style="width:5px" alt="">
              </div>
            </div>
            <span class="text text2" style="margin-left:8px;">
              时间开始
            </span>
          </div>
          <div class="contentItem" v-if="choosedType == 0">
            <span class="text" style="margin-right:8px">每</span>
            <div class="item-centerArea">
              <input type="text" placeholder="点击输入" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                v-model="loopPeriod.value">
              <van-popover v-model="showSettingPopover" @select="selectloopPeriod" trigger="click"
                :actions="[{ text: '分钟' }, { text: '小时' }, { text: '天' }]" placement="top">
                <template #reference>
                  <div class="selectArea">
                    <span class="select">
                      {{ loopPeriod.type_txt }}
                      <img src="../../assets/images/remind/arrow_r3.png" style="width:5px" alt="">
                    </span>
                  </div>
                </template>
              </van-popover>
  
            </div>
            <span class="text text2" style="margin-left:8px;">
              提醒一次
            </span>
          </div>
          <div class="contentItem" v-if="choosedType == 1">
            <span class="text" style="margin-right:8px">在</span>
            <div class="item-centerArea">
              <input type="text" placeholder="点击输入" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                v-model="delayTime.value">
              <van-popover v-model="showSettingPopover" @select="selectdelayTime" trigger="click"
                :actions="[{ text: '分钟' }, { text: '小时' }, { text: '天' }]" placement="top">
                <template #reference>
                  <div class="selectArea">
                    <span class="select">
                      {{ delayTime.type_txt }}
                      <img src="../../assets/images/remind/arrow_r3.png" style="width:5px" alt="">
                    </span>
                  </div>
                </template>
              </van-popover>
            </div>
            <span class="text text2" style="margin-left:8px;">
              后提醒
            </span>
          </div>
          <div class="contentBtn">
            <van-button class="vatBtn" @click="saveCircleRemind">保存</van-button>
          </div>
        </div>
      </vant-popup>
      <vant-popup v-model="showPicker" :close-on-click-overlay="false" round position="bottom">
        <vant-picker show-toolbar :columns="pickerType === 'cycle' ? cycleList : (
      pickerType === 'calendar' ? calendarList : (
        pickerType === 'leadTime' ? leadTimeList : pickerType === 'chooseType' ? choseList : []
      ))" @cancel="showPicker = false" @confirm="onConfirm" />
      </vant-popup>
      <vant-popup v-if="dataTimeType === 'single'" v-model="showTimePicker" :close-on-click-overlay="false" round
        position="bottom">
        <vant-datetime-picker type="datetime" :formatter="formatter" :class="dataTimeType" :min-date="new Date()"
          @confirm="confirmRemindTime" @cancel="closeRemindTime" />
      </vant-popup>
      <vant-popup v-if="dataTimeType === 'week'" v-model="showTimePicker" :close-on-click-overlay="false" round
        position="bottom">
        <vant-picker show-toolbar :columns="weekDatePickerColumns" @cancel="showTimePicker = false"
          @confirm="onWeekConfirm" />
      </vant-popup>
      <vant-popup v-if="dataTimeType !== 'single' && dataTimeType !== 'week'" v-model="showTimePicker"
        :close-on-click-overlay="false" round position="bottom">
        <vant-datetime-picker type="datetime" v-if="dataTimeType == 'loop'" :min-date="new Date()" :formatter="formatter"
          :class="dataTimeType" @confirm="confirmRemindTime" @cancel="closeRemindTime" />
        <vant-datetime-picker type="datetime" v-else :formatter="formatter" :class="dataTimeType"
          @confirm="confirmRemindTime" @cancel="closeRemindTime" />
      </vant-popup>
      <vant-overlay :show="showBindPhone">
        <phone-dialog v-if="showBindPhone" :phone="userInfo.phone" @close="closeDialog"></phone-dialog>
        <vant-icon name="close" @click="closeDialog(false)"></vant-icon>
      </vant-overlay>
      <vant-overlay :show="showConcern">
        <concern-dialog v-if="showConcern" :code="userInfo.qrCode" @close="closeConcernDialog"></concern-dialog>
        <vant-icon name="close" @click="closeConcernDialog"></vant-icon>
      </vant-overlay>
      <vant-popup v-model="remindSetPopal" round position="bottom">
        <div class="remindSetPopal">
          <div class="popalTitle">
            提醒设置
          </div>
          <div class="remidLists">
            <div class="listTitle">
              不提醒
            </div>
            <vant-switch v-model="noMindSettingBol" active-color="#52b26b"
              inactive-color="rgba(120, 120, 128, .16)"></vant-switch>
          </div>
          <div class="remidLists" v-if="!noMindSettingBol" v-for="(v, i) in settingsList[dataTimeType]" :key="i">
            <div class="listTitle">
              {{ v.name }}
            </div>
            <vant-checkbox :name="0" shape="square" checked-color="#52B26B" v-model="v.model"></vant-checkbox>
          </div>
          <div class="listBottom" v-if="!noMindSettingBol">
            <div class="listBottom-left">
              <span>到期</span>
              <div class="inputArea">
                <input type="number" oninput="if(value.length>4)value=value.slice(0,5)" v-model="mindSetting.inp"
                  placeholder="点击输入">
                <van-popover v-model="showSettingPopover" @select="onSelect2" trigger="click"
                  :actions="actionSettingRemind" placement="top">
                  <template #reference>
                    <div class="selectArea">
                      <span class="select">
                        {{ mindSetting.type }}
                        <img src="../../assets/images/remind/arrow_r3.png" style="width:5px" alt="">
                      </span>
                    </div>
                  </template>
                </van-popover>
              </div>
              <span>后未打卡提醒</span>
            </div>
            <div>
              <vant-checkbox :name="0" shape="square" checked-color="#52B26B" v-model="mindSetting.bol"></vant-checkbox>
            </div>
          </div>
          <div class="remindSaveBtn">
            <van-button class="btn" type="primary" @click="remindSettingSave">保存</van-button>
          </div>
        </div>
      </vant-popup>
      <vant-popup v-model="nonglishow" round position="bottom">
        <nongli @func="getMsgFormSon" v-if="nonglishow"></nongli>
      </vant-popup>
      <vant-popup v-model="addPhoneBol" round position="bottom">
        <div class="addPhoneBol">
          <div class="inps" v-for="(v, i) in formAddPhoone" :key="i">
            <div>
              <img :src="v.img" class="phoneImg" alt="">
            </div>
            <input :type="v.invisible ? 'password' : 'text'"
              :oninput="v.name == 'phone' ? 'if(value.length>10)value=value.slice(0,11)' : v.name == 'code' ? 'if(value.length>5)value=value.slice(0,6)' : 'if(value.length>19)value=value.slice(0,20)'"
              :placeholder="v.phaceholder" v-model="v.value">
            <div v-if="v.invisibleImg">
              <img :src="v.invisible ? v.invisibleImg : v.invisibleImg2" @click="v.invisible = !v.invisible"
                class="invisibleImg" alt="">
            </div>
            <div v-if="v.type == 'code'" class="getCode" @click="getPhoneCode">
              <span style="display:inline-block;width:15.5px;"></span>{{ phoneCodeWord }}
            </div>
          </div>
          <div class="remindSaveBtn">
            <van-button class="btn" type="primary" @click="saveAddPhone">保存</van-button>
          </div>
        </div>
  
      </vant-popup>
      <vant-popup :style="{ height: '80%' }" v-model="openModel" round position="bottom" @closed="resetUserInfo">
        <div class="popOpen">
          <div class="head"
            style="background:#fff;display:flex;align-items:center;justify-content: center;position:absolute;top:0;left:0;width:100%;">
            <span style="color: #666666;">{{ openWord }}</span>
            <vant-icon name="cross" @click="(openModel = false)"
              style="position:absolute;top:50%;right:4%;transform:translateY(-50%);color: #666666;" />
          </div>
          <open class="openDivs" v-if="(openType == 1)" @success="buySuccess"></open>
          <deposit class="openDivs" v-if="(openType == 2)" @success="buySuccess"></deposit>
        </div>
  
      </vant-popup>
      <vant-popup :style="{ height: '80%' }" v-model="colorPicker" round position="bottom">
        <div
          style="height:40px;background:#fff;display:flex;align-items:center;justify-content: center;position:relative;width: 100%;">
          <span style="color: #666666;">颜色选择</span>
          <vant-icon name="cross" @click="(colorPicker = false)"
            style="position:absolute;top:50%;right:4%;transform:translateY(-50%);color: #666666;" />
        </div>
        <div>
          <photoshop-picker v-model="colors" class="colorPicker" v-if="colorPicker" />
          <div class="openDivs">
            <van-button type="primary" class="btnBorder" size="large" @click="chargeColor">确定</van-button>
          </div>
        </div>
      </vant-popup>
      <custom-loading :loading="loading"></custom-loading>
    </div>
  </template>
  
  <script>
  import { getReminderTags, getPlanDetail, getReminderSetting, getReminderPlanDetail } from '@/api/remind/index';
  import { Field, Switch, Popup, Picker, DatetimePicker, Notify, DropdownItem, Popover, DropdownMenu, CheckboxGroup, Checkbox, Overlay, Icon, Dialog } from 'vant';
  import $ from 'jquery';
  import config from '../../../config/config.default.js';
  import phoneDialog from '../mine/setting/phoneDialog';
  import open from '../member/open'
  import deposit from '../member/deposit'
  import concern from './concern.vue';
  import { getWechatUserInfo } from '@/api/common';
  import { getCode, getUrl } from '@/utils/commonFunction';
  import loading from '@/components/loading';
  import nongli from '@/components/nongli'
  import { Sketch } from 'vue-color'
  import { sendSms, bindReminderPhone, getReminderPhone, addReminderPlan } from '@/api/mine/setting';
  
  export default {
    components: {
      'deposit': deposit,
      'open': open,
      'vant-field': Field,
      'vant-popup': Popup,
      'vant-picker': Picker,
      'photoshop-picker': Sketch,
      'vant-datetime-picker': DatetimePicker,
      'vant-checkbox-group': CheckboxGroup,
      'vant-checkbox': Checkbox,
      'vant-overlay': Overlay,
      'vant-icon': Icon,
      'phone-dialog': phoneDialog,
      'concern-dialog': concern,
      'custom-loading': loading,
      "van-dropdown-item": DropdownItem,
      "van-dropdown-menu": DropdownMenu,
      "van-popover": Popover,
      "vant-switch": Switch,
      "nongli": nongli
    },
    data() {
      return {
        xialaList: [
          "日程", "日记", "工作", "自律", "生日", "纪念日", "保质期"
        ],
        xiaLabol: false,
        openModel: false,
        openType: 1,
        colors: {
          hex: '#194d33',
          hex8: '#194D33A8',
          hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
          hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
          rgba: { r: 25, g: 77, b: 51, a: 1 },
          a: 1
        },
        colorPicker: false,
        openWord: '开通会员',
        sendData: {
          tagName: "日程",
          content: "",
          id: "",
        },
        codeTimer: "",
        phoneCodeWord: "发送验证码",
        formAddPhoone: [{
          name: "phone",
          img: require("../../assets/images/login/phone.png"),
          phaceholder: "输入手机号",
          value: ""
        }, {
          name: "code",
          img: require("../../assets/images/login/veritification.png"),
          phaceholder: "输入验证码",
          type: 'code',
          value: ""
        }
        ],
        nonglishow: false,
        remindSetPopal: false,
        tagColor: ["#1EB465", " #F5A428", " #F76420", "#44A1E8", "#6A4CEB;"],
        selectPop2: false,
        circleRemindShow: false,
        textContent: "",
        id: 0,
        labelList: [],
        labelId: '',
        labelName: '',
        content: '',
        showPicker: false,
        choosedColor: "",
        pickerType: '',
        cycleList: ['单次提醒', '每天提醒', '每周提醒', '每月提醒', '每年提醒', "循环提醒"],
        cycleValue: '',
        calendarValue: '阳历(公历)',
        calendarList: ['阳历(公历)', '阴历(农历)'],
        leadTimeValue: '当天提醒',
        leadTimeList: ['当天提醒', '提前一天', '提前两天', '提前三天'],
        dataTimeType: '',
        weekLabel: [],
        isInvite: 0,
        weekDayList: [
          { id: 1, name: '周一' },
          { id: 2, name: '周二' },
          { id: 3, name: '周三' },
          { id: 4, name: '周四' },
          { id: 5, name: '周五' },
          { id: 6, name: '周六' },
          { id: 0, name: '周日' },
          { id: 7, name: '节假日' },
        ],
        remindTimeList: [{ type: "add" }],
        CircleRemindTimeList: [{ type: "add" }],
        showTimePicker: false,
        selectTime: '',
        selectTimeIndex: 0,
        remindType1: true,
        remindType2: false,
        remindType3: false,
        originDetail: {},
        showBindPhone: false,
        showConcern: false,
        weekDatePickerColumns: [],
        loading: false,
        addPhoneBol: false,
        phoneList: [],
        phoneList2: [],
        chooseCircleTime: {
          timeValue: "",
          type: "add"
        },
        loopPeriod: { type_txt: "天", value: "" },
        delayTime: { type_txt: "天", value: "" },
        choseList: [], choosedType: 0,
        noSelefColor: "#1EB465",
        actionSettingRemind: [{ text: '分钟' }, { text: '小时' }, { text: '天' }],
        showSettingPopover: false,
        noMindSettingBol: false,
        mindSetting: {
          inp: "",
          type: "天",
          bol: false
        },
        nowSettingList: [],
        settingsList: {
  
        },
        dataChoosedSetting: [],//选中的提醒设置列表
        editfirst: true
      };
    },
    computed: {
      userInfo: function () {
        return this.$store.state.userInfo;
      }
    },
    watch: {
  
      cycleValue(newValue, oldValue) {
        if (this.cycleValue === '每月提醒') {
          this.dataTimeType = 'month';
        } else if (this.cycleValue === '每年提醒') {
          this.dataTimeType = 'year';
        } else if (this.cycleValue === '单次提醒') {
          this.dataTimeType = 'single';
        } else if (this.cycleValue === '每周提醒') {
          this.dataTimeType = 'week';
        } else if (this.cycleValue === '循环提醒') {
          this.dataTimeType = 'loop';
        } else {
          this.dataTimeType = 'day';
        }
        if (this.editfirst && this.dataChoosedSetting.length) {
          this.editfirst = false
          return
        }
        this.dataChoosedSetting = []
        this.mindSetting = {
          inp: "",
          type: "天",
          bol: false
        }
        this.settingsList[this.dataTimeType].forEach(item => {
          if (item.model) {
            this.dataChoosedSetting.push(item)
          }
        })
      },
      remindType: {
        deep: true,
        handler() {
          if (this.remindType.indexOf(1) !== -1) {
            if (!this.userInfo.phone) {
              this.showBindPhone = true;
            }
          }
        }
      },
      userInfo: {
        deep: true,
        handler(newValue, oldValue) {
          if (Object.keys(oldValue).length === 0) {
            if (this.id > 0) {
              this.getPlanDetail();
            }
          }
        }
      },
    },
    mounted() {
      // this.solveWeekDatePickerColumns();
      // this.getReminderSettingLIst()
      const code = getUrl('code');
      this.id = this.$route.query.id;
      if (localStorage.getItem('token') && localStorage.getItem('token_deadtime') && (localStorage.getItem('token_deadtime') > new Date().getTime())) {
        this.solveWeekDatePickerColumns();
        this.getReminderSettingLIst()
      } else {
        getCode(location.origin + '/remind/planAdd?id=' + (this.id ? this.id : ''))
      }
      if (this.id) {
        document.title = '修改提醒';
      }
    },
    methods: {
      chargeColor() {
        this.colorPicker = false
        this.noSelefColor = ''
        this.choosedColor = this.colors.hex
      },
      buySuccess() {
        this.openModel = false
        this.resetUserInfo()
      },
      toMember() {
        this.openModel = true
        this.openType = 2
        this.openWord = "充值积分"
      },
      getReminderPhoneList() {
        // 获取绑定手机号
        this.loading = true;
        getReminderPhone().then(res => {
          this.loading = false;
          this.phoneList = JSON.parse(JSON.stringify(res))
          this.phoneList2 = JSON.parse(JSON.stringify(res))
          if (this.id) {
            this.getReminderPlanDetail()
          } else {
            this.changfouce()
          }
        }).catch(err => {
          this.loading = false;
        })
      },
      changfouce() {
        this.$nextTick((x) => {   //正确写法
          this.$refs.inputs.focus();
        })
      },
      saveAddPhone() {
        // 增加手机号bindReminderPhone
        if (this.formAddPhoone[0].value.length != 11) {
          this.$notify({ type: 'warning', message: '请输入正确的手机号' });
          return;
        };
        if (this.formAddPhoone[1].value.length != 6) {
          this.$notify({ type: 'warning', message: '请输入正确的验证码' });
          return;
        };
        this.loading = true;
        let phone = this.formAddPhoone[0].value
        bindReminderPhone({
          phone,
          vertifyCode: this.formAddPhoone[1].value,
        }).then(res => {
          if (res.errorMsg == "绑定成功") {
            this.$notify({ type: 'success', message: '绑定成功' });
          }
          this.phoneList.push(phone)
          this.phoneList2.push(phone)
          this.loading = false;
          this.addPhoneBol = false
        }).catch(err => {
          this.loading = false;
        });
      },
      getPhoneCode() {
        if (this.phoneCodeWord != '发送验证码') {
          return;
        };
        if (this.formAddPhoone[0].value.length != 11) {
          this.$notify({ type: 'warning', message: '请输入正确的手机号' });
          return;
        };
        this.loading = true;
        sendSms({ phone: this.formAddPhoone[0].value }).then(res => {
          if (res.errorMsg == "发送成功") {
            this.loading = false;
            this.$notify({ type: 'success', message: '发送成功' });
            this.phoneCodeWord = 60;
            this.codeTimer = setInterval(() => {
              if (this.phoneCodeWord == 0) {
                this.phoneCodeWord = '发送验证码';
                window.clearInterval(this.codeTimer);
                return;
              }
              this.phoneCodeWord--;
  
            }, 1000);
          }
        }).catch(err => {
          this.loading = false;
        });
      },
      changeWxRemind() {
  
      },
      changeMailRemind(value) {
        if (this.remindType2 && this.userInfo.isVip == 0) {
          Dialog.confirm({
            title: '提示',
            message: '该功能仅会员可用，请开通会员后使用',
            confirmButtonText: "去开通"
          }).then(() => {
            this.openModel = true
            this.openType = 1
            this.openWord = '开通会员'
          }).catch(() => {
            this.remindType2 = false
          });
        }
      },
      changePhoneRemind() {
        if (this.remindType3 && this.userInfo.isVip == 0) {
          Dialog.confirm({
            title: '提示',
            message: '该功能仅会员可用，请开通会员后使用',
            confirmButtonText: "去开通"
          }).then(() => {
            this.openModel = true
            this.openType = 1
            this.openWord = '开通会员'
          }).catch(() => {
            this.remindType3 = false
          });
        }
      },
      getMsgFormSon(data) {
        this.nonglishow = false
        if (data) {
          this.remindTimeList[this.selectTimeIndex].year = data.year
          this.remindTimeList[this.selectTimeIndex].month = data.month
          this.remindTimeList[this.selectTimeIndex].day = data.day
          this.remindTimeList[this.selectTimeIndex].value = data.value
          this.remindTimeList[this.selectTimeIndex].hour = data.hour
          this.remindTimeList[this.selectTimeIndex].minute = data.minute
        }
      },
      getReminderSettingLIst() {
        getReminderSetting().then(res => {
          let obj = {}
          for (let key in res) {
            obj[key] = []
            for (let key2 in res[key]) {
              if (key2 != 8 && res[key][key2].name != "不提醒") {
                obj[key].push({
                  id: key2,
                  advanceTime: res[key][key2].advanceTime,
                  delayTime: res[key][key2].delayTime,
                  name: res[key][key2].name,
                  model: false
                })
              }
            }
          }
          this.settingsList = obj
  
          this.getReminderPhoneList()
        })
      },
      chosenoSelefColor(v) {
        if (this.isInvite) {
          return
        }
        this.choosedColor = ''
        this.noSelefColor = v
      },
      choseColor() {
        if (this.isInvite) {
          return
        }
        this.colorPicker = true
      },
      chooseTypeCLick(i) {
        this.pickerType = 'chooseType'
        this.circleType = i
        this.showPicker = true
        this.choseList = ["天", "小时"]
      },
      deletePhone(i) {
        this.phoneList.splice(i, 1)
      },
      deletePhone2(i) {
        this.phoneList2.splice(i, 1)
      },
      formatter(type, val) {
        if (type === 'year') {
          return val + '年';
        }
        if (type === 'month') {
          return val + '月';
        }
        if (type === 'day') {
          return val + '日';
        }
        if (type === 'hour') {
          return val + '时';
        }
        if (type === 'minute') {
          return val + '分';
        }
        return val;
      },
      solveWeekDatePickerColumns() {
        let arr = [
          {
            values: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            defaultIndex: 0,
          }
        ], i = 0, hourArr = [], minutesArr = [];
        for (i; i < 60; i++) {
          if (i < 24) {
            hourArr.push(i < 10 ? '0' + i + '时' : i + '时');
          }
          minutesArr.push(i < 10 ? '0' + i + '分' : i + '分');
        }
        arr.push({
          values: hourArr,
          defaultIndex: 0,
        });
        arr.push({
          values: minutesArr,
          defaultIndex: 0,
        });
        this.weekDatePickerColumns = JSON.parse(JSON.stringify(arr));
      },
      getPlanDetail() {
        getPlanDetail({ id: this.id }).then(res => {
          this.labelId = res.tagId;
          this.labelName = res.tagName;
          this.content = res.content;
          this.cycleValue = this.cycleList[res.period];
          this.remindType = res.way ? res.way.split(',').map(Number) : [];
          if (this.cycleValue === '每年提醒') {
            this.calendarValue = this.calendarList[res.calendar];
          }
          res.times.forEach(v => {
            if (this.cycleValue === '每月提醒') {
              v.value = `${v.day}日 ${v.hour}:${v.minute}`;
            } else if (this.cycleValue === '每年提醒') {
              v.value = `${v.month}月${v.day}日 ${v.hour}:${v.minute}`;
            } else if (this.cycleValue === '单次提醒') {
              v.value = `${v.year}年${v.month}月${v.day}日 ${v.hour}:${v.minute}`;
            } else if (this.cycleValue === '每周提醒') {
              v.value = `${this.weekDatePickerColumns[0].values[v.week]} ${v.hour}:${v.minute}`;
            } else {
              v.value = `${v.hour}:${v.minute}`;
            }
          });
          this.weekLabel = res.except ? res.except.split(',').map(Number) : [];
          this.originDetail = JSON.parse(JSON.stringify(res));
          this.remindTimeList = res.times;
        }).catch(err => {
          this.$router.push({
            path: '/',
          });
        });
      },
      getReminderTags() {
        getReminderTags().then(res => {
          this.labelList = res;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      changeLabel(id) {
        this.labelId = id;
      },
      changeWeekLabel(id) {
        if (this.weekLabel.indexOf(id) > -1) {
          this.weekLabel.splice(this.weekLabel.indexOf(id), 1);
        } else {
          this.weekLabel.push(id);
        }
      },
      chooseCycle() {
        if (this.isInvite) {
          return
        }
        this.showPicker = true;
        this.pickerType = 'cycle';
      },
      chooseCalendar() {
        if (this.isInvite) {
          return
        }
        this.showPicker = true;
        this.pickerType = 'calendar';
      },
      chooseLeadTime() {
        if (this.dataTimeType == "day") {
          this.actionSettingRemind = [{ text: '小时' }, { text: '分钟' }]
          this.mindSetting = {
            inp: "",
            type: "小时",
            bol: false
          }
        } else {
          this.actionSettingRemind = [{ text: '分钟' }, { text: '小时' }, { text: '天' }]
          this.mindSetting = {
            inp: "",
            type: "天",
            bol: false
          }
        }
        this.dataChoosedSetting.forEach(item => {
          if (item.id == 1) {
            this.noMindSettingBol = true
          } else if (item.id == 8) {
            this.mindSetting.bol = true
            this.mindSetting.inp = item.inp
            this.mindSetting.type = item.type
          } else {
            this.settingsList[this.dataTimeType].forEach(item2 => {
              if (item2.id == item.id) {
                item2.model = true
              }
            })
          }
        })
        this.remindSetPopal = true
      },
      chooseCircle() {
        this.showTimePicker = true;
      },
      onConfirm(res) {
        if (this.pickerType == 'chooseType') {
          if (this.choosedType == 0) {
            this.loopPeriod = res
          }
          if (this.choosedType == 1) {
            this.delayTime = res
          }
        }
        if (this.pickerType === 'cycle') {
          if (this.cycleValue == res) {
            this.showPicker = false;
            return
          }
  
          this.remindTimeList.forEach(item => {
            item.type = "del"
          })
          this.remindTimeList.push({ type: "add" })
          this.cycleValue = res;
          for (let key in this.settingsList) {
            this.settingsList[key].forEach(item => {
              if (item.name == "按时提醒") {
                item.model = true
              }
            })
          }
  
          this.CircleRemindTimeList.forEach(item => {
            item.type = "del"
          })
          this.CircleRemindTimeList.push({ type: "add" })
          if (res == "循环提醒") {
            this.selectTimeIndex = this.CircleRemindTimeList.length - 1
            this.circleRemindShow = true
          } else {
            if (res == "每年提醒") {
              this.selectTimeIndex = this.remindTimeList.length - 1
              this.showPicker = false;
              this.showPicker = true;
              this.pickerType = 'calendar';
              return
            } else {
              this.setRemindTime(this.remindTimeList.length - 1)
            }
          }
        }
        if (this.pickerType === 'calendar') {
          this.calendarValue = res;
          this.remindTimeList.forEach(item => {
            item.type = "del"
          })
          this.remindTimeList.push({ type: "add" })
          this.selectTimeIndex = this.remindTimeList.length - 1
          if (res == "阳历(公历)") {
            this.setRemindTime(this.remindTimeList.length - 1)
          } else {
            this.nonglishow = true
          }
        } else if (this.pickerType === 'leadTime') {
          this.leadTimeValue = res;
        }
        this.showPicker = false;
      },
      deleteTime(index, item) {
        if (this.cycleValue == "循环提醒") {
          // item.type="del"
          // this.CircleRemindTimeList[index].type = "del"
          let obj = JSON.parse(JSON.stringify(item))
          obj.type = "del"
          this.CircleRemindTimeList.splice(index, 1)
          this.remindTimeList.push(obj)
          if (this.CircleRemindTimeList.length === 0) {
            this.CircleRemindTimeList = [{ type: "add" }];
          }
        } else {
          let obj = JSON.parse(JSON.stringify(item))
          obj.type = "del"
          this.remindTimeList.splice(index, 1)
          this.remindTimeList.push(obj)
          // this.remindTimeList[index].type = "del"
          if (this.remindTimeList.length === 0) {
            this.remindTimeList = [{ type: "add" }];
          }
        }
  
      },
      addRemindTime() {
        let maxLength = 0
        this.remindTimeList.forEach(item => {
          if (item.type != 'del') {
            maxLength++
          }
        })
        if (maxLength == 24) {
          Notify({ type: 'warning', message: '最多增加24个提醒时间!' });
          return
        }
        if (this.cycleValue == "循环提醒") {
          this.CircleRemindTimeList.push({
            type: "add"
          });
        } else {
          this.remindTimeList.push({
            type: "add"
          });
        }
      },
      setRemindTime(index, item) {
        this.selectTimeIndex = index;
        if (this.cycleValue == "循环提醒") {
          // 循环提醒列表设置时间
          this.loopPeriod.value = ''
          this.delayTime.value = ''
          if (this.CircleRemindTimeList[index].value) {
            this.selectTime = this.CircleRemindTimeList[index].value
          }
          this.chooseCircleTime = JSON.parse(JSON.stringify(item))
          this.choosedType = item.choosedType ? item.choosedType : 0
          if (item.choosedType == 0) {
            this.loopPeriod.type_txt = item.choosedObj.type_txt ? item.choosedObj.type_txt : ''
            this.loopPeriod.value = item.choosedObj.value ? item.choosedObj.value : ''
          } else if (item.choosedType == 1) {
            this.loopPeriod.type_txt = item.choosedObj.type_txt ? item.choosedObj.type_txt : ''
            this.delayTime.value = item.choosedObj.value ? item.choosedObj.value : ''
          }
          this.circleRemindShow = true
          return
        }
        this.selectTime = this.remindTimeList[index].value;
        if (this.calendarValue == "阴历(农历)" && this.cycleValue == '每年提醒') {
          this.nonglishow = true
        } else {
          this.showTimePicker = true;
        }
      },
      confirmRemindTime(res) {
        if (this.cycleValue === '循环提醒') {
          this.chooseCircleTime.timeValue = `${new Date(res).getFullYear()}年 ${new Date(res).getMonth() + 1}月${new Date(res).getDate()}日 ${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`;;
          this.chooseCircleTime.year = new Date(res).getFullYear();
          this.chooseCircleTime.month = new Date(res).getMonth() + 1;
          this.chooseCircleTime.day = new Date(res).getDate();
          this.chooseCircleTime.hour = this.fullData(new Date(res).getHours());
          this.chooseCircleTime.minute = this.fullData(new Date(res).getMinutes());
          this.showTimePicker = false;
          return
        }
        let value = '';
        if (this.dataTimeType === 'day') {
          value = `${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`;
        } else if (this.dataTimeType === 'month') {
          value = `${new Date(res).getDate()}日 ${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`;
        } else if (this.dataTimeType === 'year') {
          value = `${new Date(res).getMonth() + 1}月${new Date(res).getDate()}日 ${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`;
        } else if (this.dataTimeType === 'single') {
          value = `${new Date(res).getFullYear()}年 ${new Date(res).getMonth() + 1}月${new Date(res).getDate()}日 ${this.fullData(new Date(res).getHours())}:${this.fullData(new Date(res).getMinutes())}`;
        }
        let index = -1;
        this.remindTimeList.forEach((v, k) => {
          if (k !== this.selectTimeIndex) {
            if (v.value === value && v.type != "del") {
              index = k;
            }
          }
        });
        if (index === -1) {
          this.remindTimeList[this.selectTimeIndex].value = value;
          this.remindTimeList[this.selectTimeIndex].type2 = ""
          if (this.dataTimeType === 'single') {
            this.remindTimeList[this.selectTimeIndex].year = new Date(res).getFullYear();
            this.remindTimeList[this.selectTimeIndex].month = new Date(res).getMonth() + 1;
            this.remindTimeList[this.selectTimeIndex].day = new Date(res).getDate();
          } else if (this.dataTimeType === 'year') {
            this.remindTimeList[this.selectTimeIndex].month = new Date(res).getMonth() + 1;
            this.remindTimeList[this.selectTimeIndex].day = new Date(res).getDate();
          } else if (this.dataTimeType === 'month') {
            this.remindTimeList[this.selectTimeIndex].day = new Date(res).getDate();
          }
          this.remindTimeList[this.selectTimeIndex].hour = this.fullData(new Date(res).getHours());
          this.remindTimeList[this.selectTimeIndex].minute = this.fullData(new Date(res).getMinutes());
          this.showTimePicker = false;
        } else {
          Notify({ type: 'warning', message: '提醒时间已存在，请重新选择!' });
        }
      },
      onWeekConfirm(res) {
        let value = `${res[0]} ${res[1].split('时')[0]}:${res[2].split('分')[0]}`;
        let index = -1;
        this.remindTimeList.forEach((v, k) => {
          if (k !== this.selectTimeIndex) {
            if (v.value === value) {
              index = k;
            }
          }
        });
        if (index === -1) {
          this.remindTimeList[this.selectTimeIndex].value = value;
          this.remindTimeList[this.selectTimeIndex].week = this.weekDatePickerColumns[0].values.indexOf(res[0]);
          this.remindTimeList[this.selectTimeIndex].hour = res[1].split('时')[0];
          this.remindTimeList[this.selectTimeIndex].minute = res[2].split('分')[0];
          this.showTimePicker = false;
        } else {
          Notify({ type: 'warning', message: '提醒时间已存在，请重新选择!' });
        }
      },
      closeRemindTime() {
        this.showTimePicker = false;
      },
      fullData(param) {
        return param < 10 ? '0' + param : '' + param;
      },
      back() {
        this.$router.replace({ name: 'Remind' });
      },
      submit() {//保存提醒
        if (!this.sendData.tagName) {
          Notify({ type: 'warning', message: '请输入标签内容!' });
          return;
        }
        if (!this.choosedColor && !this.noSelefColor) {
          Notify({ type: 'warning', message: '请选择标签颜色!' });
          return;
        }
        if (!this.sendData.content) {
          Notify({ type: 'warning', message: '请输入事项内容!' });
          return;
        }
        if (this.remindTimeList.length === 0) {
          Notify({ type: 'warning', message: '请添加提醒时间!' });
          return;
        }
        if (this.cycleValue == "循环提醒") {
          for (let i = 0; i < this.CircleRemindTimeList.length; i++) {
            if (this.CircleRemindTimeList[i].type == 'del') {
              continue
            }
            if (!this.CircleRemindTimeList[i].value) {
              Notify({ type: 'warning', message: '请选择提醒时间!' });
              return;
            }
          }
        } else {
          for (let i = 0; i < this.remindTimeList.length; i++) {
            if (this.remindTimeList[i].type == "del") {
              continue
            }
            if (!this.remindTimeList[i].value) {
              Notify({ type: 'warning', message: '请选择提醒时间!' });
              return;
            }
          }
        }
        if (!this.noMindSettingBol && this.dataChoosedSetting.length == 0) {
          Notify({ type: 'warning', message: '请选择提醒设置!' });
          return;
        }
        if (!this.remindType1 && !!this.remindType2 && !!this.remindType3) {
          Notify({ type: 'warning', message: '请选择提醒方式!' });
          return;
        }
        // period
        let setting = []
        if (this.noMindSettingBol) {
          //不提醒
          setting.push({
            id: 1,
            advanceTime: 0,
            delayTime: 0
          })
        } else {
          // 提醒
          setting = this.dataChoosedSetting
        }
        let way = []
        if (this.remindType1) {
          way.push(0)
        }
        if (this.remindType2) {
          way.push(1)
        }
        if (this.remindType3) {
          way.push(2)
        }
        const sendData = {};
        if (this.remindType2) {
          if (!this.phoneList.length) {
            Notify({ type: 'warning', message: '请添加提醒手机号!' });
            return
          }
          sendData.msg_tels = this.phoneList.join(",")//短信提醒手机号
        }
        if (this.remindType3) {
          if (!this.phoneList2.length) {
            Notify({ type: 'warning', message: '请添加提醒手机号!' });
            return
          }
          sendData.mobile_tels = this.phoneList2.join(",")//电话提醒手机号
        }
        sendData.tagName = this.sendData.tagName //标签名字
        sendData.content = this.sendData.content//提醒
        sendData.way = way.join(",")//提醒方式
        sendData.bgColor = this.choosedColor || this.noSelefColor//标签颜色
        sendData.setting = setting //提醒设置
        sendData.remark = this.textContent//提醒备注
        this.cycleList.forEach((v, k) => {
          if (this.cycleValue === v) {
            sendData.period = k; //提醒方式
          }
        });
        if (this.cycleValue === '每天提醒') {
          sendData.except = this.weekLabel.join(',');
        }
        if (this.cycleValue === '每年提醒') {
          this.calendarList.forEach((v, k) => {
            if (this.calendarValue === v) {
              sendData.calendar = k;//日历类型
            }
          });
        }
        let times = sendData.period == 5 ? this.CircleRemindTimeList : this.remindTimeList
        if (this.id) {
          sendData.id = this.id;
        }
        times.forEach(item => {
          if (item.id && !item.type == "del") {
            item.type = "edit"
          }
        })
        sendData.times = JSON.parse(JSON.stringify(times));
        if (this.loading) {
          return
        }
        this.loading = true
  
        $.ajax({
          type: 'post',
          url: config[process.env.NODE_ENV].baseUrl + '/api/v1/reminder/addReminderPlan',
          data: JSON.stringify(sendData),
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          },
          success: (res) => {
            Notify({ type: 'success', message: '操作成功!' });
            if (this.userInfo.subscribe === 0) {
              this.showConcern = true;
              this.loading = false;
            } else {
              // this.back();
              this.$router.push({
                name: 'SetRemind',
                query: {
                  id: res.id,
                }
              });
              this.loading = false;
            }
          },
          fail() {
            this.loading = false;
          },
          complete() {
            this.loading = false;
          }
        });
      },
      resetUserInfo() {
        this.loading = true
        getWechatUserInfo().then(response => {
          response.isPrise = response.isPrise === 1 ? true : false;
          localStorage.setItem('userInfo', JSON.stringify(response));
          this.$store.commit('changeUserInfo', response);
          if (response.isVip == 0) {
            this.remindType2 = false
            this.remindType3 = false
          }
          this.loading = false
        });
      },
      closeDialog(status) {
        if (status) {
          this.resetUserInfo();
        } else {
          this.remindType.splice(this.remindType.indexOf(1));
        }
        this.showBindPhone = false;
      },
      closeConcernDialog() {
        this.showConcern = false;
        this.back();
      },
      remindSettingSave() {
        if (this.noMindSettingBol) {
          this.remindSetPopal = false
          return
        } else {
          let dataChoosedSetting = []
          this.settingsList[this.dataTimeType].forEach(item => {
            if (item.model) {
              dataChoosedSetting.push(item)
            }
          })
          if (this.mindSetting.bol) {
            if (this.mindSetting.inp) {
              let time = this.mindSetting.inp
              if (this.mindSetting.type == "天") {
                time = this.mindSetting.inp * 24 * 60
              } else if (this.mindSetting.type == "小时") {
                time = this.mindSetting.inp * 60
              }
              dataChoosedSetting.push({
                id: "8",
                model: true,
                advanceTime: time,
                inp: this.mindSetting.inp,
                type: this.mindSetting.type
              })
            }
          }
          if (this.mindSetting.bol && !this.mindSetting.inp) {
            this.$notify({ type: 'warning', message: '请输入提醒到期时间' });
            return
          }
          if (this.mindSetting.bol && this.mindSetting.inp) {
            this.dataChoosedSetting = dataChoosedSetting
            this.remindSetPopal = false
            return
          }
          for (let i = 0; i < this.settingsList[this.dataTimeType].length; i++) {
            if (this.settingsList[this.dataTimeType][i].model) {
              this.remindSetPopal = false
              this.dataChoosedSetting = dataChoosedSetting
              return
            }
          }
        }
  
        this.$notify({ type: 'warning', message: '请选择提醒设置' });
      },
      onSelect2(text) {
        this.mindSetting.type = text.text
      },
      selectloopPeriod(text) {
        this.loopPeriod.type_txt = text.text
      },
      selectdelayTime(text) {
        this.delayTime.type_txt = text.text
      },
      saveCircleRemind() {
        // 循环提醒保存
        if (!this.chooseCircleTime.timeValue) {
          this.$notify({ type: 'warning', message: '请选择时间' });
          return
        }
        if (this.choosedType == 0 && !this.loopPeriod.value) {
          this.$notify({ type: 'warning', message: '请输入循环设置' });
          return
        }
        if (this.choosedType == 1 && !this.delayTime.value) {
          this.$notify({ type: 'warning', message: '请输入推算设置' });
          return
        }
        this.CircleRemindTimeList[this.selectTimeIndex] = JSON.parse(JSON.stringify(this.chooseCircleTime))
        this.CircleRemindTimeList[this.selectTimeIndex].choosedType = this.choosedType
        this.CircleRemindTimeList[this.selectTimeIndex].value = this.choosedType == 0 ? '每' + this.loopPeriod.value + this.loopPeriod.type_txt + "提醒一次" : '在' + this.delayTime.value + this.loopPeriod.type_txt + "后提醒"
        this.CircleRemindTimeList[this.selectTimeIndex].choosedObj = this.choosedType == 0 ? JSON.parse(JSON.stringify(this.loopPeriod)) : JSON.parse(JSON.stringify(this.delayTime))
        this.CircleRemindTimeList[this.selectTimeIndex].loopStartTime = this.CircleRemindTimeList[this.selectTimeIndex].timeValue
        if (this.choosedType == 0) {
          this.CircleRemindTimeList[this.selectTimeIndex].loopPeriod = this.CircleRemindTimeList[this.selectTimeIndex].choosedObj
          let text = this.CircleRemindTimeList[this.selectTimeIndex].choosedObj.type_txt
          this.CircleRemindTimeList[this.selectTimeIndex].loopPeriod.type = text == "分钟" ? 1 : text == "小时" ? 2 : text == "天" ? 3 : 1
        }
        if (this.choosedType == 1) {
          this.CircleRemindTimeList[this.selectTimeIndex].delayTime = this.CircleRemindTimeList[this.selectTimeIndex].choosedObj
          let text = this.CircleRemindTimeList[this.selectTimeIndex].choosedObj.type_txt
          this.CircleRemindTimeList[this.selectTimeIndex].delayTime.type = text == "分钟" ? 1 : text == "小时" ? 2 : text == "天" ? 3 : 1
        }
        this.circleRemindShow = false
      },
      getReminderPlanDetail() {
        getReminderPlanDetail({ id: this.id }).then(res => {
          this.sendData.content = res.content//事项
          this.textContent = res.desc//描述
          this.sendData.tagName = res.tagName
          this.cycleValue = this.cycleList[res.period];
          this.isInvite = res.isInvite
          if (this.cycleValue === '每年提醒') {
            this.calendarValue = this.calendarList[res.calendar];
            this.leadTimeValue = this.leadTimeList[res.leadTime];
          }
          if (res.way.indexOf(0) != -1) {
            this.remindType1 = true
          }
          if (res.way.indexOf(1) != -1) {
            this.remindType2 = true
            this.phoneList = res.msgTels.split(",")
          }
          if (res.way.indexOf(2) != -1) {
            this.remindType3 = true
            this.phoneList2 = res.mobileTels.split(",")
          }
          if (this.cycleValue == "循环提醒") {
            this.CircleRemindTimeList = res.times
            this.CircleRemindTimeList.forEach(item => {
              item.type = "edit"
              item.timeValue = item.loopStartTime
              if (item.loopPeriod) {
                item.choosedType = 0
                item.loopPeriod = JSON.parse(item.loopPeriod)
                item.choosedObj = item.loopPeriod
              } else {
                item.choosedType = 1
                item.delayTime = JSON.parse(item.delayTime)
                item.choosedObj = item.delayTime
              }
            })
          } else {
            this.remindTimeList = res.times
            this.remindTimeList.forEach(item => {
              item.type = "edit"
            })
          }
          this.noSelefColor = res.bgColor
          // this.tagColor.forEach(item => {
          //   if (item == res.bgColor) {
          //     this.noSelefColor = item
          //   }
          // })
          if (!this.dataTimeType) {
            this.choosedColor = res.bgColor
          }
          this.dataTimeType = 'single'
          if (this.cycleValue === '每月提醒') {
            this.dataTimeType = 'month';
          } else if (this.cycleValue === '每年提醒') {
            this.dataTimeType = 'year';
          } else if (this.cycleValue === '单次提醒') {
            this.dataTimeType = 'single';
          } else if (this.cycleValue === '每周提醒') {
            this.dataTimeType = 'week';
          } else if (this.cycleValue === '循环提醒') {
            this.dataTimeType = 'loop';
          } else {
            this.dataTimeType = 'day';
          }
          this.editfirst = true
          this.dataChoosedSetting = JSON.parse(res.setting)
          this.dataChoosedSetting.forEach(item => {
            if (item.id == 1) {
              this.noMindSettingBol = true
            } else if (item.id == 8) {
              this.mindSetting.bol = true
              this.mindSetting.inp = item.inp
              this.mindSetting.type = item.type
            } else {
              this.settingsList[this.dataTimeType].forEach(item2 => {
                if (item2.id == item.id) {
                  item2.model = true
                }
              })
            }
          })
        }).catch(err => {
          this.id = ''
        })
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .add-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 12px 0;
    background: #f9f9f9;
    z-index: 50;
  
    .add-content {
      height: 100%;
      overflow-y: auto;
  
      .content-item {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
        margin-bottom: 10px;
        padding: 0 15px;
  
        input {
          border: none;
          outline: none;
        }
  
        .inp1 {
          input {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            width: 100%;
            font-size: 18px;
            color: #333;
            line-height: 20px;
          }
  
          input::-webkit-input-placeholder {
            color: #999999 !important;
            font-size: 16px;
          }
  
        }
  
        .inp2 {
          margin-top: 14px;
          padding-bottom: 8px;
          border-bottom: 1px solid #eee;
  
          textarea {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #333;
            resize: none;
            border: none;
            width: 100%;
            line-height: 18px;
            height: 54px;
  
          }
  
          textarea::-webkit-input-placeholder {
            color: #999999 !important;
            font-size: 14px;
          }
  
          .limit-text {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
  
            color: #999999;
          }
        }
  
        .inp3 {
          display: flex;
          width: 100%;
          margin-top: 15px;
          justify-content: space-between;
  
          .inp3-left {
            position: relative;
  
  
            .xialaArea {
              width: 100%;
              position: absolute;
              top: 100%;
              padding: 6px 0;
              border: 1px solid #e4e7ed;
              border-radius: 4px;
              box-sizing: border-box;
              background-color: #fff;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
              max-height: 200px;
              overflow-y: scroll;
  
              .lis {
                color: #606266;
                height: 34px;
                font-size: 12px;
                line-height: 34px;
                font-size: 14px;
                padding-left: 18px;
              }
            }
  
            img {
              width: 12px;
            }
  
            input {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              width: 60px;
              font-size: 14px;
              line-height: 18px;
              margin-left: 7px;
              color: #333;
            }
  
            input::-webkit-input-placeholder {
              color: #999999 !important;
              font-size: 14px;
            }
          }
  
          .inp3-right {
            .colors {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 16px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
  
              img {
                width: 10px;
              }
  
              .cloreChose {
                position: absolute;
                top: 0%;
                left: 0%;
                width: 100%;
                height: 100%;
  
                input {
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  background-color: transparent;
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
  
      }
  
      .titleArea {
        display: flex;
        margin-bottom: 17px;
        justify-content: space-between;
  
        .title1 {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #666666;
        }
  
        span {
  
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          vertical-align: middle;
          line-height: 16px;
          display: inline-flex;
          align-items: center;
          /* identical to box height, or 133% */
  
  
          color: #666666;
  
  
        }
  
        .count {
          color: #51B26B;
          font-weight: 700;
          font-size: 16px;
        }
  
        .btn {
          font-family: 'PingFang SC';
          font-style: normal;
          width: 48px;
          display: inline-block;
          text-align: center;
          height: 20px;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          background: #52B26B;
          border-radius: 10px;
          color: #FFFFFF;
          margin-left: 12px;
        }
      }
  
      .checkTypeArea {
        background: #FAFAFA;
        border-radius: 8px;
  
        .checkTypes {
          padding: 15px;
          display: flex;
          border-top: 1px solid #eee;
          justify-content: space-between;
  
          .text1 {
            span {
  
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #161A22;
            }
  
            .desc {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #333333;
            }
          }
  
          .text2 {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */
            margin-top: 4px;
            color: #888888;
          }
        }
  
        .checkTypes:first-child {
          border-top: none;
        }
  
  
      }
  
      .myphones {
        padding: 0 15px 17px;
  
        .phones {
          margin-top: 6px;
          padding: 0 12px;
          background: #FFFFFF;
          border: 0.5px solid #eee;
          border-radius: 4px;
          height: 40px;
          display: flex;
          align-items: center;
          border-top: 1px solid #eee;
          justify-content: space-between;
  
          .btn_delete {
            width: 16px;
          }
  
          .phone {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #333333;
          }
  
        }
      }
  
      .plan-label-list {
        .label-list {
          display: flex;
          flex-wrap: wrap;
  
          .label-item {
            width: calc((100% - 30px)/4);
            margin-right: 10px;
            margin-bottom: 10px;
            height: 25px;
            line-height: 25px;
            background: rgba(17, 168, 153, 0.15);
            border-radius: 15px;
            text-align: center;
            font-size: 12px;
            color: #11A899;
  
            &:nth-child(4n) {
              margin-right: 0;
            }
  
            &.active {
              background: #F26A13;
              color: #fff;
            }
          }
        }
  
        .van-field {
          height: 45px;
          line-height: 45px;
          border-bottom: 1px solid #e2e2e2;
          padding: 0 10px;
          margin-bottom: 10px;
        }
      }
  
      .content {
        position: relative;
        margin-top: 8px;
  
        .van-field {
          height: 87px;
          border: 1px solid #E4E4E4;
          border-radius: 5px;
          padding: 0;
  
          &:after {
            display: none;
          }
  
          ::v-deep textarea {
            padding: 10px;
            height: 100%;
          }
        }
  
        .content-tip {
          position: absolute;
          bottom: 5px;
          right: 7px;
          font-size: 13px;
          color: #888;
        }
      }
  
      .picker-select-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border-top: 1px solid #eee;
  
        .label {
          font-size: 16px;
          color: #666666;
        }
  
        .picker-content {
          font-size: 16px;
          color: #333;
          display: flex;
          align-items: center;
  
          .picker-btn {
            vertical-align: middle;
            width: 5px;
            margin-left: 8px;
          }
        }
  
        .setting-box {
          display: inline-block;
          max-width: 40vw;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
  
          font {
            font-size: 16px;
            color: #333;
          }
        }
      }
  
      .setting-item {
        border-bottom: 1px solid #e2e2e2;
        height: 50px;
        line-height: 50px;
        display: flex;
  
        .setting-title {
          color: #666;
          font-size: 16px;
          margin-right: auto;
        }
  
        .img-box {
          width: 50px;
          height: 50px;
  
          img {
            width: 12px;
            height: 12px;
            margin: 19px;
          }
        }
  
        .setting-content {
          color: #666;
          font-size: 12px;
        }
  
        .setting-icon {
  
          margin-left: 10px;
  
          img {
            width: 5px;
            height: 10px;
          }
        }
  
        .add-remind-time-btn {
          width: 80px;
          height: 24px;
  
  
  
  
          .text {
            display: flex;
            justify-content: center;
  
            span {
              text-align: center;
              color: #52B26B;
              font-size: 12px;
              vertical-align: middle;
            }
  
            img {
              margin-bottom: 1px;
              margin-right: 2px;
              width: 12px;
              height: 12px;
              vertical-align: middle;
            }
          }
        }
  
        ::v-deep .van-field {
          flex: 1;
          padding: 0;
  
          input {
            text-align: right;
          }
        }
  
        ::v-deep .van-checkbox-group {
          width: 100%;
          height: 100px;
  
          .van-checkbox {
            height: 50px;
            border-bottom: 1px solid #e2e2e2;
  
            &:last-child {
              border-bottom: none;
            }
  
            .van-checkbox__icon--round {
              height: 100%;
              position: relative;
              width: 15px;
  
              .van-icon {
                font-size: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
  
          }
  
          .van-checkbox__label {
            color: #666;
            font-size: 12px;
            line-height: 50px;
            position: relative;
  
            .label-tip {
              position: absolute;
              left: 80px;
              top: 0;
              white-space: nowrap;
              font-size: 12px;
            }
          }
        }
      }
  
      .btns {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0px -6px 9px 0px rgba(0, 162, 146, 0.11);
        align-items: center;
  
        .back {
          width: 67.5px;
          height: 40px;
          color: #333;
          font-size: 13px;
          text-align: center;
          line-height: 40px;
          background: #eaeaea;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
  
        .submit {
          width: 260px;
          height: 40px;
          color: #fff;
          font-size: 13px;
          text-align: center;
          background: #52B26B;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  
    ::v-deep .van-datetime-picker {
      &.day {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
  
          &:nth-child(2) {
            display: none;
          }
  
          &:nth-child(3) {
            display: none;
          }
        }
      }
  
      &.month {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
  
          &:nth-child(2) {
            display: none;
          }
        }
      }
  
      &.year {
        .van-picker-column {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
  
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ::v-deep .van-checkbox__icon--checked .van-icon {
    border-radius: 4px !important;
  }
  
  ::v-deep .van-checkbox__icon .van-icon {
    border-radius: 4px !important;
    background-color: #fff;
  }
  
  .phonesBefore {
  
    padding: 0 15px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #333333;
      vertical-align: center;
    }
  
    img {
      margin-top: 2px;
      margin-right: 2px;
      width: 12px;
      height: 12px;
      vertical-align: middle;
    }
  
    .van-icon {
      display: flex;
      margin-right: 4px;
      align-items: center;
      justify-content: center;
    }
  }
  
  .bottomBtn {
    text-align: center;
    padding-top: 14px;
    padding-bottom: 24px;
  
    .submit {
      margin: 0 auto;
      width: 155px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      background: #52B26B;
      border-radius: 8px;
    }
  }
  
  .popalContent {
    padding: 16px 15px 32px;
  
    .popalTitle {
  
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666666;
    }
  
    .popalTab {
      margin-top: 24px;
      font-size: 0;
      text-align: center;
  
      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        padding-bottom: 3px;
        border-bottom: 3px solid transparent;
      }
  
      .active {
        font-weight: 600;
        border-bottom: 3px solid #51B26B;
      }
    }
  
    .contentItem {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 8;
  
      .item-centerArea {
        width: 72%;
        height: 40px;
        background: #FAFAFA;
        border: 0.5px solid #eee;
        border-radius: 4px;
        padding: 0 12px 0 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
  
        .selectArea {
          position: absolute;
          top: 50%;
          height: 20px;
          padding-left: 20px;
          border-left: 1px solid #eee;
          transform: translateY(-50%);
          right: 12px;
  
          .select {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #161A22;
  
          }
  
          img {
            margin-left: 6px;
            transform: rotate(90deg);
          }
  
          .selectPop {
            position: absolute;
            top: 100%;
            right: -10%;
            display: inline-block;
            width: 100%;
            border-radius: 4px;
            z-index: 1111;
            background: transparent;
            border: 1px solid #e4e7ed;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            border-top: none;
  
            div {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              z-index: 1111;
              color: #161A22;
              height: 25px;
              line-height: 25px;
              text-align: center;
              width: 100%;
              border-top: 1px solid #e4e7ed;
              background-color: #fff;
            }
          }
        }
  
        input {
          width: calc(100% - 70px);
          padding-left: 28px;
          border: none;
          font-size: 14px;
          color: #000000;
          outline: none;
          background-color: transparent;
        }
  
        input::-webkit-input-placeholder {
          color: #999999 !important;
          font-size: 14px;
        }
  
        span {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #999;
  
        }
  
        .active {
          color: #000000;
        }
      }
  
      .text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        line-height: 18px;
        color: #333333;
      }
  
      .text2 {
        width: 58px;
      }
    }
  
    .contentBtn {
      margin-top: 40px;
      display: flex;
      justify-content: center;
  
      .vatBtn {
        width: 155px;
        height: 44px;
        background: #52B26B;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
      }
    }
  
  }
  
  .pickedTimeListArea {
    padding-bottom: 4px;
  }
  
  .pickedTimeList {
    padding: 0 12px;
    margin-bottom: 12px;
    background: #FAFAFA;
    border: 0.5px solid #eee;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .btn_delete {
      width: 16px;
    }
  
    .span1 {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #888888;
    }
  
    .span2 {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #333333;
      vertical-align: middle;
    }
  
    .picker-btn {
      width: 6px;
      margin-left: 11px;
    }
  }
  
  .remindSetPopal {
    width: 100%;
    background: #FFFFFF;
    padding: 16px 15px 32px;
  
    .popalTitle {
  
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666666;
    }
  
    .remidLists {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
  
      .listTitle {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
      }
  
    }
  
    .listBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
  
      .listBottom-left {
        display: flex;
        align-items: center;
      }
  
      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
      }
  
      input {
        width: 56px;
        border: none;
        font-size: 14px;
        color: #000000;
        outline: none;
        background-color: transparent;
      }
  
      .inputArea {
        width: 163px;
        height: 40px;
        margin: 0 10px;
        background: #FAFAFA;
        border: 0.5px solid #eee;
        border-radius: 4px;
        padding: 0 10px 0 20px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
  
        .selectArea {
          position: absolute;
          top: 50%;
          height: 20px;
          padding-left: 20px;
          border-left: 1px solid #eee;
          transform: translateY(-50%);
          right: 12px;
  
          .select {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #161A22;
  
          }
  
          img {
            margin-left: 6px;
            transform: rotate(90deg);
          }
        }
      }
    }
  
    .remindSaveBtn {
      display: flex;
      justify-content: center;
      margin-top: 32px;
  
      .btn {
        width: 155px;
        height: 44px;
        background: #52B26B;
        border-radius: 8px;
      }
    }
  }
  
  .addPhoneBol {
    width: 100%;
    background: #FFFFFF;
    padding: 16px 15px 32px;
  
    .inps {
      width: 305px;
      height: 48px;
      background: #F7F7F7;
      border-radius: 8px;
      padding: 14px 15px;
      display: flex;
      margin: 0 auto 24px;
      box-sizing: border-box;
  
      .phoneImg {
        width: 12px;
        margin: 0 2px;
        vertical-align: middle;
  
      }
  
      .getCode {
        border-left: 1px solid #CCCCCC;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        display: inline-block;
        font-size: 14px;
        line-height: 18px;
        box-sizing: border-box;
        color: #51B26B;
        white-space: nowrap;
      }
  
      .img {
        width: 16px;
        vertical-align: middle;
      }
  
      .invisibleImg {
        width: 16px;
        vertical-align: middle;
      }
  
      input {
        min-width: 0;
        width: auto;
        flex-grow: 1;
        padding-left: 15px;
        font-size: 14px;
        outline: none;
        border: none;
        vertical-align: middle;
        background-color: transparent;
      }
    }
  
    .remindSaveBtn {
      display: flex;
      justify-content: center;
  
      .btn {
        width: 155px;
        height: 44px;
        background: #52B26B;
        border-radius: 8px;
      }
    }
  }
  
  .popOpen {
    position: relative;
    height: 100%;
    width: 100%;
  
    .head {
      height: 40px;
  
      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  
    .openDivs {
      height: calc(100% - 40px);
      padding-top: 50px;
      width: 100%;
      overflow-y: scroll;
    }
  }
  
  .openDivs {
    height: calc(100% - 40px);
    padding-top: 40px;
    width: 100%;
    overflow-y: scroll;
  }
  
  .colorPicker {
    width: 90%;
    margin: 0 auto;
  }
  
  ::v-deep .van-ellipsis {
    font-size: 16px !important;
  }
  
  ::v-deep .van-picker-column__item--selected .van-ellipsis {
    font-size: 18px !important;
  }
  </style>
  