<template>
  <div class="task-container">
    <div v-for="(item, index) in taskList" :key="index" class="task-item">
      <span class="serial-number">{{ index + 1 }}</span>
      <div class="task-info">
        <h3 class="content-title" @click="action(item)">{{ item.title }}</h3>
        <div class="content-statistics">
          <p>
            <img src="../../../assets/images/find/clock.png">
            {{ '今日打卡' + item.todaySigns + '人' }}
          </p>
          <p>
            <img src="../../../assets/images/find/total.png">
            {{ '总打卡' + item.totalSigns + '人' }}
          </p>
        </div>
      </div>
      <span 
        v-if="item.actionStatus !== -1"
        :class="['action-status', {'active': item.actionStatus === 0 || item.actionStatus === 2 || item.actionStatus === 3 }]" 
        :style="
          item.actionStatus === 0 ? (item.isTodaySign === 0 ? 'background: #C4C4C4;' : '') : (
            item.actionStatus === 2 ? 'background: #C4C4C4;' : ''
          )"
        @click="action(item)"
      >
        {{ item.actionStatus === 0 ? (
          item.isTodaySign === 1 ? '去行动' : '补签') : 
          (item.actionStatus === 1 ? '已行动' : (
            item.actionStatus === 3 ? '补签' : '已过期'
          )) }}
      </span>
      <img v-if="item.actionStatus === -1" src="../../../assets/images/find/lock.png" class="action-icon">
    </div>
    <div v-if="taskList.length === 0" class="none-data">
      <img src="../../../assets/images/remind/none.png">
      <p class="none-tip">暂无任务！</p>
    </div>
    <vant-overlay :show="showDialog">
      <exchange-dialog v-if="showDialog" :num="recordInfo.cardScore" @close="closeDialog"></exchange-dialog>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
  </div>
</template>

<script>
import { getTaskList } from '@/api/find/circleDetail.js';
import { getWechatUserInfo } from '@/api/common';
import exchangeDialog from '../../mine/clockRecord/exchangeDialog.vue';
import { Notify, Dialog, Overlay, Icon, } from 'vant';

export default {
  props: {
    id: {
      type: Number,
      require: true
    },
    isMine: {
      type: Boolean,
      default: () => false
    },
    recordInfo: {
      type: Object,
      require: true
    }
  },
  components: {
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'exchange-dialog': exchangeDialog
  },
  data() {
    return {
      taskList: [],
      showDialog: false
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.getTaskList();
  },
  methods: {
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
    getTaskList() {
      getTaskList({ circleId: this.id }).then(response => {
        this.taskList = response;
        if (response.length > 0) {
          response.forEach(v => {
            if (v.isTodaySign === 1 && v.actionStatus === 1) {
              this.$emit('change', '已行动');
            }
          });
        }
      });
    },
    action(item) {
      if (!this.isMine) {
        Notify({ type: 'warning', message: '您还未加入该圈子!'});
        return;
      }
      if ((item.actionStatus === 0 && item.isTodaySign === 0) || item.actionStatus === 3) {
        if (this.userInfo.signCardNum === 0) {
          Dialog.alert({
            title: '提示',
            message: '补签卡数量不足，请先去兑换补签卡!',
          }).then(() => {
            this.showDialog = true;
          });
        } else {
          Dialog.confirm({
            title: '提示',
            message: '补签将要消耗补签卡，确认需要补签吗？',
          }).then(() => {
            this.$emit('action', item.id);
          }).catch(() => {});
        }
      } else {
        this.$emit('action', item.id);
      }
    },
    closeDialog(type) {
      if (type) {
        this.resetUserInfo();
      }
      this.showDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .task-container {
    .task-item {
      height: 70px;
      position: relative;
      margin-left: 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E2E2E2;
      .serial-number {
        position: absolute;
        top: 0;
        left: -22px;
        width: 22px;
        height: 100%;
        line-height: 70px;
        font-size: 11px;
        color: #52B26B;
        border-bottom: 1px solid #fff;
        text-align: center;
      }
      .task-info {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 18px 0 10px 0;
        .content-title {
          color: #333;
          font-size: 13px;
          margin-bottom: auto;
        }
        .content-statistics {
          display: flex;
          p {
            height: 100%;
            margin-right: 20px;
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #666;
          }
          img {
            width: 10.5px;
            height: 11px;
            margin-right: 2px;
          }
        }
      }
      .action-icon {
        width: 12px;
        height: 16px;
        margin: 0 22px;
      }
      .action-status {
        width: 56px;
        height: 21px;
        text-align: center;
        line-height: 21px;
        color: #666;
        font-size: 11px;
        &.active {
          background: #F26A13;
          border-radius: 10.5px;
          color: #fff;
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
