<template>
    <div class="memberHole">
        <div class="member">
            <div class="tabOut">
                <div class="tab">
                    <div :class="memberType==1?'active':''" @click=" jumpTo(1)">
                        开通会员
                    </div>
                    <div :class="memberType==2?'active':''" @click=" jumpTo(2)">
                        充值积分
                    </div>
                </div>
            </div>
            <div style="width:100%;">
                <keep-alive>
                    <router-view/>
                </keep-alive>
                
                <!-- <open v-if="memberType==1"/>
                <deposit v-if="memberType==2"/> -->
            </div>
        </div>
       
    </div>
</template>
  
<script>
import open from './open.vue';
import deposit from './deposit.vue';
export default {
    data() {
        return {
            checked: false,
            memberType:1
        };
    },
    mounted() {
        this.memberType = this.$route.name=="Deposit"?2:1
    },
    components: {
        "open": open,
        "deposit": deposit
    },
    name: "member",
    methods:{
        jumpTo(i){
            if(this.memberType == i){
                return;
            }
            this.memberType = i
            if(i==1){
                this.$router.push({
                    path: '/member',
                })
            }else{
                this.$router.push({
                     name: 'Deposit',
                })
            }
        }
    }
};
</script>
  
<style lang="scss" scoped>
.memberHole {
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: #F2F2F2;
    .member {
        width: 100%;
        padding: 16px 0 0;
        .tabOut{
            padding: 0 15px;
            width: 100%;
            .tab {
                background: #FFFFFF;
                border: 0.5px solid #E8E8E8;
                border-radius: 18px;
                height: 36px;
                display: flex;
                padding: 2px;
                overflow: hidden;
                width: 100%;
                margin-bottom: 16px;
                div {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 18px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    font-family: 'PingFang SC';
                    color: #666666;
                }
    
                .active {
                    color: #924F0D;
                    background: linear-gradient(270deg, #F7D9BF 0%, #F6E7D6 100%);
                }
            }
        }
        
    }
}
</style>
  