<template>
  <div class="new-user-container">
    <div class="header">
      <img src="../../../assets/images/mine/bg.png" style="width: 100%;height: 100%;">
    </div>
    <div class="content">
      <div class="content-info">
        <p class="info-title">
          <span v-html="'——&nbsp;&nbsp;'"></span> 首次登录送100积分 <span v-html="'&nbsp;&nbsp;——'"></span></p>
        <p class="info-value"><span>100</span>积分</p>
        <p class="info-tip">用于兑换补签卡和礼物</p>
      </div>
      <span class="btn" @click="getScore">立即领取</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getScore() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
  .new-user-container {
    margin: 0 15px;
    width: 345px;
    height: 250px;
    background: #fff;
    position: absolute;
    top: 30%;
    left: 0%;
    border-radius: 5px;
    box-shadow: 0px 6px 9px 0px rgba(0, 162, 146, 0.11);
    border-radius: 5px;
    .header {
      height: 68px;
      background: #52B26B;
      border-radius: 5px 5px 0 0;
    }
    .content {
      padding: 10px;
      height: calc(100% - 68px);
      .content-info {
        height: calc(100% - 50px);
        border-bottom: 1px dashed #EFEFEF;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .info-title {
          color: #52B26B;
          font-size: 13px;
          line-height: 20px;
          span {
            font-weight: bold;
            display: inline-block;
          }
        }
        .info-value {
          height: 55px;
          color: #333;
          font-size: 11px;
          margin: auto;
          span {
            font-size: 35px;
            line-height: 55px;
          }
        }
        .info-tip {
          color: #888888;
          font-size: 10px;
          margin-bottom: 9px;
        }
      }
      .btn {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
        background: #52B26B;
        color: #fff;
        font-size: 12px;
      }
    }
  }
</style>
