<template>
  <div 
    class="circle-detail-box" 
    :style="otherDetailType === '动态' && !showCommentBox ? 'height: calc(100% - 33px);' : 'height: calc(100% - 50px);'"
    @scroll="scrollPage"
  >
    <div class="circle-detail">
      <div class="detail-image-box">
        <img :src="detailInfo.avatar">
      </div>
      <div class="detail-info">
        <p class="info-title">{{ detailInfo.title }}</p>
        <p class="info-desc">{{ detailInfo.intro }}</p>
        <p class="info-about">
          <span class="join-count">已有{{ detailInfo.joinNums }}人加入</span>
          <custom-user :users="detailInfo.joinUsers" @show="showMoreUsers"></custom-user>
        </p>
      </div>
    </div>
    <div class="detail-statistics">
      <div class="statistics-item">
        <p class="item-value">{{ detailInfo.signTime }}</p>
        <p class="item-name">平均打卡</p>
      </div>
      <div class="statistics-item">
        <p class="item-value">{{ detailInfo.todayNums }}人</p>
        <p class="item-name">今日打卡</p>
      </div>
      <div class="statistics-item" @click="openRank">
        <p class="item-value">{{ detailInfo.todayRank }}</p>
        <p class="item-name">今日排名</p>
      </div>
    </div>
    <sign-detail ref="signDetail" v-if="isMine" :type="'全部'" :id="detailInfo.id" :detail-info="detailInfo" :record-info="recordInfo" :calendar-data="calendarData" @change="changeCalendarData" @clock="action"></sign-detail>
    <div class="other-detail-box">
      <div id="other-detail-type-list" class="other-detail-type-list">
        <div 
          v-for="(item, index) in otherDetailTypeList"
          :key="index" 
          :class="['other-detail-type-item', {'active': otherDetailType === item}]"
          @click="changeOtherDetailType(item)"
        >
          {{ item }}
          <div v-if="otherDetailType === item" class="type-line"></div>
        </div>
      </div>
      <div class="other-detail-content" style="padding: 0 0.5px;">
        <custom-detail 
          v-if="otherDetailType === otherDetailTypeList[0]" 
          :lecture-info="detailInfo.lectInfo"
          :desc="detailInfo.desc"
          :intro="detailInfo.intro"
        ></custom-detail>
        <custom-task 
          v-if="otherDetailType === otherDetailTypeList[1]"
          ref="task"
          :id="detailInfo.id"
          :is-mine="isMine"
          :record-info="recordInfo"
          @change="changeTodaySignStatus"
          @action="action"
        ></custom-task>
        <custom-dynamic 
          v-if="otherDetailType === otherDetailTypeList[2]"
          ref="dynamic"
          :id="detailInfo.id"
          :is-mine="isMine"
          :show-comment-box="showCommentBox"
          @toggle="toggleCommentStatus"
        ></custom-dynamic>
      </div>
    </div>
    <div v-if="otherDetailType !== '动态'" class="footer-area" style="padding-left: 0;">
      <div class="operate-btn">
        <div class="btn" @click="home">
          <p class="btn-image">
            <img src="../../../assets/images/find/home.png">
          </p>
          <p class="btn-name">首页</p>
        </div>
        <div class="btn" 
          @click="share"
        >
          <p class="btn-image">
            <img src="../../../assets/images/find/invitation.png">
          </p>
          <p class="btn-name">邀请</p>
        </div>
      </div>
      <div
        v-html="payBtnHtml"
        :style="payBtnHtml === '已行动' || payBtnHtml === '已完成' ? 'background: #e9e9e9;' : ''"
        class="pay-btn"
        @click="action"
      ></div>
      <div class="order-tip" v-if="!isMine && detailInfo.notice">
        <p class="order-tip-content">{{ detailInfo.notice }}</p>
        <div class="triangle"></div>
      </div>
    </div>
    <div v-if="otherDetailType === '动态' && showCommentBox" class="footer-area">
      <vant-field v-model="commentValue" type="text" placeholder="简单说两句吧" ref="commentField"></vant-field>
      <span class="send-btn" @click="sendComment">发送</span>
    </div>
    <vant-overlay :show="showRank">
      <rank-dialog v-if="detailInfo.id && showRank" :id="detailInfo.id"></rank-dialog>
      <vant-icon name="close" @click="closeRank"></vant-icon>
    </vant-overlay>
    <more-users v-if="showMore" :id="detailInfo.id" :circle-id="detailInfo.id" :type="'邀请'" @close="closeMoreUsers"></more-users>
    <custom-loading :loading="loading" :content="loadingContent"></custom-loading>
    <vant-overlay :show="showShare">
      <share-dialog
        v-if="detailInfo.id && showShare"
        :id="detailInfo.id"
        :status="showShareBtn"
        :type="'邀请'"
        :share-img="shareImg"
        @close="closeShare"
        @change="changeShowShareBtn"
      ></share-dialog>
      <vant-icon v-if="!showShareBtn" name="close" @click="closeShare"></vant-icon>
      <share-btn 
        v-if="showShareBtn"
        :id="detailInfo.id"
        :status="showShareBtn"
      ></share-btn>
    </vant-overlay>
    <vant-overlay :show="showConcern">
      <concern-dialog v-if="showConcern" :code="userInfo.qrCode" @close="closeConcernDialog"></concern-dialog>
      <vant-icon name="close" @click="closeConcernDialog"></vant-icon>
    </vant-overlay>
  </div>
</template>

<script>
import Detail from './detail';
import Task from './task';
import Dynamic from './dynamic';
import { getCircleDetail, commentDynamic, replyComment, store, getCalendarData, invite } from '@/api/find/circleDetail.js';
import { getWxPayConfig } from '@/api/common';
import { getClockRecord } from '@/api/mine/clockRecord';
import { Overlay, Field, Icon, Notify, Dialog } from 'vant';
import rankDialog from './rankDialog';
import wxJsConfig from '@/utils/wxJsConfig.js';
import signDetail from './signDetail.vue';
import user from '@/components/user.vue';
import moreUsers from '@/components/moreUsers.vue';
import loading from '@/components/loading.vue';
import share from '@/components/share';
import shareBtn from '@/components/shareBtn';
import concernDialog from '../../remind/concern.vue';
import { getCode, getUrl } from '@/utils/commonFunction';
import { getWechatUserInfo } from '@/api/common';

export default {
  components: {
    'custom-detail': Detail,
    'custom-task': Task,
    'custom-dynamic': Dynamic,
    'vant-overlay': Overlay,
    'vant-field': Field,
    'vant-icon': Icon,
    'rank-dialog': rankDialog,
    'sign-detail': signDetail,
    'custom-user': user,
    'more-users': moreUsers,
    'custom-loading': loading,
    'share-dialog': share,
    'share-btn': shareBtn,
    'concern-dialog': concernDialog
  },
  data() {
    return {
      shareImg: '',
      detailInfo: {},
      otherDetailTypeList: ['详情', '任务', '动态'],
      otherDetailType: '详情',
      showRank: false,
      commentValue: '',
      showCommentBox: false,
      isMine: false,
      payBtnHtml: '',
      calendarData: [],
      showMore: false,
      loading: false,
      loadingContent: '',
      showShare: false,
      showShareBtn: false,
      recordInfo: {},
      showConcern: false,
      pageFrom: ''
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    },
    pageBack: function (){
      return this.$store.state.pageBack;
    }
  },
  watch: {
    otherDetailType() {
      this.showCommentBox = false;
      this.solvePayBtnHtml();
    },
    userInfo: {
      deep: true,
      handler(newValue, oldValue) {
        if (Object.keys(oldValue).length === 0) {
          this.getCircleDetail();
        }
        // if (this.userInfo.subscribe === 0) {
        //   if (this.pageFrom === 'PaySetting') {
        //     this.showConcern = true;
        //   }
        // }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from); // 可以拿到 from， 知道上一个路由是什么，从而进行判断
    if (from.name === 'PaySetting') {
      next((vm) => {
        console.log(vm);
        vm.pageFrom = 'PaySetting';
        vm.resetUserInfo();
      });
    } else {
      next();
    }
  },
  mounted() {
    const code = getUrl('code');
    this.id = this.$route.query.id;
    this.inviteCode = getUrl('inviteCode');
    if (this.inviteCode) {
      this.invite();
    }
    this.detailInfo.id = this.$route.query.id;
    if (code) {
      this.loading = true;
      getCode();
      this.getCircleDetail();
    } else {
      this.resetUserInfo();
      this.getCircleDetail();
    }
    
    
  },
  methods: {
    getCircleDetail() {
      this.loading = true;
      getCircleDetail({ circleId: this.$route.query.id }).then(response => {
        this.loading = false;
        response.price = response.price.split('元')[0];
        this.isMine = response.isJoin === 1 ? true : false;
        this.detailInfo = response;
        if (this.isMine) {
          if (this.pageBack) {
            this.otherDetailType = '动态';
            this.$store.commit('changePageBack', '');
          } else {
            this.otherDetailType = '任务';
          }
        } else {
          this.solvePayBtnHtml();
        }
        if (this.isMine) {
          this.getCalendarData('');
          this.getClockRecord();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getCalendarData(date) {
      this.loading = true;
      getCalendarData({ 
        circleId : this.detailInfo.id,
        month: date
      }).then(res => {
        this.calendarData = JSON.parse(JSON.stringify(res));
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    getClockRecord() {
      getClockRecord().then(res => {
        this.recordInfo = res;
      });
    },
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
        let showQrCodeConcernList = localStorage.getItem('showQrCodeConcernList') ? JSON.parse(localStorage.getItem('showQrCodeConcernList')) : [];
        if (
          showQrCodeConcernList.indexOf(this.id) === -1 
          && response.subscribe === 0
          && this.isMine
        ) {
          this.showConcern = true;
          showQrCodeConcernList.push(this.id);
          localStorage.setItem('showQrCodeConcernList', JSON.stringify(showQrCodeConcernList));
        }
      });
    },
    changeOtherDetailType(item) {
      if (item !== this.otherDetailType) {
        this.otherDetailType = item;
      }
    },
    // 处理底部按钮文字
    solvePayBtnHtml() {
      if (!this.isMine) {
        if (this.otherDetailType !== '动态') {
          this.payBtnHtml = this.detailInfo.isFree === 1 ? '免费加入' : `￥${this.detailInfo.price}&nbsp;&nbsp;&nbsp;加入`;
        }
      } else {
        // if (this.otherDetailType === '详情') {
        //   this.payBtnHtml = '去行动';
        // } else if (this.otherDetailType === '任务') {
          if (this.detailInfo.targetDays - this.detailInfo.totalSignDays > 0) {
            this.payBtnHtml = '去行动';
          } else {
            this.payBtnHtml = '已完成';
          }
        // }
      }
    },
    openRank() {
      if (this.isMine) {
        this.showRank = true;
      } else {
        Notify({ type: 'warning', message: '您还未加入该圈子!' });
      }
    },
    closeRank() {
      this.showRank = false;
    },
    toggleCommentStatus(status) {
      this.commentValue = '';
      this.showCommentBox = status;
      if (status) {
        setTimeout(() => {
          this.$refs.commentField.focus();
        }, 200);
      }
    },
    sendComment() {
      if (!this.commentValue.trim()) {
        Notify({ type: 'warning', message: '请输入评论内容' });
        return false;
      }
      if (!this.$refs.dynamic.commentId) {
        commentDynamic(
          { 
            topicId: this.$refs.dynamic.dynamicId,
            comment: this.commentValue.trim()
          }
        ).then(() => {
          Notify({ type: 'success', message: '评论成功' });
          this.showCommentBox = false;
          this.commentValue = '';
          this.$refs.dynamic.getDynamicList();
          this.$refs.dynamic.clearCommentContent();
        });
      } else {
        replyComment(
          { 
            commentId: this.$refs.dynamic.commentId,
            replyContent: this.commentValue.trim()
          }
        ).then(() => {
          Notify({ type: 'success', message: '回复成功' });
          this.showCommentBox = false;
          this.commentValue = '';
          this.$refs.dynamic.getDynamicList();
          this.$refs.dynamic.clearCommentContent();
        });
      }
    },
    action(taskId) {
      let id = typeof taskId === 'number' ? taskId : '';
      if (this.isMine) {
        if (this.otherDetailType === '任务') {
          if (!id) {
            let list = JSON.parse(JSON.stringify(this.$refs.task.taskList)),
              isAllNeedClock = true, 
              isAllClock = true;
            if (list.length > 0) {
              list.forEach(v => {
                if (v.isTodaySign === 1 && v.actionStatus === 0) {
                  id = v.id;
                }
                if (v.actionStatus !== 1) {
                  isAllClock = false;
                }
                if (v.actionStatus !== 3) {
                  isAllNeedClock = false;
                }
              });
            }
            console.log(id);
            if (id) {
              this.$router.push({
                name: 'TaskDetail',
                query: {
                  id: this.detailInfo.id,
                  taskId: id
                }
              });
            } else {
              console.log(isAllNeedClock, isAllClock);
              if (isAllNeedClock || (!isAllClock && !isAllNeedClock && list[list.length - 1].actionStatus !== -1)) {
                Dialog.alert({
                  message: '您有未完成的任务，请选择补签！',
                }).then(() => {});
              } else {
                let filterArr = list.filter((item) => {
                  return item.actionStatus !== 3 && item.actionStatus !== 1;
                });
                console.log(filterArr);
                if (filterArr.length > 0) {
                  Dialog.alert({
                    message: `还没有到行动时间！<br>下次任务开始时间：${filterArr[0].signTimeStart}`,
                  }).then(() => {});
                }
              }
              
            }
          } else {
            this.$router.push({
              name: 'TaskDetail',
              query: {
                id: this.detailInfo.id,
                taskId: id
              }
            });
          }
        } else if (this.otherDetailType === '详情') {
          this.otherDetailType = '任务';
        }
      } else {
        this.loading = true;
        this.loadingContent = '支付中...';
        store({
          circleId: this.detailInfo.id,
          addressId: 0,
          orderType: 0
        }).then((res) => {
          if (this.detailInfo.isFree === 0) {
            this.getWxPayConfig(res.orderId);
          } else {
            Notify({type: 'success', message: '加入成功' });
            this.getCircleDetail();
            this.$router.push({
              name: 'PaySetting',
              query: {
                circleId: this.detailInfo.id,
                orderId: res.orderId
              }
            });
          }
        });
      }
    },
    getWxPayConfig(id) {
      getWxPayConfig({ orderId: id, payType: 0 }).then((res) => {
        this.initWxPay(res, id);
      });
    },
    initWxPay(data, id) {
      const that = this;
      wxJsConfig.init([
        'chooseWXPay'
      ]).then(() => {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: function (res) {
            that.closeLoading();
            if (res.errMsg == 'chooseWXPay:ok') {
              Notify({type: 'success', message: '支付成功' });
              that.$router.push({
                name: 'PaySetting',
                query: {
                  circleId: that.detailInfo.id,
                  orderId: id
                }
              });
            } else {
              Notify({type: 'danger', message: '支付失败' });
            }
          },
          cancel() {
            that.closeLoading();
            Notify({type: 'warning', message: '取消支付' });
          },
          fail() {
            that.closeLoading();
            Notify({type: 'danger', message: '支付失败' });
          },
        });
      });
    },
    closeLoading() {
      this.loading = false;
      this.loadingContent = '';
    },
    home() {
      this.$router.replace({name: 'Find'});
    },
    share() {
      this.showShare = true;
    },
    closeShare() {
      this.showShare = false;
      this.showShareBtn = false;
    },
    changeShowShareBtn(obj) {
      this.showShareBtn = obj.status;
      this.shareImg = obj.img;
    },
    changeCalendarData(date) {
      this.getCalendarData(date);
    },
    showMoreUsers() {
      this.showMore = true;
    },
    closeMoreUsers() {
      this.showMore = false;
    },
    scrollPage(e) {
      let typeList = document.getElementById('other-detail-type-list'),
        baseScrollTop = 0;
        if (!this.isMine) {
          baseScrollTop = 
            document.getElementsByClassName('circle-detail')[0].offsetHeight +
            document.getElementsByClassName('detail-statistics')[0].offsetHeight + 40;
        } else {
            baseScrollTop = 
              document.getElementsByClassName('circle-detail')[0].offsetHeight +
                document.getElementsByClassName('detail-statistics')[0].offsetHeight +
                this.$refs.signDetail.$el.offsetHeight + 60;
        }
      let scrollTop = e.target.scrollTop;
      if(scrollTop > baseScrollTop){ 
        typeList.style.position = 'fixed';
        typeList.style.top = '0';
        typeList.style.left = '27px';
        typeList.style.zIndex = '99';
        typeList.style.width = 'calc(100% - 54px)';
        document.getElementsByClassName('other-detail-box')[0].style.paddingTop = '44px';
      } else {
        typeList.style.position = 'static';
        typeList.style.width = '100%';
        document.getElementsByClassName('other-detail-box')[0].style.paddingTop = '0px';
      }
    },
    invite() {
      invite({ inviteCode: this.inviteCode });
    },
    closeConcernDialog() {
      this.showConcern = false;
    },
    changeTodaySignStatus(type) {
      this.payBtnHtml = type;
    }
  },
};
</script>

<style lang="scss" scoped>
  .circle-detail-box {
    overflow-y: auto;
    .circle-detail {
      display: flex;
      padding: 13px 15px 0;
      .detail-image-box {
        width: 115px;
        height: 92px;
        border-radius: 5px;
        margin-right: 12px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .detail-info {
        flex: 1;
        height: 92px;
        display: flex;
        flex-direction: column;;
        .info-title {
          font-size: 14px;
          color: #171717;
          line-height: 18px;
          font-weight: bold;
        }
        .info-desc {
          font-size: 11px;
          color: #333;
          line-height: 16px;
          margin: 6px 0;
          flex: 1;
        }
        .info-about {
          display: flex;
          justify-content: space-between;
          .join-count {
            font-size: 12px;
            color: #888;
            line-height: 25px;
          }
        }
      }
    }
    .detail-statistics {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
      padding: 0 15px;
      .statistics-item {
        line-height: 18px;
        width: 90px;
        text-align: center;
        .item-value {
          font-size: 15px;
          color: #F26A13;
          margin-bottom: 6px;
          font-weight: bold;
        }
        .item-name {
          font-size: 11px;
          color: #666666;
        }
      }
    }
    .other-detail-box {
      margin: 0 15px;
      padding: 0 12px 20px;
      border-radius: 10px;
      box-shadow: 0px 6px 9px 0px rgba(0, 162, 146, 0.07);
      background: #fff;
      .other-detail-type-list {
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        justify-content: space-around;
        background: #fff;
        .other-detail-type-item {
          color: #333;
          font-size: 14px;
          line-height: 44px;
          position: relative;
          &.active {
            color: #52B26B;
            font-weight: bold;
            font-size: 15px;
          }
          .type-line {
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background: #52B26B;
          }
        }
      }
    }
    .footer-area {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: 49px;
      display: flex;
      background: #fff;
      align-items: center;
      padding: 0 17.5px;
      box-shadow: -1px -6px 6px 0px rgba(0, 162, 146, 0.05);
      .operate-btn {
        display: flex;
        width: calc(100% - 217.5px);
        height: 100%;
        justify-content: space-evenly;
        .btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 5.5px 0 3.5px;
          .btn-image {
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
              vertical-align: top;
            }
          }
          .btn-name {
            color: #333333;
            font-size: 10px;
          }
        }
      }
      .pay-btn {
        width: 217.5px;
        height: 40px;
        line-height: 40px;
        align-items: center;
        text-align: center;
        font-size: 13px;
        color: #fff;
        font-weight: bold;
        background: #52B26B;
        border-radius: 5px;
        margin: 4.5px 0 4.5px auto;
        position: relative;
      }
      .order-tip {
        position: absolute;
        right: 17.5px;
        top: -25px;
        height: 20px;
        background: #F26A13;
        width: 217.5px;
        border-radius: 5px;
        .order-tip-content {
          font-size: 11px;
          color: #fff;
          line-height: 20px;
          text-align: center;
        }
        .triangle {
          width: 0px;
          height: 0px;
          position: absolute;
          left: calc(50% - 2.5px);
          bottom: -5px;
          line-height: 0px;/*为了防止ie下出现题型*/
          border-top: 5px solid #F26A13;
          border-left: 5px solid #fff;
          border-right: 5px solid #fff;
        }
      }
      .van-field {
        width: calc(100% - 70px);
        height: 30px;
        padding: 0 60px 0 15px;
        background: #F7F7F7;
        border-radius: 15px;
        .van-field_body {
          height: 100%;
        }
      }
      .send-btn {
        width: 65px;
        height: 30px;
        border-radius: 15px;
        background: #52B26B;
        font-size: 13px;
        color: #fff;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 8%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
