<template>
  <div class="remind-container" >
    <div class="remind-type-list">
      <div 
        :class="['remind-type-item', { 'active': type === '提醒'}]"
        @click="changeType('提醒')"
      >
        提醒
        <div v-if="type === '提醒'" class="active-line"></div>
      </div>
      <div 
        :class="['remind-type-item', { 'active': type === '节日'}]"
        @click="changeType('节日')"
      >
        节日
        <div v-if="type === '节日'" class="active-line"></div>
      </div>
    </div>
    <div class="content">
      <plan-list v-if="type === '提醒'" ref="plan"></plan-list>
      <holiday-list v-if="type === '节日'" ref="holiday"></holiday-list>
    </div>
    <div class="add-plan-btn" style="max-left:320px;" @click="addPlan">
      <vant-icon name="plus"></vant-icon>
    </div>
    <openmini-popup></openmini-popup>
  </div>
</template>

<script>
import { getCode, getUrl } from '@/utils/commonFunction';
import { getWechatUserInfo } from '@/api/common';
import planList from './planList.vue';
import holidayList from './holidayList.vue';
import config from '../../../config/config.default';
import { Icon } from 'vant';
import openminiPopup from '@/components/openminiPopup';

export default {
  components: {
    'plan-list': planList,
    'holiday-list': holidayList,
    'vant-icon': Icon,
    'openmini-popup': openminiPopup
  },
  data() {
    return {
      type: '提醒',
      showAdd: false,
      show:false,
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  watch: {
    userInfo: {
      deep: true,
      handler(newValue, oldValue) {
        if (Object.keys(oldValue).length === 0) {
          if (this.type === '提醒') {
            this.$refs.plan.getTagList();
          } else {
            this.$refs.holiday.getHolidayList();
          }
        }
      }
    },
  },
  mounted() {
    const code = getUrl('code');
    if (code) {
      getCode();
    }
    if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
      getWechatUserInfo().then(response => {
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    };
  },
  craeted(){
    this.$nextTick(()=>{
      document.ontouchstart=()=>{
      }
    })
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
    addPlan() {
      if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
        this.$router.push({ name: 'PlanAdd'});
      } else {
        this.$router.push({  name: "LoginBefore"});
      }
    },
  }
};
</script>

<style lang="scss" scoped>
 .remind-container {
   width: 100%;
   height: calc(100% - 49px);
   background: #F7F7F7;
   display: flex;
   flex-direction: column;
   .remind-type-list {
     width: 100%;
     height: 44px;
     background: #F7F7F7;
     display: flex;
     justify-content: space-evenly;
     .remind-type-item {
       height: 40px;
       line-height: 40px;
       font-size: 15px;
       color: #333;
       position: relative;
       &.active {
         color:  #000000;;
         font-weight: bold;
       }
       .active-line {
         width: 80%;
         height: 3px;
         border-radius: 1.5px;
         background: #52B26B;
         position: absolute;
         left: 10%;
         bottom: 0;
       }
     }
   }
   .content {
     padding: 0 15px 20px;
     height: calc(100% - 40px);
    overflow-y: scroll;
   }
   .add-plan-btn {
		width: 50px;
		height: 50px;
		background:  #51B26B;
		border-radius: 50%;
		border: 5px solid #FFFFFF;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
		text-align: center;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 60px;
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
		.van-icon-plus {
		  color: #fff;
		  font-size: 24px;
		}
	}
 }
</style>
