<template>
  <div class="my-dynamics-container">
    <custom-back></custom-back>
    <div class="dynamic-box">
      <h3 class="content-title">我发布的动态</h3>
      <vant-list 
        class="content-list" 
        :style="showCommentBox ? 'height: calc(100% - 106px);' : 'height: calc(100% - 57px);'"
        :finished="noMore"
        @load="loadMore"
      >
        <div v-for="(item, index) in dynamicList" :key="item.id" class="dynamic-item"
          :style="index === dynamicList.length - 1 ? 'margin-bottom: 0;border-bottom: none;' : ''"
        >
          <div class="dynamic-title">
            <div class="anchor-avatar-box">
              <img :src="item.userInfo.avatar">
            </div>
            <div class="dynamic-info">
              <p class="info-name">
                {{ item.userInfo.nickName }}
                <span class="edit-box" @click="editDynamic(item.id)">
                  <img src="../../../assets/images/mine/edit.png">
                </span>
              </p>
              <p class="info-time">{{ item.createdAt }}</p>
            </div>
          </div>
          <p class="dynamic-content">{{ item.content }}</p>
          <div v-if="item.type === 0" class="dynamic-image-list">
            <div v-for="(tmp, index) in item.file" :key="index" class="dynamic-image"
              :style="item.file.length === 1 ? 'max-width: 100%;height: 200px;' : 'width: 70px;height: 70px;position:relative;'"
            >
              <img :src="tmp" :style="item.file.length > 1 ? 'position:absolute;top:50%;left: 50%;transform: translate(-50%, -50%);' : ''" @click="previewImage(item.file, index)">
            </div>
          </div>
          <div v-if="item.type === 1" class="audio-box">
            <audio :src="item.file[0]" controls="controls" :autoplay="false"></audio>
          </div>
          <div v-if="item.type === 2" class="video-box">
            <video 
              :src="item.file[0]" 
              controls="controls" 
              :autoplay="false"
              webkit-playsinline="true"
              x5-video-player-type="h5"
              x5-video-orientation="portraint"
            ></video>
          </div>
          <div class="dynamic-other-operate">
            <p 
              :style="item.isPrise === 1 ? 'color: #F26C16;' : ''" class="dynamic-operate-btn"
              @click="prise(item.id, item.isPrise === 1 ? 0 : 1)"
            >
              <img v-if="item.isPrise === 1" src="../../../assets/images/find/liked.png" style="margin-right: 3px;">
              <img v-if="item.isPrise === 0" src="../../../assets/images/find/nolike.png" style="margin-right: 3px;">
              {{ item.priseNum }}
            </p>
            <p class="dynamic-operate-btn" @click="comment('', '', item.id)">
              <img src="../../../assets/images/find/remark.png" style="margin-right: 3px;">
              {{ item.commentNum }}
            </p>
          </div>
          <div v-if="item.comment.length > 0" class="remark-list">
            <p v-for="(commentItem, k) in item.comment" :key="k" class="remark-item" @click="comment(commentItem.userId, commentItem.replyUid, item.id, commentItem.id)">
              <span v-if="commentItem.replyNickname" class="remark-name">
              {{ commentItem.nickName }}<span> 回复 </span>{{ commentItem.replyNickname }}：
            </span>
            <span v-if="!commentItem.replyNickname" class="remark-name">
              {{ commentItem.nickName }}：
            </span>
                {{ commentItem.comment }}
            </p>
          </div>
        </div>
        <div v-if="dynamicList.length === 0" class="none-data">
          <img src="../../../assets/images/remind/none.png">
          <p class="none-tip">暂无动态！</p>
        </div>
      </vant-list>
    </div>
    <div v-if="showCommentBox" class="footer-area">
      <vant-field v-model="commentValue" type="text" placeholder="简单说两句吧"></vant-field>
      <span class="send-btn" @click="sendComment">发送</span>
    </div>
    <vant-popup 
      v-model="showBtns"
      round 
      position="bottom" 
      :style="{ height: '95px' }"
      :close-on-click-overlay="false"
      :close-on-popstate="true"
    >
      <div class="btns-area">
        <div class="delete-btn btn" @click="deleteDynamic">删除动态</div>
        <div class="close-btn btn" @click="closeDialog">取消</div>
      </div>
    </vant-popup>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { priseDynamic } from '@/api/find/circleDetail.js';
import { Notify, Field, Popup, Dialog, List, ImagePreview } from 'vant';
import Back from '@/components/back.vue';
import { getMyDynamic, deleteDynamic } from '@/api/mine/myDynamic';
import { commentDynamic, replyComment, } from '@/api/find/circleDetail.js';
import loading from '@/components/loading';

export default {
  components: {
    'custom-back': Back,
    'vant-field': Field,
    'vant-popup': Popup,
    'custom-loading': loading,
    'vant-list': List
  },
  data() {
    return {
      dynamicList: [],
      dynamicId: '',
      commentId: '',
      showCommentBox: false,
      commentValue: '',
      showBtns: false,
      dynamicBtnsId: '',
      loading: false,
      noMore: false,
      page: 1,
      pageSize: 10
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.getMyDynamic();
  },
  methods: {
    getMyDynamic() {
      this.loading = true;
      getMyDynamic({ page: this.page, limit: this.pageSize }).then((response) => {
        this.loading = false;
        if (response.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.dynamicList = [];
        }
        this.dynamicList = this.dynamicList.concat(JSON.parse(JSON.stringify(response)));
      }).catch(() => {
        this.loading = false;
      });
    },
    prise(id, val) {
      console.log(val);
      priseDynamic({ topicId: id, isPrise: val }).then(() => {
        this.getMyDynamic();
        if (val === 1) {
          Notify({ type: 'success', message: '点赞成功' });
        } else {
          Notify({ type: 'success', message: '取消成功' });
        }
      });
    },
    comment(uid, replyUid, id, commentId) {
      console.log(this.userInfo, uid, replyUid, id, commentId);
      this.commentValue = '';
      if (replyUid && replyUid > 0) {
        this.dynamicId = '';
        this.commentId = '';
        this.showCommentBox = false;
        return;
      }
      if (uid === this.userInfo.id) {
        this.dynamicId = '';
        this.commentId = '';
        this.showCommentBox = false;
        return;
      }
      if (id !== this.dynamicId) {
        this.dynamicId = id;
        this.showCommentBox = true;
        if (commentId) {
          this.commentId = commentId;
        } else {
          this.commentId = '';
        }
      } else {
        if (commentId && commentId !== this.commentId) {
          this.commentId = commentId;
          this.showCommentBox = true;
        } else {
          this.dynamicId = '';
          this.commentId = '';
          this.showCommentBox = false;
        }
      }
    },
    clearCommentContent() {
      this.dynamicId = '';
      this.commentId = '';
    },
    sendComment() {
      if (!this.commentValue.trim()) {
        Notify({ type: 'warning', message: '请输入评论内容' });
        return false;
      }
      if (!this.commentId) {
        commentDynamic(
          { 
            topicId: this.dynamicId,
            comment: this.commentValue.trim()
          }
        ).then(() => {
          Notify({ type: 'success', message: '评论成功' });
          this.showCommentBox = false;
          this.commentValue = '';
          this.getMyDynamic();
          this.clearCommentContent();
        });
      } else {
        replyComment(
          { 
            commentId: this.commentId,
            replyContent: this.commentValue.trim()
          }
        ).then(() => {
          Notify({ type: 'success', message: '回复成功' });
          this.showCommentBox = false;
          this.commentValue = '';
          this.getMyDynamic();
          this.clearCommentContent();
        });
      }
    },
    closeDialog() {
      this.showBtns = false;
    },
    deleteDynamic() {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除该评论吗？',
      }).then(() => {
        deleteDynamic({ topicId: this.dynamicBtnsId }).then(() => {
          Notify({ type: 'success', message: '删除成功' });
          this.dynamicBtnsId = '';
          this.showBtns = false;
          this.getMyDynamic();
        });
      });
    },
    editDynamic(id) {
      this.showBtns = true;
      this.dynamicBtnsId = id;
    },
    loadMore() {
      if (this.dynamicList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        this.getMyDynamic();
      }
    },
    previewImage(files, index) {
      ImagePreview({
        images: files,
        startPosition: index,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-dynamics-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .dynamic-box {
      flex: 1;
      padding: 0 15px;
      overflow-y: hidden;
      .content-title {
        padding: 10px 0;
        font-size: 13px;
        color: #333;
      }
      .content-list {
        overflow-y: auto;
        background: #fff;
        padding: 10px 13px;
        border-radius: 5px;
        .dynamic-item {
          margin-bottom: 19px;
          border-bottom: 1px solid #e2e2e2;
          &:last-child {
            border-bottom: none;
          }
          .dynamic-title {
            display: flex;
            .anchor-avatar-box {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .dynamic-info {
              position: relative;
              flex: 1;
              .info-name {
                font-size: 13px;
                color: #666;
                line-height: 30px;
                position: relative;
                .edit-box {
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 30px;
                  height: 30px;
                  img {
                    width: 15.5px;
                    height: 15px;
                    vertical-align: top;
                    margin: 7.5px 7.5px;
                  }
                }
              }
              .info-time {
                font-size: 10px;
                color: #888;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }
          .dynamic-content {
            margin: 12.5px 0;
            font-size: 13px;
            color: #666;
          }
          .dynamic-image-list {
            display: flex;
            .dynamic-image {
              border-radius: 5px;
              overflow: hidden;
              margin-bottom: 10px;
              margin-right: calc((100% - 280px)/3);
              &:nth-child(4n) {
                margin-right: 0;
              }
              img {
                width: auto;
                height: 100%;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .audio-box {
            position: relative;
            audio {
              width: 100%;
              height: 40px;
            }
          }
          .video-box {
            position: relative;
            video {
              width: 100%;
              height: 200px;
            }
          }
          .dynamic-other-operate {
            height: 48px;
            display: flex;
            align-items: center;
            .dynamic-operate-btn {
              font-size: 12px;
              color: #8A8A8A;
              display: flex;
              align-items: center;
              &:first-child {
                margin-left: auto;
                img {
                  width: 16px;
                  height: 15.5px;
                }
              }
              &:last-child {
                margin: 0 6.5px 0 31px;
                img {
                  width: 17.5px;
                  height: 15px;
                }
              }
            } 
          }
          .remark-list {
            padding: 5px 7px;
            background: #F7F7F7;
            border-radius: 5px;
            margin-bottom: 12px;
            .remark-item {
              font-size: 12px;
              color: #333;
              line-height: 18px;
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
              .remark-name {
                display: inline-block;
                color: #F26C16;
                span {
                  color: #333;
                }
              }
            }
          }
        }
      }
    }
    .footer-area {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: 49px;
      display: flex;
      background: #fff;
      align-items: center;
      padding: 0 17.5px;
      box-shadow: -1px -6px 6px 0px rgba(0, 162, 146, 0.05);
      .van-field {
        width: calc(100% - 70px);
        height: 30px;
        padding: 0 60px 0 15px;
        background: #F7F7F7;
        border-radius: 15px;
        .van-field_body {
          height: 100%;
        }
      }
      .send-btn {
        width: 65px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #52B26B;
        font-size: 13px;
        color: #fff;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .btns-area {
      width: 100%;
      height: 100%;
      padding: 5px 15px;
      .btn {
        border-radius: 5px;
        background: #52B26B;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        color: #FEFEFE;
        text-align: center;
        &:last-child {
          margin-top: 5px;
        }
        &.close-btn {
          background: #eaeaea;
          color: #333;
        }
      }
    }
  }
</style>
