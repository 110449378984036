<template>
  <div class="mine-container">
    <div class="mine-info-out">
      <img src="../../assets/images/mine/bg_my.png" alt="">
      <div :class="['mine-info', { 'is-login': userInfo.nickName }]">
        <div class="info-avatar-box">
          <img :src="userInfo.avatar ? userInfo.avatar : defaultHead">
        </div>
        <div class="info-content">
          <div>
            <h4 class="info-name" @click="toLogin">{{ userInfo.username || userInfo.nickName || userInfo.phone ||
        '您还没有登录' }}
            </h4>
            <div class="info-sign-days">
              <div style="display: flex;align-items: center;">
                <span class="myCountWords">我的积分：</span><span class="myCount">{{ userInfo.score || 0 }}</span>
                <span v-if="miniShow" class="despoit" @click="toDeposit">充值</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="application-container">
      <div class="application-list vipArea" v-if="miniShow">
        <div class="vipFlex">
          <div class="vip-l">
            <div class="imgOut">
              <img src="../../assets/images/mine/vip.png" class="vipimg" alt="">
            </div>
            <span class="spanvip" v-if="userInfo.isVip && userInfo.isVip">VIP会员</span>
            <span class="spanvip" v-else>您还不是VIP会员</span>
          </div>
          <div class="vip-r">
            <span class="vipDate" v-if="userInfo.vipDays == '-1'">终身会员</span>
            <span class="vipDate" v-if="userInfo.isVip && userInfo.isVip > 0 && userInfo.vipDays != '-1'">{{
        userInfo.vipDays }}天后到期</span>
            <span class="openVip" v-if="!userInfo.isVip || userInfo.vipDays != '-1'">
              <div>
                <span v-if="userInfo.isVip" @click="toMember">
                  立即续费
                </span>
                <span v-else @click="toMember">
                  立即开通
                </span>
                <p class="item-btn"></p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="application-list">
        <div class="application-item" @click="toMyOrders">
          <p class="item-icon"><img src="../../assets/images/mine/order.png"></p>
          <p class="item-name">我的订单</p>
          <p class="item-btn"></p>
        </div>
        <!--<div class="application-item" @click="toClockRecord">
          <p class="item-icon"><img src="../../assets/images/mine/record.png"></p>
          <p class="item-name">打卡记录</p>
          <p class="item-btn"></p>
        </div>
        <div class="application-item" @click="toMyCircles">
          <p class="item-icon"><img src="../../assets/images/mine/circle.png"></p>
          <p class="item-name">我加入的圈子</p>
          <p class="item-btn"></p>
        </div>
        <div class="application-item" @click="toMyDynamics">
          <p class="item-icon"><img src="../../assets/images/mine/dynamic.png"></p>
          <p class="item-name">我发布的动态</p>
          <p class="item-btn"></p>
        </div>-->
      </div>
      <div class="application-list">
        <div class="application-item" @click="toSetting">
          <p class="item-icon"><img src="../../assets/images/mine/setting.png"></p>
          <p class="item-name">设置</p>
          <p class="item-btn"></p>
        </div>
        <div class="application-item" @click="toCustomService">
          <p class="item-icon"><img src="../../assets/images/mine/customService.png"></p>
          <p class="item-name">联系客服</p>
          <p class="item-btn"></p>
        </div>
        <div class="application-item" @click="toFeedback">
          <p class="item-icon"><img src="../../assets/images/mine/feedback.png"></p>
          <p class="item-name">意见反馈</p>
          <p class="item-btn"></p>
        </div>
      </div>
      <div class="application-list laoutArea">
        <div @click="layOut">
          <img src="../../assets/images/mine/icon_quit.png" alt="">
          <span>退出登录</span>
        </div>
      </div>
    </div>
    <vant-overlay :show="showCustomService">
      <custom-service v-if="showCustomService"></custom-service>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getCode, getUrl } from '@/utils/commonFunction';
import { getWechatUserInfo } from '@/api/common';
import loading from '@/components/loading';
import { Overlay, Icon, Dialog } from 'vant';
import CustomService from './customService.vue';

export default {
  components: {
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'custom-service': CustomService,
    'custom-loading': loading
  },
  data() {
    return {
      miniShow:false,
      showCustomService: false,
      loading: false,
      defaultHead: require("../../assets/images/mine/head_default.png")
    };
  },
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    
    const code = getUrl('code');
    if (code) {
      getCode();
    }
    if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
      this.loading = true
      getWechatUserInfo().then(response => {
        this.loading = false
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      }).catch(err => {
        this.loading = false
      });
    }
    this.isMiniprogram();
  },
  methods: {
    vertifyToken(callback) {
      if (localStorage.getItem('userInfo')) {
        callback();
      } else {
        this.$router.push({
          name: "LoginBefore"
        })
      }
    },
    toLogin() {
      this.vertifyToken(() => {
      });
    },
    toMember() {
      this.vertifyToken(() => {
        this.$router.push({
          path: '/member',
        })
      });
    },
    toDeposit() {
      this.vertifyToken(() => {
        this.$router.push({
          name: 'Deposit',
        })
      });
    },
    toMyOrders() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'MyOrders' });
      });
    },
    toClockRecord() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'ClockRecord' });
      });
    },
    toMyCircles() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'MyCircles' });
      });
    },
    toMyDynamics() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'MyDynamics' });
      });
    },
    toSetting() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'Setting' });
      });
    },
    toIntegral() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'Integral' });
      });
    },
    toCustomService() {
      this.showCustomService = true;
    },
    toFeedback() {
      this.vertifyToken(() => {
        this.$router.push({ name: 'Feedback' });
      });
    },
    closeDialog() {
      this.showCustomService = false;
    },
    //创建提醒按钮
    addPlan() {
      if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
        this.$router.push({ name: 'PlanAdd' });
      } else {
        this.$router.push({ name: "LoginBefore" });
      }
    },
    // 退出登录
    layOut() {
      Dialog.confirm({
        message: '您确定退出登录吗？',
      })
        .then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('token_deadtime');
          this.$store.commit('changeUserInfo', {});
          localStorage.removeItem('userInfo')
          that.$notify({ type: 'success', message: '退出登录成功' });
        })
        .catch(() => {
          // on cancel
        });

    },
    isMiniprogram(){
        var ua = window.navigator.userAgent.toLowerCase();
        let that = this;
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //微信环境下
            wx.miniProgram.getEnv(function (res) {
                // alert(JSON.stringify(res))
            if (res.miniprogram) {
                // 小程序环境下逻辑
                console.og("小程序环境下")
                that.miniShow = false;
                return;
            } else {
                //非小程序环境下逻辑
                console.log("非小程序环境下")
                that.miniShow = true;
                return;
            }
            });
        }else{
            that.miniShow = true;
            return;
        }
    },
  }
};
</script>

<style lang="scss" scoped>
.mine-container {
  width: 100%;
  padding-bottom: 108px;

  .mine-info-out {
    width: 100%;
    position: relative;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .mine-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    box-shadow: 0px 6px 9px 0px rgba(0, 162, 146, 0.05);
    border-radius: 10px;
    align-items: center;
    padding: 24px 20px;
    margin-bottom: 10px;


    .info-content {
      flex-grow: 1;
      justify-content: left;
      align-items: center;

      .info-sign-days {
        margin-top: 5px;

        display: flex;
        justify-content: left;

        div {
          width: 100%;

          .myCountWords {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            vertical-align: middle;
          }
        }

        .myCount {
          font-family: 'DIN Alternate';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: #51B26B;
          vertical-align: middle;
        }

        .despoit {
          vertical-align: middle;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 20px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #FFFFFF;
          width: 48px;
          background: #52B26B;
          border-radius: 10px;
          margin-left: 10px;
        }
      }
    }

    .info-integral {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 15px;

      img {
        width: 22px;
        height: 18px;
      }

      .integral-value {
        font-size: 11px;
        color: #333;
        margin-top: 11px;
      }
    }


    .info-avatar-box {
      height: 100%;
      margin-right: 15px;
      box-shadow: 0 0.16rem 0.64rem rgb(0 0 0 / 10%);
      border-radius: 50%;
      overflow: hidden;
      width: 64px;
      height: 64px;

      img {
        border-radius: 50%;
        height: 100%;
      }
    }

    .info-name {
      color: #333;
      font-size: 18px;
    }


  }

  .application-list {
    margin-bottom: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border-radius: 8px;
    padding: 0 21px;


    .application-item {
      display: flex;
      border-bottom: 1px solid #eee;
      height: 52px;
      align-items: center;

      &:last-child {
        border-bottom: 0;
      }

      .item-icon {
        width: 16.361650466918945px;
        margin-right: 10px;

        img {
          width: 100%;
          vertical-align: top;
        }
      }

      .item-name {
        font-size: 16px;
        color: #333;
      }

      .item-btn {
        width: 10px;
        height: 10px;
        border-left: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        -webkit-transform: translate(0, -50%) rotate(-135deg);
        transform: translate(0, -50%) rotate(-135deg);
        margin-left: auto;
        margin-top: 12px;
        margin-right: 5px;
      }
    }
  }

  .van-icon-close {
    position: absolute;
    color: #fff;
    left: 50%;
    bottom: 15%;
    transform: translateX(-50%);
    font-size: 28px;
  }
}

.application-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;

  .vipArea {
    background: linear-gradient(270deg, #F7D9BF 0%, #F6E7D6 100%);
    padding-top: 12px;
    padding-bottom: 12px;

    .vipFlex {
      display: flex;
      justify-content: space-between;

      .vip-l,
      vip-r {
        display: flex;
      }


      .imgOut {
        display: flex;
        align-items: center;

        .vipimg {
          width: 20px;
          height: 18px;
        }
      }


      .spanvip {
        margin-left: 10px;
        font-family: 'PingFang SC';
        font-style: normal;
        vertical-align: bottom;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #924F0D;
      }

      .vipDate {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #333;
      }

      .openVip {
        margin-left: 12px;
        background: #343233;
        border-radius: 5px;
        display: inline-flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        color: #F7DFC8;
        font-size: 0;

        .item-btn {
          width: 7.5px;
          height: 7.5px;
          border-left: 2px solid #F7DBC2;
          border-bottom: 2px solid #F7DBC2;
          -webkit-transform: translateY(0.5px) rotate(-135deg);
          transform: translateY(0.5px) rotate(-135deg);
          margin-left: 4px;
        }

        div {
          font-size: 0;
          display: flex;
          align-items: center;
        }

        span {
          font-size: 14px;
          font-family: 'PingFang SC';
          font-style: normal;
          line-height: 16px;
          font-weight: 400;
        }

        img {
          width: 5px;
          height: 10px;

        }
      }
    }

  }
}

.laoutArea {
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    margin-right: 7px;
    vertical-align: middle;
  }

  span {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    vertical-align: middle;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #666666;
  }
}

.add-plan-btn {
  width: 50px;
  height: 50px;
  background: #51B26B;
  border-radius: 50%;
  border: 5px solid #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .van-icon-plus {
    color: #fff;
    font-size: 24px;
  }
}
.loading-box{
	display: none;
}
</style>
<style scoped>
@font-face {
  font-family: 'iconfont2';
  /* Project id 3823280 */
  src: url('//at.alicdn.com/t/c/font_3823280_eygdu1xzbm.woff2?t=1672731436866') format('woff2'),
    url('//at.alicdn.com/t/c/font_3823280_eygdu1xzbm.woff?t=1672731436866') format('woff'),
    url('//at.alicdn.com/t/c/font_3823280_eygdu1xzbm.ttf?t=1672731436866') format('truetype');
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  font-style: normal;
  color: #999;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #F7DFC8;
}
</style>
