import { request } from '@/utils/ajax.js';
export function vipInfo(params = {}) {
  return request({
    url: '/api/v1/user/vipInfo',
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export function buyvip(params = {}) {
  return request({
    url: '/api/v1/order/buyvip',
    method: 'post',
    data: params,
    authorization:true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export function score(params = {}) {
  return request({
    url: '/api/v1/user/score',
    method: 'get',
    params: params,
    authorization:true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export function recharge(params = {}) {
  return request({
    url: '/api/v1/order/recharge',
    method: 'post',
    data: params,
    authorization:true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};