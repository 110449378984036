<template>
  <div class="bottomTab" v-if="
    $route.name == 'Find' ||
    $route.name == 'Mine' ||
    $route.name == 'Remind'
  " style="max-width: 640px;">
    <div v-for="(item, i) in tabbarList" class="tabs" :key="i" @click="push(item.path)">
      <div>
        <img :src="active==i?item.activeImage:item.image" class="img" />
      </div>
      <div>
        <span :class="active==i?'active':null">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';

export default {
  components: {
    'vant-tabbar': Tabbar,
    'vant-tabbar-item': TabbarItem
  },
  data() {
    return {
      active: 0,
      tabbarList: [
        {
          name: '提醒',
          image: require('../assets/images/tabbar/tabbar01.png'),
          activeImage: require('../assets/images/tabbar/tabbar01_pre.png'),
          path: '/remind'
        },
        /*{
          name: '发现',
          image: require('../assets/images/tabbar/tabbar02.png'),
          activeImage: require('../assets/images/tabbar/tabbar02_pre.png'),
          path: '/find'
        },*/
        {
          name: '我的',
          image: require('../assets/images/tabbar/tabbar03.png'),
          activeImage: require('../assets/images/tabbar/tabbar03_pre.png'),
          path: '/mine'
        },
      ]
    };
  },
  //监听路由变化
  watch: {
    $route(to) {
      this.activeTab(to.path);
    },
  },
  methods: {
    activeTab(path) {
      var index = this.tabbarList.map((item) => item.path).indexOf(path);
      if (index != -1) {
        this.active = index;
      }
    },
    push(path){
      this.$router.push(
        {
          path
        }
      )
    }
  }
};
</script>

<style lang="scss">
.bottomTab {
  width: 100%;
  height: 49px;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 5px 0;
  display: flex;
}

.tabs {
  width: 50%;
  div{
    text-align: center;
  }
  .img {
    width: 22px;
  }

  span {
    margin-top: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000;
  }
  .active{
    color: #52B26B;
  }
}
</style>
