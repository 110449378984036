<template>
  <div class="plan-list-container">
    <plan-calendar ref="calendar" @change="changeDate"></plan-calendar>
    <div class="plan-list-content">
      <div class="plan-list">
        <div class="titleList" v-if="titleList.length && userInfo.nickName">
          <div :class="v.name == selectedTitle ? 'listItem active' : 'listItem'" @click="selectTag(v.name)"
            v-for="(v, i) in titleList" :key="i" >
            #{{ v.name }}
            <img src="./del.svg"  class="del" v-if="v.name == selectedTitle &&selectedTitle != '全部'&&(!planList[1] || planList[1].length === 0) && (nowHandledReminds.length === 0)" @click="delTag(v)"/>
          </div>
        </div>
        <van-swipe-cell v-for="(v, i) in planList[1]" :key="'item1' + i"
          v-if="selectedTitle == '全部' || selectedTitle == v.tagName">
          <div class="contentList" @click="toSetRemind(v.id)">
            <div class="contentLeft">
              <div class="contentTitle" :style="'background-color:' + v.tagBgColor">
                {{ v.tagName }}
              </div>
              <div class="contentContent">
                <div style="width:100%">
                  <div class="tltle1">{{ v.content }}</div>
                  <div class="imgList" v-if="v.inviteUserNum && v.inviteUserNum> 1">
                    <img v-if="j < 4" :style="'transform:translateX(calc(' + (-j) * 30 + '% + -0px))'" :src="item.avatar"
                      v-for="(item, j) in v.joinUsers" :key="j" alt="">
                    <img v-if="v.inviteUserNum > 4" src="../../assets/images/remind/icon_more.png"
                      :style="'transform:translateX(calc(' + (-4) * 30 + '% + 0px))'" alt="" style="">
                    <img v-else src="../../assets/images/remind/icon_more.png"
                      :style="'transform:translateX(calc(' + (-v.inviteUserNum) * 30 + '% + 0px))'" alt="" style="">
                    <span>{{ v.inviteUserNum }}人加入</span>
                  </div>
                  <div class="title2" v-else>距离 {{ v.date }} 还有</div>
                </div>
              </div>
            </div>
            <div class="contentRight">
              <div>
                <span v-if="v.restDay >= 1">{{ v.restDay }}</span>
                <span class="percent" v-if="v.restDay >= 1">
                  天
                </span>
                <span v-if="v.restHour >= 1 && v.restDay < 1">{{ v.restHour }}</span>
                <span class="percent" v-if="v.restHour && v.restDay < 1">
                  时
                </span>
                <span v-if="v.restDay < 1 && v.restHour < 1">{{ v.restMinute }}</span>
                <span class="percent" v-if="v.restDay < 1 && v.restHour < 1">
                  分
                </span>
              </div>
            </div>
          </div>
          <template #right>
            <div class="editBtn" @click="editPlan(v.id)">
              编辑
            </div>
            <div class="editBtn deleteBtn" v-if="!v.isInvite" @click="deletePlan(v.id)">
              删除
            </div>
            <div class="editBtn deleteBtn" v-else @click="deletePlan(v.id)">
              退出
            </div>
          </template>
        </van-swipe-cell>
        <div v-if="nowHandledReminds.length > 0" class="sub-title">已完成提醒</div>
        <van-swipe-cell v-for="(v, i) in nowHandledReminds" :key="'item2' + i"
          v-if="selectedTitle == '全部' || selectedTitle == v.tagName">
          <div class="contentList" @click="toSetRemind(v.id)">
            <div class="contentLeft">
              <div class="contentTitle" :style="'background-color:' + v.tagBgColor">
                {{ v.tagName }}
              </div>
              <div class="contentContent">
                <div style="width:100%">
                  <div class="tltle1">{{ v.content }}</div>
                  <div class="imgList" v-if="v.inviteUserNum && v.inviteUserNum > 1">
                    <img v-if="j < 4" :style="'transform:translateX(calc(' + (-j) * 30 + '% + -0px))'" :src="item.avatar"
                      v-for="(item, j) in v.joinUsers" :key="j" alt="">
                    <img v-if="v.inviteUserNum > 4" src="../../assets/images/remind/icon_more.png"
                      :style="'transform:translateX(calc(' + (-4) * 30 + '% + 0px))'" alt="" style="">
                    <img v-else src="../../assets/images/remind/icon_more.png"
                      :style="'transform:translateX(calc(' + (-v.inviteUserNum) * 30 + '% + 0px))'" alt="" style="">
                    <span>{{ v.inviteUserNum }}人加入</span>
                  </div>
                  <div class="title2" v-else>距离 {{ v.date }} 已过去</div>
                </div>
              </div>
            </div>
            <div class="contentRight">
              <div>
                <span v-if="v.restDay >= 1">{{ v.restDay }}</span>
                <span class="percent" v-if="v.restDay >= 1">
                  天
                </span>
                <span v-if="v.restHour >= 1 && v.restDay < 1">{{ v.restHour }}</span>
                <span class="percent" v-if="v.restHour && v.restDay < 1">
                  时
                </span>
                <span v-if="v.restDay < 1 && v.restHour < 1">{{ v.restMinute }}</span>
                <span class="percent" v-if="v.restDay < 1 && v.restHour < 1">
                  分
                </span>
              </div>
            </div>
          </div>
          <template #right>
            <div class="editBtn" @click="editPlan(v.id)">
              编辑
            </div>
            <div class="editBtn deleteBtn" v-if="!v.isInvite" @click="deletePlan(v.id)">
              删除
            </div>
            <div class="editBtn deleteBtn" v-else @click="deletePlan(v.id)">
              退出
            </div>
          </template>
        </van-swipe-cell>
        <div v-if="(!planList[1] || planList[1].length === 0) && (nowHandledReminds.length === 0)" class="none-data">
          <img src="../../assets/images/remind/none.png">
          <p class="none-tip" v-if="userInfo.nickName">暂无提醒事项</p>
          <div class="no-Login" v-else @click="toLogin">亲，您还没有登录哦
            <div class="Login-btn">立即登录</div>
          </div>

        </div>
      </div>
    </div>
    <vant-popup v-model="showBtns" round position="bottom" :style="{ height: '140px' }" :close-on-click-overlay="false"
      :close-on-popstate="true">
      <div class="btns-area">
        <div class="edit-btn btn" @click="editPlan">编辑</div>
        <div class="delete-btn btn" @click="deletePlan">删除</div>
        <div class="close-btn btn" @click="closeDialog">取消</div>
      </div>
    </vant-popup>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import planCalendar from '@/components/planCalendar.vue';
import { Step, Steps, Popup, Dialog, Notify, SwipeCell } from 'vant';
import { getPlanList, delPlan, getReminderTags,delReminderTags } from '@/api/remind/index.js';
import loading from '@/components/loading';

export default {
  components: {
    'plan-calendar': planCalendar,
    'vant-step': Step,
    'vant-steps': Steps,
    'vant-popup': Popup,
    'custom-loading': loading,
    'van-swipe-cell': SwipeCell
  },
  data() {
    return {
      getPlanListData:{},
      planList: {},
      showBtns: false,
      clickPlanId: 0,
      loading: false,
      showCalendar: true,
      selectedTitle: '全部',
      titleList: [{
        bgColor: "#1EB465",
        id: 0,
        name: "全部",
      }],
      nowHandledReminds: [],
      firstBol: true
    };
  },
  watch: {
    showCalendar() {
      if (!this.showCalendar) {
        this.getTagList()
      }
    }
  },

  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    }
  },

  mounted() {
    // this.getTagList()
    if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
      this.getTagList()
    }
  },
  methods: {
    selectTag(name) {
      this.selectedTitle = name
      this.nowHandledReminds = []
      this.planList[1] = []
      if(this.getPlanListData[1]){
        this.getPlanListData[1].forEach(item => {
        if (this.selectedTitle == "全部" || item.tagName == name) {
          this.planList[1].push(item)
        }
      })
      }

      this.getPlanListData[2].forEach(item => {
        if (this.selectedTitle == "全部" || item.tagName == name) {
          this.nowHandledReminds.push(item)
        }
      })
    },
    delTag(v){
      Dialog.confirm({
        title: '提示',
        confirmButtonText: "确定删除",
        message: '确定删除该标签？',
      }).then(()=>{
        delReminderTags({
          id:v.id
        }).then((res)=>{
          Notify({ type: 'success', message: '删除成功' });
          this.selectedTitle = '全部'
          this.getTagList();
        })
      })
    },
    getTagList() {
      this.loading = true;
      getReminderTags().then(res => {
        this.titleList = [{
          bgColor: "#1EB465",
          id: 0,
          name: "全部",
        }]
        this.selectedTitle = '全部'
        this.titleList = [...this.titleList, ...res]
        if (res.length == 0) {
          this.loading = false;
          return
        }
        this.selectedTitle = this.titleList[0].name
        this.getPlanList();
      })
    },
    toSetRemind(id) {
      let date2 = this.showCalendar ? `${this.$refs.calendar.nowYear}/${this.$refs.calendar.nowMonth}/${this.$refs.calendar.nowDate}` : '';
      if (this.firstBol) {
        date2 = ''
      }
      this.$router.push({
        name: 'SetRemind',
        query: {
          id: id,
          date: date2
        }
      });
    },
    changeDate() {
      this.firstBol = false
      this.getTagList()
    },
    getPlanList() {
      this.loading = true;
      let date = this.showCalendar ? `${this.$refs.calendar.nowYear}/${this.$refs.calendar.nowMonth}/${this.$refs.calendar.nowDate}` : '';
      if (this.firstBol) {
        date = ''
      }
      getPlanList({ date: date }).then(res => {
        this.loading = false;
        this.getPlanListData = JSON.parse(JSON.stringify(res));
        this.planList = JSON.parse(JSON.stringify(res));
        this.selectTag(this.titleList[0].name)
      }).catch(() => {
        this.loading = false;
      });
    },
    toDetail(id) {
      // this.$router.push({
      //   name: 'PlanDetail',
      //   query: {
      //     id: id
      //   }
      // });
    },
    showDialog(id) {
      this.showBtns = true;
      this.clickPlanId = id;
    },
    editPlan(id) {
      this.$router.push({
        name: 'PlanAdd',
        query: {
          id: id
        }
      });
    },
    deletePlan(id) {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除该提醒吗？',
      }).then(() => {
        this.loading = true
        delPlan({ planId: id }).then(() => {
          Notify({ type: 'success', message: '删除成功' });
          this.clickPlanId = 0;
          this.getTagList();
        });
      });
    },
    //跳转登录页
    vertifyToken(callback) {
      if (localStorage.getItem('userInfo')) {
        callback();
      } else {
        // getCode();
        this.$router.push({
          name: "LoginBefore"
        })
      }
    },
    toLogin() {
      this.vertifyToken(() => {
      });
    },

    closeDialog() {
      this.showBtns = false;
      this.clickPlanId = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.plan-list-container {
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .plan-list-content {
    flex: 1;

    .plan-list {
      height: 100%;

      ::v-deep .van-steps {
        background-color: transparent;

        .van-step {
          &:before {
            display: none;
          }

          &:after {
            display: none;
          }

          &.van-step--vertical {
            padding: 5px 10px 5px 0;
          }
        }

        .van-step__circle-container {
          top: 50px;
        }

        .van-step__line {
          top: 50px;
        }

        .last-first-plan {
          .van-step__line {
            height: calc(100% + 37px);
          }
        }
      }

      .sub-title {
        color: #666666;
        font-size: 12px;
        margin: 30px 0 0px;
      }

      .plan-item {
        height: 75px;
        background: #FFFFFF;
        box-shadow: 0px 6px 8px 1px rgba(204, 204, 204, 0.22);
        border-radius: 10px;
        margin-bottom: 7.5px;
        display: flex;

        .plan-title {
          width: 33px;
          height: 100%;
          border-radius: 5px 0 0 5px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #fff;
          margin-right: 9px;
          background: #B4B4B4;
        }

        .plan-info {
          display: flex;
          flex-direction: column;
          padding: 10px 0;
          width: 130px;

          .info-content {
            color: #333333;
            font-size: 13px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: bold;
          }

          .info-date {
            color: #666666;
            font-size: 11px;
            margin-top: auto;
          }
        }

        .plan-rest {
          color: #888888;
          font-size: 9px;
          margin: 30px 6px 30px auto;
          height: 15px;

          span {
            color: #2FB2A5;
            font-size: 18px;
          }
        }

        img {
          width: 20px;
          height: 19.5px;
          margin: 27.75px 14px 27.75px auto;
        }
      }

      .title {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #333;
        font-weight: bold;
        position: relative;
        margin-bottom: 10px;

        img {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    .none-data {
      display: flex;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;

      img {
        width: 175px;
        height: 134px;
      }

      .none-tip {
        font-size: 14px;
        color: #51B26B;
        margin-top: 5px;
      }

      .no-Login {
        color: #51b26b;
        text-align: center;

        .Login-btn {
          display: block;
          text-align: center;
          width: 150px;
          margin-top: 15px;
          padding: 10px;
          background: #52b26b;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
  }

  .btns-area {
    width: 100%;
    height: 100%;
    padding: 5px 15px;

    .btn {
      border-radius: 5px;
      background: #51B26B;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      text-align: center;
      background: #eaeaea;
      color: #333;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }

      &.edit-btn {
        background: #51B26B;
        color: #FEFEFE;
      }
    }
  }
}

.titleList::-webkit-scrollbar {
  display: none;
}

.titleList {
  padding-bottom: 4px;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  white-space: noraw;

  .listItem {
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    height: 24px;
    width: calc((100vw - 30px)/5);
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #999999;
    line-height: 24px;
    position:relative;
    .del{
      position:absolute;
      top:4px;
      width:15px;
      right:-4px;
    }
  }

  .active {
    color: #FFFFFF;
    background: #52B26B;
  }
}

.contentList {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;
  overflow: hidden;
  display: flex;
  padding-right: 15px;
  justify-content: space-between;

  .contentLeft {
    display: flex;
    width: 70%;
  }

  .contentTitle {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    height: 76px;
    width: 40px;
    box-sizing: border-box;
    padding-left: 13px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #52B26B;
  }

  .contentContent {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    align-items: center;

    .tltle1 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }

    .imgList {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;

      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        vertical-align: middle;
      }

      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        vertical-align: middle;
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
    }

    .title2 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #888888;

    }
  }

  .contentRight {
    display: flex;
    align-items: center;

    span {
      font-family: 'DIN Alternate';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 34px;
      /* identical to box height, or 113% */

      text-align: right;

      color: #51B26B;

    }

    .percent {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #888888;
    }
  }

}

.editBtn {
  background: #4FA7FF;
  border-radius: 8px 0px 0px 8px;
  width: 72px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 76px;
  text-align: center;
  color: #FFFFFF;
  height: 76px;
  display: inline-block;
  margin-left: 4px;
}

.deleteBtn {
  margin-left: 0px;
  background: #FF4747;
  border-radius: 0px 8px 8px 0px;
}

.deleteBtn2 {
  background: #FF4747;
}
</style>
