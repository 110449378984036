<template>
  <div class="integral-container">
    <custom-back></custom-back>
    <div class="integral-content">
      <div class="integral-recharge-content">
        <div class="recharge-intro">
          <div class="intro-title">
            <span class="title-left">我的积分</span>
            <span class="title-right">积分说明</span>
          </div>
          <div class="intro-value">
            <span class="value-left">{{ integralInfo.myScore }}</span>
            <span class="value-right">{{ integralInfo.minScore }}积分/{{ integralInfo.minScore / integralInfo.scoreRate }}元</span>
          </div>
        </div>
        <div class="recharge-score-list">
          <p 
            v-for="(item, index) in integralInfo.scoreCard"
            :key="index"
            :class="['score-list-item', {'active': rechargeValue === item.num }]"
            @click="changeRechargeValue(item.num)">{{ item.num }}<span class="unit">积分</span></p>
        </div>
        <p v-if="rechargeValue > 0" class="recharge-money">微信支付：<span class="money">{{ rechargeValue / integralInfo.scoreRate }}元</span></p>
      </div>
      <div class="integral-record" :style="rechargeValue > 0 ? 'height: calc(100% - 252px);' : ''">
        <h3 class="record-title">积分记录</h3>
        <vant-list 
          class="record-list"
          :finished="noMore"
          @load="loadMore"
        >
          <div 
            v-for="(item, index) in integralInfo.scoreList || []"
            :key="index"
            class="list-item"
          >
            <p class="item-time">{{ item.createdAt }}</p>
            <p class="item-info">
              <span class="info-title">{{ item.type }}</span>
              <span class="info-value">{{ item.score }}</span>
            </p>
          </div>
          <div v-if="integralInfo.scoreList && integralInfo.scoreList.length === 0" class="none-data">
            <img src="../../../assets/images/remind/none.png">
            <p class="none-tip">暂无积分记录！</p>
          </div>
        </vant-list>
      </div>
      <div v-if="rechargeValue > 0" class="recharge-btn-area">
        <div class="recharge-btn" @click="recharge">立即充值</div>
      </div>
    </div>
    <custom-loading :loading="loading" :content="loadingContent"></custom-loading>
    <vant-overlay :show="showDialog">
      <new-user v-if="showDialog" @close="closeDialog"></new-user>
      <vant-icon name="close" @click="closeDialog"></vant-icon>
    </vant-overlay>
  </div>
</template>

<script>
import Back from '@/components/back.vue';
import { Notify, Overlay, Icon, List } from 'vant';
import { getIntegralRecord, recharge } from '@/api/mine/integral';
import { getWxPayConfig, getWechatUserInfo } from '@/api/common';
import wxJsConfig from '@/utils/wxJsConfig.js';
import loading from '@/components/loading.vue';
import newUser from './newUser.vue';

export default {
  components: {
    'custom-back': Back,
    'custom-loading': loading,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'new-user': newUser,
    'vant-list': List
  },
  data() {
    return {
      rechargeValue: 0,
      integralInfo: {},
      loading: false,
      loadingContent: '',
      showDialog: false,
      noMore: false,
      page: 1,
      pageSize: 10
    };
  },
  computed: {
    userInfo: function (){
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    this.getIntegralRecord();
    if (localStorage.getItem('isNew') === '1') {
      this.showDialog = true;
      localStorage.setItem('isNew', '0');
    }
  },
  methods: {
    resetUserInfo() {
      getWechatUserInfo().then(response => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem('userInfo', JSON.stringify(response));
        this.$store.commit('changeUserInfo', response);
      });
    },
    getIntegralRecord() {
      this.loading = true;
      getIntegralRecord({ page: this.page, limit: this.pageSize }).then(res => {
        this.loading = false;
        this.integralInfo.minScore = res.minScore;
        this.integralInfo.myScore = res.myScore;
        this.integralInfo.scoreCard = res.scoreCard;
        this.integralInfo.scoreRate = res.scoreRate;
        if (res.scoreList.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.integralInfo.scoreList = [];
        }
        this.integralInfo.scoreList = this.integralInfo.scoreList.concat(JSON.parse(JSON.stringify(res.scoreList)));
      }).catch(() => {
        this.loading = false;
      });
    },
    changeRechargeValue(val) {
      this.rechargeValue = val;
    },
    recharge() {
      let rechargeId;
      this.integralInfo.scoreCard.forEach(v => {
        if (v.num === this.rechargeValue) {
          rechargeId = v.id;
        }
      });
      this.loading = true;
      this.loadingContent = '支付中...';
      recharge({
        scoreCardId: rechargeId,
      }).then((res) => {
        this.getWxPayConfig(res.orderId);
      });
    },
    getWxPayConfig(id) {
      getWxPayConfig({ orderId: id, payType: 0 }).then((res) => {
        console.log(res);
        this.initWxPay(res);
      });
    },
    initWxPay(data) {
      const that = this;
      wxJsConfig.init([
        'chooseWXPay'
      ]).then(() => {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success(res) {
            that.closeLoading();
            if (res.errMsg == 'chooseWXPay:ok') {
              Notify({type: 'success', message: '支付成功' });
              that.resetUserInfo();
              that.page = 1;
              that.noMore = false;
              that.getIntegralRecord();
            } else {
              Notify({type: 'danger', message: '支付失败' });
            }
          },
          cancel() {
            that.closeLoading();
            Notify({type: 'warning', message: '取消支付' });
          },
          fail() {
            that.closeLoading();
            Notify({type: 'danger', message: '支付失败' });
          }
        });
      });
    },
    closeLoading() {
      this.loading = false;
      this.loadingContent = '';
    },
    closeDialog() {
      this.showDialog = false;
    },
    loadMore() {
      if (this.integralInfo.scoreList && this.integralInfo.scoreList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        this.getIntegralRecord();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .integral-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .integral-content {
      flex: 1;
      overflow-y: auto;
      .integral-recharge-content {
        width: 100%;
        height: 202px;
        background: #C7E8E4;
        box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.05);
        border-radius: 0 0 40px 40px;
        padding: 23px 15px;
        .recharge-intro {
          .intro-title {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            margin-bottom: 13px;
          }
          .intro-value {
            display: flex;
            justify-content: space-between;
            font-size: 17px;
            color: #52B26B;
            .value-left {
              width: 56px;
              text-align: center;
            }
            .value-right {
              font-size: 10px;
            }
          }
        }
        .recharge-score-list {
          display: flex;
          justify-content: space-between;
          margin: 20px 0 15px;
          .score-list-item {
            width: 74px;
            height: 50px;
            border-radius: 5px;
            background: #fff;
            color: #52B26B;
            box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.11);
            text-align: center;
            font-size: 17px;
            line-height: 50px;
            &.active {
              background: #52B26B;
              color: #fff;
            }
            .unit {
              font-size: 10px;
            }
          }
        }
        .recharge-money {
          font-size: 14px;
          color: #333;
          font-weight: bold;
          .money {
            color: #52B26B;
          }
        }
      }
      .integral-record {
        height: calc(100% - 202px);
        padding: 15px;
        .record-title {
          font-size: 14px;
          color: #333;
          line-height: 36px;
        }
        .record-list {
          height: calc(100% - 36px);
          overflow-y: auto;
          padding: 0 11px;
          background: #fff;
          box-shadow: 0px 3px 4.5px 0px rgba(0, 162, 146, 0.13);
          border-radius: 5px;
          .list-item {
            height: 60px;
            border-bottom: 1px solid #e2e2e2;
            padding: 10px 0 15px;
            display: flex;
            flex-direction: column;
            &:last-child {
              border-bottom: none;
            }
            .item-time {
              color: #666;
              font-size: 10px;
            }
            .item-info {
              font-size: 13px;
              color: #333;
              margin-top: auto;
              .info-value {
                float: right;
              }
            }
          }
        }
      }
      .recharge-btn-area {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 50;
        width: 100%;
        height: 50px;
        padding: 5px 15px;
        background: #FFFFFF;
        box-shadow: 0px -3px 4.5px 0px rgba(0, 162, 146, 0.13);
        .recharge-btn {
          border-radius: 5px;
          background: #52B26B;
          height: 40px;
          line-height: 40px;
          font-size: 13px;
          color: #FEFEFE;
          text-align: center;
        }
      }
    }
    .van-icon-close {
      position: absolute;
      color: #fff;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      font-size: 28px;
    }
  }
</style>
