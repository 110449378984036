<template>
  <div class="setRemind">
    <div
      class="holeRemind"
      :style="'min-height: 100vh;background-image:url(' + backImage + ')'"
    >
      <div class="contentArea">
        <div class="headBtn">
          <div>
            <img
              @click="toHome"
              class="home"
              src="@/assets/images/remind/home.png"
              alt=""
            />
          </div>
          <div class="homeOut">
            <transition name="van-fade">
              <div class="popover" v-show="popoverShow">
                <div class="lists" @click.stop="poverListClick(1)">自定义背景</div>
                <div
                  class="lists"
                  @click.stop="poverListClick(2)"
                  v-if="!planDetail.isInvite"
                >
                  编辑提醒
                </div>
                <div
                  class="lists"
                  @click.stop="poverListClick(3)"
                  v-if="!planDetail.isInvite"
                >
                  删除提醒
                </div>
                <div class="lists" @click.stop="poverListClick(3)" v-else>退出提醒</div>
                <div class="trangle"></div>
              </div>
            </transition>
            <img
              class="more"
              src="@/assets/images/remind/more.png"
              alt=""
              @click.stop="popoverShow = !popoverShow"
            />
          </div>
        </div>
        <div class="headImg">
          <img :src="planDetail.avatar || defaultHead" alt="" />
          <div class="vipWords" v-if="userInfo.isVip">
            <span> VIP </span>
          </div>
        </div>
        <div class="headTiitle">
          {{ planDetail.content }}
        </div>
        <div
          class="headDate"
          v-for="(v, i) in planDetail.times"
          :key="i"
          :style="planDetail.reminderTimeId == v.id ? 'font-weight:600;' : null"
        >
          {{ planDetail ? cycleList[planDetail.period].split("提醒")[0] : null }}
          {{ v.value }}
        </div>
        <div class="remainDate">
          <div class="trangle"></div>
          <div>
            <span v-if="planDetail.status == 2"> 已过去 </span>
            <span v-else> 还有 </span>
            <!-- <span class="middle" v-if="planDetail.restPercent && planDetail.period == 5">{{ planDetail.restPercent
                      }}</span>
                      <span v-if="planDetail.restPercent && planDetail.period == 5">
                          %
                      </span> -->
            <span class="middle" v-if="planDetail.restDay >= 1">{{
              planDetail.restDay
            }}</span>
            <span v-if="planDetail.restDay >= 1"> 天 </span>
            <span
              class="middle"
              v-if="planDetail.restHour >= 1 && planDetail.restDay < 1"
              >{{ planDetail.restHour }}</span
            >
            <span v-if="planDetail.restHour && planDetail.restDay < 1"> 时 </span>
            <span
              class="middle"
              v-if="planDetail.restDay < 1 && planDetail.restHour < 1"
              >{{ planDetail.restMinute }}</span
            >
            <span v-if="planDetail.restDay < 1 && planDetail.restHour < 1"> 分 </span>
          </div>
        </div>
        <pre class="remarks">{{ planDetail.desc || null }}</pre>
        <div class="headOpenVip" v-if="vipShow && userInfo.isVip == 0">
          <div @click="toOpen">
            <img src="../../assets/images/remind/vip.png" class="vipimg" alt="" />
          </div>
          <div class="close" @click="closeVip">
            <span>关闭</span>
          </div>
        </div>
        <div class="headTab">
          <span :class="nowTab == 1 ? 'active' : ''" @click="clickTab(1)">提醒详情</span>
          <span :class="nowTab == 2 ? 'active' : ''" @click="clickTab(2)">打卡记录</span>
        </div>
        <div class="bottomContent" v-if="nowTab == 1">
          <div class="remindDate">提醒日期</div>
          <div class="forceDate">
            <span class="words">将 {{ settingDetailList.join("、") }} 提醒</span>
            <span class="btn" @click="toDetail"><span>自定义</span></span>
          </div>
          <div class="counts">成员（{{ planDetail.inviteUserNum }}人）</div>
          <div class="peopleArea">
            <div>
              <img :src="peopleImg" @click="invateClick" class="imgs" alt="" />
            </div>
            <div v-for="(v, i) in getInviteList" :key="i">
              <img :src="v.avatar" @click="delThis(v, i)" class="imgs" alt="" />
            </div>
          </div>
          <div
            class="seeMore"
            v-if="lastInviteList.length == 27 || lastInviteList.length == 35"
            @click="getJoinUserListMethod()"
          >
            查看更多
          </div>
        </div>
        <div class="recordArea" @scroll="scrollList" ref="recordArea" v-else>
          <div ref="scrollHeight">
            <div class="poeopleRecords" v-for="(v, i) in recordList" :key="i">
              <div class="head">
                <div class="left">
                  <div class="img">
                    <img :src="v.avatar" alt="" />
                  </div>
                  <div class="titleArea">
                    <div class="title1">
                      {{ v.username || "默认用户" }}
                    </div>
                    <div class="title2" v-if="v.content">
                      {{ v.content }}
                    </div>
                    <div class="title2" style="color: #999999" v-else>
                      {{ v.createdAt }}
                      已坚持打卡{{ v.signTotal }}次
                    </div>
                  </div>
                </div>
                <!--<div class="right">
                                  <img src="../../assets/images/remind/arrow_r2.png" alt="">
                              </div>-->
              </div>
              <div class="bottom" v-if="v.content">
                {{ v.createdAt }}
                已坚持打卡{{ v.signTotal }}次
              </div>
            </div>
          </div>
          <div style="text-align: center; padding: 10px 0" v-if="loadingCords">
            <van-loading />
          </div>
          <div
            style="text-align: center; padding: 20px 0; font-size: 12px; color: #999"
            v-if="recordList.length == 0"
          >
            暂无更多数据
          </div>
        </div>
      </div>
    </div>
    <div class="bottomArea" style="max-width: 640px">
      <div class="bottomContent">
        <img src="../../assets/images/remind/icon_invite.png" alt="" />
        <span class="invateWord" @click="invateClick">邀请加入</span>
        <van-button type="primary" class="btn" @click.stop="clocoInBol = true">
          打卡
        </van-button>
      </div>
    </div>
    <transition name="van-slide-up">
      <div class="changeBack" style="max-width: 640px" v-show="changeBackBol" @click.stop>
        <div class="backs">
          <div
            class="imgOut"
            v-for="(v, i) in imgListSmall"
            :key="i"
            @click.stop="chooseBack(i)"
          >
            <img :src="v" alt="" class="img" />
            <img
              src="../../assets/images/remind/icon_selected.png"
              v-if="backImage == imgList[i]"
              class="icon_selected"
              alt=""
            />
          </div>
          <div class="imgOut">
            <img src="../../assets/images/remind/img_bg02.png" alt="" class="img" />
            <div class="imgInArea">
              <div>
                <div style="font-size: 0">
                  <img
                    src="../../assets/images/remind/upload.png"
                    class="uploadImg"
                    alt=""
                  />
                </div>
                <div class="loadWords">上传</div>
              </div>
              <div
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 131%;
                  height: 100%;
                  opacity: 0;
                "
              >
                <van-uploader :after-read="afterRead" />
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 12px">
          <van-button type="primary" class="btn" @click.stop="saveBackImg">
            保存
          </van-button>
        </div>
      </div>
    </transition>
    <div
      class="topForCare"
      style="max-width: 640px"
      v-if="topForCareBol && userInfo.subscribe == 0"
    >
      <div>
        <span> 您还没有关注小提醒，无法接收提醒！ </span>
        <span class="btn" @click="showOverlay2 = true"> 去关注 </span>
        <img
          src="../../assets/images/remind/btn_close_white12.png"
          @click="topForCareBol = false"
          alt=""
        />
      </div>
    </div>
    <van-overlay :show="clocoInBol" @click="show = false">
      <transition name="van-slide-up">
        <div
          class="clockInArea"
          @click.stop
          style="max-width: 640px"
          v-if="clocoInBol"
          @click.stop
        >
          <div class="record">打卡记录</div>
          <div class="textArea">
            <textarea
              placeholder="请输入打卡内容（选填）"
              maxlength="100"
              v-model="textContent"
            ></textarea>
            <div class="limit-text">{{ textContent.length }}/100</div>
          </div>
          <div class="title2">温馨提示：请大家务必不要提交违规信息哦。</div>
          <div class="btns">
            <van-button class="clickBtn calcle" @click.stop="clocoInBol = false"
              >取消</van-button
            >
            <van-button type="primary" class="clickBtn" @click.stop="submitSign"
              >提交</van-button
            >
          </div>
        </div>
      </transition>
    </van-overlay>
    <van-overlay :show="showOverlay1">
      <div class="overPlayWrapper" style="max-width: 640px" @click.stop>
        <div class="wrapperTop" id="long2">
          <div class="topArea">
            <div class="title1">{{ planDetail.content }}</div>
            <div class="date">
              {{ planDetail && planDetail.date ? (planDetail.date.split("/")[0] + '年' + planDetail.date.split("/")[1] +
      "月" + planDetail.date.split("/")[2].split(" ")[0] + "日" + planDetail.date.split("/")[2].split("
              ")[1]):null}}
            </div>
            <div class="leftDate">
              <div>
                <span v-if="planDetail.status == 2"> 已过去 </span>
                <span v-else> 还有 </span>
                <span class="dateSpan" v-if="planDetail.restDay >= 1">{{
                  planDetail.restDay
                }}</span>
                <span v-if="planDetail.restDay >= 1"> 天 </span>
                <span
                  class="dateSpan"
                  v-if="planDetail.restHour >= 1 && planDetail.restDay < 1"
                  >{{ planDetail.restHour }}</span
                >
                <span v-if="planDetail.restHour && planDetail.restDay < 1"> 时 </span>
                <span
                  class="dateSpan"
                  v-if="planDetail.restDay < 1 && planDetail.restHour < 1"
                  >{{ planDetail.restMinute }}</span
                >
                <span v-if="planDetail.restDay < 1 && planDetail.restHour < 1"> 分 </span>
              </div>
              <div class="trangle"></div>
            </div>
          </div>
          <div class="maContent">
            <div class="leftImg">
              <img :src="defaultLeftImg" alt="" />
            </div>
            <div>
              <div class="title1">小提醒助手</div>
              <div class="title2">长按识别二维码，加入该提醒吧！</div>
            </div>
          </div>
          <img
            :src="shareImg2"
            class="shareImg"
            alt=""
            v-if="shareImg2"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          />
        </div>
        <div class="wrapperBottom">长按图片「转发给好友」，邀请加入该提醒</div>
        <div class="wrapperClose">
          <img
            src="../../assets/images/remind/btn_close_white.png"
            @click="showOverlay1 = false"
            alt=""
            class="closeimg"
          />
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showOverlay2">
      <div class="showOverlay2" style="max-width: 640px">
        <div class="showOverlay2Area">
          <div class="showOverlay2AreaTop">
            <img src="../../assets/images/remind/bg.png" alt="" />
            <div>接下来请关注小提醒服务号</div>
          </div>
          <div class="showOverlay2AreaMiddle">
            <div class="erma">
              <img
                src="https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20230101/e655d40d685460e23630deaf28e56e5a.jpg"
                alt=""
              />
            </div>
            <div class="desc">请长按扫码关注，否则将无法接收提醒!</div>
          </div>
        </div>
        <div class="wrapperClose">
          <img
            src="../../assets/images/remind/btn_close_white.png"
            @click="showOverlay2 = false"
            alt=""
            class="closeimg"
          />
        </div>
      </div>
    </van-overlay>
    <van-overlay
      :show="showOverlay3"
      style="max-width: 640px"
      @click="
        showOverlay3 = false;
        shareImg = '';
      "
    >
      <div class="showOverlay3" @click.stop v-if="showOverlay3">
        <div class="showOverlay3Top">
          <div style="width: 100%; position: relative; font-size: 0" id="long">
            <img :src="shareImg" v-if="shareImg" alt="" class="img1" />
            <img :src="defaultImg1" alt="" v-if="!shareImg" class="img1" />
            <div v-if="!shareImg" style="position: absolute; top: 30px; left: 20px">
              <img
                :src="signGetData.avatar || defaultHead"
                style="
                  width: 46px;
                  border-radius: 50%;
                  border: 2px solid #ffffff;
                  height: 46px;
                  vertical-align: middle;
                "
                alt=""
              />
              <span
                style="
                  color: #ffffff;
                  font-size: 12px;
                  margin-left: 12px;
                  margin-right: 20px;
                "
                >{{ signGetData.address }}</span
              >
              <span style="color: #ffffff; font-size: 12px">{{ signGetData.wea }}</span>
              <div
                style="
                  margin-top: 22px;
                  background: rgba(0, 0, 0, 0.2);
                  border-radius: 8px;
                  display: flex;
                  padding: 10px 46px 10px 16px;
                "
              >
                <div style="width: 122px">
                  <span style="font-size: 32px; color: #ffffff">
                    {{ signGetData.signTime }}
                  </span>
                  <div style="font-size: 14px; color: #ffffff; margin-top: 4px">
                    {{ signGetData.signDate }}
                  </div>
                </div>
                <div>
                  <span style="font-size: 32px; color: #ffffff">
                    {{ signGetData.serialSignDays }}
                  </span>
                  <span style="font-size: 14px; color: #ffffff"> 天 </span>
                  <div style="font-size: 14px; color: #ffffff; margin-top: 4px">
                    连续打卡
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!shareImg"
              style="
                position: absolute;
                bottom: 15px;
                left: 0;
                padding: 0 20px;
                box-sizing: border-box;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div style="width: 224px; font-size: 16px; color: #ffffff">
                {{ signGetData.signContent }}
              </div>
              <img :src="signGetData.qrCode" style="width: 60px; height: 60px" alt="" />
            </div>
          </div>
          <div class="top-bottom">
            <div class="change-btn">
              <div class="change">
                <img src="../../assets/images/remind/change.png" alt="" />
                <div @click="changeImg">更换图片</div>
              </div>
              <div>
                <van-button
                  class="save-btn"
                  @click="showOverlay3BottomBol = !showOverlay3BottomBol"
                  >分享这一刻</van-button
                >
              </div>
            </div>
            <div class="clockCount">
              <img
                :src="v"
                alt=""
                :style="
                  'transform:translateX(calc(' +
                  (signGetData.todaySignUsers.length - i - 1) * 33 +
                  '% + 7px))'
                "
                v-for="(v, i) in signGetData.todaySignUsers"
                :key="i"
                v-if="i < 4"
              />
              <img
                :src="clockBeforeList[0]"
                alt=""
                style=""
                v-if="signGetData.todaySignUsers.length.length > 4"
              />
              <span style="margin-left: 8px"> 你是今天打卡第 </span>
              <span class="fontColor">
                {{ signGetData.todayRank }}
              </span>
              <span> 名 </span>
            </div>
          </div>
        </div>
        <div
          class="showOverlay3Bottom"
          v-if="!showOverlay3BottomBol"
          style="background: transparent"
        ></div>
        <div class="showOverlay3Bottom" v-if="showOverlay3BottomBol">
          <div class="items">
            <div class="item">
              <img
                src="../../assets/images/remind/icon_save.png"
                @click="makeSaveImg"
                class="icons"
                alt=""
              />
              <div>保存到本地</div>
            </div>
            <div class="item" @click="shareShow = true">
              <img
                src="../../assets/images/remind/icon_wechat.png"
                class="icons"
                alt=""
              />
              <div>微信好友</div>
            </div>
            <div class="item share-btn" @click="shareShow = true">
              <img
                src="../../assets/images/remind/icon_pengyouquan.png"
                class="icons"
                alt=""
              />
              <div>朋友圈</div>
            </div>
            <div class="item" :data-clipboard-text="linkUrl" @click="copyLink">
              <img
                src="../../assets/images/remind/icon_copylink.png"
                class="icons"
                alt=""
              />
              <div>复制链接</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="shareShow" @click="shareShow = false">
      <div class="shareToArea">
        请点击右上角 ... 转发给朋友或分享到朋友圈
        <div class="trangle"></div>
      </div>
    </van-overlay>
    <vant-popup
      :style="{ height: '80%' }"
      v-model="openModel"
      round
      position="bottom"
      @closed="resetUserInfo"
    >
      <div
        style="
          height: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <span style="font-size: 14px; color: #666666">{{ openWord }}</span>
        <vant-icon
          name="cross"
          @click="openModel = false"
          style="
            position: absolute;
            top: 50%;
            right: 4%;
            transform: translateY(-50%);
            color: #666666;
          "
        />
      </div>
      <open v-if="openType == 1" @success="buySuccess"></open>
      <deposit v-if="openType == 2" @success="buySuccess"></deposit>
    </vant-popup>
    <vant-popup
      :style="{ height: '25%' }"
      v-model="deleteInvite.show"
      round
      position="bottom"
      @closed="deleteInvite.show = false"
    >
      <div style="text-align: center">
        <img :src="deleteInvite.avatar" style="border-radius: 4px; width: 45px;margin:20px 0;" />
      </div>
      <van-button block @click="deleteThis2" style="width:90%;margin:0 auto;">移除该成员</van-button>
    </vant-popup>
    <custom-loading :loading="loading" />
    <openmini-popup></openmini-popup>
  </div>
</template>

<script>
function copy(text) {
  // text是复制文本
  // 创建input元素
}
import Clipboard from "clipboard";
import { getPlanDetail, getJoinUserList, delJoinUserList } from "@/api/remind/index";
import { Checkbox, Uploader, Overlay, Loading, Notify, Dialog, Popup } from "vant";
import loading from "@/components/loading.vue";
import openminiPopup from '@/components/openminiPopup';
import {
  getSignList,
  sign,
  delPlan,
  invitePop,
  signPop,
  updateBackImage,
  getImageByBase64,
  saveBackImage,
  inviteReminderPlan,
} from "@/api/remind/index";
import config from "../../../config/config.default";
import html2canvas from "html2canvas";
import wxJsConfig from "@/utils/wxJsConfig.js";
import { getWechatUserInfo } from "@/api/common";
import open from "../member/open";
import deposit from "../member/deposit";
import $ from "jquery";
import { getCode, getUrl, getloginSession } from "@/utils/commonFunction";
export default {
  components: {
    "van-checkbox": Checkbox,
    "custom-loading": loading,
    "van-uploader": Uploader,
    "van-overlay": Overlay,
    "van-loading": Loading,
    deposit: deposit,
    open: open,
    "vant-popup": Popup,
    'openmini-popup': openminiPopup
  },
  name: "SetRemind",
  data() {
    return {
      deleteInvite: {
        show: false,
        id: "",
        avatar:"",
      },
      getInviteList: [],
      lastInviteList: [],
      defaultLeftImg:
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20230101/e655d40d685460e23630deaf28e56e5a.jpg",
      shareShow: false,
      showOverlay3BottomBol: false,
      id: "",
      date: "",
      signGetData: {},
      cardWeCode: require("../../assets/images/remind/ma.png"),
      loadingCords: false,
      defaultCount: 59,
      finished: false,
      showOverlay3: false, //遮罩3
      showOverlay1: false, //遮罩2
      showOverlay2: false, //遮罩1
      clockBeforeList: [
        require("../../assets/images/remind/icon_more.png"),
        require("../../assets/images/remind/icon_more.png"),
        require("../../assets/images/remind/icon_more.png"),
        require("../../assets/images/remind/icon_more.png"),
        require("../../assets/images/remind/icon_more.png"),
        require("../../assets/images/remind/icon_more.png"),
      ],
      loading: false,
      backImage: null,
      nowTab: 1,
      topForCareBol: true,
      vipShow: true,
      popoverShow: false,
      textContent: "",
      linkUrl: "",
      clocoInBol: false,
      rid: 89,
      selectedBack: null, //选中的背景
      changeBackBol: false, //背景选择的弹出
      defaultHead: require("../../assets/images/mine/head_default.png"),
      imgList: [
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20221230/f581dea527218b8927e8331d9b6f507a.png",
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20221230/deecdf6e1ae8a379a63a88de995682c2.png",
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20221230/0b0e4ddf058be134ea2303b54b757219.png",
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20221230/0390cd14ec814fae51547d642edb86fa.png",
        "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20221230/e87dd11180ff17f43bac183adf6f9439.png",
      ],
      imgListSmall: [
        require("../../assets/images/remind/img_bg01.png"),
        require("../../assets/images/remind/img_bg02.png"),
        require("../../assets/images/remind/img_bg03.png"),
        require("../../assets/images/remind/img_bg04.png"),
        require("../../assets/images/remind/img_bg05.png"),
      ],
      peopleImg: require("../../assets/images/remind/add.png"),
      userInfo: {},
      recordList: [],
      shareImg: "",
      planDetail: "",
      defaultImg1: require("../../assets/images/remind/base.png"),
      openModel: false,
      openType: 1,
      openWord: "开通会员",
      shareImg2: "",
      settingDetailList: [],
      cycleList: ["单次提醒", "每天提醒", "每周提醒", "每月提醒", "每年提醒", "循环提醒"],
      createListId:''
    };
  },
  mounted() {
    this.id = this.$route.query.id || 0;
    this.date = this.$route.query.date || "";
    // this.getSignLists()
    // this.getPlanDetail()
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token_deadtime") &&
      localStorage.getItem("token_deadtime") > new Date().getTime()
    ) {
      this.id = this.$route.query.id || 0;
      this.date = this.$route.query.date || "";
      this.userInfo = this.$store.state.userInfo;
      this.getSignLists();
      this.getPlanDetail();
      this.linkUrl =
        location.origin + "/remind/setRemind?id=" + this.id + "&date=" + this.date;
    } else {
      let code = getUrl("code");
      this.id = this.$route.query.id || 0;
      this.loading = true;
      getCode(location.origin + "/remind/setRemind?id=" + this.id + "&date=" + this.date);
    }
  },
  created() {
    let that = this;
    document.addEventListener("click", () => {
      that.popoverShow = false;
      that.changeBackBol = false;
      this.clocoInBol = false;
    });
  },
  methods: {
    shareInit() {
      let desc = "时间：" + this.cycleList[this.planDetail.period].split("提醒")[0];
      this.planDetail.times.forEach((item) => {
        if (item.id == this.planDetail.reminderTimeId) {
          desc += item.value;
        }
      });
      desc += "，请点此加入该提醒";
      let that = this;
      wxJsConfig
        .init(["updateAppMessageShareData", "updateTimelineShareData"])
        .then(() => {
          wx.updateAppMessageShareData({
            title: that.planDetail.content, // 分享标题
            desc: desc, // 分享描述
            link: that.linkUrl, // 分享链接
            imgUrl:
              "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20230114/6650774619f70a458ae45f79efdda59d.png", // 分享图标
          });
          wx.updateTimelineShareData({
            title: that.planDetail.content, // 分享标题
            desc: desc, // 分享描述
            link: that.linkUrl, // 分享链接
            imgUrl:
              "https://wangluo-remind.oss-cn-beijing.aliyuncs.com/upload/images/20230114/6650774619f70a458ae45f79efdda59d.png", // 分享图标
          });
        });
    },
    copyLink() {
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", this.linkUrl);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
      Notify({ type: "success", message: "复制成功" });
    },
    changeImg() {
      this.loading = true;
      this.shareImg = "";
      updateBackImage({ id: this.id }).then((res) => {
        this.defaultImg1 = res.image;
        setTimeout(() => {
          this.makeSaveImg();
        }, 100);
      });
    },
    toOpen() {
      this.$router.push({
        path: "/member",
      });
    },
    closeVip() {
      Dialog.confirm({
        title: "提示",
        message: "该功能仅会员可用，请开通会员后使用",
        confirmButtonText: "去开通",
      })
        .then(() => {
          this.openModel = true;
          this.openType = 1;
          this.openWord = "开通会员";
        })
        .catch(() => {});
    },
    buySuccess() {
      this.openModel = false;
    },
    resetUserInfo() {
      this.loading = true;
      getWechatUserInfo().then((response) => {
        response.isPrise = response.isPrise === 1 ? true : false;
        localStorage.setItem("userInfo", JSON.stringify(response));
        this.$store.commit("changeUserInfo", response);
        this.userInfo = response;
        this.loading = false;
      });
    },
    submitSign() {
      this.loading = true;
      sign({
        id: this.id,
        signContent: this.textContent,
        time: this.planDetail.time,
      })
        .then((res) => {
          if (res.id) {
            Notify({ type: "success", message: "打卡成功" });
            this.nowTab = 2;
            this.recordList = [];
            this.getSignLists();
            signPop({
              id: res.id,
              linkUrl: this.linkUrl,
              // linkUrl:"https://m.xtx.vta.cn"
            }).then((res) => {
              this.signGetData = res;
              this.clocoInBol = false;
              this.loading = false;
              this.showOverlay3 = true;
              setTimeout(() => {
                this.makeSaveImg();
              }, 100);
            });
          } else {
            this.loading = false;
            Notify({ type: "warning", message: res.msg });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.clocoInBol = false;
        });
    },
    toDetail() {
      this.$router.push({
        name: "PlanAdd",
        query: {
          id: this.id,
        },
      });
    },
    deleteThis2(){
      if(this.planDetail.inviteUserId!=this.planDetail.userId){
        Notify({ type: "warning", message: "只有提醒创建人可以移除成员" });
        return
      }
      this.loading = true;
        delJoinUserList({
          id: this.deleteInvite.id,
          planId: this.id,
        })
          .then((res) => {
            this.loading = false;
            Notify({ type: "success", message: "移除成功" });
            this.deleteInvite.show = false
            let index = this.getInviteList.findIndex((val, index) => {
              return val.id == this.deleteInvite.id;
            });
            this.getInviteList.splice(index, 1);
            
          })
          .catch(() => {
            this.loading = false;
          });
    },
    delThis(v, i) {
      this.deleteInvite.show = true;
      this.deleteInvite.avatar = v.avatar;
      this.deleteInvite.id = v.id;
    },
    getPlanDetail() {
      this.loading = true;
      getPlanDetail({ id: this.id, date: this.date })
        .then((res) => {
          if (res.isOther) {
            this.loading = false;
            Dialog.confirm({
              title: "提示",
              confirmButtonText: "确定加入",
              message: "您还未加入该提醒，确定加入该提醒？",
            })
              .then(() => {
                this.loading = true;
                inviteReminderPlan({ id: this.id })
                  .then((res) => {
                    this.loading = false;
                    if (res.id) {
                      Notify({ type: "success", message: "加入成功" });
                      // this.id = res.id
                      debugger;
                      // this.getPlanDetail()
                      location.replace(
                        location.origin +
                          "/remind/setRemind?id=" +
                          res.id +
                          "&date=" +
                          this.date
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err,'err1')
                    this.toHome();
                  });
              })
              .catch((err) => {
                console.log(err,'err2')
                this.toHome();
              });
            return;
          }
          if (res.invitePlanId) {
            location.replace(
              location.origin +
                "/remind/setRemind?id=" +
                res.invitePlanId +
                "&date=" +
                this.date
            );
            return;
          }
          this.planDetail = res;
          this.backImage = res.backUrl;
          let settings = JSON.parse(res.setting);
          this.getJoinUserListMethod();
          settings.forEach((item) => {
            if (item.name) {
              this.settingDetailList.push(item.name.split("提醒")[0]);
            }
            if (item.id == 8) {
              this.settingDetailList.push("到期" + item.inp + item.type);
            }
          });
          getWechatUserInfo()
            .then((response) => {
              this.loading = false;
              response.isPrise = response.isPrise === 1 ? true : false;
              localStorage.setItem("userInfo", JSON.stringify(response));
              this.$store.commit("changeUserInfo", response);
              this.userInfo = this.$store.state.userInfo;
              if (this.userInfo.subscribe == 0) {
                Dialog.confirm({
                  title: "提示",
                  confirmButtonText: "去关注",
                  message: "您还未关注公众号，无法收到消息，是否关注公众号？",
                })
                  .then(() => {
                    this.showOverlay2 = true;
                  })
                  .catch(() => {});
              }
            })
            .catch((err) => {
              this.loading = false;
            });
          this.shareInit();
          this.loading = false;
        })
        .catch((err) => {
          console.log(11);
          console.log(err,'err3')
          this.loading = false;
          this.toHome();
        });
    },
    getJoinUserListMethod() {
      this.loading = true;
      let inviteId = 0;
      console.log(this.lastInviteList[this.lastInviteList.length - 1]);
      if (this.lastInviteList.length) {
        inviteId = this.lastInviteList[this.lastInviteList.length - 1].id;
      }
      getJoinUserList({
        inviteId: inviteId || 0,
        limit: !inviteId ? 35 : 27,
        planId: this.id,
      })
        .then((res) => {
          this.getInviteList = [...this.getInviteList, ...res];
          this.lastInviteList = res;
          console.log(this.lastInviteList);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    clickTab(i) {
      this.nowTab = i;
    },
    toHome() {
      this.$router.push({
        path: "/",
      });
    },

    poverListClick(i) {
      if (i == 1) {
        // 自定义背景
        this.changeBackBol = !this.changeBackBol;
      } else if (i == 2) {
        // 编辑提醒
        this.toDetail();
      } else if (i == 3) {
        // 删除提醒
        Dialog.confirm({
          title: "提示",
          message: "确定要删除该提醒吗？",
        }).then(() => {
          this.loading = true;
          delPlan({ planId: this.id }).then(() => {
            this.loading = false;
            Notify({ type: "success", message: "删除成功" });
            this.toHome();
          });
        });
      }
    },
    chooseBack(v) {
      // 自定义背景
      if (this.backImage == this.imgList[v]) {
        return;
      }
      this.backImage = this.imgList[v];
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      console.log(file);
      let img = new Image();
      img.src = file.content;
      img.onload = function () {
        this.loading = true;
        $.ajax({
          type: "post",
          url: config[process.env.NODE_ENV].baseUrl + "/api/v1/file/upload",
          data: formData,
          contentType: false,
          processData: false,
          mineType: "multipart/form-data",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          mimeType: "multipart/form-data",
          success: (res) => {
            this.loading = false;
            this.selectedBack = null;
            this.backImage = JSON.parse(res).path;
          },
        });
      };
    },
    saveBackImg() {
      if (!this.backImage) {
        Notify({ type: "warning", message: "请选择背景图片" });
        return;
      }
      if (this.userInfo.isVip == 0) {
        Dialog.confirm({
          title: "提示",
          message: "该功能仅会员可用，请开通会员后使用",
          confirmButtonText: "去开通",
        })
          .then(() => {
            this.openModel = true;
            this.openType = 1;
            this.openWord = "开通会员";
          })
          .catch(() => {
            return;
          });
      } else {
        this.loading = true;
        saveBackImage({
          id: this.id,
          image: this.backImage,
        })
          .then((res) => {
            this.loading = false;
            Notify({ type: "success", message: "保存成功" });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    getSignLists(id) {
      getSignList({
        limit: 10,
        id: id || 0,
        rid: this.id,
      }).then((res) => {
        this.loadingCords = false;
        if (res.length < 10) {
          this.finished = true;
        }
        if (res.length) {
          res.forEach((item) => {
            this.recordList.push(item);
          });
        }
      });
    },
    scrollList() {
      if (this.loadingCords || this.finished) {
        return;
      }
      if (
        this.$refs.recordArea.scrollTop - 0 + this.$refs.recordArea.offsetHeight + 5 >=
        this.$refs.scrollHeight.offsetHeight
      ) {
        this.loadingCords = true;
        this.getSignLists(this.recordList[this.recordList.length - 1].id);
      }
    },
    invateClick() {
      this.loading = true;
      invitePop({
        id: this.id,
        linkUrl: this.linkUrl,
      }).then((res) => {
        this.showOverlay1 = true;
        this.defaultLeftImg = res.qrCode;
        setTimeout(() => {
          this.saveImg2();
        }, 200);
      });
    },
    makeSaveImg() {
      let htmlDom = document.getElementById("long"); // 你要截图的元素  可直接是img
      this.$nextTick(function () {
        html2canvas(htmlDom, {
          scale: 3,
          allowTaint: false, //允许污染
          height: htmlDom.scrollHeight - 1, //canvas高, 高度减 1 是为了解决底部出现白线问题
          width: htmlDom.scrollWidth - 1, //canvas宽
          taintTest: true, //在渲染前测试图片
          useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用)
        }).then((canvas) => {
          this.loading = true;
          getImageByBase64({
            base64Str: canvas.toDataURL("image/png"),
          }).then((res) => {
            this.loading = false;
            this.shareImg = res.image;
            Notify({ type: "success", message: "请长按保存图片" });
          });
        });
      });
    },
    saveImg2() {
      let htmlDom = document.getElementById("long2"); // 你要截图的元素  可直接是img
      this.$nextTick(function () {
        html2canvas(htmlDom, {
          scale: 3,
          height: htmlDom.scrollHeight - 1, //canvas高, 高度减 1 是为了解决底部出现白线问题
          width: htmlDom.scrollWidth - 1, //canvas宽
          allowTaint: false, //允许污染
          taintTest: true, //在渲染前测试图片
          useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用)
        }).then((canvas) => {
          this.loading = true;
          getImageByBase64({
            base64Str: canvas.toDataURL("image/png"),
          }).then((res) => {
            this.loading = false;
            this.shareImg2 = res.image;
            Notify({ type: "success", message: "请长按保存图片" });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setRemind {
  width: 100%;
  position: relative;

  .holeRemind {
    width: 100%;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .contentArea {
      padding: 16px 16px 99px;
      box-sizing: border-box;

      .headBtn {
        position: relative;
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .homeOut {
          position: relative;

          .popover {
            position: absolute;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
            top: calc(100% + 8px);
            right: 0;

            .lists {
              padding: 12px 12px;
              border-bottom: 0.5px solid #e8e8e8;
              font-family: "PingFang SC";
              white-space: nowrap;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: left;
              color: #000000;
            }

            .lists:last-child {
              border-bottom: none;
            }

            .trangle {
              position: absolute;
              top: -15px;
              height: 0;
              width: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 8px solid #fff;
              border-top: 8px solid transparent;
              right: 10px;
              transform: translateX(50%);
            }
          }
        }

        .home {
          width: 18px;
        }

        .more {
          width: 20px;
        }
      }

      .headImg {
        width: 100%;
        position: relative;
        text-align: center;

        img {
          width: 55px;
          border-radius: 50%;
        }

        .vipWords {
          background: linear-gradient(180deg, #ffd75f 0.28%, #ffb33c 100%);
          border-radius: 15px;
          width: 28px;
          height: 16px;
          text-align: center;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            line-height: 16px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #924f0d;
            transform: scale(0.85);
            display: inline-block;
          }
        }
      }

      .headTiitle {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        margin-top: 18px;
        text-align: center;
        color: #000000;
      }

      .headDate {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        margin-top: 8px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        opacity: 0.6;
      }

      .remainDate {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        width: 315px;
        height: 96px;
        margin: 27px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .trangle {
          position: absolute;
          top: -20px;
          height: 0;
          width: 0;
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          border-bottom: 11px solid #fff;
          border-top: 11px solid transparent;
          left: 50%;
          transform: translateX(-50%);
        }

        span {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #000000;
        }

        .middle {
          font-family: "DIN Alternate";
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 48px;
          text-align: center;
          color: #52b26b;
        }
      }

      .remarks {
        width: 315px;
        text-align: center;
        margin: 16px auto 0;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        opacity: 0.6;
      }

      .headOpenVip {
        margin-top: 16px;
        background: linear-gradient(270deg, #f7d9bf 0%, #f6e7d6 100%);
        border-radius: 4px;
        height: 44px;
        text-align: center;
        width: 345px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .close {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba($color: #000000, $alpha: 0.4);
          width: 38px;
          height: 16px;
          position: absolute;
          right: 0;
          top: 0;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 4px;

          span {
            font-family: "PingFang SC";
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            text-align: right;
            color: #ffffff;
            display: inline-block;
            transform: scale(0.85);
          }
        }

        .vipimg {
          width: 100%;
          vertical-align: middle;
        }

        .vipWords {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #924f0d;
          margin-left: 8px;
          vertical-align: middle;
        }

        .arrowImg {
          vertical-align: middle;
          width: 5px;
        }
      }

      .headTab {
        margin-top: 24px;
        width: 100%;
        text-align: center;

        span {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #666666;
          padding-bottom: 2px;
        }

        span:last-child {
          margin-left: 64px;
        }

        .active {
          font-weight: 500;
          color: #000000;
          border-bottom: 3px solid #51b26b;
        }
      }

      .bottomContent {
        margin-top: 16px;
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 16px 15px;
      }

      .remindDate {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #52b26b;
      }

      .forceDate {
        margin-top: 8px;

        .words {
          margin-right: 8px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #888888;
        }

        .btn {
          background: #52b26b;
          border-radius: 10px;
          padding: 2px 10px;
          line-height: 16px;
          display: inline-flex;
          align-items: center;

          span {
            display: inline-block;
            color: #ffffff;
            font-size: 11px;
            transform: scale(0.9);
          }
        }
      }

      .counts {
        margin-top: 20px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #52b26b;
      }

      .peopleArea {
        margin-top: 8px;
        font-size: 0;

        div {
          display: inline-block;
          font-size: 0;
          width: calc((100vw - 31px) / 10);
          height: calc((100vw - 31px) / 10);
          padding: 2px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .imgs {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      .seeMore {
        margin-top: 20px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #52b26b;
        background-color: rgba(82, 178, 107, 0.1);
        border-radius: 4px;
        width: 100%;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }
    }
  }

  .bottomArea {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    overflow: hidden;

    .bottomContent {
      float: right;

      img {
        width: 15px;
        vertical-align: middle;
      }

      .invateWord {
        vertical-align: middle;
        margin-left: 8px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;
      }

      .btn {
        width: 200px;
        height: 44px;
		font-size: 16px;
		font-weight: bold;
        margin-left: 40px;
        vertical-align: middle;
        background: #52b26b;
        border-radius: 8px;
      }
    }
  }
}

.recordArea {
  padding: 0 15px 0;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-top: 16px;
  height: 400px;
  overflow-y: scroll;

  .poeopleRecords {
    padding: 16px 0;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .img {
          margin-right: 8px;

          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }

        .titleArea {
          .title1 {
            font-size: 12px;
            line-height: 16px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 600;
            color: #161a22;
          }

          .title2 {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #333333;
            margin-top: 6px;
          }
        }
      }

      .right {
        img {
          width: 5px;
        }
      }
    }

    .bottom {
      margin-top: 6px;
      padding-left: 44px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #999999;
    }
  }

  .poeopleRecords:last-child {
    border-bottom: none;
  }
}

.changeBack {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  padding: 12px 15px 27px;
  background: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px 20px 0px 0px;

  .backs {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;

    .imgOut {
      font-size: 0;
      position: relative;

      .img {
        width: 100%;
        border-radius: 50%;
      }

      .icon_selected {
        position: absolute;
        width: 16px;
        top: 0;
        right: 0;
      }
    }

    .imgInArea {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      background-color: rgba(82, 178, 107, 0.1);
      text-align: center;

      .uploadImg {
        width: 13px;
      }

      .loadWords {
        margin-top: 5px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #52b26b;
      }
    }
  }

  .btn {
    width: 100%;
    border-radius: 8px;
    height: 44px;
  }
}

.van-uploader {
  width: 100%;
  height: 100%;

  .van-uploader__wrapper {
    width: 100%;
    height: 100%;

    .van-uploader__input-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.topForCare {
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    font-size: 0;

    span {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      vertical-align: middle;
    }

    .btn {
      margin-left: 10px;
      margin-right: 27px;
      display: inline-block;
      width: 58px;
      height: 24px;
      background: #51b26b;
      border-radius: 4px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      vertical-align: middle;
      color: #ffffff;
    }

    img {
      width: 12px;
      vertical-align: middle;
    }
  }
}

.clockInArea {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 366px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding: 16px 15px;
  box-sizing: border-box;

  .record {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
  }

  .textArea {
    width: 100%;
    margin-top: 16px;
    background: #fafafa;
    border: 0.5px solid #e8e8e8;
    border-radius: 4px;
    width: 100%;

    textarea {
      resize: none;
      border: none;
      padding: 16px 15px 0;
      background: #fafafa;
      font-family: "PingFang SC";
      font-style: normal;
      width: 100%;
      height: 164px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000;
    }

    textarea::placeholder {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
      box-sizing: border-box;
    }

    .limit-text {
      color: #999999;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;
      text-align: right;
      height: 16px;
    }
  }

  .title2 {
    margin-top: 12px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #999999;
  }

  .btns {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    .calcle {
      background: #f6f6f6;
    }

    .clickBtn {
      width: 165px;
      height: 44px;
      border-radius: 8px;
    }
  }
}

.overPlayWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  .wrapperTop {
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    width: 295px;
    min-height: 363px;
    overflow: hidden;
    background: #eaeeed;
    position: relative;

    .shareImg {
      border-radius: 8px;
    }

    .topArea {
      width: 100%;
      background: #ffffff;
      border-radius: 6px;
      padding: 40px 20px 20px;

      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #000000;
      }

      .date {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-top: 8px;
        color: #999999;
      }

      .leftDate {
        background: rgba($color: #52b26b, $alpha: 0.1);
        border-radius: 4px;
        width: 100%;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        position: relative;

        span {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #000000;
        }

        .dateSpan {
          font-family: "DIN Alternate";
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 48px;
          text-align: center;
          color: #52b26b;
          margin: 0 4px;
        }

        .trangle {
          position: absolute;
          top: -20px;
          height: 0;
          width: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid rgba($color: #52b26b, $alpha: 0.1);
          border-top: 10px solid transparent;
          right: 50%;
          transform: translateX(50%);
        }
      }
    }

    .maContent {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .leftImg {
        margin-right: 10px;
        font-size: 0;

        img {
          width: 60px;
        }
      }

      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }

      .title2 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-top: 8px;
        color: #666666;
      }
    }
  }

  .wrapperBottom {
    margin-top: 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }

  .wrapperClose {
    text-align: center;
    margin-top: 32px;

    .closeimg {
      width: 30px;
    }
  }
}

.showOverlay2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .showOverlay2Area {
    background: #ffffff;
    border-radius: 8px;
    width: 275px;
    overflow: hidden;

    .showOverlay2AreaTop {
      width: 100%;
      font-size: 0;
      position: relative;
      width: 100%;

      img {
        width: 100%;
      }

      div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 100%;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
      }
    }

    .showOverlay2AreaMiddle {
      background-color: #fff;
      padding: 26px 0 38px;

      .erma {
        text-align: center;
        font-size: 0;

        img {
          width: 155px;
        }
      }

      .desc {
        text-align: center;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #666666;
        margin-top: 26px;
      }
    }
  }

  .wrapperClose {
    text-align: center;
    margin-top: 32px;

    .closeimg {
      width: 30px;
    }
  }
}

.showOverlay3 {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15px;
  border-radius: 8px;
  width: 100%;

  .showOverlay3Top {
    width: 100%;
    font-size: 0;
    border-radius: 8px;
    overflow: hidden;

    .img1 {
      width: 100%;
    }

    .top-bottom {
      background: #ffffff;
      padding: 12px 15px;

      .change-btn {
        display: flex;
        justify-content: space-between;

        .change {
          font-size: 0;
          text-align: center;

          img {
            width: 20px;
          }

          div {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-top: 8px;
            color: #51b26b;
          }
        }

        .save-btn {
          border-radius: 8px;
          width: 252px;
          height: 40px;
          background: #52b26b;
          color: #fff;
        }
      }

      .clockCount {
        text-align: right;
        margin-top: 12px;
        font-size: 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: relative;
          border: 1px solid #ffffff;
          vertical-align: middle;
          box-sizing: border-box;
        }

        span {
          vertical-align: middle;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
        }

        .fontColor {
          color: #52b26b;
        }
      }
    }
  }

  .showOverlay3Bottom {
    margin-top: 15px;
    background: #ffffff;
    height: 108px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .items {
      display: flex;

      .item {
        text-align: center;
        font-size: 0;

        .icons {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          border: 1.5px solid #ffffff;
          box-shadow: 0px 2px 8px rgba(81, 178, 107, 0.16);
        }

        div {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin-top: 12px;
          width: 86px;
          color: #666666;
        }
      }
    }
  }
}

.shareToArea {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: rgb(56, 103, 246);
  color: white;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 20px;

  .trangle {
    position: absolute;
    top: -15px;
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid rgb(56, 103, 246);
    border-top: 8px solid transparent;
    right: 15px;
    transform: translateX(50%);
  }
}
</style>
