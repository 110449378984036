<template>
  <div class="dialog-container">
    <div class="dialog-form">
      <div class="form-item">
        <div class="icon-box" style="height: 20px;">
          <img src="../../../assets/images/login/phone.png" style="width: 12px;height: 100%;">
        </div>
        <vant-field type="tel" v-model="formInfo.phone" placeholder="请输入手机号"></vant-field>
      </div>
      <div class="form-item">
        <div class="icon-box" style="height: 16px;">
          <img src="../../../assets/images/login/veritification.png" style="width: 17.5px;height: 100%;">
        </div>
        <vant-field type="text" v-model="formInfo.vertifyCode" placeholder="请输入验证码"></vant-field>
        <span class="send-btn" :style="sending ? 'background: #c4c4c4;' : ''" @click="sendCode">{{ sending ? '重新发送' + time : '发送验证码' }}</span>
      </div>
      <div class="form-item">
        <div class="icon-box" style="height: 16px;">
          <img src="../../../assets/images/login/password.png" style="height: 100%;">
        </div>
        <vant-field type="password" v-model="formInfo.password" placeholder="请设置密码：6 ～ 24 位"></vant-field>
      </div>
    </div>
    <div class="submit-btn" @click="bindPhone">绑定</div>
  </div>
</template>

<script>
import { Field, Notify } from 'vant';
import utils from '@/utils/utils';
import { sendSms, bindPhone } from '@/api/mine/setting';

export default {
  props: {
    phone: {
      type: String,
      require: true
    }
  },
  components: {
    'vant-field': Field
  },
  data() {
    return {
      formInfo: {
        phone: '',
        vertifyCode: '',
        password:''
      },
      sending: false,
      time: 60
    };
  },
  watch: {
    phone() {
      this.formInfo.phone = this.phone;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.formInfo.phone = this.phone;
  },
  methods: {
    sendCode() {
      if (!this.formInfo.phone) {
        Notify({ type: 'warning', message: '请输入手机号!' });
        return false;
      } else if (!utils.config.phoneReg.test(this.formInfo.phone)) {
        Notify({ type: 'warning', message: '手机号格式错误，请重新输入!' });
        return false;
      }
      this.sending = true;
      this.timer = setInterval(() => {
        this.time -= 1;
        if (this.time === 0) {
          this.sending = false;
          this.time = 60;
          clearInterval(this.timer);
        }
      }, 1000);
      sendSms({ phone: this.formInfo.phone }).then(() => {
        Notify({ type: 'success', message: '发送成功' });
      });
    },
    bindPhone() {
      if (!this.formInfo.phone) {
        Notify({ type: 'warning', message: '请输入手机号!' });
        return false;
      } else if (!utils.config.phoneReg.test(this.formInfo.phone)) {
        Notify({ type: 'warning', message: '手机号格式错误，请重新输入!' });
        return false;
      }
      if (!this.formInfo.vertifyCode) {
        Notify({ type: 'warning', message: '请输入验证码!' });
        return false;
      }
      if (!this.formInfo.password) {
        Notify({ type: 'warning', message: '请输入密码!' });
        return false;
      }
      if (this.formInfo.password.length<6||this.formInfo.password.length>24) {
        Notify({ type: 'warning', message: '请输入6-24位密码!' });
        return false;
      }
      bindPhone(JSON.parse(JSON.stringify(this.formInfo))).then(() => {
        Notify({ type: 'success', message: '绑定成功' });
        this.$emit('close', true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .dialog-container {
    margin: 0 15px;
    width: 345px;
    height: 275px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 0%;
    border-radius: 5px;
    padding: 18px 20px 0 24.5px;
    .dialog-form {
      .form-item {
        display: flex;
        align-items: center;
        height: 50px;
        position: relative;
        .icon-box {
          width: 17.5px;
          text-align: center;
          margin-right: 15px;
          img {
            vertical-align: top;
          }
        }
        .van-cell {
          padding: 0 100px 0 0;
          border-bottom: 1px solid #e2e2e2;
          height: 100%;
          input {
            font-size: 12px;
            color: #333;
          }
        }
        .send-btn {
          width: 75px;
          height: 25px;
          border-radius: 12.5px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          color: #fff;
          text-align: center;
          font-size: 12px;
          background: #52B26B;
          line-height: 25px;
        }
      }
    }
    .submit-btn {
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 5px;
      background: #52B26B;
      line-height: 40px;
      font-size: 13px;
      margin-top: 39px;
    }
  }
</style>
