import { getWxConfig } from '@/api/common.js';

export default {
  init(apiList = []) {
    return new Promise((resolve, reject) => {
      getWxConfig({ url: window.location.href }).then((response) => {
        if (response.appId) {
          wx.config({
            appId: response.appId,
            timestamp: response.timestamp, 
            nonceStr: response.nonceStr, 
            signature: response.signature,
            jsApiList: apiList
          });
          wx.ready(() => {
            resolve(response);
          });
          wx.error((res) => {
            console.log(res);
          });
        } else {
          reject(res);
        }
      });
    });
  }
};
