var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar"},[_vm._m(0),_c('div',{staticClass:"content"},[(_vm.calendarType === 'month')?_c('div',{staticClass:"back-month"},[_vm._v(" "+_vm._s(_vm.currentMonth >= 10 ? _vm.currentMonth : '0' + _vm.currentMonth)+" ")]):_vm._e(),_vm._l((_vm.allArr),function(item,index){return _c('div',{key:index,class:['itemData', { 'week-hidden': _vm.calendarType === 'week' && (index + 1 > _vm.weekStartIndex * 7 || index + 1 < (_vm.weekStartIndex - 1) * 7 + 1) }]},[_c('div',{style:(item.month === 'pre' || item.month === 'next' ? 'display: none;': '')},[_c('p',{staticClass:"icon",style:(item.month === 'current' ? (
            item.isTodaySign === 1 ? 'background: #CCECE9' : (
              item.isTask ? 'background: #fff' : '')): '')},[(item.isTask && item.signStatus === 1 && item.isLast === 0)?_c('img',{staticStyle:{"width":"15px","height":"11.5px"},attrs:{"src":require("../assets/images/calendar/isClock.png")}}):_vm._e(),(
              item.isTask && ((
              item.signStatus === 0 && 
              (
                _vm.currentYear < _vm.nowYear || 
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth < _vm.nowMonth) ||
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && _vm.nowDate > item.day))) || item.actionStatus === 3) &&
              item.isLast === 0)?_c('img',{staticStyle:{"width":"12.5px","height":"12.5px"},attrs:{"src":require("../assets/images/calendar/noClock.png")}}):_vm._e(),(
              item.isTask && 
              item.signStatus === 0 && 
              (
                _vm.currentYear > _vm.nowYear || 
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth > _vm.nowMonth) ||
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && _vm.nowDate <= item.day)) &&
                item.isLast === 0)?_c('img',{staticStyle:{"width":"13px","height":"14px"},attrs:{"src":require("../assets/images/calendar/needClock.png")}}):_vm._e(),(item.isTask && item.isLast === 1)?_c('img',{staticStyle:{"width":"16px","height":"15.5px"},attrs:{"src":require("../assets/images/calendar/gift.png")}}):_vm._e()]),(
            !item.isTask ||
            item.signStatus === 1 ||
            (
                _vm.currentYear > _vm.nowYear || 
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth > _vm.nowMonth) ||
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && _vm.nowDate <= item.day)) ||
            _vm.type === '今日')?_c('p',{class:['date', { 'today': _vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && item.date === _vm.nowDate}]},[_vm._v(_vm._s(_vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && item.date === _vm.nowDate ? '今日' : item.date))]):_vm._e(),(
          item.isTask && ((
            item.signStatus === 0 && 
              (_vm.currentYear < _vm.nowYear || 
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth < _vm.nowMonth) ||
                (_vm.currentYear === _vm.nowYear && _vm.currentMonth === _vm.nowMonth && _vm.nowDate > item.day)) && item.actionStatus === 3)) && _vm.type === '全部')?_c('p',{staticClass:"date close-date",on:{"click":function($event){return _vm.clockPreTask(item.taskId)}}},[_vm._v("补签")]):_vm._e()])])}),_c('div',{staticClass:"pre-month operate",on:{"click":_vm.gotoPreMonth}},[_c('img',{attrs:{"src":require("../assets/images/calendar/arrow-left.png")}})]),_c('div',{staticClass:"next-month operate",on:{"click":_vm.gotoNextMonth}},[_c('img',{attrs:{"src":require("../assets/images/calendar/arrow-right.png")}})])],2),_c('div',{staticClass:"arrow-btn"},[_c('img',{style:(_vm.calendarType === 'month' ? 'transform: rotate(180deg);' : ''),attrs:{"src":require("../assets/images/mine/more.png")},on:{"click":_vm.changeCalendarType}})]),_c('vant-overlay',{attrs:{"show":_vm.showDialog}},[(_vm.showDialog)?_c('exchange-dialog',{attrs:{"num":_vm.recordInfo.cardScore},on:{"close":_vm.closeDialog}}):_vm._e(),_c('vant-icon',{attrs:{"name":"close"},on:{"click":_vm.closeDialog}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w100P showData"},[_c('div',{staticClass:"title"},[_vm._v("日")]),_c('div',{staticClass:"title"},[_vm._v("一")]),_c('div',{staticClass:"title"},[_vm._v("二")]),_c('div',{staticClass:"title"},[_vm._v("三")]),_c('div',{staticClass:"title"},[_vm._v("四")]),_c('div',{staticClass:"title"},[_vm._v("五")]),_c('div',{staticClass:"title"},[_vm._v("六")])])
}]

export { render, staticRenderFns }