<template>
  <div class="selected-orders-container">
    <div class="back">
      <p class="back-icon" @click="back"></p>
      <h3 class="back-name" @click="back">返回</h3>
    </div>
    <div class="selected-orders-content">
      <div class="selected-orders-item">
        <h3 class="item-title">{{ type }}</h3>
        <vant-list
          class="item-list"
          :finished="noMore"
          @load="loadMore"
        >
          <div 
            v-for="(item, index) in circleList" 
            :key="index"
            class="order-list-item"
            :style="index === circleList.length - 1 ? 'border-bottom: none;': ''"
          >
            <selected-circle-item 
              :item="item" 
              :type="type" 
              @change="getOrderList"
              @apply="openTicket"
              @open="openLogistics"
            ></selected-circle-item>
          </div>
          <div v-if="circleList.length === 0" class="none-data">
            <img src="../../../assets/images/remind/none.png">
            <p class="none-tip">暂无订单！</p>
          </div>
        </vant-list>
      </div>
    </div>
    <custom-logistics v-if="showLogistics" :item="operateCircleItem" @close="closeLogistics"></custom-logistics>
    <custom-ticket v-if="showTicket" :item="operateCircleItem" @close="closeTicket"></custom-ticket>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import selectedCircleItem from './selectedCircleItem';
import { getOrderList } from '@/api/mine/myOrders';
import logistics from './logistics.vue';
import ticket from './ticket.vue';
import loading from '@/components/loading.vue';
import { List } from 'vant';

export default {
  props: {
    type: {
      type: String,
      require: true
    }
  },
  components: {
    'selected-circle-item': selectedCircleItem,
    'custom-logistics': logistics,
    'custom-ticket': ticket,
    'custom-loading': loading,
    'vant-list': List
  },
  data() {
    return {
      circleList: [],
      showLogistics: false,
      operateCircleItem: {},
      showTicket: false,
      loading: false,
      noMore: false,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      getOrderList({ 
        orderStatus: this.type === '待付款' ? 0 : (
          this.type === '待发货' ? 1 : (
            this.type === '待收货' ? 2 : (
              this.type === '已完成' ? 3 : ''))),
        page: this.page,
        limit: this.pageSize
      }).then(res => {
        this.loading = false;
        if (res.length < this.pageSize) {
          this.noMore = true;
        }
        if (this.page === 1) {
          this.circleList = [];
        }
        this.circleList = this.circleList.concat(JSON.parse(JSON.stringify(res)));
      }).catch(() => {
        this.loading = false;
      });
    },
    back() {
      this.$emit('close');
    },
    openLogistics(item) {
      this.operateCircleItem = item;
      this.showLogistics = true;
    },
    closeLogistics() {
      this.showLogistics = false;
      this.operateCircleItem = {};
    },
    openTicket(item) {
      this.operateCircleItem = item;
      this.showTicket = true;
    },
    closeTicket() {
      this.showTicket = false;
      this.operateCircleItem = {};
    },
    loadMore() {
      if (this.circleList.length === 0) {
        return;
      }
      if (!this.noMore && !this.loading) {
        this.loading = true;
        this.page += 1;
        this.getOrderList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-orders-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9f9f9;
  .back {
    position: relative;
    height: 44px;
    border-top: 1px solid transparent;
    background: #fff;
    .back-icon {
      width: 10px;
      height: 10px;
      border-right: 2px solid #333;
      border-top: 2px solid #333;
      -webkit-transform: translate(0,-50%) rotate(-135deg);
      transform: translate(0,-50%) rotate(-135deg);
      margin-left: 15px;
      margin-top: 21px;
    }
    .back-name {
      position: absolute;
      top: 0;
      left: 32px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #333;
    }
  }
  .selected-orders-content {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0 15px;
    overflow-y: auto;
    .selected-orders-item {
      .item-title {
        color: #333;
        font-size: 13px;
        line-height: 32px;
      }
      .item-list {
        background: #fff;
        border-radius: 5px;
        border-top: 1px solid transparent;
        box-shadow: 0px 4px 6px 0px rgba(9, 193, 158, 0.08);
        padding: 0 14px;
        .order-list-item {
          border-bottom: 1px solid #E2E2E2;
        }
      }
    }
  }
}
</style>
