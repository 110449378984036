<template>
  <div class="holiday-list-container">
    <div class="holiday-list">
      <!--<p class="title">
        <img src="../../assets/images/remind/time.png">
        节日
      </p>-->
      <ul class="list-content">
        <li
          v-for="(item, index) in holidayList[1]"
          :key="index"
          class="list-item"
        >
          <!--<img :src="item.image">-->
          <div class="holiday-info">
            <p class="info-t">
              <span class="info-name">{{ item.name }}</span>
              剩余<span> {{ item.restDay }} </span>天
            </p>
            <p class="info-b">
              {{ item.date }} ({{ item.lunarDate }})
            </p>
          </div>
          <vant-switch v-model="item.isStart" active-color="#52B26B" inactive-color="#EDEDED" @change="concern(item)"></vant-switch>
        </li>
      </ul>
    </div>
    <!--<div class="holiday-list">
      <p class="title">
        <img src="../../assets/images/remind/time.png">
        节气
      </p>
      <ul class="list-content">
        <li
          v-for="(item, index) in holidayList[2]"
          :key="index"
          class="list-item"
        >
          <img :src="item.image">
          <div class="holiday-info">
            <p class="info-t">
              <span style="font-size: 13px;color: #333;margin-right: auto;font-weight: bold;">{{ item.name }}</span>
              剩余<span style="font-size: 13px;color: #52B26B;">{{ item.restDay }}</span>天
            </p>
            <p class="info-b">
              {{ item.date }} ({{ item.lunarDate }})
            </p>
          </div>
          <vant-switch v-model="item.isStart" active-color="#52B26B" inactive-color="#EDEDED" @change="concern(item)"></vant-switch>
        </li>
      </ul>
    </div>-->
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import { getHolidayList, concernHoliday } from '@/api/remind/index';
import { Switch, Notify } from 'vant';
import loading from '@/components/loading';

export default {
  components: {
    'vant-switch': Switch,
    'custom-loading': loading
  },
  data() {
    return {
      holidayList: [],
      loading: false
    };
  },
  mounted() {
    if (localStorage.getItem('token_deadtime') > new Date().getTime()) {
      this.getHolidayList();
    }
  },
  methods: {
    getHolidayList() {
      this.loading = true;
      getHolidayList().then(res => {
        this.loading = false;
        if (res[1] && res[1].length > 0) {
          res[1].forEach(v => {
            v.isStart = v.isStart === 1 ? true : false;
          });
        } else {
          res[1] = [];
        }
        if (res[2] && res[2].length > 0) {
          res[2].forEach(v => {
            v.isStart = v.isStart === 1 ? true : false;
          });
        } else {
          res[2] = [];
        }
        this.holidayList = res;
      }).catch(() => {
        this.loading = false;
      });
    },
    concern(item) {
      concernHoliday({
        id: item.id,
        status: item.isStart ? 1 : 0
      }).then(() => {
        if (item.isStart) {
          Notify({ type: 'success', message: '开启成功' });
        } else {
          Notify({ type: 'success', message: '取消成功' });
        }
        this.getHolidayList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .holiday-list-container {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow-y: auto;
    .holiday-list {
      background: #FFFFFF;
	  min-height: 100%;
      box-shadow: 0px 6px 3px 0px rgba(0, 162, 146, 0.04);
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px;
      .title {
        font-size: 13px;
        color: #333;
        line-height: 30px;
        img {
          width: 12px;
          height: 12px;
          vertical-align: top;
          margin: 9px 9px 9px 0;
        }
      }
      .list-content {
        .list-item {
          display: flex;
          padding: 9px 0;
          border-bottom: 1px solid #ededed;
          &:last-child {
            border-bottom: none;
          }
          img {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            margin-right: 15px;
          }
          .holiday-info {
            padding: 10px;
            display: flex;
            flex-direction: column;
            .info-t {
              width: 100%;
              font-size: 13px;
              color: #666;
              height: 20px;
              display: flex;
              align-items: baseline;
			  
			  .info-name{
				  font-size:16px;
				  color:#333;
				  margin-right:10px;
				  font-weight:bold;
			  }
			  span{
				  font-size:16px;
				  color:#52B26B;
			  }
            }
            .info-b {
              margin-top: 5px;
              font-size: 12px;
              color: #aaa;
            }
          }
          .van-switch {
            margin-left: auto;
            margin-top: 15px;
            border: none;
          }
        }
      }
    }
  }
</style>
