<template>
  <div class="task-detail-container">
    <!-- <sign-detail :type="'今日'" :calendar-data="calendarData" @change="changeCalendarData" :id="circleId"></sign-detail> -->
    <div class="detail-container">
      <h3 class="task-day">第{{ taskDetail.day }}课 {{ taskDetail.title }}</h3>
      <div class="course-info">
        <p class="course-info-desc">{{ taskDetail.intro }}</p>
        <p class="course-info-intro" v-html="taskDetail.content"></p>
      </div>
    </div>
    <div class="footer-area">
      <div class="operate-btn">
        <div class="btn" @click="home">
          <p class="btn-image">
            <img src="../../../assets/images/find/home.png">
          </p>
          <p class="btn-name">首页</p>
        </div>
        <div class="btn" @click="share">
          <p class="btn-image">
            <img src="../../../assets/images/find/invitation.png">
          </p>
          <p class="btn-name">邀请</p>
        </div>
      </div>
      <div 
        v-html="payBtnHtml"
        :style="payBtnHtml === '已行动' ? 'background: #e9e9e9;' : (payBtnHtml === '打卡' ? '' : 'background: #CCECE9;color: #52B26B;')"
        class="pay-btn"
        @click="action"
      ></div>
    </div>
    <clock-dialog 
      v-if="circleId && taskId && showClockDialog"
      :task-id="taskId"
      :circle-id="circleId"
      @close="closeClock"
    ></clock-dialog>
    <vant-overlay :show="showShare">
      <share-dialog
        v-if="circleId && showShare"
        :id="circleId"
        :status="showShareBtn"
        :type="shareContent"
        :share-img="shareImg"
        @close="closeShare"
        @change="changeShowShareBtn"
      ></share-dialog>
      <vant-icon v-if="!showShareBtn" name="close" @click="closeShare"></vant-icon>
      <share-btn 
        v-if="showShareBtn"
        :id="circleId"
        :status="showShareBtn"
      ></share-btn>
    </vant-overlay>
    <custom-loading :loading="loading"></custom-loading>
  </div>
</template>

<script>
import signDetail from './signDetail';
import { Overlay, Icon, Notify } from 'vant';
import clockDialog from './clockDialog';
import share from '@/components/share';
import shareBtn from '@/components/shareBtn';
import loading from '@/components/loading';
import { getTaskDetail, getCalendarData } from '@/api/find/circleDetail';

export default {
  components: {
    'sign-detail': signDetail,
    'vant-overlay': Overlay,
    'vant-icon': Icon,
    'clock-dialog': clockDialog,
    'share-dialog': share,
    'share-btn': shareBtn,
    'custom-loading': loading
  },
  data() {
    return {
      circleId: 0,
      taskId: 0,
      payBtnHtml: '打卡',
      showClockDialog: false,
      showShare: false,
      showShareBtn: false,
      shareContent: '',
      taskDetail: {},
      calendarData: [],
      loading: false,
      shareImg: ''
    };
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.circleId = Number(this.$route.query.id);
    this.taskId = Number(this.$route.query.taskId);
    this.loading = true;
    // this.getCalendarData();
    this.getTaskDetail();
  },
  methods: {
    getTaskDetail() {
      getTaskDetail({ taskId: this.taskId }).then(res => {
        this.loading = false;
        this.taskDetail = res;
        this.solvePayBtnHtml();
        if (this.taskDetail.countdown > 0) {
          this.timer = setInterval(() => {
            this.solvePayBtnHtml();
          }, 1000);
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getCalendarData(date) {
      getCalendarData({ 
        circleId : this.circleId,
        month: date
      }).then(res => {
        this.calendarData = JSON.parse(JSON.stringify(res));
      });
    },
    // 处理底部按钮文字
    solvePayBtnHtml() {
      if (this.taskDetail.signStatus === 1) {
        this.payBtnHtml = '已行动';
      } else {
        if (this.taskDetail.countdown === 0) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.payBtnHtml = '打卡';
        } else {
          this.task();
        }
      }
    },
    task(){
      let h = parseInt((this.taskDetail.countdown%(3600*24)/3600)),//对天数求余数剩下的是秒数是小时对应的秒数
        m = parseInt(this.taskDetail.countdown%3600/60),//分
        s = this.taskDetail.countdown%60;//秒
      if(h < 10) h = '0' + h;
      if(m < 10) m = '0' + m;
      if(s < 10) s = '0' + s;
      this.payBtnHtml =`${h}:${m}:${s} 后可打卡`;
      this.taskDetail.countdown -= 1;
    },
    home() {
      this.$router.replace({name: 'Find'});
    },
    share() {
      this.showShare = true;
      this.shareContent = '邀请';
    },
    action() {
      if (this.payBtnHtml === '打卡') {
        this.showClockDialog = true;
      }
    },
    closeClock(status) {
      if (status) {
        this.shareContent = '';
        this.showShare = true;
        this.getCalendarData();
        this.getTaskDetail();
      }
      this.showClockDialog = false;
    },
    closeShare() {
      this.showShare = false;
      this.showShareBtn = false;
      this.$store.commit('changePageBack', 'taskDetail');
      this.$router.go(-1);
    },
    changeShowShareBtn(obj) {
      this.showShareBtn = obj.status;
      this.shareImg = obj.img;
    },
    changeCalendarData(date) {
      this.getCalendarData(date);
    }
  }
};
</script>

<style lang="scss" scoped>
  .task-detail-container {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-bottom: 74px;
    padding-top: 10px;
    .detail-container {
      margin: 0 15px;
      width: 345px;
      padding: 10px 12px 18px;
      background: #FFFFFF;
      box-shadow: 0px 5px 3.5px 0px rgba(0, 162, 146, 0.06);
      border-radius: 5px;
      .task-day {
        line-height: 35px;
        font-size: 14px;
        color: #333;
      }
      .course-info {
        .course-info-desc {
          font-size: 11px;
          color: #666;
          line-height: 16px;
          margin: 9px 0;
        }
        ::v-deep .course-info-intro {
          width: 100%;
          border-radius: 5px;
          p {
            width: 100%;
            video {
              width: 100%!important;
              height: 200px!important;
            }
            audio {
              width: 100%;
              height: 40px;
            }
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
    .footer-area {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: 49px;
      display: flex;
      background: #fff;
      align-items: center;
      padding: 0 17.5px 0 0;
      box-shadow: -1px -6px 6px 0px rgba(0, 162, 146, 0.05);
      .operate-btn {
        display: flex;
        width: calc(100% - 217.5px);
        height: 100%;
        justify-content: space-evenly;
        .btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 5.5px 0 3.5px;
          .btn-image {
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
              vertical-align: top;
            }
          }
          .btn-name {
            color: #333333;
            font-size: 10px;
          }
        }
      }
      .pay-btn {
        width: 217.5px;
        height: 40px;
        line-height: 40px;
        align-items: center;
        text-align: center;
        font-size: 13px;
        color: #fff;
        font-weight: bold;
        background: #52B26B;
        border-radius: 5px;
        margin: 4.5px 0 4.5px auto;
      }
    }
  }
  .van-icon-close {
    position: absolute;
    color: #fff;
    left: 50%;
    bottom: 8%;
    transform: translateX(-50%);
    font-size: 28px;
  }
</style>
