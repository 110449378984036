<template>
  <div class="back">
    <p class="back-icon" @click="back"></p>
    <h3 class="back-name" @click="back">返回</h3>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.back {
  position: relative;
  height: 44px;
  border-top: 1px solid transparent;
  background: #fff;
  .back-icon {
    width: 10px;
    height: 10px;
    border-right: 2px solid #333;
    border-top: 2px solid #333;
    -webkit-transform: translate(0,-50%) rotate(-135deg);
    transform: translate(0,-50%) rotate(-135deg);
    margin-left: 15px;
    margin-top: 21px;
  }
  .back-name {
    position: absolute;
    top: 0;
    left: 32px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #333;
  }
}
</style>
